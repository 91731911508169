import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/label.module.scss'
import TextArea from 'components/Form/TextArea'
import LightLabel from 'components/Label/LightLabel'

type Props = {
  labelName: string
  description: string
  line: number
  maxLength: number
  rows: number
  size: string
  value: string
  placeholder: string
  errorText?: string | null
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onInput?: () => void
}

const TextAreaWithLightLabel: FC<Props> = (props) => {
  const {
    labelName,
    description,
    line,
    maxLength,
    rows,
    size,
    value,
    placeholder,
    errorText,
    onChange,
  } = props

  return (
    <div>
      <LightLabel name={labelName} description={description} line={line} />
      <div className={styles.lightLabelInputContainer}>
        <TextArea
          maxLength={maxLength}
          rows={rows}
          size={size}
          value={value}
          placeholder={placeholder}
          errorText={errorText}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
      </div>
    </div>
  )
}

export default TextAreaWithLightLabel
