import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import MyPageTemplate from 'components/Templates/MyPageTemplate'

const MyPage: FC = () => {
  return (
    <Layout title={'マイページ'} page="MyPage">
      <MyPageTemplate />
    </Layout>
  )
}

export default MyPage
