import { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from 'styles/components/pagehedding.module.scss'
import pageHeddingArrow from 'images/PageHedding/page-hedding-arrow.png'

type Props = {
  title: string
  to: string
}

const PageHedding: FC<Props> = (props) => {
  const { title, to } = props

  return (
    <div className={styles.container}>
      {to ? (
        <Link className={styles.pageHeddingLink} to={to}>
          <img
            className={styles.pageHeddingArrow}
            src={pageHeddingArrow}
            alt="戻る"
          ></img>
        </Link>
      ) : (
        ''
      )}
      <p className={styles.pageHeddingText}>{title}</p>
    </div>
  )
}

export default PageHedding
