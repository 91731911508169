import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  getDocs,
  DocumentReference,
  Timestamp,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  where,
  orderBy,
} from 'firebase/firestore'
import { ProductInformation } from 'components/Form/AddForm/ProductInformation'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'

interface DoughState {
  dough: Dough | null
  doughs: Dough[]
}

interface Dough {
  id: string
  pettern_name: string
  material_informations: MaterialInformation[]
  process: string
  memo: string
  is_disabled: boolean
  is_percent: boolean
  is_gram: boolean
  shop_ref: DocumentReference
  created_at: Timestamp | null
}

interface MaterialInformation {
  productName: string | null
  manufacturerName: string | null
  middle_knead_percent: number
  authentic_knead_percent: number
  material_ref: DocumentReference | null
  dough_ref: DocumentReference | null
  filling_ref: DocumentReference | null
  recipe_ref: DocumentReference | null
}

export type DoughType = Dough

export type MaterialInformationType = MaterialInformation

const initialState = {
  dough: null,
  doughs: [],
} as DoughState

export const getDoughs = createAsyncThunk<
  Dough[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('dough/getDoughs', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const doughs: Dough[] = []
  const q = query(
    collection(db, 'doughs'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    orderBy('pettern_name'),
  )
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const dough = doc.data()
      doughs.push({
        id: doc.id,
        pettern_name: dough.pettern_name,
        material_informations: dough.material_informations,
        process: dough.process,
        memo: dough.memo,
        is_disabled: dough.is_disabled,
        is_percent: dough.is_percent,
        is_gram: dough.is_gram,
        shop_ref: dough.shop_ref,
        created_at: dough.created_at,
      })
    }),
  )
  return doughs
})

export const getAvailableDoughs = createAsyncThunk<
  Dough[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('dough/getAvailableDoughs', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const doughs: Dough[] = []
  const q = query(
    collection(db, 'doughs'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    where('is_disabled', '==', false),
    orderBy('pettern_name'),
  )
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const dough = doc.data()
      doughs.push({
        id: doc.id,
        pettern_name: dough.pettern_name,
        material_informations: dough.material_informations,
        process: dough.process,
        memo: dough.memo,
        is_disabled: dough.is_disabled,
        is_percent: dough.is_percent,
        is_gram: dough.is_gram,
        shop_ref: dough.shop_ref,
        created_at: dough.created_at,
      })
    }),
  )
  return doughs
})

export const getDoughById = createAsyncThunk<
  Dough | null,
  { id: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('dough/getDoughById', async ({ id }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const q = doc(db, 'doughs', id)
  const querySnapshot = await getDoc(q)
  const dough = querySnapshot.data()
  if (!dough) {
    return null
  }
  const doughShopId = dough.shop_ref.id
    ? dough.shop_ref.id
    : // @ts-ignore
      dough.shop_ref._key.path.segments[6]
  // 所属しているお店の商品情報しか取得できない
  if (doughShopId !== staff.shopId) {
    return null
  }
  const response: Dough = {
    id: querySnapshot.id,
    pettern_name: dough.pettern_name,
    material_informations: dough.material_informations,
    process: dough.process,
    memo: dough.memo,
    is_disabled: dough.is_disabled,
    is_percent: dough.is_percent,
    is_gram: dough.is_gram,
    shop_ref: dough.shop_ref,
    created_at: dough.created_at,
  }
  return response
})

// 管理者が使用する
export const getAllDoughs = createAsyncThunk<
  Dough[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('dough/getDoughsByMaterialId', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.isAdmin) {
    return null
  }
  const doughs: Dough[] = []
  const q = query(collection(db, 'doughs'))
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const dough = doc.data()
      doughs.push({
        id: doc.id,
        pettern_name: dough.pettern_name,
        material_informations: dough.material_informations,
        process: dough.process,
        memo: dough.memo,
        is_disabled: dough.is_disabled,
        is_percent: dough.is_percent,
        is_gram: dough.is_gram,
        shop_ref: dough.shop_ref,
        created_at: dough.created_at,
      })
    }),
  )
  return doughs
})

export const createDough = createAsyncThunk<
  string | null,
  {
    pettern_name: string
    material_informations: ProductInformation[] | null
    process: string
    memo: string
    is_disabled: boolean
    is_percent: boolean
    is_gram: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'dough/createDough',
  async (
    {
      pettern_name,
      material_informations,
      process,
      memo,
      is_disabled,
      is_percent,
      is_gram,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return null
    }
    const materialInformations: MaterialInformation[] = []
    if (material_informations) {
      await Promise.all(
        material_informations.map(async (info) => {
          materialInformations.push({
            productName:
              !info.materialId &&
              !info.doughId &&
              !info.fillingId &&
              !info.recipeId
                ? info.productName
                : null,
            manufacturerName:
              !info.materialId &&
              !info.doughId &&
              !info.fillingId &&
              !info.recipeId
                ? info.manufacturerName
                : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const doughRef = collection(db, 'doughs')
    const data = {
      pettern_name: pettern_name,
      material_informations: materialInformations,
      process: process,
      memo: memo,
      is_disabled: is_disabled,
      is_percent: is_percent,
      is_gram: is_gram,
      shop_ref: doc(db, 'shops', staff.shopId),
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    }
    const docRef = await addDoc(doughRef, data)
    return docRef.id
  },
)

export const updateDough = createAsyncThunk<
  string | null,
  {
    id: string
    pettern_name: string
    material_informations: ProductInformation[] | null
    process: string
    memo: string
    is_disabled: boolean
    is_percent: boolean
    is_gram: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'dough/updateDough',
  async (
    {
      id,
      pettern_name,
      material_informations,
      process,
      memo,
      is_disabled,
      is_percent,
      is_gram,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return null
    }
    const doughRef = doc(db, 'doughs', id)
    const querySnapshot = await getDoc(doughRef)
    const dough = querySnapshot.data()
    if (!dough) {
      return null
    }
    const doughShopId = dough.shop_ref.id
      ? dough.shop_ref.id
      : // @ts-ignore
        dough.shop_ref._key.path.segments[6]
    // 所属しているお店の商品情報しか更新できない
    if (doughShopId !== staff.shopId) {
      return null
    }
    const materialInformations: MaterialInformation[] = []
    if (material_informations) {
      await Promise.all(
        material_informations.map(async (info) => {
          materialInformations.push({
            productName:
              !info.materialId &&
              !info.doughId &&
              !info.fillingId &&
              !info.recipeId
                ? info.productName
                : null,
            manufacturerName:
              !info.materialId &&
              !info.doughId &&
              !info.fillingId &&
              !info.recipeId
                ? info.manufacturerName
                : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const data = {
      pettern_name: pettern_name,
      material_informations: materialInformations,
      process: process,
      memo: memo,
      is_disabled: is_disabled,
      is_percent: is_percent,
      is_gram: is_gram,
      updated_at: serverTimestamp(),
    }
    await updateDoc(doughRef, data)
    return id
  },
)

export const doughSlice: Slice<
  DoughState,
  { clearDough(state: DoughState): void },
  'dough'
> = createSlice({
  name: 'dough',
  initialState,
  reducers: {
    clearDough(state) {
      state.dough = null
    },
  },
  extraReducers: {
    [getDoughs.fulfilled.type]: (
      state,
      action: PayloadAction<Dough[] | null>,
    ) => {
      if (action.payload !== null) {
        state.doughs = action.payload
      }
    },
    [getDoughById.fulfilled.type]: (
      state,
      action: PayloadAction<Dough | null>,
    ) => {
      if (action.payload !== null) {
        state.dough = action.payload
      }
    },
  },
})

export const { clearDough } = doughSlice.actions
export default doughSlice.reducer
