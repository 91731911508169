import { FC } from 'react'
import styles from 'styles/components/label.module.scss'
import RadioButton from 'components/Form/RadioButton'
import LightLabel from 'components/Label/LightLabel'

type Props = {
  labelName: string
  description: string
  line: number
  radioSize?: string
  checkInfos: checkInfo[]
  isChecked: boolean
  onChange?: () => void
  required?: boolean
}

export type checkInfo = {
  isChecked: boolean
  label: string
  onChange?: () => void
}

const RadioButtonWithLightLabel: FC<Props> = (props) => {
  const { labelName, description, line, radioSize, checkInfos, required } =
    props

  const sizeStyle = (() => {
    switch (radioSize) {
      case 'large':
        return styles.radio_large
      case 'middle':
        return styles.radio_middle
      case 'small':
        return styles.radio_small
      default:
        return styles.radio_middle
    }
  })()

  const className = `${sizeStyle}`

  return (
    <div>
      <LightLabel
        required={required}
        name={labelName}
        description={description}
        line={line}
      />
      <div className={styles.lightLabelInputContainer}>
        {checkInfos.map((checkInfo) => (
          <div className={className} key={checkInfo.label}>
            <RadioButton
              isChecked={checkInfo.isChecked}
              label={checkInfo.label}
              size={radioSize}
              onChange={() =>
                checkInfo.onChange ? checkInfo.onChange() : null
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default RadioButtonWithLightLabel
