// import { createStore as reduxCreateStore, combineReducers, applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { History } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { reducers } from './reducers'

// createStoreの再定義 - historyを引数で受け、connected-react-routerの利用を抽象化
export default function createStore(history: History) {
  // historyとredux-thunkをミドルウェアに追加
  const middleWares = [routerMiddleware(history), thunk]

  // 開発環境の場合、redux-loggerを設定
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    const logger = createLogger({
      collapsed: true, // 折り畳まれた状態で表示
      // diff: true, // 変更前後の差分を表示
    })
    middleWares.push(logger)
  }

  // 別名importしたcreateStoreメソッドにReducersとミドルウェアを渡す
  return configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      ...middleWares,
    ],
    devTools: true,
  })
}
