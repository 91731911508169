import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import ShopInfoTemplate from 'components/Templates/ShopInfoTemplate'

const EditShop: FC = () => {
  return (
    <Layout title={'店舗入力'} page="ShopEdit">
      <ShopInfoTemplate isRegister={false} />
    </Layout>
  )
}

export default EditShop
