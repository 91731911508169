import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onAuthStateChanged } from 'firebase/auth'
import { setAuth } from 'reducks/auth/slice'
import { getStaff } from 'reducks/staff/slice'
import { getCategories } from 'reducks/category/slice'
import { getAdditiveUses } from 'reducks/additiveUse/slice'
import { auth } from 'fb/index'

const DataLoad: FC = () => {
  const dispatch = useDispatch<any>()
  useEffect(() => {
    const authStateChanged = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await dispatch(setAuth({ uid: user.uid }))
        await dispatch(getStaff({ uid: user.uid }))
        await dispatch(getCategories())
        await dispatch(getAdditiveUses())
      }
    })
    return () => {
      authStateChanged()
    }
  }, [])
  return <></>
}

export default DataLoad
