import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/label.module.scss'
import InputText from 'components/Form/InputText'
import InputLabel from 'components/Label/InputLabel'

type Props = {
  required: boolean
  labelName: string
  description: string
  line: number
  type: string
  size: string
  value: string | number
  placeholder: string
  unit: string
  unitDescription?: string
  value2: string | number
  placeholder2: string
  unit2: string
  unitDescription2?: string
  readOnly2?: boolean
  errorText?: string | null
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onInput?: () => void
}

const InputTextAndUnitDoubleWithLabel: FC<Props> = (props) => {
  const {
    required,
    labelName,
    description,
    line,
    type,
    size,
    value,
    placeholder,
    unit,
    unitDescription,
    value2,
    placeholder2,
    unit2,
    unitDescription2,
    readOnly2,
    errorText,
    onChange,
    onInput,
  } = props

  return (
    <div className={styles.labelFormContainer}>
      <InputLabel
        required={required}
        name={labelName}
        description={description}
        line={line}
      />
      <div>
        <InputText
          required={required}
          type={type}
          size={size}
          value={value}
          placeholder={placeholder}
          rightAligned={true}
          errorText={errorText}
          onChange={(e) => (onChange ? onChange(e) : null)}
          onInput={onInput}
        />
      </div>
      <div className={styles.inputAndUnitTextContainer}>
        {unit}
        <br></br>
        {unitDescription}
      </div>
      <div className={styles.inputAndUnitTextContainer}>
        <InputText
          required={required}
          type={type}
          size={size}
          value={value2}
          placeholder={placeholder2}
          readOnly={readOnly2}
          rightAligned={true}
          onChange={(e) => (onChange ? onChange(e) : null)}
          onInput={onInput}
        />
      </div>
      <div className={styles.inputAndUnitTextContainer}>
        {unit2}
        <br></br>
        {unitDescription2}
      </div>
    </div>
  )
}

export default InputTextAndUnitDoubleWithLabel
