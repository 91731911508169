import { FC } from 'react'
import styles from 'styles/components/tab.module.scss'
// import { useDispatch } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
// import {
//   getBreadById,
// } from 'reducks/bread/slice'

type Props = {
  name: string
  to: string
  selected: boolean
  breadId?: string | null
  handleSubmit?: () => Promise<void>
}

const Tab: FC<Props> = (props) => {
  const { name, selected, handleSubmit } = props
  // const dispatch = useDispatch<any>()
  // const navigate = useNavigate()

  const tabStyle = (() => {
    return selected ? styles.tab_selected : styles.tab
  })()

  const className = `${tabStyle}`

  // const toPage = async () => {
  //   if (handleSubmit) {
  //     await handleSubmit()
  //   }
  //   if (breadId) {
  //     await dispatch(getBreadById({ id: breadId }))
  //   }
  //   navigate(to)
  // }

  return (
    <>
      {/* {to ? (
        // <Link className={className} to={to} onClick={handleSubmit}>
        //   <span>{name}</span>
        // </Link>
        <span className={className} onClick={toPage}>{name}</span>
      ) : (
        <span className={className}>{name}</span>
      )} */}
      {handleSubmit ? (
        <span className={className} onClick={handleSubmit}>
          {name}
        </span>
      ) : (
        <span className={className}>{name}</span>
      )}
    </>
  )
}

export default Tab
