import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import BreadAddRecipeTemplate from 'components/Templates/BreadAddRecipeTemplate'

const BreadAddRecipe: FC = () => {
  return (
    <Layout title={'レシピ登録'} page="BreadAddRecipe">
      <BreadAddRecipeTemplate />
    </Layout>
  )
}

export default BreadAddRecipe
