import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  getDocs,
  DocumentReference,
  Timestamp,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  orderBy,
} from 'firebase/firestore'

interface MaterialState {
  material: Material | null
  materials: Material[]
}

interface Material {
  id: string
  material_category_ref: DocumentReference
  material_sub_category_ref: DocumentReference
  manufacturer: string
  product_name: string
  product_name_kana: string
  notation_fluctuation: string[]
  origin_name: string
  single_material: boolean
  composite_material: boolean
  no_material: boolean
  material_name: string
  raw_material_name: string
  composite_material_name: string
  genetically_no_selection: boolean
  genetically_modified: boolean
  genetically_no_difference: boolean
  constitute_material: ConstituteMaterial[]
  base_allergens: string[]
  internal_capacity: InternalCapacity[] // TODO: 未使用となったため削除
  additive: Additive[]
  additive_allergens: string[]
  energy: number
  protein: number
  lipid: number
  carbohydrate: number
  salt_equivalent: number
  source_information: string // standard(規格書), web, eight_orders(日本食品標準成分表2020年版 八訂), package
  urls: string[]
  classified_name: string
  author: string
  memo: string
  created_at: Timestamp | null
  updated_at: Timestamp | null
}

interface ConstituteMaterial {
  material_name: string
  genetically_no_selection: boolean
  genetically_modified: boolean
  genetically_no_difference: boolean
}

interface Additive {
  additive_use_ref: DocumentReference | null
  additive_material: string | null
  disabled_material_name: boolean
}

interface AdditiveInput {
  additive_use_id: string | null
  additive_material: string | null
  disabled_material_name: boolean
}

// TODO: 未使用となったため削除
interface InternalCapacity {
  internal_capacity_count: number | null
  internal_capacity_unit: string
  number_item: number | null
  number_item_unit: string | null
  jancode: string | null
  origin_name: string | null
}

export type MaterialType = Material

export type ConstituteMaterialType = ConstituteMaterial

export type AdditiveType = Additive

export type AdditiveInputType = AdditiveInput

export type InternalCapacityType = InternalCapacity

export enum Allergens {
  EGG = 'egg',
  MILK = 'milk',
  WHEAT = 'wheat',
  SHRIMP = 'shrimp',
  CRUB = 'crub',
  PEANUTS = 'peanuts',
  SOBA = 'soba',
}

const initialState = {
  material: null,
  materials: [],
} as MaterialState

export const getMaterials = createAsyncThunk<Material[] | null, void>(
  'material/getMaterials',
  async (_) => {
    const materials: Material[] = []
    const q = query(collection(db, 'materials'), orderBy('product_name'))
    const querySnapshot = await getDocs(q)
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const material = doc.data()
        materials.push({
          id: doc.id,
          material_category_ref: material.material_category_ref,
          material_sub_category_ref: material.material_sub_category_ref,
          manufacturer: material.manufacturer,
          product_name: material.product_name,
          product_name_kana: material.product_name_kana,
          notation_fluctuation: material.notation_fluctuation,
          origin_name: material.origin_name,
          single_material: material.single_material,
          composite_material: material.composite_material,
          no_material: material.no_material,
          material_name: material.material_name,
          raw_material_name: material.raw_material_name,
          composite_material_name: material.composite_material_name,
          genetically_no_selection: material.genetically_no_selection,
          genetically_modified: material.genetically_modified,
          genetically_no_difference: material.genetically_no_difference,
          constitute_material: material.constitute_material,
          base_allergens: material.base_allergens,
          internal_capacity: material.internal_capacity,
          additive: material.additive,
          additive_allergens: material.additive_allergens,
          energy: material.energy,
          protein: material.protein,
          lipid: material.lipid,
          carbohydrate: material.carbohydrate,
          salt_equivalent: material.salt_equivalent,
          source_information: material.source_information,
          urls: material.urls,
          classified_name: material.classified_name,
          author: material.author,
          memo: material.memo,
          created_at: material.created_at,
          updated_at: material.updated_at,
        })
      }),
    )
    return materials
  },
)

export const getMaterialById = createAsyncThunk<
  Material | null,
  { id: string }
>('material/getMaterialById', async ({ id }) => {
  const q = doc(db, 'materials', id)
  const querySnapshot = await getDoc(q)
  const material = querySnapshot.data()
  if (!material) {
    return null
  }
  const response: Material = {
    id: querySnapshot.id,
    material_category_ref: material.material_category_ref,
    material_sub_category_ref: material.material_sub_category_ref,
    manufacturer: material.manufacturer,
    product_name: material.product_name,
    product_name_kana: material.product_name_kana,
    notation_fluctuation: material.notation_fluctuation,
    origin_name: material.origin_name,
    single_material: material.single_material,
    composite_material: material.composite_material,
    no_material: material.no_material,
    material_name: material.material_name,
    raw_material_name: material.raw_material_name,
    composite_material_name: material.composite_material_name,
    genetically_no_selection: material.genetically_no_selection,
    genetically_modified: material.genetically_modified,
    genetically_no_difference: material.genetically_no_difference,
    constitute_material: material.constitute_material,
    base_allergens: material.base_allergens,
    internal_capacity: material.internal_capacity,
    additive: material.additive,
    additive_allergens: material.additive_allergens,
    energy: material.energy,
    protein: material.protein,
    lipid: material.lipid,
    carbohydrate: material.carbohydrate,
    salt_equivalent: material.salt_equivalent,
    source_information: material.source_information,
    urls: material.urls,
    classified_name: material.classified_name,
    author: material.author,
    memo: material.memo,
    created_at: material.created_at,
    updated_at: material.updated_at,
  }
  return response
})

export const createMaterial = createAsyncThunk<
  string | null,
  {
    category: string
    sub_category: string
    manufacturer: string
    product_name: string
    product_name_kana: string
    notation_fluctuation: string[]
    origin_name: string
    single_material: boolean
    composite_material: boolean
    no_material: boolean
    material_name: string | null
    raw_material_name: string | null
    composite_material_name: string | null
    genetically_no_selection: boolean | null
    genetically_modified: boolean | null
    genetically_no_difference: boolean | null
    constitute_material: ConstituteMaterial[] | null
    base_allergens: string[]
    internal_capacity: InternalCapacity[] | null
    additive_inputs: AdditiveInput[] | null
    additive_allergens: string[]
    energy: number
    protein: number
    lipid: number
    carbohydrate: number
    salt_equivalent: number
    source_information: string
    urls: string[]
    classified_name: string
    author: string
    memo: string
  }
>(
  'material/createMaterial',
  async ({
    category,
    sub_category,
    manufacturer,
    product_name,
    product_name_kana,
    notation_fluctuation,
    origin_name,
    single_material,
    composite_material,
    no_material,
    material_name,
    raw_material_name,
    composite_material_name,
    genetically_no_selection,
    genetically_modified,
    genetically_no_difference,
    constitute_material,
    base_allergens,
    internal_capacity,
    additive_inputs,
    additive_allergens,
    energy,
    protein,
    lipid,
    carbohydrate,
    salt_equivalent,
    source_information,
    urls,
    classified_name,
    author,
    memo,
  }) => {
    const additive: Additive[] = []
    if (additive_inputs) {
      await Promise.all(
        additive_inputs.map(async (additive_input) => {
          if (
            additive_input.additive_use_id ||
            additive_input.additive_material
          ) {
            additive.push({
              additive_use_ref: additive_input.additive_use_id
                ? doc(db, 'additive_uses', additive_input.additive_use_id)
                : null,
              additive_material: additive_input.additive_material
                ? additive_input.additive_material
                : null,
              disabled_material_name: additive_input.disabled_material_name,
            })
          }
        }),
      )
    }
    const materialRef = collection(db, 'materials')
    const data = {
      material_category_ref: doc(db, 'material_categories', category),
      material_sub_category_ref: doc(
        db,
        'material_sub_categories',
        sub_category,
      ),
      manufacturer: manufacturer,
      product_name: product_name,
      product_name_kana: product_name_kana,
      notation_fluctuation: notation_fluctuation,
      origin_name: origin_name,
      single_material: single_material,
      composite_material: composite_material,
      no_material: no_material,
      raw_material_name: raw_material_name,
      material_name: material_name,
      composite_material_name: composite_material_name,
      genetically_no_selection: genetically_no_selection,
      genetically_modified: genetically_modified,
      genetically_no_difference: genetically_no_difference,
      constitute_material: constitute_material,
      base_allergens: base_allergens,
      internal_capacity: internal_capacity,
      additive: additive,
      additive_allergens: additive_allergens,
      energy: energy,
      protein: protein,
      lipid: lipid,
      carbohydrate: carbohydrate,
      salt_equivalent: salt_equivalent,
      source_information: source_information,
      urls: urls,
      classified_name: classified_name,
      author: author,
      memo: memo,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    }
    const docRef = await addDoc(materialRef, data)
    return docRef.id
  },
)

export const updateMaterial = createAsyncThunk<
  string | null,
  {
    id: string
    category: string
    sub_category: string
    manufacturer: string
    product_name: string
    product_name_kana: string
    notation_fluctuation: string[]
    origin_name: string
    single_material: boolean
    composite_material: boolean
    no_material: boolean
    material_name: string | null
    raw_material_name: string | null
    composite_material_name: string | null
    genetically_no_selection: boolean | null
    genetically_modified: boolean | null
    genetically_no_difference: boolean | null
    constitute_material: ConstituteMaterial[] | null
    base_allergens: string[]
    internal_capacity: InternalCapacity[] | null
    additive_inputs: AdditiveInput[] | null
    additive_allergens: string[]
    energy: number
    protein: number
    lipid: number
    carbohydrate: number
    salt_equivalent: number
    source_information: string
    urls: string[]
    classified_name: string
    author: string
    memo: string
  }
>(
  'material/updateMaterial',
  async ({
    id,
    category,
    sub_category,
    manufacturer,
    product_name,
    product_name_kana,
    notation_fluctuation,
    origin_name,
    single_material,
    composite_material,
    no_material,
    material_name,
    raw_material_name,
    composite_material_name,
    genetically_no_selection,
    genetically_modified,
    genetically_no_difference,
    constitute_material,
    base_allergens,
    internal_capacity,
    additive_inputs,
    additive_allergens,
    energy,
    protein,
    lipid,
    carbohydrate,
    salt_equivalent,
    source_information,
    urls,
    classified_name,
    author,
    memo,
  }) => {
    const additive: Additive[] = []
    if (additive_inputs) {
      await Promise.all(
        additive_inputs.map(async (additive_input) => {
          additive.push({
            additive_use_ref: additive_input.additive_use_id
              ? doc(db, 'additive_uses', additive_input.additive_use_id)
              : null,
            additive_material: additive_input.additive_material
              ? additive_input.additive_material
              : null,
            disabled_material_name: additive_input.disabled_material_name,
          })
        }),
      )
    }
    const materialRef = doc(db, 'materials', id)
    const data = {
      material_category_ref: doc(db, 'material_categories', category),
      material_sub_category_ref: doc(
        db,
        'material_sub_categories',
        sub_category,
      ),
      manufacturer: manufacturer,
      product_name: product_name,
      product_name_kana: product_name_kana,
      notation_fluctuation: notation_fluctuation,
      origin_name: origin_name,
      single_material: single_material,
      composite_material: composite_material,
      no_material: no_material,
      material_name: material_name,
      raw_material_name: raw_material_name,
      composite_material_name: composite_material_name,
      genetically_no_selection: genetically_no_selection,
      genetically_modified: genetically_modified,
      genetically_no_difference: genetically_no_difference,
      constitute_material: constitute_material,
      base_allergens: base_allergens,
      internal_capacity: internal_capacity,
      additive: additive,
      additive_allergens: additive_allergens,
      energy: energy,
      protein: protein,
      lipid: lipid,
      carbohydrate: carbohydrate,
      salt_equivalent: salt_equivalent,
      source_information: source_information,
      urls: urls,
      classified_name: classified_name,
      author: author,
      memo: memo,
      updated_at: serverTimestamp(),
    }
    await updateDoc(materialRef, data)
    return id
  },
)

export const deleteMaterial = createAsyncThunk<void, { id: string }>(
  'material/deleteMaterial',
  async ({ id }) => {
    await deleteDoc(doc(db, 'materials', id))
  },
)

export const materialSlice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    clearMaterial(state) {
      state.material = null
    },
  },
  extraReducers: {
    [getMaterials.fulfilled.type]: (
      state,
      action: PayloadAction<Material[] | null>,
    ) => {
      if (action.payload !== null) {
        state.materials = action.payload
      }
    },
    [getMaterialById.fulfilled.type]: (
      state,
      action: PayloadAction<Material | null>,
    ) => {
      if (action.payload !== null) {
        state.material = action.payload
      }
    },
  },
})

export const { clearMaterial } = materialSlice.actions
export default materialSlice.reducer
