import { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import SubmitButton from 'components/Button/SubmitButton'
import Table, { TableBody } from 'components/Table/Table'
import {
  clearCostSimulation,
  getCostSimulationById,
  getCostSimulations,
  CostSimulationType,
  deleteCostSimulation,
} from 'reducks/costSimulation/slice'
import { RootState } from 'reducks/reducers'
import deleteConfirmImage from 'images/delete-confirm.png'

const CostSimulationTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const [costSimulationList, setCostSimulationList] = useState<TableBody[][]>(
    [],
  )
  const [searchWord, setSearchWord] = useState<string>('')
  const [isPreview, setIsPreview] = useState(false)
  const [deleteId, setDeleteId] = useState<string | null>(null)

  const handleSearchWord = (searchWord: string) => {
    setSearchWord(searchWord)
    filter({ inputSearchWord: searchWord })
  }

  const { costSimulation, costSimulations } = useSelector(
    (state: RootState) => state.costSimulationSlice,
  )
  const { staff, adminShopId } = useSelector(
    (state: RootState) => state.staffSlice,
  )
  const dispatchGetmaterials = useCallback(async () => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    // 使用できないプラン or スーパー管理者じゃなければ使用できない
    if (
      (staff?.isAdmin === true && adminShopId) ||
      (staff?.isAdmin === false && staff.isStandardPlan === true)
    ) {
      //
    } else {
      navigate('/initload')
      window.scrollTo(0, 0)
      return
    }
    const costSimulations = await dispatch(getCostSimulations())
    createCostSimulationList(costSimulations.payload)
  }, [dispatch])
  const createCostSimulationList = useCallback(
    async (costSimulations: CostSimulationType[]) => {
      const costSimulationList: TableBody[][] = []
      costSimulations.forEach((costSimulation) => {
        costSimulationList.push([
          {
            category: 'long_name',
            active: true,
            elementInfo: {
              text: {
                label: costSimulation.name,
              },
            },
          },
          {
            category: 'price',
            active: true,
            elementInfo: {
              text: {
                label: String(costSimulation.selling_price) + '円',
              },
            },
          },
          {
            category: 'price',
            active: true,
            elementInfo: {
              text: {
                label:
                  String(
                    costSimulation.material_cost +
                      costSimulation.packaging_cost,
                  ) + '円',
              },
            },
          },
          {
            category: 'price',
            active: true,
            elementInfo: {
              text: {
                label: String(costSimulation.material_cost) + '円',
              },
            },
          },
          {
            category: 'price',
            active: true,
            elementInfo: {
              text: {
                label: String(costSimulation.packaging_cost) + '円',
              },
            },
          },
          {
            category: 'price',
            active: true,
            elementInfo: {
              text: {
                label: String(costSimulation.cost_rate) + '%',
              },
            },
          },
          {
            category: 'icon',
            active: true,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size: 'icon',
                icon: 'edit_gray',
                to: '/simulation/cost/edit/' + costSimulation.id,
              },
            },
          },
          {
            category: 'icon',
            active: true,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size: 'icon',
                icon: 'copy_gray',
                to: '/simulation/cost/copy/' + costSimulation.id,
              },
            },
          },
          {
            category: 'icon',
            active: true,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size: 'icon',
                icon: 'delete_gray',
                deleteId: costSimulation.id,
              },
            },
          },
        ])
      })
      setCostSimulationList(costSimulationList)
    },
    [costSimulations],
  )

  useEffect(() => {
    dispatchGetmaterials()
  }, [])

  const filter = (payload: { inputSearchWord: string }) => {
    let newCostSimulations = [...costSimulations]
    let newSearchWord = payload.inputSearchWord
    if (newSearchWord) {
      newCostSimulations = newCostSimulations.filter(
        (costSimulation) =>
          costSimulation.name
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWord.replace(/\s+/g, '').toLowerCase()) > -1,
      )
    }
    createCostSimulationList(newCostSimulations)
  }

  const handleSubmmit = async (
    to: string | undefined,
    deleteId: string | undefined,
  ) => {
    if (to) {
      const id = to.split('/simulation/cost/edit/')[1]
        ? to.split('/simulation/cost/edit/')[1]
        : to.split('/simulation/cost/copy/')[1]
        ? to.split('/simulation/cost/copy/')[1]
        : null
      if (id) {
        await dispatch(getCostSimulationById({ id: id }))
        navigate(to)
      }
    }
    if (deleteId) {
      await dispatch(getCostSimulationById({ id: deleteId }))
      handleIsPreview(deleteId)
    }
  }

  const handleDelete = async () => {
    if (deleteId) {
      await dispatch(deleteCostSimulation({ id: deleteId }))
      const costSimulations = await dispatch(getCostSimulations())
      await createCostSimulationList(costSimulations.payload)
      handleCloseIsPreview()
    }
  }

  const toAddPage = async () => {
    await dispatch(clearCostSimulation())
    navigate('/simulation/cost/add')
  }

  const handleIsPreview = (deleteId: string) => {
    // PCでのスクロール禁止
    document.addEventListener('mousewheel', scrollControl, { passive: false })
    // スマホでのタッチ操作でのスクロール禁止
    document.addEventListener('touchmove', scrollControl, { passive: false })
    setIsPreview(true)
    setDeleteId(deleteId)
  }
  const handleCloseIsPreview = () => {
    document.removeEventListener('mousewheel', scrollControl)
    document.removeEventListener('touchmove', scrollControl)
    setIsPreview(false)
    setDeleteId(null)
  }
  const scrollControl = useCallback((event: any) => {
    event.preventDefault()
  }, [])

  return (
    <>
      <div className={styles.container}>
        <PageHedding title="原価シミュレーション" to="" />
        <div className={styles.pattern}>
          <div className={styles.pattern_head}>
            <InputTextWithLightLabel
              labelName="フリーワード検索"
              description=""
              line={1}
              type="text"
              size="xlarge2"
              value={searchWord}
              placeholder="検索ワードを入力"
              onChange={(event) => handleSearchWord(event.target.value)}
            />
            <SubmitButton
              label="シミュレーションを登録"
              color="orange"
              size="xlarge"
              icon="plus"
              onClick={() => toAddPage()}
            />
          </div>
          <div className={styles.pattern_table}>
            <Table
              headers={[
                {
                  category: 'long_name',
                  elementInfo: {
                    text: {
                      label: '原価シミュレーション名',
                    },
                  },
                },
                {
                  category: 'price',
                  elementInfo: {
                    text: {
                      label: '売価',
                    },
                  },
                },
                {
                  category: 'price',
                  elementInfo: {
                    text: {
                      label: '原価',
                    },
                  },
                },
                {
                  category: 'price',
                  elementInfo: {
                    text: {
                      label: '材料費',
                    },
                  },
                },
                {
                  category: 'price',
                  elementInfo: {
                    text: {
                      label: '包材費',
                    },
                  },
                },
                {
                  category: 'price',
                  elementInfo: {
                    text: {
                      label: '原価率',
                    },
                  },
                },
                {
                  category: 'icon',
                  elementInfo: {
                    text: {
                      label: '編集',
                    },
                  },
                },
                {
                  category: 'icon',
                  elementInfo: {
                    text: {
                      label: '複製',
                    },
                  },
                },
                {
                  category: 'icon',
                  elementInfo: {
                    text: {
                      label: '削除',
                    },
                  },
                },
              ]}
              bodies={costSimulationList}
              handleSubmmit={handleSubmmit}
            />
          </div>
        </div>
      </div>
      {isPreview && (
        <div className={styles.modal_material_delete}>
          <img
            className={styles.modal_material_delete_image_confirm}
            src={deleteConfirmImage}
            alt="削除確認"
          ></img>
          <div className={styles.modal_material_delete_text_message}>
            {costSimulation ? costSimulation.name : ''}
            を削除しますか？
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="キャンセルする"
              color="orange_outlined"
              size="xmiddle"
              icon="none"
              onClick={handleCloseIsPreview}
            />
            <div style={{ marginLeft: 32 }}>
              <SubmitButton
                label="削除する"
                color="orange"
                size="xmiddle"
                icon="none"
                onClick={handleDelete}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CostSimulationTemplate
