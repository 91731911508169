import { FC } from 'react'
// import { useDispatch } from 'react-redux'
import styles from 'styles/components/template.module.scss'
import SubmitButton from 'components/Button/SubmitButton'
import { useNavigate } from 'react-router-dom'
import Logo from 'components/Icons/Logo'
import SendMail from 'images/send-mail.png'
// import { RootState } from 'reducks/reducers'
// import { AppDispatch } from 'index'

const ResetPasswordMailTemplate: FC = () => {
  // const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  // const { roles } = useSelector((state: RootState) => state.staffSlice)

  const handleSubmit = async () => {
    // await dispatch(signIn({ email }))
    navigate('/login')
  }

  return (
    <div className={styles.login_container}>
      <div className={styles.login_logo}>
        <Logo width={368} />
      </div>
      <div className={styles.login_form_container}>
        <div className={styles.login_description}>
          <div className={styles.login_description_title}>
            {`入力したメールアドレス宛にパスワード再設定用の
リンクを記載したメールを送信しました。`}
          </div>
          <div
            className={styles.login_description_text}
          >{`※10分経ってもメールが届かない場合は、メールアドレスが
間違っている可能性がありますので別のアドレスでお試しください。`}</div>
        </div>
        <img
          className={styles.login_description_img}
          src={SendMail}
          alt="send-mail"
        />
      </div>
      <div className={styles.login_button_container} style={{ marginTop: 40 }}>
        <SubmitButton
          label="ログインページへ戻る"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  )
}

export default ResetPasswordMailTemplate
