import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface LoadingState {
  isLoading: boolean
}

const initialState = { isLoading: false } as LoadingState

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<LoadingState>) {
      state.isLoading = action.payload.isLoading
    },
  },
  extraReducers: {},
})

export const { setIsLoading } = loadingSlice.actions
export default loadingSlice.reducer
