import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { db } from 'fb'
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  addDoc,
  query,
  where,
  or,
  and,
} from 'firebase/firestore'

interface ShopSubscriptionState {
  shopSubscription: ShopSubscriptionType | null
}

const initialState = {
  shopSubscription: null,
} as ShopSubscriptionState

export type ShopSubscriptionType = {
  is_daseruno_standard_plan: boolean
  is_sacri_station: boolean
  stripe_scheduled_subscription_id: string
  stripe_subscription_id: string
  stripe_daseruno_subscription_id?: string
  stripe_daseruno_subscription_schedule_id?: string
  is_bill_payment: boolean
}

export const getShopSubscriptionById = createAsyncThunk<
  ShopSubscriptionType | null,
  { storeId: string }
>('shopSubscription/getShopSubscriptionById', async ({ storeId }) => {
  const storeRef = doc(db, 'stores', storeId)
  const q = query(
    collection(db, 'shop_subscriptions'),
    and(
      where('store_ref', '==', storeRef),
      or(
        where('stripe_daseruno_subscription_id', '!=', ''),
        where('is_daseruno_standard_plan', '==', true),
      ),
    ),
  )
  const querySnapshot = await getDocs(q)
  const shopSubscriptions: ShopSubscriptionType[] = []
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const shopSubscription = doc.data()
      shopSubscriptions.push({
        is_daseruno_standard_plan: shopSubscription.is_daseruno_standard_plan,
        is_sacri_station: shopSubscription.is_sacri_station,
        stripe_scheduled_subscription_id:
          shopSubscription.stripe_scheduled_subscription_id,
        stripe_subscription_id: shopSubscription.stripe_subscription_id,
        stripe_daseruno_subscription_id:
          shopSubscription.stripe_daseruno_subscription_id,
        stripe_daseruno_subscription_schedule_id:
          shopSubscription.stripe_daseruno_subscription_schedule_id,
        is_bill_payment: shopSubscription.is_bill_payment,
      })
    }),
  )
  return shopSubscriptions[shopSubscriptions.length - 1]
})

export const updateIsBillPaymentByStoreId = createAsyncThunk<
  string | null,
  { storeId: string; isBillPayment: boolean }
>(
  'shopSubscription/updateIsBillPayment',
  async ({ storeId, isBillPayment }) => {
    const storeRef = doc(db, 'stores', storeId)
    const q = query(
      collection(db, 'shop_subscriptions'),
      and(
        where('store_ref', '==', storeRef),
        or(
          where('stripe_daseruno_subscription_id', '!=', ''),
          where('is_daseruno_standard_plan', '==', true),
        ),
      ),
    )
    const querySnapshot = await getDocs(q)
    const id = querySnapshot.docs[0].id
    if (!id) {
      // 新規登録
      const docRef = await addDoc(collection(db, 'shop_subscriptions'), {
        is_sacri_station: false,
        store_ref: storeRef,
        is_bill_payment: isBillPayment,
        is_daseruno_standard_plan: true,
      })
      return docRef.id
    }
    const shopRef = doc(db, 'shop_subscriptions', id)
    await updateDoc(shopRef, {
      is_bill_payment: isBillPayment,
      is_daseruno_standard_plan: true,
    })
    return id
  },
)

// export const createShopSubscription = createAsyncThunk<
//   boolean,
//   { subscriptionId: string }
// >('shopSubscription/createShopSubscription', async ({ subscriptionId }) => {
//   const shopSubscriptionRef = collection(db, 'shop_subscriptions')

//   const createData = {
//     is_sacri_station: false,
//     stripe_scheduled_subscription_id: '',
//     stripe_subscription_id: '',
//     stripe_daseruno_subscription_id: subscriptionId,
//   }
//   addDoc(shopSubscriptionRef, createData)
//     .then((result) => {
//       console.log(result)
//     })
//     .catch((e) => console.log(e))
//   return true
// })

export const shopSubscriptionSlice = createSlice({
  name: 'shopSubscription',
  initialState,
  reducers: {
    clearShopSubscription(state) {
      state.shopSubscription = null
    },
  },
  extraReducers: {
    [getShopSubscriptionById.fulfilled.type]: (
      state,
      action: PayloadAction<ShopSubscriptionType | null>,
    ) => {
      if (action.payload !== null) {
        state.shopSubscription = action.payload
      }
    },
  },
})

export const { clearShopSubscription } = shopSubscriptionSlice.actions
export default shopSubscriptionSlice.reducer
