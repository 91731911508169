// import React from 'react';
// import logo from './logo.svg';
import './App.css'
// import Router from 'Router'
import { useRoutes } from 'react-router-dom'
// import { Routes } from 'react-router'
// import { Route } from 'use-react-router-breadcrumbs'
// import Portal from 'pages/Portal'
// import BreadAdd from 'pages/BreadAdd'
import { Routes } from './Routes'
import Loading from 'components/Loading/Loading'

function App() {
  const routes = useRoutes(Routes)
  return (
    <>
      {routes}
      <Loading />
    </>
  )
}

export default App
