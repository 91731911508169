import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'
import styles from 'styles/components/loading.module.scss'

const Loading: FC = () => {
  const loading = useSelector((state: RootState) => state.loadingSlice)
  if (loading.isLoading) {
    return (
      <div className={styles.spinner}>
        <div className={styles.bounce1} />
        <div className={styles.bounce2} />
        <div className={styles.bounce3} />
      </div>
    )
  } else {
    return <></>
  }
}

export default Loading
