import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import DoughTemplate from 'components/Templates/DoughTemplate'

const Dough: FC = () => {
  return (
    <Layout title={'生地パターン'} page="Dough">
      <DoughTemplate />
    </Layout>
  )
}

export default Dough
