import { FC } from 'react'
import LoginLayout from 'components/Layout/LoginLayout'
import ResetPasswordCompleteTemplate from 'components/Templates/ResetPasswordCompleteTemplate'

const ResetPasswordComplete: FC = () => {
  return (
    <LoginLayout title={'パスワード再設定完了'}>
      <ResetPasswordCompleteTemplate />
    </LoginLayout>
  )
}

export default ResetPasswordComplete
