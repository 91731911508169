import { FC } from 'react'
import styles from 'styles/components/label.module.scss'
import RadioButton from 'components/Form/RadioButton'
import InputLabel from 'components/Label/InputLabel'

type Props = {
  required: boolean
  labelName: string
  description: string
  line: number
  radioSize?: string
  checkInfos: checkInfo[]
  errorText?: string | null
  margin?: string
  labelSize?: string
}

export type checkInfo = {
  isChecked: boolean
  label: string
  onChange?: () => void
}

const RadioButtonWithLabel: FC<Props> = (props) => {
  const {
    required,
    labelName,
    description,
    line,
    radioSize,
    checkInfos,
    errorText,
    margin,
    labelSize,
  } = props

  const sizeStyle = (() => {
    switch (radioSize) {
      case 'large':
        return styles.radio_large
      case 'middle':
        return styles.radio_middle
      default:
        return styles.radio_middle
    }
  })()

  const sizeClassName = `${sizeStyle}`

  const labelFormContainerStyle = (() => {
    switch (margin) {
      case 'default':
        return styles.labelFormContainer
      case 'narrow':
        return styles.labelFormContainerSub
      case 'min':
        return styles.labelFormContainerMin
      default:
        return styles.labelFormContainer
    }
  })()

  const className = `${labelFormContainerStyle}`

  return (
    <div className={className}>
      <InputLabel
        required={required}
        name={labelName}
        description={description}
        line={line}
        size={labelSize}
      />
      <div className={styles.radioButtonContainer}>
        {checkInfos.map((checkInfo, i) => (
          <div className={sizeClassName} key={checkInfo.label}>
            <RadioButton
              isChecked={checkInfo.isChecked}
              label={checkInfo.label}
              onChange={() =>
                checkInfo.onChange ? checkInfo.onChange() : null
              }
              errorText={i === 0 ? errorText : null}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default RadioButtonWithLabel
