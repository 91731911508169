import { FC, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from 'styles/components/sidemenu.module.scss'
import InputTextSuggestWithLightLabel from 'components/Form/WithLightLabel/InputTextSuggest'
import { suggestInfo } from 'components/Form/InputTextSuggest'
// import SubmitButton from 'components/Button/SubmitButton'
import logo from 'images/daseruno-logo.png'
import shopIcon from 'images/shop-icon.svg'
import homeIcon from 'images/SideMenu/home-icon.svg'
import myPageIcon from 'images/SideMenu/mypage-icon.svg'
import productIcon from 'images/SideMenu/product-icon.svg'
import doughIcon from 'images/SideMenu/dough-icon.svg'
import fillingIcon from 'images/SideMenu/filling-icon.svg'
// import contractIcon from 'images/SideMenu/contract-icon.svg'
import contactIcon from 'images/SideMenu/contact-icon.svg'
import breadIcon from 'images/SideMenu/bread-icon.png'
import materialIcon from 'images/SideMenu/material-icon.svg'
import logoutIcon from 'images/SideMenu/logout-icon.png'
import sacriLogo from 'images/SideMenu/sacri-logo.png'
import clearCrossIcon from 'images/Form/clear-cross-icon.png'
import SideMenuLink from 'components/SideMenu/SideMenuLink'
// import SideMenuModal from 'components/SideMenu/SideMenuModal'
import { getStoreById } from 'reducks/store/slice'
import { setAdminShopId, setAdminStoreId } from 'reducks/staff/slice'
import { RootState } from 'reducks/reducers'

type Props = {
  page: string
}

const SideMenu: FC<Props> = (props) => {
  const { page } = props
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const { staff, adminShopId, adminStoreId } = useSelector(
    (state: RootState) => state.staffSlice,
  )
  const [storeId, setStoreId] = useState<string>('')
  const [storeName, setStoreName] = useState<string>('')
  const [accountName, setAccountName] = useState<string>('')
  const [staffImageUrl, setStaffImageUrl] = useState<string>('')

  let homeSelected = false
  let mypageSelected = false
  let breadSelected = false
  let doughSelected = false
  let fillingSelected = false
  let breadCostSelected = false
  let materialSelected = false
  let simulationSelected = false
  let contactSelected = false

  switch (page) {
    case 'Home':
      homeSelected = true
      break
    case 'MyPage':
    case 'PlanConfirm':
    case 'ShopEdit':
    case 'Account':
    case 'Members':
      mypageSelected = true
      break
    case 'BreadAdd':
    case 'BreadAddRecipe':
      breadSelected = true
      break
    case 'Dough':
    case 'DoughOperation':
      doughSelected = true
      break
    case 'Filling':
    case 'FillingOperation':
      fillingSelected = true
      break
    case 'BreadCost':
      breadCostSelected = true
      break
    case 'Material':
    case 'MaterialOperation':
      materialSelected = true
      break
    case 'CostSimulation':
    case 'CostSimulationOperation':
      simulationSelected = true
      break
    case 'contact':
      contactSelected = true
      break
    default:
      homeSelected = true
      break
  }

  const handleStore = (storeId: string, storeName: string) => {
    setStoreId(storeId)
    setStoreName(storeName)
  }
  const handleChangeStoreName = (storeName: string) => {
    setStoreName(storeName)
  }
  const handleStoreNameSuggest = async (info: suggestInfo) => {
    const storeInfo = await dispatch(getStoreById({ storeId: info.id }))
    if (storeInfo && storeInfo.payload) {
      await dispatch(setAdminShopId(storeInfo.payload.shop_ref.id))
      await dispatch(setAdminStoreId(storeInfo.payload.store_id))
      handleStore(storeInfo.payload.store_id, storeInfo.payload.name)
      navigate('/initload')
    }
  }
  const clearStore = async () => {
    setStoreId('')
    setStoreName('')
    await dispatch(setAdminShopId(null))
    await dispatch(setAdminStoreId(null))
    navigate('/initload')
  }
  const initStore = useCallback(
    async (adminStoreId: string | null) => {
      if (adminStoreId) {
        const store = await dispatch(getStoreById({ storeId: adminStoreId }))
        const storeName = store.payload.name ? store.payload.name : '不明な店舗'
        handleStore(adminStoreId, storeName)
      }
      if (staff) {
        setAccountName(
          staff.isAdmin
            ? 'スーパー管理者'
            : staff.storeName
            ? staff.storeName
            : '',
        )
        setStaffImageUrl(staff.imageUrl ? staff.imageUrl : '')
      }
    },
    [dispatch, adminStoreId],
  )

  useEffect(() => {
    initStore(adminStoreId)
  }, [])

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Link className={styles.sideMenuLink} to="/initload">
          <div className={styles.containerLogo}>
            <img
              className={styles.logo}
              src={logo}
              alt="ダセルーノ for sacri"
            ></img>
          </div>
        </Link>
        <div className={styles.containerAccount}>
          <img
            className={styles.accountIcon}
            src={staffImageUrl ? staffImageUrl : shopIcon}
            alt="ユーザーアカウント"
          ></img>
          <p className={styles.accountText}>{accountName}</p>
        </div>
        {staff?.isAdmin ? (
          <div className={styles.shop_change}>
            <InputTextSuggestWithLightLabel
              labelName=""
              description=""
              line={1}
              type="text"
              size="semimiddle"
              value={storeName}
              placeholder="店舗名検索"
              suggestType="store_name"
              onSelect={handleStoreNameSuggest}
              onChange={(event) => handleChangeStoreName(event.target.value)}
              readOnly={storeId ? true : false}
              style={{ height: 40, paddingRight: 40 }}
            />
            {storeId ? (
              <div onClick={clearStore}>
                <img
                  className={styles.shop_change_clear}
                  src={clearCrossIcon}
                  alt="x"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        <div className={styles.containerUseMenu}>
          <SideMenuLink
            title="ホーム"
            iconPath={homeIcon}
            to="/initload"
            selected={homeSelected}
          />
          {/* <SideMenuModal
            title="マイページ"
            iconPath={myPageIcon}
            selected={mypageSelected}
            linkInfos={[
              {
                title: 'ユーザー情報変更',
                to: '#',
              },
              {
                title: '店舗情報変更',
                to: '/shop/edit',
              },
              {
                title: 'メールアドレス変更',
                to: '#',
              },
              {
                title: '退会手続き',
                to: '#',
              },
              {
                title: 'プライバシーポリシー',
                to: '#',
              },
              {
                title: '利用規約',
                to: '#',
              },
            ]}
          /> */}
          {(staff?.isAdmin === false &&
            (!staff?.ds_roles ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              ).length === 0 ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              )[0].role === 9)) ||
          staff?.isAdmin === true ? (
            <SideMenuLink
              title="マイページ"
              iconPath={myPageIcon}
              to="/mypage"
              selected={mypageSelected}
            />
          ) : (
            ''
          )}
          {(staff?.isAdmin === false &&
            (!staff?.ds_roles ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              ).length === 0 ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              )[0].role === 9)) ||
          (staff?.isAdmin === true && adminShopId) ? (
            <>
              <SideMenuLink
                title="商品を登録"
                iconPath={productIcon}
                to="/bread/add"
                selected={breadSelected}
              />
              <SideMenuLink
                title="生地パターン一覧"
                iconPath={doughIcon}
                to="/dough"
                selected={doughSelected}
              />
              <SideMenuLink
                title="フィリングパターン一覧"
                iconPath={fillingIcon}
                to="/filling"
                selected={fillingSelected}
              />
              {/* <SideMenuLink
                title="契約情報"
                iconPath={contractIcon}
                to="/plan_confirm"
                selected={contractSelected}
              /> */}
            </>
          ) : (
            ''
          )}
          {(staff?.isStandardPlan &&
            staff?.isAdmin === false &&
            (!staff?.ds_roles ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              ).length === 0 ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              )[0].role === 9)) ||
          (staff?.isAdmin === true && adminShopId) ? (
            <>
              <SideMenuLink
                title="原価一覧"
                iconPath={productIcon}
                to="/bread/cost"
                selected={breadCostSelected}
              />
            </>
          ) : (
            ''
          )}
          {(staff?.isStandardPlan &&
            staff?.isAdmin === false &&
            (!staff?.ds_roles ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              ).length === 0 ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              )[0].role === 9)) ||
          staff?.isAdmin === true ? (
            <>
              <SideMenuLink
                title="原材料情報一覧"
                iconPath={materialIcon}
                to="/material"
                selected={materialSelected}
              />
            </>
          ) : (
            ''
          )}
          {(staff?.isStandardPlan &&
            staff?.isAdmin === false &&
            (!staff?.ds_roles ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              ).length === 0 ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              )[0].role === 9)) ||
          (staff?.isAdmin === true && adminShopId) ? (
            <>
              <SideMenuLink
                title="シミュレーション"
                iconPath={productIcon}
                to="/simulation/cost"
                selected={simulationSelected}
              />
            </>
          ) : (
            ''
          )}
          <SideMenuLink
            title="お問い合わせ"
            iconPath={contactIcon}
            to="https://sacrisupport.zendesk.com/hc/ja/requests/new"
            selected={contactSelected}
            external={true}
          />
        </div>
        <div className={styles.containerExitMenu}>
          <SideMenuLink
            title="sacri for owners"
            iconPath={breadIcon}
            to="https://admin-sacri.web.app"
            selected={false}
            imagePath={sacriLogo}
            external={true}
          />
          <div style={{ marginLeft: -8 }}>
            <SideMenuLink
              title="ログアウト"
              iconPath={logoutIcon}
              to="/logout"
              selected={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideMenu
