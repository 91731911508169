import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import SubmitButton from 'components/Button/SubmitButton'
import { StaffType, getStaffs, deleteStaff } from 'reducks/staff/slice'
import { RootState } from 'reducks/reducers'
import IconArrow from 'components/Templates/PriceCardTemplate/IconArrow'
import SelectBox from 'components/Form/SelectBox'
import InputText from 'components/Form/InputText'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'fb'
import { setIsLoading } from 'reducks/loading/slice'

const roleLabel = (role: number) => {
  switch (role) {
    case 9:
      return '管理者'
    case 5:
      return '制限ユーザー'
    default:
      return '管理者'
  }
}

type Role = 'admin' | 'viewer'

const roles = [
  { label: '制限ユーザー', value: 'viewer' },
  { label: '管理者', value: 'admin' },
]

const MembersTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const [staffs, setStaffs] = useState<StaffType[] | null>()
  const [openRegistModal, setOpenRegistModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedStaff, setSelectedStaff] = useState<StaffType | null>()
  const [inviteEmail, setInviteEmail] = useState<string>()
  const [selectedRole, setSelectedRole] = useState<Role>()

  const { staff } = useSelector((state: RootState) => state.staffSlice)
  const myRole = useMemo(
    () =>
      staff?.ds_roles
        ? staff?.ds_roles.find((role) => role.store_ref.id === staff.storeId)
            ?.role
        : 9,
    [staff],
  )

  const initGetStaffs = async () => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    if (staff && staff.storeId) {
      const staffs = await dispatch(
        getStaffs({ uid: staff.uid, storeId: staff.storeId }),
      )
      setStaffs(staffs.payload)
    }
  }
  useEffect(() => {
    initGetStaffs()
  }, [])

  return (
    <div className={styles.container}>
      <PageHedding title="ユーザー権限" to="/mypage" />
      <div className={styles.members}>
        <div className={styles.members_table_wrap}>
          {staffs?.map((s) => {
            return (
              <div className={styles.members_table} key={s.uid}>
                <div
                  className={
                    styles[
                      `members_role_label_${
                        s.ds_roles?.find(
                          (role) => role.store_ref.id === staff?.storeId,
                        )?.role || 9
                      }`
                    ]
                  }
                >
                  {roleLabel(
                    s.ds_roles?.find(
                      (role) => role.store_ref.id === staff?.storeId,
                    )?.role || 9,
                  )}
                </div>
                <div className={styles.members_email}>{s.email}</div>
                {myRole === 9 && s.email !== staff?.email && (
                  <>
                    <div className={styles.members_edit_button}>
                      <div
                        className={styles.members_edit_button_label}
                        onClick={() => {
                          setOpenEditModal(true)
                          setSelectedRole(
                            s.ds_roles
                              ? s.ds_roles.find(
                                  (role) =>
                                    role.store_ref.id === staff?.storeId,
                                )?.role === 9
                                ? 'admin'
                                : 'viewer'
                              : 'admin',
                          )
                          setSelectedStaff(s)
                        }}
                      >
                        編集する
                      </div>
                      <IconArrow
                        fill="#4A5568"
                        rotate="rotate(90deg)"
                        width={8}
                      />
                    </div>
                    <SubmitButton
                      label=""
                      color="white"
                      size="icon"
                      icon="delete_gray"
                      onClick={() => {
                        setOpenDeleteModal(true)
                        setSelectedStaff(s)
                      }}
                    />
                  </>
                )}
              </div>
            )
          })}
        </div>
        <div className={styles.members_button}>
          <SubmitButton
            label="新しいユーザーを追加する"
            color="orange"
            size="large"
            icon="none"
            onClick={() => setOpenRegistModal(true)}
          />
        </div>
      </div>
      {openRegistModal && (
        <RegistModal
          setOpenRegistModal={setOpenRegistModal}
          inviteEmail={inviteEmail}
          setInviteEmail={setInviteEmail}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          storeId={staff?.storeId}
          shopId={staff?.shopId}
        />
      )}
      {openEditModal && (
        <EditModal
          setOpenEditModal={setOpenEditModal}
          selectedStaff={selectedStaff}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          storeId={staff?.storeId}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          setOpenDeleteModal={setOpenDeleteModal}
          selectedStaff={selectedStaff}
          storeId={staff?.storeId}
        />
      )}
    </div>
  )
}

type RegistModalProps = {
  setOpenRegistModal: (e: boolean) => void
  inviteEmail?: string | null
  setInviteEmail: React.Dispatch<string>
  selectedRole?: Role
  setSelectedRole: React.Dispatch<
    React.SetStateAction<'admin' | 'viewer' | undefined>
  >
  storeId?: string | null
  shopId?: string | null
}
export const RegistModal: FC<RegistModalProps> = ({
  setOpenRegistModal,
  inviteEmail,
  setInviteEmail,
  selectedRole,
  setSelectedRole,
  storeId,
  shopId,
}) => {
  const inviteDaserunoStaffs = httpsCallable(functions, 'inviteDaserunoStaffs')
  const dispatch = useDispatch<any>()
  const handleCreateRole = () => {
    dispatch(setIsLoading({ isLoading: true }))
    if (!inviteEmail || !storeId || !shopId) return
    inviteDaserunoStaffs({
      email: inviteEmail,
      addStaffOnly: true,
      storeId,
      shopId,
      dsRole: selectedRole === 'admin' ? 9 : 5,
    })
      .then(() => {
        setOpenRegistModal(false)
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        dispatch(setIsLoading({ isLoading: false }))
      })
  }
  return (
    <div className={styles.members_modal}>
      <div className={styles.members_modal_title}>新しいメンバーを招待する</div>
      <div className={styles.members_modal_notification}>
        管理画面へのアクセスを許可する人のメールアドレスを入力してください。招待リンクは7日間有効です。
      </div>
      <div className={styles.members_modal_wrap_label}>
        <span className={styles.members_modal_role_label}>メールアドレス</span>
        <div className={styles.members_modal_require}>必須</div>
      </div>
      <InputText
        required={true}
        type="text"
        placeholder=""
        rightAligned={false}
        style={{ width: 463, marginTop: 12 }}
        size="large"
        value={inviteEmail ? inviteEmail : ''}
        errorTextStyle={{ top: 54 }}
        onChange={(e) => setInviteEmail(e.target.value)}
      />
      <div className={styles.members_modal_wrap_label}>
        <span className={styles.members_modal_role_label}>権限</span>
        <div className={styles.members_modal_require}>必須</div>
      </div>
      <SelectBox
        style={{ width: 463, marginTop: 12 }}
        containerStyle={{ position: 'relative' }}
        size="large"
        value={selectedRole ? selectedRole : ''}
        options={roles}
        errorTextStyle={{ top: 54 }}
        onChange={(e) => setSelectedRole(e.target.value as Role)}
      />
      <div
        className={styles.members_modal_notification}
      >{`管理者は全ての操作を行うことができます。制限ユーザーは裏貼りシール印刷ができますが、レシピの確認・編集はできません。詳しくはこちらの記事を参考にしてください。`}</div>
      <div className={styles.modalButtonContainer}>
        <SubmitButton
          label="キャンセル"
          color="orange_outlined"
          size="xmiddle"
          icon="none"
          onClick={() => setOpenRegistModal(false)}
        />
        <div style={{ width: 24 }} />
        <SubmitButton
          label="招待する"
          color="orange"
          size="xmiddle"
          icon="none"
          onClick={handleCreateRole}
        />
      </div>
    </div>
  )
}

type EditModalProps = {
  setOpenEditModal: (e: boolean) => void
  selectedStaff?: StaffType | null
  selectedRole?: Role
  setSelectedRole: React.Dispatch<
    React.SetStateAction<'admin' | 'viewer' | undefined>
  >
  storeId?: string | null
}
export const EditModal: FC<EditModalProps> = ({
  setOpenEditModal,
  selectedStaff,
  selectedRole,
  setSelectedRole,
  storeId,
}) => {
  const apiUpdateDaserunoRole = httpsCallable(
    functions,
    'apiUpdateDaserunoRole',
  )
  const dispatch = useDispatch<any>()
  const handleUpdateRole = () => {
    dispatch(setIsLoading({ isLoading: true }))
    if (!selectedStaff || !storeId) return
    apiUpdateDaserunoRole({
      staffId: selectedStaff?.uid,
      storeId,
      role: selectedRole === 'admin' ? 9 : 5,
    })
      .then(() => {
        setOpenEditModal(false)
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        dispatch(setIsLoading({ isLoading: false }))
      })
  }
  if (!selectedRole) return <></>
  return (
    <div className={styles.members_modal}>
      <div className={styles.members_modal_title}>メンバーの権限を変更する</div>
      <div className={styles.members_modal_email}>{selectedStaff?.email}</div>
      <div className={styles.members_modal_wrap_label}>
        <span className={styles.members_modal_role_label}>権限</span>
        <div className={styles.members_modal_require}>必須</div>
      </div>
      <SelectBox
        style={{ width: 463, marginTop: 12 }}
        containerStyle={{ position: 'relative' }}
        size="large"
        value={selectedRole}
        options={roles}
        errorTextStyle={{ top: 54 }}
        onChange={(e) => setSelectedRole(e.target.value as Role)}
      />
      <div
        className={styles.members_modal_notification}
      >{`管理者は全ての操作を行うことができます。制限ユーザーは裏貼りシール印刷ができますが、レシピの確認・編集はできません。詳しくはこちらの記事を参考にしてください。`}</div>
      <div className={styles.modalButtonContainer}>
        <SubmitButton
          label="キャンセル"
          color="orange_outlined"
          size="xmiddle"
          icon="none"
          onClick={() => setOpenEditModal(false)}
        />
        <div style={{ width: 24 }} />
        <SubmitButton
          label="変更する"
          color="orange"
          size="xmiddle"
          icon="none"
          onClick={handleUpdateRole}
        />
      </div>
    </div>
  )
}

type DeleteModalProps = {
  setOpenDeleteModal: (e: boolean) => void
  selectedStaff?: StaffType | null
  storeId?: string | null
}
export const DeleteModal: FC<DeleteModalProps> = ({
  setOpenDeleteModal,
  selectedStaff,
  storeId,
}) => {
  const dispatch = useDispatch<any>()
  const handleDeleteRole = async () => {
    dispatch(setIsLoading({ isLoading: true }))
    if (!selectedStaff || !storeId) return
    await dispatch(deleteStaff({ uid: selectedStaff.uid }))
    setOpenDeleteModal(false)
    window.location.reload()
    dispatch(setIsLoading({ isLoading: false }))
  }
  return (
    <div className={styles.members_modal}>
      <div className={styles.members_modal_title}>メンバーを削除する</div>
      <div className={styles.members_modal_email}>{selectedStaff?.email}</div>
      <div
        className={styles.members_modal_notification_red}
      >{`このユーザーを店舗スタッフから削除します。この操作は取り消せません。`}</div>
      <div className={styles.modalButtonContainer}>
        <SubmitButton
          label="キャンセル"
          color="orange_outlined"
          size="xmiddle"
          icon="none"
          onClick={() => setOpenDeleteModal(false)}
        />
        <div style={{ width: 24 }} />
        <SubmitButton
          label="削除する"
          color="orange"
          size="xmiddle"
          icon="none"
          onClick={handleDeleteRole}
        />
      </div>
    </div>
  )
}

export default MembersTemplate
