type Props = {
  reverse?: boolean
  rotate?: string
  fill?: string
  width?: number
}

function IconArrow(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '20'}
      height="37"
      fill="none"
      viewBox="0 0 20 37"
      style={{
        transform: props.rotate
          ? props.rotate
          : props.reverse
          ? 'rotate(180deg)'
          : 'rotate(0deg)',
      }}
    >
      <path
        stroke={props.fill ?? '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1l18 17.5L1 36"
      ></path>
    </svg>
  )
}

export default IconArrow
