import { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import SelectCheckBoxWithLightLabel, {
  checkInfo,
} from 'components/Form/WithLightLabel/SelectCheckBox'
import RadioButtonFilterWithLightLabel from 'components/Form/WithLightLabel/RadioButtonFilter'
import SubmitButton from 'components/Button/SubmitButton'
import Table, { TableBody, TableHeader } from 'components/Table/Table'
import {
  getBreads,
  BreadType,
  getBreadById,
  BreadStatus,
  BackingStickerStatus,
} from 'reducks/bread/slice'
import { getCategories } from 'reducks/category/slice'
import defaultBread from 'images/default-bread.png'
import { RootState } from 'reducks/reducers'
import { TAX } from 'Constants'
import { setIsLoading } from 'reducks/loading/slice'
import { Staff } from 'reducks/staff/slice'

const BreadDashBoardTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [searchWord, setSearchWord] = useState<string>('')
  // const [categoryMealsBread, setCategoeyMealsBread] = useState<boolean>(false)
  // const [categorySideDishBread, setCategoeySideDishBread] = useState<boolean>(false)
  // const [categorySweetenedBread, setCategoeySweetenedBread] = useState<boolean>(false)
  // const [categoryDoughnut, setCategoeyDoughnut] = useState<boolean>(false)
  // const [categorySandwich, setCategoeySandwich] = useState<boolean>(false)
  // const [categoryHardBread, setCategoeyHardBread] = useState<boolean>(false)
  // const [categoryDanish, setCategoeyDanish] = useState<boolean>(false)
  // const [categoryBagel, setCategoeyBagel] = useState<boolean>(false)
  // const [categoryOther, setCategoeyOther] = useState<boolean>(false)
  const [breadSaling, setBreadSaling] = useState<boolean>(false)
  const [breadSalesSuspension, setBreadSalesSuspension] =
    useState<boolean>(false)
  const [backingSealPrintable, setBackingSealPrintable] =
    useState<boolean>(false)
  const [backingSealUnPrintable, setBackingSealUnPrintable] =
    useState<boolean>(false)
  const [breadInfoPublic, setBreadInfoPublic] = useState<boolean>(false)
  const [breadInfoDraft, setBreadInfoDraft] = useState<boolean>(false)
  const [recipeInfoPublic, setRecipeInfoPublic] = useState<boolean>(false)
  const [recipeInfoDraft, setRecipeInfoDraft] = useState<boolean>(false)
  const [materialInfoComplete, setMaterialInfoComplete] =
    useState<boolean>(false)
  const [materialInfoNotInput, setMaterialInfoNotInput] =
    useState<boolean>(false)
  const [materialInfoAnalyze, setMaterialInfoAnalyze] = useState<boolean>(false)
  // const [priceCardPrintable, setPriceCardPrintable] = useState<boolean>(false)
  // const [priceCardUnPrintable, setPriceCardUnPrintable] =
  //   useState<boolean>(false)
  const [breadList, setBreadList] = useState<TableBody[][]>([])
  const [headerList, setheaderList] = useState<TableHeader[]>([])
  const [categoryIsChecked0, setCategoryIsChecked0] = useState<boolean>(false)
  const [categoryIsChecked1, setCategoryIsChecked1] = useState<boolean>(false)
  const [categoryIsChecked2, setCategoryIsChecked2] = useState<boolean>(false)
  const [categoryIsChecked3, setCategoryIsChecked3] = useState<boolean>(false)
  const [categoryIsChecked4, setCategoryIsChecked4] = useState<boolean>(false)
  const [categoryIsChecked5, setCategoryIsChecked5] = useState<boolean>(false)
  const [categoryIsChecked6, setCategoryIsChecked6] = useState<boolean>(false)
  const [categoryIsChecked7, setCategoryIsChecked7] = useState<boolean>(false)
  const [categoryIsChecked8, setCategoryIsChecked8] = useState<boolean>(false)
  const [categoryIsChecked9, setCategoryIsChecked9] = useState<boolean>(false)
  const [displaySelectBoxValue, setDisplaySelectBoxValue] = useState<string>('')
  const [displaySelectBoxPlaceholder, setDisplaySelectBoxPlaceholder] =
    useState<string>('1')
  const [checkedBreadIdList, setCheckedBreadIdList] = useState<string[]>([])
  const [headerCheck, setHeaderCheck] = useState<boolean>(false)

  const { adminShopId, staff } = useSelector(
    (state: RootState) => state.staffSlice,
  )

  const handleSearchWord = (searchWord: string) => {
    setSearchWord(searchWord)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
    // const newBreads = breads.filter( bread => bread.name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1)
    // createBreadList(newBreads)
  }
  const handleBreadSalesAll = () => {
    setBreadSaling(false)
    setBreadSalesSuspension(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: false,
      inputBreadSalesSuspension: false,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleBreadSaling = () => {
    setBreadSaling(true)
    setBreadSalesSuspension(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: true,
      inputBreadSalesSuspension: false,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleBreadSalesSuspension = () => {
    setBreadSaling(false)
    setBreadSalesSuspension(true)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: false,
      inputBreadSalesSuspension: true,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleBackingSealAll = () => {
    setBackingSealPrintable(false)
    setBackingSealUnPrintable(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: false,
      inputBackingSealUnPrintable: false,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleBackingSealPrintable = () => {
    setBackingSealPrintable(true)
    setBackingSealUnPrintable(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: true,
      inputBackingSealUnPrintable: false,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleBackingSealUnPrintable = () => {
    setBackingSealPrintable(false)
    setBackingSealUnPrintable(true)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: false,
      inputBackingSealUnPrintable: true,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleBreadInfoAll = () => {
    setBreadInfoPublic(false)
    setBreadInfoDraft(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: false,
      inputBreadInfoDraft: false,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleBreadInfoPublic = () => {
    setBreadInfoPublic(true)
    setBreadInfoDraft(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: true,
      inputBreadInfoDraft: false,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleBreadInfoDraft = () => {
    setBreadInfoPublic(false)
    setBreadInfoDraft(true)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: false,
      inputBreadInfoDraft: true,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleRecipeInfoAll = () => {
    setRecipeInfoPublic(false)
    setRecipeInfoDraft(false)
    setMaterialInfoComplete(false)
    setMaterialInfoNotInput(false)
    setMaterialInfoAnalyze(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: false,
      inputRecipeInfoDraft: false,
      inputMaterialInfoComplete: false,
      inputMaterialInfoNotInput: false,
      inputMaterialInfoAnalyze: false,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  // const handleRecipeInfoPublic = () => {
  //   setRecipeInfoPublic(true)
  //   setRecipeInfoDraft(false)
  //   breadFilter({
  //     inputSearchWord: searchWord,
  //     inputCategoryIsChecked0: categoryIsChecked0,
  //     inputCategoryIsChecked1: categoryIsChecked1,
  //     inputCategoryIsChecked2: categoryIsChecked2,
  //     inputCategoryIsChecked3: categoryIsChecked3,
  //     inputCategoryIsChecked4: categoryIsChecked4,
  //     inputCategoryIsChecked5: categoryIsChecked5,
  //     inputCategoryIsChecked6: categoryIsChecked6,
  //     inputCategoryIsChecked7: categoryIsChecked7,
  //     inputCategoryIsChecked8: categoryIsChecked8,
  //     inputCategoryIsChecked9: categoryIsChecked9,
  //     inputBreadSaling: breadSaling,
  //     inputBreadSalesSuspension: breadSalesSuspension,
  //     inputBackingSealPrintable: backingSealPrintable,
  //     inputBackingSealUnPrintable: backingSealUnPrintable,
  //     inputBreadInfoPublic: breadInfoPublic,
  //     inputBreadInfoDraft: breadInfoDraft,
  //     inputRecipeInfoPublic: true,
  //     inputRecipeInfoDraft: false,
  //     inputMaterialInfoComplete: materialInfoComplete,
  //     inputMaterialInfoNotInput: materialInfoNotInput,
  //     inputMaterialInfoAnalyze: materialInfoAnalyze,
  //     inputPriceCardPrintable: false,
  //     inputPriceCardUnPrintable: false,
  //   })
  // }
  const handleRecipeInfoDraft = () => {
    setRecipeInfoPublic(false)
    setRecipeInfoDraft(true)
    setMaterialInfoComplete(false)
    setMaterialInfoNotInput(false)
    setMaterialInfoAnalyze(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: false,
      inputRecipeInfoDraft: true,
      inputMaterialInfoComplete: false,
      inputMaterialInfoNotInput: false,
      inputMaterialInfoAnalyze: false,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  // const handleMaterialInfoAll = () => {
  //   setMaterialInfoComplete(false)
  //   setMaterialInfoNotInput(false)
  //   setMaterialInfoAnalyze(false)
  //   breadFilter({
  //     inputSearchWord: searchWord,
  //     inputCategoryIsChecked0: categoryIsChecked0,
  //     inputCategoryIsChecked1: categoryIsChecked1,
  //     inputCategoryIsChecked2: categoryIsChecked2,
  //     inputCategoryIsChecked3: categoryIsChecked3,
  //     inputCategoryIsChecked4: categoryIsChecked4,
  //     inputCategoryIsChecked5: categoryIsChecked5,
  //     inputCategoryIsChecked6: categoryIsChecked6,
  //     inputCategoryIsChecked7: categoryIsChecked7,
  //     inputCategoryIsChecked8: categoryIsChecked8,
  //     inputCategoryIsChecked9: categoryIsChecked9,
  //     inputBreadSaling: breadSaling,
  //     inputBreadSalesSuspension: breadSalesSuspension,
  //     inputBackingSealPrintable: backingSealPrintable,
  //     inputBackingSealUnPrintable: backingSealUnPrintable,
  //     inputBreadInfoPublic: breadInfoPublic,
  //     inputBreadInfoDraft: breadInfoDraft,
  //     inputRecipeInfoPublic: recipeInfoPublic,
  //     inputRecipeInfoDraft: recipeInfoDraft,
  //     inputMaterialInfoComplete: false,
  //     inputMaterialInfoNotInput: false,
  //     inputMaterialInfoAnalyze: false,
  //     inputPriceCardPrintable: false,
  //     inputPriceCardUnPrintable: false,
  //   })
  // }
  const handleMaterialInfoComplete = () => {
    setRecipeInfoPublic(false)
    setRecipeInfoDraft(false)
    setMaterialInfoComplete(true)
    setMaterialInfoNotInput(false)
    setMaterialInfoAnalyze(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: false,
      inputRecipeInfoDraft: false,
      inputMaterialInfoComplete: true,
      inputMaterialInfoNotInput: false,
      inputMaterialInfoAnalyze: false,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleMaterialInfoNotInput = () => {
    setRecipeInfoPublic(false)
    setRecipeInfoDraft(false)
    setMaterialInfoComplete(false)
    setMaterialInfoNotInput(true)
    setMaterialInfoAnalyze(false)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: false,
      inputRecipeInfoDraft: false,
      inputMaterialInfoComplete: false,
      inputMaterialInfoNotInput: true,
      inputMaterialInfoAnalyze: false,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleMaterialInfoAnalyze = () => {
    setRecipeInfoPublic(false)
    setRecipeInfoDraft(false)
    setMaterialInfoComplete(false)
    setMaterialInfoNotInput(false)
    setMaterialInfoAnalyze(true)
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: false,
      inputRecipeInfoDraft: false,
      inputMaterialInfoComplete: false,
      inputMaterialInfoNotInput: false,
      inputMaterialInfoAnalyze: true,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }
  const handleBreadCategory = (index: number) => {
    let isChecked0 = categoryIsChecked0
    let isChecked1 = categoryIsChecked1
    let isChecked2 = categoryIsChecked2
    let isChecked3 = categoryIsChecked3
    let isChecked4 = categoryIsChecked4
    let isChecked5 = categoryIsChecked5
    let isChecked6 = categoryIsChecked6
    let isChecked7 = categoryIsChecked7
    let isChecked8 = categoryIsChecked8
    let isChecked9 = categoryIsChecked9
    if (index === 0) {
      setCategoryIsChecked0(!categoryIsChecked0 ? true : false)
      isChecked0 = !categoryIsChecked0
    }
    if (index === 1) {
      setCategoryIsChecked1(!categoryIsChecked1 ? true : false)
      isChecked1 = !categoryIsChecked1
    }
    if (index === 2) {
      setCategoryIsChecked2(!categoryIsChecked2 ? true : false)
      isChecked2 = !categoryIsChecked2
    }
    if (index === 3) {
      setCategoryIsChecked3(!categoryIsChecked3 ? true : false)
      isChecked3 = !categoryIsChecked3
    }
    if (index === 4) {
      setCategoryIsChecked4(!categoryIsChecked4 ? true : false)
      isChecked4 = !categoryIsChecked4
    }
    if (index === 5) {
      setCategoryIsChecked5(!categoryIsChecked5 ? true : false)
      isChecked5 = !categoryIsChecked5
    }
    if (index === 6) {
      setCategoryIsChecked6(!categoryIsChecked6 ? true : false)
      isChecked6 = !categoryIsChecked6
    }
    if (index === 7) {
      setCategoryIsChecked7(!categoryIsChecked7 ? true : false)
      isChecked7 = !categoryIsChecked7
    }
    if (index === 8) {
      setCategoryIsChecked8(!categoryIsChecked8 ? true : false)
      isChecked8 = !categoryIsChecked8
    }
    if (index === 9) {
      setCategoryIsChecked9(!categoryIsChecked9 ? true : false)
      isChecked9 = !categoryIsChecked9
    }

    let newDisplaySelectBoxValue = ''
    if (isChecked0) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[0].name + ' / '
    }
    if (isChecked1) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[1].name + ' / '
    }
    if (isChecked2) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[2].name + ' / '
    }
    if (isChecked3) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[3].name + ' / '
    }
    if (isChecked4) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[4].name + ' / '
    }
    if (isChecked5) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[5].name + ' / '
    }
    if (isChecked6) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[6].name + ' / '
    }
    if (isChecked7) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[7].name + ' / '
    }
    if (isChecked8) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[8].name + ' / '
    }
    if (isChecked9) {
      newDisplaySelectBoxValue =
        newDisplaySelectBoxValue + categories[9].name + ' / '
    }
    // 末尾の2文字を削除
    newDisplaySelectBoxValue = newDisplaySelectBoxValue.slice(0, -2)
    setDisplaySelectBoxValue(newDisplaySelectBoxValue)

    if (newDisplaySelectBoxValue.length === 0) {
      setDisplaySelectBoxPlaceholder('1')
    } else {
      setDisplaySelectBoxPlaceholder('0')
    }

    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: isChecked0,
      inputCategoryIsChecked1: isChecked1,
      inputCategoryIsChecked2: isChecked2,
      inputCategoryIsChecked3: isChecked3,
      inputCategoryIsChecked4: isChecked4,
      inputCategoryIsChecked5: isChecked5,
      inputCategoryIsChecked6: isChecked6,
      inputCategoryIsChecked7: isChecked7,
      inputCategoryIsChecked8: isChecked8,
      inputCategoryIsChecked9: isChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: breads,
    })
  }

  // const handleReset = () => {
  //   setSearchWord('')
  //   setCategoryIsChecked0(false)
  //   setCategoryIsChecked1(false)
  //   setCategoryIsChecked2(false)
  //   setCategoryIsChecked3(false)
  //   setCategoryIsChecked4(false)
  //   setCategoryIsChecked5(false)
  //   setCategoryIsChecked6(false)
  //   setCategoryIsChecked7(false)
  //   setCategoryIsChecked8(false)
  //   setBreadSaling(false)
  //   setBreadSalesSuspension(false)
  //   setBackingSealPrintable(false)
  //   setBackingSealUnPrintable(false)
  //   setPriceCardPrintable(false)
  //   setPriceCardUnPrintable(false)
  //   setDisplaySelectBoxValue('')
  //   setDisplaySelectBoxPlaceholder('1')
  //   breadFilter({
  //     inputSearchWord: '',
  //     inputCategoryIsChecked0: false,
  //     inputCategoryIsChecked1: false,
  //     inputCategoryIsChecked2: false,
  //     inputCategoryIsChecked3: false,
  //     inputCategoryIsChecked4: false,
  //     inputCategoryIsChecked5: false,
  //     inputCategoryIsChecked6: false,
  //     inputCategoryIsChecked7: false,
  //     inputCategoryIsChecked8: false,
  //     inputCategoryIsChecked9: false,
  //     inputBreadSaling: false,
  //     inputBreadSalesSuspension: false,
  //     inputBackingSealPrintable: false,
  //     inputBackingSealUnPrintable: false,
  //     inputPriceCardPrintable: false,
  //     inputPriceCardUnPrintable: false,
  //   })
  // }

  // TODO: SearchCheckBox2に移行してハードコーディング箇所を少なくする
  const breadFilter = (payload: {
    inputSearchWord: string
    inputCategoryIsChecked0: boolean
    inputCategoryIsChecked1: boolean
    inputCategoryIsChecked2: boolean
    inputCategoryIsChecked3: boolean
    inputCategoryIsChecked4: boolean
    inputCategoryIsChecked5: boolean
    inputCategoryIsChecked6: boolean
    inputCategoryIsChecked7: boolean
    inputCategoryIsChecked8: boolean
    inputCategoryIsChecked9: boolean
    inputBreadSaling: boolean
    inputBreadSalesSuspension: boolean
    inputBackingSealPrintable: boolean
    inputBackingSealUnPrintable: boolean
    inputPriceCardPrintable: boolean
    inputPriceCardUnPrintable: boolean
    inputBreadInfoPublic: boolean
    inputBreadInfoDraft: boolean
    inputRecipeInfoPublic: boolean
    inputRecipeInfoDraft: boolean
    inputMaterialInfoNotInput: boolean
    inputMaterialInfoAnalyze: boolean
    inputMaterialInfoComplete: boolean
    localBreads: BreadType[] | null
  }) => {
    let newBreads = payload.localBreads ? [...payload.localBreads] : [...breads]
    let newSearchWord = payload.inputSearchWord
    if (newSearchWord) {
      newBreads = newBreads.filter(
        (bread) =>
          bread.name
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWord.replace(/\s+/g, '').toLowerCase()) > -1,
      )
    }
    let newCategoryIsChecked0 = payload.inputCategoryIsChecked0
    let newCategoryIsChecked1 = payload.inputCategoryIsChecked1
    let newCategoryIsChecked2 = payload.inputCategoryIsChecked2
    let newCategoryIsChecked3 = payload.inputCategoryIsChecked3
    let newCategoryIsChecked4 = payload.inputCategoryIsChecked4
    let newCategoryIsChecked5 = payload.inputCategoryIsChecked5
    let newCategoryIsChecked6 = payload.inputCategoryIsChecked6
    let newCategoryIsChecked7 = payload.inputCategoryIsChecked7
    let newCategoryIsChecked8 = payload.inputCategoryIsChecked8
    let newCategoryIsChecked9 = payload.inputCategoryIsChecked9
    if (
      newCategoryIsChecked0 ||
      newCategoryIsChecked1 ||
      newCategoryIsChecked2 ||
      newCategoryIsChecked3 ||
      newCategoryIsChecked4 ||
      newCategoryIsChecked5 ||
      newCategoryIsChecked6 ||
      newCategoryIsChecked7 ||
      newCategoryIsChecked8 ||
      newCategoryIsChecked9
    ) {
      newBreads = newBreads.filter((bread) => {
        if (bread.category) {
          if (
            newCategoryIsChecked0 &&
            bread.category.id === categories[0].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked1 &&
            bread.category.id === categories[1].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked2 &&
            bread.category.id === categories[2].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked3 &&
            bread.category.id === categories[3].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked4 &&
            bread.category.id === categories[4].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked5 &&
            bread.category.id === categories[5].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked6 &&
            bread.category.id === categories[6].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked7 &&
            bread.category.id === categories[7].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked8 &&
            bread.category.id === categories[8].category_ref.id
          ) {
            return true
          }
          if (
            newCategoryIsChecked9 &&
            bread.category.id === categories[9].category_ref.id
          ) {
            return true
          }
        }
        return false
      })
    }
    let newBreadSaling = payload.inputBreadSaling
    let newBreadSalesSuspension = payload.inputBreadSalesSuspension
    if (newBreadSaling || newBreadSalesSuspension) {
      newBreads = newBreads.filter((bread) => {
        if (newBreadSaling && bread.is_salling === newBreadSaling) {
          return true
        }
        if (
          newBreadSalesSuspension &&
          (bread.is_salling === !newBreadSalesSuspension || !bread.is_salling)
        ) {
          return true
        }
        return false
      })
    }
    let newBackingSealPrintable = payload.inputBackingSealPrintable
    let newBackingSealUnPrintable = payload.inputBackingSealUnPrintable
    if (newBackingSealPrintable || newBackingSealUnPrintable) {
      newBreads = newBreads.filter((bread) => {
        if (
          newBackingSealPrintable &&
          bread.backing_sticker_status === BackingStickerStatus.OK &&
          bread.daseruno_status === BreadStatus.PUBLIC &&
          bread.daseruno_recipe_status === BreadStatus.PUBLIC
        ) {
          return true
        }
        if (
          newBackingSealUnPrintable &&
          (bread.backing_sticker_status !== BackingStickerStatus.OK ||
            bread.daseruno_status !== BreadStatus.PUBLIC ||
            bread.daseruno_recipe_status !== BreadStatus.PUBLIC)
        ) {
          return true
        }
        return false
      })
    }
    let newInputBreadInfoPublic = payload.inputBreadInfoPublic
    let newInputBreadInfoDraft = payload.inputBreadInfoDraft
    if (newInputBreadInfoPublic || newInputBreadInfoDraft) {
      newBreads = newBreads.filter((bread) => {
        if (
          newInputBreadInfoPublic &&
          bread.daseruno_status === BreadStatus.PUBLIC
        ) {
          return true
        }
        if (
          newInputBreadInfoDraft &&
          bread.daseruno_status !== BreadStatus.PUBLIC
        ) {
          return true
        }
        return false
      })
    }
    let newInputMaterialInfoComplete = payload.inputMaterialInfoComplete
    let newInputMaterialInfoNotInput = payload.inputMaterialInfoNotInput
    let newInputMaterialInfoAnalyze = payload.inputMaterialInfoAnalyze
    // let newInputRecipeInfoPublic = payload.inputRecipeInfoPublic
    let newInputRecipeInfoDraft = payload.inputRecipeInfoDraft
    if (
      newInputRecipeInfoDraft ||
      newInputMaterialInfoComplete ||
      newInputMaterialInfoNotInput ||
      newInputMaterialInfoAnalyze
    ) {
      newBreads = newBreads.filter((bread) => {
        if (
          newInputRecipeInfoDraft &&
          !!bread.daseruno_recipe_status &&
          bread.daseruno_recipe_status !== BreadStatus.PUBLIC
        ) {
          return true
        }
        if (
          newInputMaterialInfoComplete &&
          bread.daseruno_recipe_status === BreadStatus.PUBLIC &&
          bread.backing_sticker_status === BackingStickerStatus.OK
        ) {
          return true
        }
        if (
          newInputMaterialInfoNotInput &&
          bread.daseruno_recipe_status === BreadStatus.PUBLIC &&
          bread.backing_sticker_status === BackingStickerStatus.NG
        ) {
          return true
        }
        if (
          newInputMaterialInfoNotInput &&
          bread.daseruno_recipe_status === BreadStatus.PUBLIC &&
          bread.backing_sticker_status !== BackingStickerStatus.OK &&
          bread.backing_sticker_status !== BackingStickerStatus.NG &&
          bread.backing_sticker_status !== BackingStickerStatus.ANALYSIS
        ) {
          return true
        }
        if (newInputMaterialInfoNotInput && !bread.daseruno_recipe_status) {
          return true
        }
        if (
          newInputMaterialInfoAnalyze &&
          bread.daseruno_recipe_status === BreadStatus.PUBLIC &&
          bread.backing_sticker_status === BackingStickerStatus.ANALYSIS
        ) {
          return true
        }
        // if (
        //   newInputMaterialInfoNotInput &&
        //   bread.daseruno_recipe_status === BreadStatus.PUBLIC &&
        //   bread.backing_sticker_status !== BackingStickerStatus.OK &&
        //   bread.backing_sticker_status !== BackingStickerStatus.NG &&
        //   bread.backing_sticker_status !== BackingStickerStatus.ANALYSIS
        // ) {
        //   return true
        // }
        return false
      })
    }
    // let newInputMaterialInfoComplete = payload.inputMaterialInfoComplete
    // let newInputMaterialInfoNotInput = payload.inputMaterialInfoNotInput
    // let newInputMaterialInfoAnalyze = payload.inputMaterialInfoAnalyze
    // if (
    //   newInputMaterialInfoComplete ||
    //   newInputMaterialInfoNotInput ||
    //   newInputMaterialInfoAnalyze
    // ) {
    //   newBreads = newBreads.filter((bread) => {
    //     if (
    //       newInputMaterialInfoComplete &&
    //       bread.backing_sticker_status === BackingStickerStatus.OK
    //     ) {
    //       return true
    //     }
    //     if (newInputMaterialInfoNotInput && !bread.daseruno_recipe_status) {
    //       return true
    //     }
    //     if (
    //       newInputMaterialInfoNotInput &&
    //       bread.backing_sticker_status === BackingStickerStatus.NG
    //     ) {
    //       return true
    //     }
    //     if (
    //       newInputMaterialInfoAnalyze &&
    //       bread.backing_sticker_status === BackingStickerStatus.ANALYSIS
    //     ) {
    //       return true
    //     }
    //     if (
    //       newInputMaterialInfoNotInput &&
    //       bread.backing_sticker_status !== BackingStickerStatus.OK &&
    //       bread.backing_sticker_status !== BackingStickerStatus.NG &&
    //       bread.backing_sticker_status !== BackingStickerStatus.ANALYSIS
    //     ) {
    //       return true
    //     }
    //     return false
    //   })
    // }
    // TODO: プライスカード実装時に復活させる
    // let newPriceCardPrintable = payload.inputPriceCardPrintable
    // let newPriceCardUnPrintable = payload.inputPriceCardUnPrintable
    // if (newPriceCardPrintable || newPriceCardUnPrintable) {
    //   newBreads = newBreads.filter((bread) => {
    //     if (
    //       newPriceCardPrintable &&
    //       bread.is_print_price_card === newPriceCardPrintable
    //     ) {
    //       return true
    //     }
    //     if (
    //       newPriceCardUnPrintable &&
    //       bread.is_print_price_card === !newPriceCardUnPrintable
    //     ) {
    //       return true
    //     }
    //     return false
    //   })
    // }
    createBreadList(newBreads, staff)
  }

  const { categories } = useSelector((state: RootState) => state.categorySlice)
  //const [breadCategoryList, setBreadCategoryList] = useState<checkInfo[]>([])
  const createCategoryList = useCallback(async () => {
    await dispatch(getCategories())
    // const categoriesPayload: CategoryType[] = categories.payload
    const categoryList: checkInfo[] = []
    categories.forEach((category, index) => {
      categoryList.push({
        isChecked: false,
        label: category.name,
        size: 'semi_middle',
        type: 'default',
        onChange: () => handleBreadCategory(index),
      })
    })
    //setBreadCategoryList(categoryList)
  }, [dispatch, categories])

  const { breads } = useSelector((state: RootState) => state.breadSlice)
  const dispatchGetBreads = useCallback(async () => {
    if (!staff) {
      navigate('/initload')
      return
    }
    dispatch(setIsLoading({ isLoading: true }))
    const localBreadsPayload = await dispatch(getBreads())
    const localBreads = localBreadsPayload.payload
    breadFilter({
      inputSearchWord: searchWord,
      inputCategoryIsChecked0: categoryIsChecked0,
      inputCategoryIsChecked1: categoryIsChecked1,
      inputCategoryIsChecked2: categoryIsChecked2,
      inputCategoryIsChecked3: categoryIsChecked3,
      inputCategoryIsChecked4: categoryIsChecked4,
      inputCategoryIsChecked5: categoryIsChecked5,
      inputCategoryIsChecked6: categoryIsChecked6,
      inputCategoryIsChecked7: categoryIsChecked7,
      inputCategoryIsChecked8: categoryIsChecked8,
      inputCategoryIsChecked9: categoryIsChecked9,
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputBackingSealPrintable: backingSealPrintable,
      inputBackingSealUnPrintable: backingSealUnPrintable,
      inputBreadInfoPublic: breadInfoPublic,
      inputBreadInfoDraft: breadInfoDraft,
      inputRecipeInfoPublic: recipeInfoPublic,
      inputRecipeInfoDraft: recipeInfoDraft,
      inputMaterialInfoComplete: materialInfoComplete,
      inputMaterialInfoNotInput: materialInfoNotInput,
      inputMaterialInfoAnalyze: materialInfoAnalyze,
      inputPriceCardPrintable: false,
      inputPriceCardUnPrintable: false,
      localBreads: localBreads,
    })
    // createBreadList(breads.payload)
    dispatch(setIsLoading({ isLoading: false }))
  }, [dispatch])
  const createBreadList = useCallback(
    async (breads: BreadType[], staff: Staff | null) => {
      const headerList: TableHeader[] = [
        {
          category: 'check',
          elementInfo: {
            checkbox: {
              isChecked: headerCheck,
              label: 'ALL',
              size: 'table_header_small',
              type: 'table_header',
            },
          },
        },
        {
          category: 'thumbnail',
          elementInfo: {
            text: {
              label: '',
            },
          },
        },
        {
          // category: 'name',semi_long_name2
          category: 'semi_long_name',
          elementInfo: {
            text: {
              label: '商品名',
            },
          },
        },
        {
          category: 'price',
          elementInfo: {
            text: {
              label: '価格(税込)',
            },
          },
        },
        {
          category: 'status',
          elementInfo: {
            text: {
              label: '裏貼りシール',
            },
          },
        },
        {
          category: 'status',
          elementInfo: {
            text: {
              label: '商品基本情報',
            },
          },
        },
        // {
        //   category: 'status',
        //   elementInfo: {
        //     text: {
        //       label: 'プライスカード',
        //     },
        //   },
        // },
        // {
        //   category: 'status',
        //   elementInfo: {
        //     text: {
        //       label: '販売状況',
        //     },
        //   },
        // },
        {
          category: 'status',
          elementInfo: {
            text: {
              label: 'レシピ情報',
            },
          },
        },
        // TODO: 削除の運用方針が定まったらアクティブにする
        // {
        //   category: 'icon',
        //   elementInfo: {
        //     text: {
        //       label: '削除',
        //     },
        //   },
        // },
      ]
      if (
        (staff?.isAdmin === false &&
          (!staff?.ds_roles ||
            staff?.ds_roles?.filter(
              (role) => role.store_ref.id === staff?.storeId,
            ).length === 0 ||
            staff?.ds_roles?.filter(
              (role) => role.store_ref.id === staff?.storeId,
            )[0].role === 9)) ||
        (staff?.isAdmin === true && adminShopId)
      ) {
        headerList.push({
          category: 'icon',
          elementInfo: {
            text: {
              label: '編集',
            },
          },
        })
      }
      setheaderList(headerList)
      const breadList: TableBody[][] = []
      breads.forEach((bread) => {
        const breadRow: TableBody[] = [
          {
            category: 'check',
            active: bread.is_salling,
            elementInfo: {
              checkbox: {
                isChecked: false,
                label: '',
                size: 'table_body_small',
                type: 'table_body',
                readOnly: !(
                  bread.backing_sticker_status === BackingStickerStatus.OK &&
                  bread.daseruno_status === BreadStatus.PUBLIC &&
                  bread.daseruno_recipe_status === BreadStatus.PUBLIC
                ),
                breadId: bread.id,
              },
            },
          },
          {
            category: 'thumbnail',
            active: bread.is_salling,
            elementInfo: {
              image: {
                src: bread.image_url ? bread.image_url : defaultBread,
                alt: 'bread',
              },
            },
          },
          {
            // 権限のない人は商品詳細へリンクさせない
            category:
              (staff?.isAdmin === false &&
                (!staff?.ds_roles ||
                  staff?.ds_roles?.filter(
                    (role) => role.store_ref.id === staff?.storeId,
                  ).length === 0 ||
                  staff?.ds_roles?.filter(
                    (role) => role.store_ref.id === staff?.storeId,
                  )[0].role === 9)) ||
              (staff?.isAdmin === true && adminShopId)
                ? 'semi_long_link'
                : 'name',
            active: bread.is_salling,
            elementInfo:
              (staff?.isAdmin === false &&
                (!staff?.ds_roles ||
                  staff?.ds_roles?.filter(
                    (role) => role.store_ref.id === staff?.storeId,
                  ).length === 0 ||
                  staff?.ds_roles?.filter(
                    (role) => role.store_ref.id === staff?.storeId,
                  )[0].role === 9)) ||
              (staff?.isAdmin === true && adminShopId)
                ? {
                    link: {
                      label: bread.name,
                      to: '/bread/detail/' + bread.id,
                    },
                  }
                : {
                    text: {
                      label: bread.name,
                    },
                  },
          },
          {
            category: 'price',
            active: bread.is_salling,
            elementInfo: {
              text: {
                label: bread.selling_price
                  ? String(Math.floor(Number(bread.selling_price) * TAX)) + '円'
                  : '円',
              },
            },
          },
          {
            category: 'status',
            active: bread.is_salling,
            elementInfo: {
              status: {
                name:
                  bread.backing_sticker_status === BackingStickerStatus.OK &&
                  bread.daseruno_status === BreadStatus.PUBLIC &&
                  bread.daseruno_recipe_status === BreadStatus.PUBLIC
                    ? '印刷可能'
                    : '印刷不可',
                color: !bread.is_salling
                  ? 'gray'
                  : bread.backing_sticker_status === BackingStickerStatus.OK &&
                    bread.daseruno_status === BreadStatus.PUBLIC &&
                    bread.daseruno_recipe_status === BreadStatus.PUBLIC
                  ? 'red'
                  : 'blue',
              },
            },
          },
          {
            category: 'status',
            active: bread.is_salling,
            elementInfo: {
              status: {
                name:
                  bread.daseruno_status === BreadStatus.PUBLIC
                    ? '完了'
                    : '下書き',
                color: !bread.is_salling
                  ? 'gray'
                  : bread.daseruno_status === BreadStatus.PUBLIC
                  ? 'red'
                  : 'blue',
              },
            },
          },
          {
            category: 'status',
            active: bread.is_salling,
            elementInfo: {
              status: {
                name: !bread.daseruno_recipe_status
                  ? '未入力'
                  : bread.daseruno_recipe_status !== BreadStatus.PUBLIC
                  ? '下書き'
                  : bread.backing_sticker_status === BackingStickerStatus.OK
                  ? '完了'
                  : bread.backing_sticker_status ===
                    BackingStickerStatus.ANALYSIS
                  ? '算出中'
                  : '未入力',
                color: !bread.is_salling
                  ? 'gray'
                  : bread.daseruno_recipe_status !== BreadStatus.PUBLIC
                  ? 'blue'
                  : bread.backing_sticker_status === BackingStickerStatus.OK
                  ? 'red'
                  : bread.backing_sticker_status ===
                    BackingStickerStatus.ANALYSIS
                  ? 'blue'
                  : 'blue',
              },
            },
          },
          // {
          //   category: 'status',
          //   active: bread.is_salling,
          //   elementInfo: {
          //     status: {
          //       name:
          //         bread.backing_sticker_status === BackingStickerStatus.OK
          //           ? '完了'
          //           : bread.backing_sticker_status ===
          //             BackingStickerStatus.ANALYSIS
          //           ? '算出中'
          //           : '未入力',
          //       color: !bread.is_salling
          //         ? 'gray'
          //         : bread.backing_sticker_status === BackingStickerStatus.OK
          //         ? 'red'
          //         : bread.backing_sticker_status ===
          //           BackingStickerStatus.ANALYSIS
          //         ? 'blue'
          //         : 'blue',
          //     },
          //   },
          // },
          // {
          //   category: 'status',
          //   elementInfo: {
          //     status: {
          //       name:
          //         bread.backing_sticker_status === BackingStickerStatus.ANALYSIS
          //           ? '分析中'
          //           : bread.daseruno_status === BreadStatus.DRAFT
          //           ? '入力途中'
          //           : bread.backing_sticker_status === BackingStickerStatus.OK
          //           ? '可能'
          //           : '入力途中',
          //       color:
          //         bread.backing_sticker_status === BackingStickerStatus.ANALYSIS
          //           ? 'gray'
          //           : bread.daseruno_status === BreadStatus.DRAFT
          //           ? 'gray'
          //           : bread.backing_sticker_status === BackingStickerStatus.OK
          //           ? 'blue'
          //           : 'gray',
          //     },
          //   },
          // },
          // {
          //   category: 'status',
          //   elementInfo: {
          //     status: {
          //       name: bread.is_print_price_card ? '可能' : '不可能',
          //       color: bread.is_print_price_card ? 'blue' : 'gray',
          //     },
          //   },
          // },
          // {
          //   category: 'status',
          //   elementInfo: {
          //     status: {
          //       name: bread.is_salling ? '販売中' : '未販売',
          //       color: bread.is_salling ? 'green' : 'gray',
          //     },
          //   },
          // },
          // {
          //   category: 'status',
          //   elementInfo: {
          //     status: {
          //       name:
          //         bread.daseruno_status === BreadStatus.PUBLIC
          //           ? '公開'
          //           : '下書き',
          //       color:
          //         bread.daseruno_status === BreadStatus.PUBLIC ? 'red' : 'gray',
          //     },
          //   },
          // },
          // TODO: 削除の運用方針が定まったらアクティブにする
          // {
          //   category: 'icon',
          //   elementInfo: {
          //     button: {
          //       label: '',
          //       color: 'white',
          //       size: 'icon',
          //       icon: 'delete_gray',
          //     },
          //   },
          // },
        ]
        // 権限のない人は編集できない
        if (
          (staff?.isAdmin === false &&
            (!staff?.ds_roles ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              ).length === 0 ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              )[0].role === 9)) ||
          (staff?.isAdmin === true && adminShopId)
        ) {
          breadRow.push({
            category: 'icon',
            active: bread.is_salling,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size: 'icon',
                icon: 'edit_gray',
                to: '/bread/edit/' + bread.id,
              },
            },
          })
        }
        breadList.push(breadRow)
      })
      setBreadList(breadList)
    },
    [breads, staff],
  )

  const handleSubmmit = async (to: string | undefined) => {
    if (to) {
      const id = to.split('/bread/edit/')[1]
        ? to.split('/bread/edit/')[1]
        : null
      if (id) {
        await dispatch(getBreadById({ id: id }))
        navigate(to)
      }
    }
  }

  const handleBreadIdList = (breadIdList: string[]) => {
    setCheckedBreadIdList(breadIdList)
  }

  const handleHeaderCheck = (headerCheck: boolean) => {
    setHeaderCheck(headerCheck)
  }

  useEffect(() => {
    dispatchGetBreads()
    createCategoryList()
  }, [staff])
  useEffect(() => {
    dispatchGetBreads()
  }, [adminShopId])

  return (
    <div className={styles.container}>
      <div className={styles.dashboardPageHeadContainer}>
        <PageHedding title="ホーム" to="" />
        <div>
          {(staff?.isAdmin === false &&
            (!staff?.ds_roles ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              ).length === 0 ||
              staff?.ds_roles?.filter(
                (role) => role.store_ref.id === staff?.storeId,
              )[0].role === 9)) ||
          (staff?.isAdmin === true && adminShopId) ? (
            <SubmitButton
              label="新規商品を登録する"
              color="orange"
              size="xlarge"
              icon="plus"
              onClick={() => navigate('/bread/add')}
            />
          ) : (
            <SubmitButton
              label="新規商品を登録する"
              color="gray"
              size="xlarge"
              icon="plus"
              onClick={() => {}}
            />
          )}
        </div>
      </div>
      <div className={styles.dashboardSearchContainer}>
        <div className={styles.dashboardSearchRow}>
          <InputTextWithLightLabel
            labelName="フリーワード検索"
            description=""
            line={1}
            type="text"
            size="middle"
            value={searchWord}
            placeholder="検索ワードを入力"
            onChange={(event) => handleSearchWord(event.target.value)}
          />
          <div className={styles.breadCategoryContainer}>
            <SelectCheckBoxWithLightLabel
              labelName="商品カテゴリーを選ぶ"
              description=""
              line={1}
              size="xlarge"
              value={displaySelectBoxPlaceholder}
              displaySelectBoxValue={displaySelectBoxValue}
              onBlur={() => {}}
              onFocus={() => {}}
              checkInfos={[
                {
                  isChecked: categoryIsChecked0,
                  label:
                    categories[0] && categories[0].name
                      ? categories[0].name
                      : '',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(0),
                },
                {
                  isChecked: categoryIsChecked1,
                  label:
                    categories[1] && categories[1].name
                      ? categories[1].name
                      : '',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(1),
                },
                {
                  isChecked: categoryIsChecked2,
                  label:
                    categories[2] && categories[2].name
                      ? categories[2].name
                      : '',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(2),
                },
                {
                  isChecked: categoryIsChecked3,
                  label:
                    categories[3] && categories[3].name
                      ? categories[3].name
                      : '',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(3),
                },
                {
                  isChecked: categoryIsChecked4,
                  label:
                    categories[4] && categories[4].name
                      ? categories[4].name
                      : '',
                  size: 'middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(4),
                },
                {
                  isChecked: categoryIsChecked5,
                  label:
                    categories[5] && categories[5].name
                      ? categories[5].name
                      : '',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(5),
                },
                {
                  isChecked: categoryIsChecked6,
                  label:
                    categories[6] && categories[6].name
                      ? categories[6].name
                      : '',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(6),
                },
                {
                  isChecked: categoryIsChecked7,
                  label:
                    categories[7] && categories[7].name
                      ? categories[7].name
                      : '',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(7),
                },
                {
                  isChecked: categoryIsChecked8,
                  label:
                    categories[8] && categories[8].name
                      ? categories[8].name
                      : '',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(8),
                },
                {
                  isChecked: categoryIsChecked9,
                  label:
                    categories[9] && categories[9].name
                      ? categories[9].name
                      : '',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBreadCategory(9),
                },
              ]}
            />
          </div>
        </div>
        <div className={styles.dashboardSearchRow}>
          <RadioButtonFilterWithLightLabel
            labelName="店頭販売状況"
            description=""
            line={1}
            radioSize="small"
            isChecked={!breadSaling && !breadSalesSuspension}
            onChange={handleBreadSalesAll}
            checkInfos={[
              {
                isChecked: breadSaling,
                label: '販売中',
                onChange: handleBreadSaling,
              },
              {
                isChecked: breadSalesSuspension,
                label: '販売停止',
                onChange: handleBreadSalesSuspension,
              },
            ]}
          />
          <div className={styles.dashboardSearchRowSection}></div>
          <RadioButtonFilterWithLightLabel
            labelName="裏貼りシール印刷"
            description=""
            line={1}
            radioSize="small"
            isChecked={!backingSealPrintable && !backingSealUnPrintable}
            onChange={handleBackingSealAll}
            checkInfos={[
              {
                isChecked: backingSealPrintable,
                label: '可能',
                onChange: handleBackingSealPrintable,
              },
              {
                isChecked: backingSealUnPrintable,
                label: '不可能',
                onChange: handleBackingSealUnPrintable,
              },
            ]}
          />
          <div className={styles.dashboardSearchRowSection}></div>
          <RadioButtonFilterWithLightLabel
            labelName="商品基本情報"
            description=""
            line={1}
            radioSize="small"
            isChecked={!breadInfoPublic && !breadInfoDraft}
            onChange={handleBreadInfoAll}
            checkInfos={[
              {
                isChecked: breadInfoPublic,
                label: '完了',
                onChange: handleBreadInfoPublic,
              },
              {
                isChecked: breadInfoDraft,
                label: '下書き',
                onChange: handleBreadInfoDraft,
              },
            ]}
          />
          <div className={styles.dashboardSearchRowSection}></div>
          <RadioButtonFilterWithLightLabel
            labelName="レシピ情報"
            description=""
            line={1}
            radioSize="small"
            isChecked={
              !recipeInfoDraft &&
              !materialInfoComplete &&
              !materialInfoNotInput &&
              !materialInfoAnalyze
            }
            onChange={handleRecipeInfoAll}
            checkInfos={[
              {
                isChecked: materialInfoComplete,
                label: '完了',
                onChange: handleMaterialInfoComplete,
              },
              {
                isChecked: materialInfoNotInput,
                label: '未入力',
                onChange: handleMaterialInfoNotInput,
              },
              {
                isChecked: materialInfoAnalyze,
                label: '算出中',
                onChange: handleMaterialInfoAnalyze,
              },
              {
                isChecked: recipeInfoDraft,
                label: '下書き',
                onChange: handleRecipeInfoDraft,
              },
            ]}
          />
          {/* <div className={styles.dashboardSearchRowSection}></div>
          <RadioButtonFilterWithLightLabel
            labelName="原材料入力状況"
            description=""
            line={1}
            radioSize="small"
            isChecked={
              !materialInfoComplete &&
              !materialInfoNotInput &&
              !materialInfoAnalyze
            }
            onChange={handleMaterialInfoAll}
            checkInfos={[
              {
                isChecked: materialInfoComplete,
                label: '完了',
                onChange: handleMaterialInfoComplete,
              },
              {
                isChecked: materialInfoNotInput,
                label: '未入力',
                onChange: handleMaterialInfoNotInput,
              },
              {
                isChecked: materialInfoAnalyze,
                label: '算出中',
                onChange: handleMaterialInfoAnalyze,
              },
            ]}
          /> */}
        </div>
      </div>

      <Table
        headers={headerList}
        bodies={breadList}
        handleSubmmit={handleSubmmit}
        handleBreadIdList={handleBreadIdList}
        handleHeaderCheck={handleHeaderCheck}
      />
      <span className={styles.dashboardTableNotes}>
        ※sacri for
        Ownersで入力した情報が仮で入っています。必要に応じて、残りの情報や、追加で商品の登録をしてください。
      </span>

      <div className={styles.dashboardButtonWrapContainer}>
        <div className={styles.dashboardButtonContainer}>
          {checkedBreadIdList.length > 0 ? (
            <SubmitButton
              label="裏貼りシールを印刷する"
              color="orange"
              size="large"
              icon="none"
              onClick={() =>
                navigate(
                  '/food_nutrition_label?breadIds=' +
                    checkedBreadIdList.join(','),
                )
              }
            />
          ) : (
            <SubmitButton
              label="裏貼りシールを印刷する"
              color="gray"
              size="large"
              icon="none"
              onClick={() => {}}
            />
          )}
          {/* <span className={styles.dashboardButtonSpacer}></span>
          <SubmitButton
            label="プライスカードを印刷する"
            color="orange"
            size="large"
            icon="none"
            onClick={() => console.log('test')}
          /> */}
        </div>
        {/* <SubmitButton
          label="CSV形式に書き出す"
          color="orange"
          size="large"
          icon="none"
          onClick={() => console.log('test')}
        /> */}
      </div>
    </div>
  )
}

export default BreadDashBoardTemplate
