import { FC } from 'react'
import styles from 'styles/components/pagehedding.module.scss'
import pageHeddingArrow from 'images/PageHedding/page-hedding-arrow.png'

type Props = {
  title: string
  handleClick: () => void
}

const BackEditHedding: FC<Props> = (props) => {
  const { title, handleClick } = props

  return (
    <div className={styles.container}>
      <div className={styles.pageHeddingLink} onClick={handleClick}>
        <img
          className={styles.pageHeddingArrow}
          src={pageHeddingArrow}
          alt="戻る"
        ></img>
      </div>
      <p className={styles.pageHeddingText}>{title}</p>
    </div>
  )
}

export default BackEditHedding
