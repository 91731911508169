import { FC } from 'react'
import styles from 'styles/components/plan.module.scss'
import checkIcon from 'images/check-icon.png'

type Props = {
  mainText: string
  subText?: string
  color?: string
}

const PlanColumn: FC<Props> = (props) => {
  const { mainText, subText, color } = props

  const colorMainStyle = (() => {
    switch (color) {
      case 'red':
        return styles.mainText_red
      default:
        return styles.mainText
    }
  })()

  const colorSubStyle = (() => {
    switch (color) {
      case 'red':
        return styles.subText_red
      default:
        return styles.subText
    }
  })()

  return (
    <div className={styles.container}>
      <div>
        <img className={styles.checkImage} src={checkIcon} alt="check"></img>
      </div>
      <div>
        <p className={colorMainStyle}>{mainText}</p>
        {subText ? <p className={colorSubStyle}>{subText}</p> : ''}
      </div>
    </div>
  )
}

export default PlanColumn
