import SubmitButton from 'components/Button/SubmitButton'
import RadioButton from 'components/Form/RadioButton'
import SelectBoxWithLightLabel from 'components/Form/WithLightLabel/SelectBox'
import PageHedding from 'components/PageHedding/PageHedding'
import { FC, useCallback, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import styles from 'styles/components/template.module.scss'
import CardItem from './CardItem'
import IconArrow from './IconArrow'

export type PriceCardData = {
  breadName: string
  count: number
  price: number
  description: string
  allergen: string
}
const datas: PriceCardData[] = [
  {
    breadName: 'バターなんていらないかも、と思わず声に出したくなるほどの生地',
    count: 30,
    price: 1000,
    description:
      '北海道産バター使用。表面サクサク中はしっとり！北海道産バター使用。表面サクサク中はしっとり！',
    allergen: '卵・乳・小麦・えび・かに・落花生・そば',
  },
  {
    breadName: 'バターなんていらないかも、と思わず声に出したくなるほどの生地2',
    count: 30,
    price: 1000,
    description:
      '北海道産バター使用。表面サクサク中はしっとり！北海道産バター使用。表面サクサク中はしっとり！',
    allergen: '卵・乳・小麦・えび・かに・落花生・そば',
  },
  {
    breadName: 'バターなんていらないかも、と思わず声に出したくなるほどの生地3',
    count: 30,
    price: 1000,
    description:
      '北海道産バター使用。表面サクサク中はしっとり！北海道産バター使用。表面サクサク中はしっとり！',
    allergen: '卵・乳・小麦・えび・かに・落花生・そば',
  },
]
const PriceCardTemplate: FC = () => {
  const [font, setFont] = useState<string>('')
  const [priceCardData, setPriceCardData] = useState<PriceCardData[]>(datas)
  const [priceCardDataForPreview, setPriceCardDataForPreviewt] =
    useState<PriceCardData[]>(datas)
  const [previewPage, setPreviewPage] = useState<number>(0)
  const [isPreview, setIsPreview] = useState(false)
  const [isOnlyTakeOut, setIsOnlyTakeOut] = useState(true)
  const handlePreview = useCallback(() => {
    const previewArr: PriceCardData[] = []
    if (priceCardData.length !== 0) {
      priceCardData.forEach((datas) => {
        ;[...Array(datas.count)].forEach(() => {
          previewArr.push(datas)
        })
      })
    }
    setPriceCardDataForPreviewt(previewArr)
    setIsPreview(true)
  }, [priceCardData])
  const handleClosePreview = useCallback(() => {
    setIsPreview(false)
  }, [])

  return (
    <>
      <div className={styles.container}>
        <PageHedding title="プライスカード" to="/initload" />
        <div className={styles.priceCard_head_section}>
          <div className={styles.priceCard_section_title}>
            プライスカードテンプレートの種類を全て選んでください。
          </div>
          <div className={styles.priceCard_head_unit_container}>
            <div className={styles.priceCard_head_unit_text}>
              記載する商品価格：
            </div>
            <div>
              <span className={styles.priceCard_head_radio_unit_text}>
                お持ち帰りのみ
              </span>
            </div>
            <RadioButton
              isChecked={isOnlyTakeOut}
              label=""
              onChange={() => {
                setIsOnlyTakeOut(true)
              }}
            />
            <div>
              <span className={styles.priceCard_head_radio_unit_text}>
                お持ち帰り+店内飲食
              </span>
            </div>
            <RadioButton
              isChecked={!isOnlyTakeOut}
              label=""
              onChange={() => {
                setIsOnlyTakeOut(false)
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 32,
          }}
        >
          <SelectBoxWithLightLabel
            style={{ width: 480 }}
            labelName="フォント"
            description=""
            line={1}
            size="large2"
            value={font}
            options={[
              {
                is_default: true,
                label: '----',
                value: '0',
              },
              {
                is_default: false,
                label: 'ゴシック',
                value: '1',
              },
              {
                is_default: false,
                label: '明朝体',
                value: '2',
              },
            ]}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              setFont(event.target.value)
            }
          />
          <SelectBoxWithLightLabel
            style={{ width: 480 }}
            labelName="背景色"
            description=""
            line={1}
            size="large2"
            value={font}
            options={[
              {
                is_default: true,
                label: '----',
                value: '0',
              },
              {
                is_default: false,
                label: 'ゴシック',
                value: '1',
              },
              {
                is_default: false,
                label: '明朝体',
                value: '2',
              },
            ]}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              setFont(event.target.value)
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 24,
          }}
        >
          <SelectBoxWithLightLabel
            style={{ width: 480 }}
            labelName="レイアウト"
            description=""
            line={1}
            size="large2"
            value={font}
            options={[
              {
                is_default: true,
                label: '----',
                value: '0',
              },
              {
                is_default: false,
                label: 'ゴシック',
                value: '1',
              },
              {
                is_default: false,
                label: '明朝体',
                value: '2',
              },
            ]}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              setFont(event.target.value)
            }
          />
          <SelectBoxWithLightLabel
            style={{ width: 480 }}
            labelName="枠戦"
            description=""
            line={1}
            size="large2"
            value={font}
            options={[
              {
                is_default: true,
                label: '----',
                value: '0',
              },
              {
                is_default: false,
                label: 'ゴシック',
                value: '1',
              },
              {
                is_default: false,
                label: '明朝体',
                value: '2',
              },
            ]}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              setFont(event.target.value)
            }
          />
        </div>

        <div
          className={styles.priceCard_section_title}
          style={{ marginTop: 56 }}
        >
          商品ごとに印刷したい数、表示したい項目を入力してください。
        </div>
        <div className={styles.priceCard_table}>
          <div className={styles.priceCard_table_container_head}>
            <div
              className={`${styles.priceCard_table_row} ${styles.priceCard_table_row_gray}`}
            >
              <div
                className={`${styles.priceCard_table_row_head} ${styles.priceCard_table_row_bread_name}`}
              >
                商品名
              </div>
              <div
                className={`${styles.priceCard_table_row_head} ${styles.priceCard_table_row_count}`}
              >
                枚数
              </div>
              <div
                className={`${styles.priceCard_table_row_head} ${styles.priceCard_table_row_price}`}
              >
                価格（税込）
              </div>
              <div
                className={`${styles.priceCard_table_row_head} ${styles.priceCard_table_row_description}`}
              >
                商品説明
              </div>
              <div
                className={`${styles.priceCard_table_row_head} ${styles.priceCard_table_row_allergen}`}
              >
                アレルゲン（特定原材料）
              </div>
              <div
                className={`${styles.priceCard_table_row_head} ${styles.priceCard_table_row_delete}`}
              >
                削除
              </div>
            </div>
          </div>
          <div className={styles.priceCard_table_container_body}>
            <Scrollbars style={{ width: 998 }}>
              {datas.map((data, i) => {
                return (
                  <CardItem
                    data={data}
                    i={i}
                    priceCardData={priceCardData}
                    setPriceCardData={setPriceCardData}
                  />
                )
              })}
            </Scrollbars>
          </div>
        </div>

        <div
          className={styles.priceCard_section_title}
          style={{ marginTop: 56 }}
        >
          商品ごとに印刷したい数、表示したい項目を入力してください。
        </div>
        <SelectBoxWithLightLabel
          style={{ width: 430 }}
          labelName=""
          description=""
          line={1}
          size="large2"
          value={font}
          options={[
            {
              is_default: true,
              label: 'マルチカード 各種プリンタ兼用紙（51002）91mm×55mm',
              value: '0',
            },
            {
              is_default: false,
              label: 'マルチカード 各種プリンタ兼用紙（51002）91mm×55mm',
              value: '1',
            },
            {
              is_default: false,
              label: 'マルチカード 各種プリンタ兼用紙（51002）91mm×55mm',
              value: '2',
            },
          ]}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            setFont(event.target.value)
          }
        />
        <div className={styles.shopInfoButtonContainer}>
          <SubmitButton
            label="プレビューをみる"
            color="orange_outlined"
            size="xmiddle"
            icon="none"
            onClick={handlePreview}
          />
          <div style={{ marginLeft: 32 }}>
            <SubmitButton
              label="プライスカードを印刷する"
              color="orange"
              size="xmiddle"
              icon="none"
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
      {isPreview && (
        <div className={styles.modal_price_card}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {previewPage !== 0 ? (
              <div
                style={{ marginRight: 52, cursor: 'pointer' }}
                onClick={() => {
                  setPreviewPage(previewPage - 1)
                }}
              >
                <IconArrow reverse />
              </div>
            ) : (
              <div style={{ marginRight: 52, width: 20 }} />
            )}
            <div
              style={{
                width: 416,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {[...Array(10)].map((_, i) => {
                return priceCardDataForPreview[previewPage * 10 + i] ? (
                  <div className={styles.modal_price_card_item}>
                    <div className={styles.modal_price_card_text_bread_name}>
                      {priceCardDataForPreview[previewPage * 10 + i].breadName}
                    </div>
                    <div className={styles.modal_price_card_text_price}>
                      ¥ {priceCardDataForPreview[previewPage + i].price}
                    </div>
                    <div className={styles.modal_price_card_text_allergen}>
                      {priceCardDataForPreview[previewPage * 10 + i].allergen}
                    </div>
                  </div>
                ) : (
                  <div className={styles.modal_price_card_item} />
                )
              })}
            </div>
            {priceCardDataForPreview[(previewPage + 1) * 10 + 1] ? (
              <div
                style={{ marginLeft: 52, cursor: 'pointer' }}
                onClick={() => {
                  setPreviewPage(previewPage + 1)
                }}
              >
                <IconArrow />
              </div>
            ) : (
              <div style={{ marginLeft: 52, width: 20 }} />
            )}
          </div>

          <div className={styles.shopInfoButtonContainer}>
            <SubmitButton
              label="閉じる"
              color="orange_outlined"
              size="xmiddle"
              icon="none"
              onClick={handleClosePreview}
            />
            <div style={{ marginLeft: 32 }}>
              <SubmitButton
                label="プライスカードを印刷する"
                color="orange"
                size="xmiddle"
                icon="none"
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PriceCardTemplate
