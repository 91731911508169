import { FC, useState } from 'react'
import styles from 'styles/components/label.module.scss'
import inputToolTipIcon from 'images/Form/input-tooltip-icon.png'

type Props = {
  name: string
  description: string
  line: number
  size?: string
  required?: boolean
  questioned?: boolean
}

const LightLabel: FC<Props> = (props) => {
  const { name, description, line, size, required, questioned } = props
  const [viewToolTip, setViewToolTip] = useState<boolean>(false)

  const handleMouseEnter = () => {
    setViewToolTip(true)
  }
  const handleMouseLeave = () => {
    setViewToolTip(false)
  }

  const sizeStyle = (() => {
    switch (size) {
      case 'minimum':
        return styles.lightLabelContainer_minimum
      default:
        return styles.lightLabelContainer
    }
  })()

  const className = `${sizeStyle}`

  return (
    <div className={className}>
      <div className={styles.lightLabelText}>{name}</div>
      <div className={styles.inputToolTipContainer}>
        {questioned ? (
          <img
            className={
              line === 3
                ? styles.inputToolTipIconLine3
                : line === 2
                ? styles.inputToolTipIconLine2
                : styles.inputToolTipIcon
            }
            src={inputToolTipIcon}
            alt="?"
          ></img>
        ) : (
          ''
        )}
      </div>
      {description ? (
        <div
          className={styles.inputToolTipContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            className={
              // line === 3
              //   ? styles.inputToolTipIconLine3
              //   : line === 2
              //   ? styles.inputToolTipIconLine2
              //   : styles.inputToolTipIcon
              // name.length > 15
              //   ? styles.inputToolTipIconLine3
              //   : name.length > 6
              //   ? styles.inputToolTipIconLine2
              //   : styles.inputToolTipIcon
              styles.inputToolTipIcon
            }
            src={inputToolTipIcon}
            alt="?"
          ></img>
        </div>
      ) : (
        ''
      )}
      {viewToolTip ? (
        <div className={styles.inputToolTip_light}>
          {description.split('<br>').map((text) => (
            <div>{text}</div>
          ))}
        </div>
      ) : (
        ''
      )}
      {required && <div className={styles.requiredLabel}>必須</div>}
    </div>
  )
}

export default LightLabel
