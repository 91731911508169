import { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import { DataType } from 'reducks/business/slice'
import SubmitButton from 'components/Button/SubmitButton'
import { getMaterialById } from 'reducks/material/slice'
import { RootState } from 'reducks/reducers'

type Props = {
  maker: string
  name: string
  middlePercent: string
  mainPercent: string
  allPercent: string
  unitPrice: string | null
  costPrice: string | null
  dataType: DataType | null
  docId: string | null
  isHeader: boolean
  isBold: boolean
  isParagraphDown: boolean
  mode: 'primary' | 'secondary' | undefined
  nameMinusWidth?: number
  underLine?: 'gray' | 'orange' | undefined
  isSimulation?: boolean
  handleBoxDeleteButton?: (
    index: number | undefined,
    dataType: DataType | undefined,
  ) => void
  boxDeleteIndex?: number
  boxDeleteDataType?: DataType
  unitPriceInvisible?: boolean
}

const BreadDetailRow: FC<Props> = (props) => {
  const {
    maker,
    name,
    middlePercent,
    mainPercent,
    allPercent,
    unitPrice,
    costPrice,
    dataType,
    docId,
    isHeader,
    isBold,
    isParagraphDown,
    mode,
    nameMinusWidth,
    underLine,
    isSimulation,
    handleBoxDeleteButton,
    boxDeleteIndex,
    boxDeleteDataType,
    unitPriceInvisible,
  } = props

  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [editTo, setEditTo] = useState<string>('')
  const [costEditTo, setCostEditTo] = useState<string>('')
  const { staff, adminShopId } = useSelector(
    (state: RootState) => state.staffSlice,
  )

  useEffect(() => {
    determineEditTo()
  }, [docId, dataType])

  const determineEditTo = useCallback(() => {
    if (!docId || !dataType) {
      return
    }
    if (dataType === DataType.RECIPE) {
      // breadIdが渡される想定
      setEditTo('/bread/edit/' + docId)
    } else if (dataType === DataType.DOUGH) {
      setEditTo('/dough/edit/' + docId)
    } else if (dataType === DataType.FILLING) {
      setEditTo('/filling/edit/' + docId)
    } else if (dataType === DataType.MATERIAL) {
      setEditTo('/material/edit/' + docId)
      setCostEditTo('/material/' + docId + '/cost')
    }
  }, [docId, dataType])

  const handleSubmit = async (toCostEdit = false) => {
    if (toCostEdit) {
      if (docId && dataType === DataType.MATERIAL && editTo) {
        await dispatch(getMaterialById({ id: docId }))
        navigate(costEditTo)
      }
    } else {
      if (docId && dataType === DataType.MATERIAL && editTo) {
        await dispatch(getMaterialById({ id: docId }))
        navigate(editTo)
      }
    }
  }

  return (
    <div
      className={`${styles.bread_detail_table_row} ${
        isHeader ? styles.bread_detail_table_row_gray : ''
      } ${
        underLine === 'gray'
          ? styles.bread_detail_table_row_under_line_gray
          : underLine === 'orange'
          ? styles.bread_detail_table_row_under_line_orange
          : ''
      } ${styles.bread_detail_table_row_box_delete_relative}`}
    >
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head_bold
            : isBold
            ? styles.bread_detail_table_row_body_bold
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_name} ${
          isHeader
            ? ''
            : isBold && mode === 'secondary'
            ? ''
            : mode === 'primary'
            ? styles.bread_detail_table_row_right_border_gray
            : mode === 'secondary'
            ? styles.bread_detail_table_row_right_border_orange
            : ''
        } ${
          !isHeader && isParagraphDown && mode === 'primary'
            ? styles.bread_detail_table_row_paragraph_down
            : ''
        }`}
        style={{ width: 388 - Number(nameMinusWidth) }}
      >
        {name}
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_maker} ${
          styles.bread_detail_table_row_margin_left
        }`}
      >
        {maker}
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_percent} ${
          styles.bread_detail_table_row_margin_right
        }`}
      >
        {middlePercent}
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_percent} ${
          styles.bread_detail_table_row_margin_right
        } ${
          isHeader || (isBold && mode === 'secondary')
            ? styles.bread_detail_table_row_right_border_white
            : mode === 'primary'
            ? styles.bread_detail_table_row_right_border_gray
            : mode === 'secondary'
            ? styles.bread_detail_table_row_right_border_orange
            : ''
        }`}
      >
        {mainPercent}
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head_bold
            : styles.bread_detail_table_row_body_bold
        } ${styles.bread_detail_table_row_percent} ${
          styles.bread_detail_table_row_margin_right
        } ${
          isHeader || (isBold && mode === 'secondary')
            ? styles.bread_detail_table_row_right_border_white
            : mode === 'primary'
            ? styles.bread_detail_table_row_right_border_gray
            : mode === 'secondary'
            ? styles.bread_detail_table_row_right_border_orange
            : ''
        }`}
      >
        {allPercent}
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_price}`}
      >
        {!unitPriceInvisible && unitPrice ? unitPrice : ''}
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head_bold
            : isBold
            ? styles.bread_detail_table_row_body_bold
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_price}`}
      >
        {costPrice ? costPrice : ''}
      </div>
      {!isSimulation ? (
        <>
          <div
            className={`${
              isHeader
                ? styles.bread_detail_table_row_head
                : styles.bread_detail_table_row_body
            } ${styles.bread_detail_table_row_action}`}
          >
            {((staff?.isAdmin === false && staff.isStandardPlan === true) ||
              (staff?.isAdmin === true && adminShopId)) &&
            isHeader ? (
              '原価'
            ) : ((staff?.isAdmin === false && staff.isStandardPlan === true) ||
                (staff?.isAdmin === true && adminShopId)) &&
              costEditTo ? (
              <SubmitButton
                label=""
                color="white"
                size="icon"
                icon="cost_input_gray"
                onClick={() => handleSubmit(true)}
              />
            ) : (
              ''
            )}
          </div>
          <div
            className={`${
              isHeader
                ? styles.bread_detail_table_row_head
                : styles.bread_detail_table_row_body
            } ${styles.bread_detail_table_row_action}`}
          >
            {staff?.isAdmin && isHeader ? (
              '編集'
            ) : staff?.isAdmin && editTo ? (
              <SubmitButton
                label=""
                color="white"
                size="icon"
                icon="edit_gray"
                onClick={() => handleSubmit()}
              />
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        ''
      )}
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_action}`}
      >
        {staff?.isAdmin && isHeader && isSimulation ? '削除' : ''}
      </div>
      {handleBoxDeleteButton ? (
        <div className={`${styles.bread_detail_table_row_box_delete_absolute}`}>
          <SubmitButton
            label=""
            color="none"
            size="icon"
            icon="delete_orange"
            onClick={() =>
              handleBoxDeleteButton(boxDeleteIndex, boxDeleteDataType)
            }
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default BreadDetailRow
