import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import BreadDetailTemplate from 'components/Templates/BreadDetailTemplate'

const BreadDetail: FC = () => {
  return (
    <Layout title={'商品詳細'} page="BreadDetail">
      <BreadDetailTemplate />
    </Layout>
  )
}

export default BreadDetail
