import { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import RadioButtonFilterWithLightLabel from 'components/Form/WithLightLabel/RadioButtonFilter'
// import SubmitButton from 'components/Button/SubmitButton'
import Table, { TableBody } from 'components/Table/Table'
import {
  getBreads,
  BreadType,
  //  updateSellingPrice,
  //  updateIdealCostRate,
  BackingStickerStatus,
  getBreadByRecipeId,
  clearBreads,
  BreadStatus,
} from 'reducks/bread/slice'
import { RootState } from 'reducks/reducers'
import { TAX } from 'Constants'
import { setIsLoading } from 'reducks/loading/slice'
import alertIcon from 'images/alert-icon.png'
import { getMaterialById, MaterialType } from 'reducks/material/slice'

const BreadCostTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const [breadList, setBreadList] = useState<TableBody[][]>([])
  const [breadSaling, setBreadSaling] = useState<boolean>(false)
  const [breadSalesSuspension, setBreadSalesSuspension] =
    useState<boolean>(false)
  const [costInputComplete, setCostInputComplete] = useState<boolean>(false)
  const [costInputNotComplete, setCostInputNotComplete] =
    useState<boolean>(false)

  const [formData, setFormData] = useState<Map<string, string>>(
    new Map<string, string>(),
  )
  const [notCostCalculatedReasonTextMap, setNotCostCalculatedReasonTextMap] =
    useState<Map<string, string>>(new Map<string, string>())
  const [currentBreads, setCurrentBreads] = useState<BreadType[]>([])

  const handleBreadSalesAll = () => {
    setBreadSaling(false)
    setBreadSalesSuspension(false)
    breadFilter({
      inputBreadSaling: false,
      inputBreadSalesSuspension: false,
      inputCostInputComplete: costInputComplete,
      inputCostInputNotComplete: costInputNotComplete,
    })
  }
  const handleBreadSaling = () => {
    setBreadSaling(true)
    setBreadSalesSuspension(false)
    breadFilter({
      inputBreadSaling: true,
      inputBreadSalesSuspension: false,
      inputCostInputComplete: costInputComplete,
      inputCostInputNotComplete: costInputNotComplete,
    })
  }
  const handleBreadSalesSuspension = () => {
    setBreadSaling(false)
    setBreadSalesSuspension(true)
    breadFilter({
      inputBreadSaling: false,
      inputBreadSalesSuspension: true,
      inputCostInputComplete: costInputComplete,
      inputCostInputNotComplete: costInputNotComplete,
    })
  }
  const handleCostInputCompleteAll = () => {
    setCostInputComplete(false)
    setCostInputNotComplete(false)
    breadFilter({
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputCostInputComplete: false,
      inputCostInputNotComplete: false,
    })
  }
  const handleCostInputComplete = () => {
    setCostInputComplete(true)
    setCostInputNotComplete(false)
    breadFilter({
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputCostInputComplete: true,
      inputCostInputNotComplete: false,
    })
  }
  const handleCostInputNotComplete = () => {
    setCostInputComplete(false)
    setCostInputNotComplete(true)
    breadFilter({
      inputBreadSaling: breadSaling,
      inputBreadSalesSuspension: breadSalesSuspension,
      inputCostInputComplete: false,
      inputCostInputNotComplete: true,
    })
  }
  const breadFilter = (payload: {
    inputBreadSaling: boolean
    inputBreadSalesSuspension: boolean
    inputCostInputComplete: boolean
    inputCostInputNotComplete: boolean
  }) => {
    let newBreads = [...breads]
    let newBreadSaling = payload.inputBreadSaling
    let newBreadSalesSuspension = payload.inputBreadSalesSuspension
    if (newBreadSaling || newBreadSalesSuspension) {
      newBreads = newBreads.filter((bread) => {
        if (newBreadSaling && bread.is_salling === newBreadSaling) {
          return true
        }
        if (
          newBreadSalesSuspension &&
          (bread.is_salling === !newBreadSalesSuspension || !bread.is_salling)
        ) {
          return true
        }
        return false
      })
    }
    let newCostInputComplete = payload.inputCostInputComplete
    let newCostInputNotComplete = payload.inputCostInputNotComplete
    if (newCostInputComplete || newCostInputNotComplete) {
      newBreads = newBreads.filter((bread) => {
        if (
          newCostInputComplete &&
          bread.cost_price &&
          Object.keys(bread.not_cost_calculated_reasons).length === 0 &&
          (!bread.not_cost_calculated_recipe_reasons ||
            bread.not_cost_calculated_recipe_reasons.length === 0) &&
          bread.daseruno_recipe_status === BreadStatus.PUBLIC &&
          bread.backing_sticker_status === BackingStickerStatus.OK
        ) {
          return true
        }
        if (
          newCostInputNotComplete &&
          !(
            bread.cost_price &&
            Object.keys(bread.not_cost_calculated_reasons).length === 0 &&
            (!bread.not_cost_calculated_recipe_reasons ||
              bread.not_cost_calculated_recipe_reasons.length === 0) &&
            bread.daseruno_recipe_status === BreadStatus.PUBLIC &&
            bread.backing_sticker_status === BackingStickerStatus.OK
          )
        ) {
          return true
        }
        return false
      })
    }
    setCurrentBreads(newBreads)
    createBreadList(newBreads, notCostCalculatedReasonTextMap)
  }

  const handleFormUnit = (
    breadId: string | undefined,
    type: string | undefined,
    value: string,
  ) => {
    if (!breadId || !type) {
      return
    }
    const newFormData = new Map<string, string>(formData)
    const targetBread = breads.filter((bread) => bread.id === breadId)
    const cost_price = targetBread[0].cost_price
    if (cost_price) {
      if (type === 'updated_selling_price') {
        // 変更後原価率を設定
        const selling_price = Number(value)
        // 小数点第二位を切り上げ
        newFormData.set(
          breadId + '*updated_cost_rate',
          String(
            Math.ceil(
              ((selling_price - cost_price) / selling_price) * 100 * 100,
            ) / 100,
          ),
        )
      }
      if (type === 'updated_cost_rate') {
        // 変更後売価を設定
        const cost_rate = Number(value)
        newFormData.set(
          breadId + '*updated_selling_price',
          String(Math.ceil((100 * cost_price) / (100 - cost_rate))),
        )
      }
      if (type === 'simulate_selling_price') {
        // シミュレーション原価率をクリア
        newFormData.set(breadId + '*simulate_cost_rate', '')
      }
      if (type === 'simulate_cost_rate') {
        // シミュレーション売価をクリア
        newFormData.set(breadId + '*simulate_selling_price', '')
      }
    }
    newFormData.set(breadId + '*' + type, value)
    setFormData(newFormData)
  }

  const { breads } = useSelector((state: RootState) => state.breadSlice)
  const dispatchGetBreads = useCallback(async () => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    // 使用できないプラン or スーパー管理者じゃなければ使用できない
    if (
      (staff?.isAdmin === true && adminShopId) ||
      (staff?.isAdmin === false && staff.isStandardPlan === true)
    ) {
      //
    } else {
      navigate('/initload')
      window.scrollTo(0, 0)
    }
    dispatch(setIsLoading({ isLoading: true }))
    await dispatch(clearBreads())
    const breadsPayload = await dispatch(getBreads())
    const breads: BreadType[] = breadsPayload.payload
    let notCostCalculatedReasonTextMap: Map<string, string> = new Map<
      string,
      string
    >()
    await Promise.all(
      breads.map(async (bread) => {
        let notCostCalculatedReasonArray = []
        let notAmountDoughBeforeBakingFlg = false
        if (bread.not_cost_calculated_reasons) {
          await Promise.all(
            Object.keys(bread.not_cost_calculated_reasons).map(
              async (materialId) => {
                const materialPayload = await dispatch(
                  getMaterialById({ id: materialId }),
                )
                const material: MaterialType | null = materialPayload.payload
                if (material) {
                  if (
                    bread.not_cost_calculated_reasons[materialId]
                      .notAmountDoughBeforeBaking
                  ) {
                    // 呼び出しレシピの焼成前重量がないのか、元のレシピの焼成前重量がないのか判定
                    const notAmountDoughBeforeBakingRecipeId =
                      bread.not_cost_calculated_reasons[materialId]
                        .notAmountDoughBeforeBakingRecipeId
                    if (notAmountDoughBeforeBakingRecipeId) {
                      const breadCallRecipePayload = await dispatch(
                        getBreadByRecipeId({
                          id: notAmountDoughBeforeBakingRecipeId,
                        }),
                      )
                      const breadCallRecipe: BreadType =
                        breadCallRecipePayload.payload
                      if (breadCallRecipe) {
                        // 呼び出しレシピの方にない
                        notCostCalculatedReasonArray.push(
                          '「' +
                            breadCallRecipe.name +
                            '」の「商品１個あたりの焼成後の重量」が入力されていません。',
                        )
                      } else {
                        // 元のレシピにないと見なす
                        notAmountDoughBeforeBakingFlg = true
                      }
                    } else {
                      // 元のレシピにない
                      notAmountDoughBeforeBakingFlg = true
                    }
                  }
                  if (
                    bread.not_cost_calculated_reasons[materialId]
                      .notInputInternalCapacity
                  ) {
                    notCostCalculatedReasonArray.push(
                      '「' +
                        material.product_name +
                        '」の原価情報の内容量・入数が登録待ちです。',
                    )
                  }
                  if (
                    bread.not_cost_calculated_reasons[materialId]
                      .notMaterialCostData
                  ) {
                    notCostCalculatedReasonArray.push(
                      '「' +
                        material.product_name +
                        '」の原価情報が入力されていません。',
                    )
                  }
                }
              },
            ),
          )
        }
        if (notAmountDoughBeforeBakingFlg) {
          notCostCalculatedReasonArray.push(
            'レシピの「商品1個あたりの重量(焼成前生地量)」が入力されていません。',
          )
        }
        if (bread.backing_sticker_status !== BackingStickerStatus.OK) {
          notCostCalculatedReasonArray.push(
            'レシピが未入力、または算出中です。',
          )
        }
        if (
          bread.daseruno_status !== BreadStatus.PUBLIC ||
          bread.daseruno_recipe_status !== BreadStatus.PUBLIC
        ) {
          notCostCalculatedReasonArray.push('レシピが下書き状態です。')
        }
        if (
          bread.not_cost_calculated_recipe_reasons &&
          bread.not_cost_calculated_recipe_reasons.length > 0
        ) {
          notCostCalculatedReasonArray.push(
            bread.not_cost_calculated_recipe_reasons.join('<br>'),
          )
        }
        notCostCalculatedReasonTextMap.set(
          bread.id,
          Array.from(new Set(notCostCalculatedReasonArray)).join('<br>'),
        )
        setNotCostCalculatedReasonTextMap(notCostCalculatedReasonTextMap)
      }),
    )
    setCurrentBreads(breads)
    createBreadList(breads, notCostCalculatedReasonTextMap)
    dispatch(setIsLoading({ isLoading: false }))
  }, [dispatch])
  const createBreadList = useCallback(
    (
      breads: BreadType[],
      notCostCalculatedReasonTextMap: Map<string, string>,
    ) => {
      const breadList: TableBody[][] = []
      breads.forEach((bread) => {
        const breadColList: TableBody[] = []

        breadColList.push({
          category: 'link',
          active: bread.is_salling,
          elementInfo: {
            link: {
              label: bread.name,
              to: '/bread/detail/' + bread.id,
            },
          },
        })
        breadColList.push({
          category: 'price',
          active: bread.is_salling,
          elementInfo: {
            text: {
              label: bread.selling_price
                ? String(Math.floor(Number(bread.selling_price) * TAX)) + '円'
                : '円',
            },
          },
        })
        if (
          (bread.cost_price || bread.cost_price === 0) &&
          !notCostCalculatedReasonTextMap.get(bread.id)
        ) {
          breadColList.push({
            category: 'price',
            active: bread.is_salling,
            elementInfo: {
              text: {
                // cost_price内に消費税が含まれている
                label: String(Math.floor(Number(bread.cost_price))) + '円',
              },
            },
          })
        } else {
          const notCostCalculatedReasonText =
            notCostCalculatedReasonTextMap.get(bread.id)
          breadColList.push({
            category: 'tooltip',
            active: bread.is_salling,
            elementInfo: {
              tooltip: {
                // cost_priceがない場合もしくは非算出理由がある場合はtooltip画像を表示
                icon: alertIcon,
                color: 'red',
                value: notCostCalculatedReasonText
                  ? notCostCalculatedReasonText
                  : 'レシピが未入力、または算出中です。',
                key: bread.id,
              },
            },
          })
        }
        const costDiff = String(
          Math.abs(
            Math.floor(
              Number(bread.cost_price) - Number(bread.pre_change_cost_price),
            ),
          ),
        )
        breadColList.push({
          category: 'price',
          active: bread.is_salling,
          elementInfo: {
            text: {
              // cost_price内に消費税が含まれている
              label: !notCostCalculatedReasonTextMap.get(bread.id)
                ? (bread.cost_price || bread.cost_price === 0) &&
                  (bread.pre_change_cost_price ||
                    bread.pre_change_cost_price === 0)
                  ? Number(bread.cost_price) -
                      Number(bread.pre_change_cost_price) >=
                    0
                    ? '+ ' + costDiff + '円'
                    : '- ' + costDiff + '円'
                  : '+0円'
                : '',
            },
          },
          color:
            (bread.cost_price || bread.cost_price === 0) &&
            (bread.pre_change_cost_price || bread.pre_change_cost_price === 0)
              ? Number(bread.cost_price) -
                  Number(bread.pre_change_cost_price) >=
                0
                ? 'red'
                : 'blue'
              : 'red',
        })
        const costRate =
          (bread.selling_price || bread.selling_price !== 0) &&
          (bread.cost_price || bread.cost_price === 0)
            ? Math.floor(
                (Number(bread.cost_price) /
                  (Number(bread.selling_price) * TAX)) *
                  100,
              )
            : null
        breadColList.push({
          category: 'price',
          active: bread.is_salling,
          elementInfo: {
            text: {
              label:
                (costRate || costRate === 0) &&
                !notCostCalculatedReasonTextMap.get(bread.id)
                  ? String(costRate) + '%'
                  : '%',
            },
          },
        })
        const preChangeCostRate =
          (bread.selling_price || bread.selling_price !== 0) &&
          (bread.pre_change_cost_price || bread.pre_change_cost_price === 0)
            ? Math.floor(
                (Number(bread.pre_change_cost_price) /
                  (Number(bread.selling_price) * TAX)) *
                  100,
              )
            : null
        breadColList.push({
          category: 'price',
          active: bread.is_salling,
          elementInfo: {
            text: {
              label: !notCostCalculatedReasonTextMap.get(bread.id)
                ? (costRate || costRate === 0) &&
                  (preChangeCostRate || preChangeCostRate === 0)
                  ? costRate - preChangeCostRate >= 0
                    ? '+ ' +
                      String(Math.abs(costRate - preChangeCostRate)) +
                      '%'
                    : '- ' +
                      String(Math.abs(costRate - preChangeCostRate)) +
                      '%'
                  : '+0%'
                : '%',
            },
          },
          color:
            costRate && preChangeCostRate
              ? costRate - preChangeCostRate >= 0
                ? 'red'
                : 'blue'
              : 'red',
        })
        breadColList.push({
          category: 'form_unit',
          active: bread.is_salling,
          elementInfo: {
            formUnit: {
              value:
                bread.cost_price || bread.cost_price === 0
                  ? formData.get(bread.id + '*simulate_selling_price')
                    ? String(formData.get(bread.id + '*simulate_selling_price'))
                    : formData.get(bread.id + '*simulate_cost_rate')
                    ? String(
                        Math.round(
                          Number(bread.cost_price) /
                            (Number(
                              formData.get(bread.id + '*simulate_cost_rate'),
                            ) /
                              100),
                        ),
                      )
                    : ''
                  : '',
              placeholder: '0',
              unit: '円',
              breadId: bread.id,
              key: 'simulate_selling_price',
              readOnly:
                bread.cost_price &&
                !notCostCalculatedReasonTextMap.get(bread.id)
                  ? false
                  : true,
            },
          },
        })
        breadColList.push({
          category: 'form_unit',
          active: bread.is_salling,
          elementInfo: {
            formUnit: {
              value:
                bread.cost_price || bread.cost_price === 0
                  ? formData.get(bread.id + '*simulate_cost_rate')
                    ? String(formData.get(bread.id + '*simulate_cost_rate'))
                    : formData.get(bread.id + '*simulate_selling_price')
                    ? String(
                        Math.round(
                          (Number(bread.cost_price) /
                            Number(
                              formData.get(
                                bread.id + '*simulate_selling_price',
                              ),
                            )) *
                            100 *
                            10,
                        ) / 10,
                      )
                    : ''
                  : '',
              placeholder: '0',
              unit: '%',
              breadId: bread.id,
              key: 'simulate_cost_rate',
              readOnly:
                bread.cost_price &&
                !notCostCalculatedReasonTextMap.get(bread.id)
                  ? false
                  : true,
            },
          },
        })
        // breadList.push([
        //   {
        //     category: 'form_unit',
        //     active: bread.is_salling,
        //     elementInfo: {
        //       formUnit: {
        //         value: formData.get(bread.id + '*updated_selling_price')
        //           ? String(formData.get(bread.id + '*updated_selling_price'))
        //           : '',
        //         placeholder: '0,000',
        //         unit: '円',
        //         breadId: bread.id,
        //         key: 'updated_selling_price',
        //       },
        //     },
        //   },
        //   {
        //     category: 'form_unit',
        //     active: bread.is_salling,
        //     elementInfo: {
        //       formUnit: {
        //         value: formData.get(bread.id + '*updated_cost_rate')
        //           ? String(formData.get(bread.id + '*updated_cost_rate'))
        //           : '',
        //         placeholder: '0',
        //         unit: '%',
        //         breadId: bread.id,
        //         key: 'updated_cost_rate',
        //       },
        //     },
        //   },
        //   {
        //     category: 'form_unit',
        //     active: bread.is_salling,
        //     elementInfo: {
        //       formUnit: {
        //         // 初期値は30%
        //         value: bread.ideal_cost_rate
        //           ? String(bread.ideal_cost_rate * 100)
        //           : formData.get(bread.id + '*ideal_cost_rate')
        //           ? String(formData.get(bread.id + '*ideal_cost_rate'))
        //           : '30',
        //         placeholder: '0',
        //         unit: '%',
        //         breadId: bread.id,
        //         key: 'ideal_cost_rate',
        //       },
        //     },
        //   },
        //   {
        //     category: 'price',
        //     active: bread.is_salling,
        //     elementInfo: {
        //       text: {
        //         label:
        //           bread.cost_price &&
        //           formData.get(bread.id + '*ideal_cost_rate')
        //             ? String(
        //                 Math.floor(
        //                   (100 * Number(bread.cost_price)) /
        //                     (100 -
        //                       Number(
        //                         formData.get(bread.id + '*ideal_cost_rate'),
        //                       )),
        //                 ),
        //               ) + '円'
        //             : bread.cost_price
        //             ? // 初期値は理想原価率30%から算出
        //               String(
        //                 Math.floor((100 * Number(bread.cost_price)) / 70),
        //               ) + '円'
        //             : '円',
        //       },
        //     },
        //   },
        // ])
        breadList.push(breadColList)
      })
      setBreadList(breadList)
    },
    [breads, formData, notCostCalculatedReasonTextMap],
  )

  const sortBread = async (
    desc: boolean,
    costPrice: boolean,
    sallingPrice: boolean,
    costRate: boolean,
    costPriceDiff: boolean,
    costRateDiff: boolean,
  ) => {
    const newBreads = [...currentBreads]
    if (costPrice) {
      newBreads.sort((first, second) => {
        if (first.cost_price !== 0 && !first.cost_price) {
          return desc ? 1 : -1
        }
        if (second.cost_price !== 0 && !second.cost_price) {
          return desc ? -1 : 1
        }
        return desc
          ? second.cost_price - first.cost_price
          : first.cost_price - second.cost_price
      })
    }
    if (sallingPrice) {
      newBreads.sort((first, second) => {
        if (first.selling_price !== 0 && !first.selling_price) {
          return desc ? 1 : -1
        }
        if (second.selling_price !== 0 && !second.selling_price) {
          return desc ? -1 : 1
        }
        return desc
          ? second.selling_price - first.selling_price
          : first.selling_price - second.selling_price
      })
    }
    if (costRate) {
      newBreads.sort((first, second) => {
        if (
          (first.cost_price !== 0 && !first.cost_price) ||
          (first.selling_price !== 0 && !first.selling_price)
        ) {
          return desc ? 1 : -1
        }
        if (
          (second.cost_price !== 0 && !second.cost_price) ||
          (second.selling_price !== 0 && !second.selling_price)
        ) {
          return desc ? -1 : 1
        }
        const firstCostRate = Math.floor(
          ((Number(first.selling_price) * TAX - Number(first.cost_price)) /
            (Number(first.selling_price) * TAX)) *
            100,
        )
        const secondCostRate = Math.floor(
          ((Number(second.selling_price) * TAX - Number(second.cost_price)) /
            (Number(second.selling_price) * TAX)) *
            100,
        )
        return desc
          ? secondCostRate - firstCostRate
          : firstCostRate - secondCostRate
      })
    }
    if (costRateDiff) {
      newBreads.sort((first, second) => {
        if (
          (first.cost_price !== 0 && !first.cost_price) ||
          (first.pre_change_cost_price !== 0 && !first.pre_change_cost_price) ||
          (first.selling_price !== 0 && !first.selling_price)
        ) {
          return desc ? 1 : -1
        }
        if (
          (second.cost_price !== 0 && !second.cost_price) ||
          (second.pre_change_cost_price !== 0 &&
            !second.pre_change_cost_price) ||
          (second.selling_price !== 0 && !second.selling_price)
        ) {
          return desc ? -1 : 1
        }
        const firstCostRate =
          first.selling_price && first.cost_price
            ? Math.floor(
                (Number(first.cost_price) /
                  (Number(first.selling_price) * TAX)) *
                  100,
              )
            : 0
        const firstPreChangeCostRate =
          first.selling_price && first.pre_change_cost_price
            ? Math.floor(
                (Number(first.pre_change_cost_price) /
                  (Number(first.selling_price) * TAX)) *
                  100,
              )
            : 0
        const secondCostRate =
          second.selling_price && second.cost_price
            ? Math.floor(
                (Number(second.cost_price) /
                  (Number(second.selling_price) * TAX)) *
                  100,
              )
            : 0
        const secondPreChangeCostRate =
          second.selling_price && second.pre_change_cost_price
            ? Math.floor(
                (Number(second.pre_change_cost_price) /
                  (Number(second.selling_price) * TAX)) *
                  100,
              )
            : 0
        const firstCostRateDiff = firstCostRate - firstPreChangeCostRate
        const secondCostRateDiff = secondCostRate - secondPreChangeCostRate
        return desc
          ? secondCostRateDiff - firstCostRateDiff
          : firstCostRateDiff - secondCostRateDiff
      })
    }
    if (costPriceDiff) {
      newBreads.sort((first, second) => {
        if (
          (first.cost_price !== 0 && !first.cost_price) ||
          (first.pre_change_cost_price !== 0 && !first.pre_change_cost_price)
        ) {
          return desc ? 1 : -1
        }
        if (
          (second.cost_price !== 0 && !second.cost_price) ||
          (second.pre_change_cost_price !== 0 && !second.pre_change_cost_price)
        ) {
          return desc ? -1 : 1
        }
        const firstCostPriceDiff = Math.floor(
          Number(first.cost_price) - Number(first.pre_change_cost_price),
        )
        const secondCostPriceDiff = Math.floor(
          Number(second.cost_price) - Number(second.pre_change_cost_price),
        )
        return desc
          ? secondCostPriceDiff - firstCostPriceDiff
          : firstCostPriceDiff - secondCostPriceDiff
      })
    }
    setCurrentBreads(newBreads)
    createBreadList(newBreads, notCostCalculatedReasonTextMap)
  }

  // const handleSubmmit = async () => {
  //   dispatch(setIsLoading({ isLoading: true }))
  //   await Promise.all(
  //     Array.from(formData.entries()).map(async ([k, v]) => {
  //       const kl = k.split('*')
  //       const breadId = kl[0]
  //       const key = kl[1]
  //       if (key === 'updated_selling_price' && (v || v === '0')) {
  //         await dispatch(
  //           updateSellingPrice({
  //             id: breadId,
  //             selling_price: Math.ceil(Number(v) / TAX),
  //           }),
  //         )
  //       }
  //       if (key === 'ideal_cost_rate' && (v || v === '0')) {
  //         await dispatch(
  //           updateIdealCostRate({
  //             id: breadId,
  //             ideal_cost_rate: Number(v) / 100,
  //           }),
  //         )
  //       }
  //     }),
  //   )
  //   const breadsPayload = await dispatch(getBreads())
  //   const breads = breadsPayload.payload
  //   createBreadList(breads, notCostCalculatedReasonTextMap)
  //   dispatch(setIsLoading({ isLoading: false }))
  //   window.location.reload()
  // }

  useEffect(() => {
    dispatchGetBreads()
  }, [])

  const { staff, adminShopId } = useSelector(
    (state: RootState) => state.staffSlice,
  )
  useEffect(() => {
    dispatchGetBreads()
  }, [adminShopId])

  useEffect(() => {
    createBreadList(currentBreads, notCostCalculatedReasonTextMap)
  }, [currentBreads, formData])

  return (
    <div className={styles.container}>
      <PageHedding title="原価一覧" to="" />

      <div className={styles.pattern_head}>
        <RadioButtonFilterWithLightLabel
          labelName="店頭販売状況"
          description=""
          line={1}
          radioSize="small"
          flex={true}
          isChecked={!breadSaling && !breadSalesSuspension}
          onChange={handleBreadSalesAll}
          checkInfos={[
            {
              isChecked: breadSaling,
              label: '販売中',
              onChange: handleBreadSaling,
            },
            {
              isChecked: breadSalesSuspension,
              label: '販売停止',
              onChange: handleBreadSalesSuspension,
            },
          ]}
        />
        <div className={styles.dashboardSearchRowSection_middle}></div>
        <RadioButtonFilterWithLightLabel
          labelName="原価入力状況"
          description=""
          line={1}
          radioSize="small"
          flex={true}
          isChecked={!costInputComplete && !costInputNotComplete}
          onChange={handleCostInputCompleteAll}
          checkInfos={[
            {
              isChecked: costInputComplete,
              label: '算出可',
              onChange: handleCostInputComplete,
            },
            {
              isChecked: costInputNotComplete,
              label: '算出不可',
              onChange: handleCostInputNotComplete,
            },
          ]}
        />
        <div className={styles.pattern_margin_large}></div>
      </div>

      <Table
        headers={[
          {
            category: 'name',
            elementInfo: {
              text: {
                label: '商品名',
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '現売価(税込)',
                sortAscSubmit: () =>
                  sortBread(false, false, true, false, false, false),
                sortDescSubmit: () =>
                  sortBread(true, false, true, false, false, false),
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '原価(税込)',
                sortAscSubmit: () =>
                  sortBread(false, true, false, false, false, false),
                sortDescSubmit: () =>
                  sortBread(true, true, false, false, false, false),
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '原価変動',
                sortAscSubmit: () =>
                  sortBread(false, false, false, false, true, false),
                sortDescSubmit: () =>
                  sortBread(true, false, false, false, true, false),
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '原価率',
                sortAscSubmit: () =>
                  sortBread(false, false, false, true, false, false),
                sortDescSubmit: () =>
                  sortBread(true, false, false, true, false, false),
              },
            },
          },
          {
            category: 'price',
            elementInfo: {
              text: {
                label: '原価率変動',
                sortAscSubmit: () =>
                  sortBread(false, false, false, false, false, true),
                sortDescSubmit: () =>
                  sortBread(true, false, false, false, false, true),
              },
            },
          },
          {
            category: 'form_unit',
            elementInfo: {
              text: {
                label: '売価シミュレーション',
              },
            },
          },
          {
            category: 'form_unit',
            elementInfo: {
              text: {
                label: '原価率シミュレーション',
              },
            },
          },
        ]}
        bodies={breadList}
        handleFormUnit={handleFormUnit}
      />

      {/* <div className={styles.dashboardButtonWrapContainer}>
        <div className={styles.dashboardButtonContainer}>
          <SubmitButton
            label="反映"
            color="orange"
            size="large"
            icon="none"
            onClick={handleSubmmit}
          />
        </div>
      </div> */}
    </div>
  )
}

export default BreadCostTemplate
