import { FC, ChangeEvent, ReactNode } from 'react'
import styles from 'styles/components/label.module.scss'
import InputText from 'components/Form/InputText'
import LightLabel from 'components/Label/LightLabel'

type Props = {
  labelName: string
  rightRight?: ReactNode
  description: string
  line: number
  type: string
  size: string
  value: string
  placeholder: string
  errorText?: string | null
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onInput?: () => void
  required?: boolean
  questioned?: boolean
  containerStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties
  errorTextStyle?: React.CSSProperties
  readOnly?: boolean
}

const InputTextWithLightLabel: FC<Props> = (props) => {
  const {
    labelName,
    rightRight,
    description,
    line,
    type,
    size,
    value,
    placeholder,
    errorText,
    onChange,
    onInput,
    required,
    questioned,
    containerStyle,
    inputStyle,
    errorTextStyle,
    readOnly,
  } = props

  return (
    <div style={containerStyle}>
      <div style={{ display: 'flex' }}>
        <LightLabel
          name={labelName}
          description={description}
          line={line}
          required={required}
          questioned={questioned}
        />
        {rightRight}
      </div>
      <div className={styles.lightLabelInputContainer}>
        <InputText
          required={false}
          type={type}
          size={size}
          value={value}
          placeholder={placeholder}
          rightAligned={false}
          errorText={errorText}
          onChange={(e) => (onChange ? onChange(e) : null)}
          onInput={onInput}
          style={inputStyle}
          errorTextStyle={errorTextStyle}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}

export default InputTextWithLightLabel
