import { FC } from 'react'
import styles from 'styles/components/box.module.scss'
import wheatActive from 'images/Allergens/wheat-active.png'
import wheatInActive from 'images/Allergens/wheat-inactive.png'
import eggActive from 'images/Allergens/egg-active.png'
import eggInActive from 'images/Allergens/egg-inactive.png'
import milkActive from 'images/Allergens/milk-active.png'
import milkInActive from 'images/Allergens/milk-inactive.png'
import shrimpActive from 'images/Allergens/shrimp-active.png'
import shrimpInActive from 'images/Allergens/shrimp-inactive.png'
import crubActive from 'images/Allergens/crub-active.png'
import crubInActive from 'images/Allergens/crub-inactive.png'
import sobaActive from 'images/Allergens/soba-active.png'
import sobaInActive from 'images/Allergens/soba-inactive.png'
import peanutsActive from 'images/Allergens/peanuts-active.png'
import peanutsInActive from 'images/Allergens/peanuts-inactive.png'
import walnutActive from 'images/Allergens/walnut-active.png'
import walnutInActive from 'images/Allergens/walnut-inactive.png'

type Props = {
  allergen: Allergens
  active: boolean
  label: string
}

export enum Allergens {
  WHEAT = 'wheat',
  EGG = 'egg',
  MILK = 'milk',
  SHRIMP = 'shrimp',
  CRUB = 'crub',
  SOBA = 'soba',
  PEANUTS = 'peanuts',
  WALNUT = 'walnut',
}

const AllergenIconBox: FC<Props> = (props) => {
  const { allergen, active, label } = props

  const icon = () => {
    if (active) {
      if (allergen === Allergens.WHEAT) {
        return wheatActive
      } else if (allergen === Allergens.EGG) {
        return eggActive
      } else if (allergen === Allergens.MILK) {
        return milkActive
      } else if (allergen === Allergens.SHRIMP) {
        return shrimpActive
      } else if (allergen === Allergens.CRUB) {
        return crubActive
      } else if (allergen === Allergens.SOBA) {
        return sobaActive
      } else if (allergen === Allergens.PEANUTS) {
        return peanutsActive
      } else if (allergen === Allergens.WALNUT) {
        return walnutActive
      } else {
        return ''
      }
    } else {
      if (allergen === Allergens.WHEAT) {
        return wheatInActive
      } else if (allergen === Allergens.EGG) {
        return eggInActive
      } else if (allergen === Allergens.MILK) {
        return milkInActive
      } else if (allergen === Allergens.SHRIMP) {
        return shrimpInActive
      } else if (allergen === Allergens.CRUB) {
        return crubInActive
      } else if (allergen === Allergens.SOBA) {
        return sobaInActive
      } else if (allergen === Allergens.PEANUTS) {
        return peanutsInActive
      } else if (allergen === Allergens.WALNUT) {
        return walnutInActive
      } else {
        return ''
      }
    }
  }

  return (
    <>
      <div className={styles.allergen_icon_box}>
        <div className={styles.allergen_icon_box_image}>
          <img
            className={styles.allergen_icon_box_image}
            src={icon()}
            alt="アレルゲン"
          ></img>
        </div>
        <div
          className={
            active
              ? styles.allergen_icon_box_label_bold
              : styles.allergen_icon_box_label
          }
        >
          {label}
        </div>
      </div>
    </>
  )
}

export default AllergenIconBox
