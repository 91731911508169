import { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import TextAreaWithLightLabel from 'components/Form/WithLightLabel/TextArea'
import SelectBoxWithLightLabel from 'components/Form/WithLightLabel/SelectBox'
import { SelectOption } from 'components/Form/SelectBox'
import CheckBox from 'components/Form/CheckBox'
import RadioButton from 'components/Form/RadioButton'
import InputTextAndUnitWithLightLabel from 'components/Form/WithLightLabel/InputTextAndUnit'
import AddFormProductInformation, {
  ProductInformation,
} from 'components/Form/AddForm/ProductInformation'
import SubmitButton from 'components/Button/SubmitButton'
import {
  clearFilling,
  createFilling,
  updateFilling,
  getFillings,
  getAvailableFillings,
  getFillingById,
  FillingType,
} from 'reducks/filling/slice'
import { getMaterialById, MaterialType } from 'reducks/material/slice'
import { getDoughs, DoughType } from 'reducks/dough/slice'
import { getRecipes, RecipeType } from 'reducks/recipe/slice'
import { DataType } from 'reducks/business/slice'
import {
  getMaterialSubCategoryById,
  MaterialSubCategoryType,
} from 'reducks/materialSubCategory/slice'
import deleteConfirmImage from 'images/delete-confirm.png'
import { RootState } from 'reducks/reducers'
import slack, { NotifyPettern } from 'util/slack'
import { setIsLoading } from 'reducks/loading/slice'
import Big from 'big.js'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'fb/index'

const BreadAddTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const search = useLocation().search
  // 更新の場合か確認
  const path = location.pathname
  const fillingId = path.split('/filling/edit/')[1]
    ? path.split('/filling/edit/')[1]
    : null
  const copyFillingId = path.split('/filling/copy/')[1]
    ? path.split('/filling/copy/')[1]
    : null
  const query = new URLSearchParams(search)
  const recipeBreadId = query.get('recipeBreadId')
  const doughId = query.get('doughId')
  const [isPreview, setIsPreview] = useState(false)
  const [petternName, setPetternName] = useState<string>('')
  const [fillingPattern, setFillingPattern] = useState<string>('')
  const [productInformations, setProductInformations] = useState<
    ProductInformation[]
  >([
    {
      productName: '',
      manufacturerName: '',
      categoryName: '',
      middleKneadPercent: '',
      authenticKneadPercent: '',
      materialId: '',
      doughId: '',
      fillingId: '',
      recipeId: '',
    },
  ])
  const [finishedWeight, setFinishedWeight] = useState<string>('')
  const [process, setProcess] = useState<string>('')
  const [memo, setMemo] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [displayPercent, setDisplayPercent] = useState<boolean>(false)
  const [displayGram, setDisplayGram] = useState<boolean>(true)

  const [fillingList, setFillingList] = useState<SelectOption[]>([])
  const createPetternList = useCallback(async () => {
    const fillingsPayload = await dispatch(getAvailableFillings())
    const fillings: FillingType[] = fillingsPayload.payload
    const fillingList: SelectOption[] = [
      {
        is_default: true,
        label: '----',
        value: '',
      },
    ]
    fillings.forEach((filling) => {
      if (fillingId && fillingId === filling.id) {
        return
      }
      fillingList.push({
        is_default: false,
        label: filling.pettern_name,
        value: filling.id,
      })
    })
    setFillingList(fillingList)
  }, [dispatch])

  useEffect(() => {
    createPetternList()
  }, [])

  const handlePetternName = (petternName: string) => {
    setPetternName(petternName)
  }
  const handleFillingPattern = (fillingPattern: string) => {
    setFillingPattern(fillingPattern)
  }
  const handleProductInformation = (
    productInformations: ProductInformation[],
  ) => {
    setProductInformations(productInformations)
  }
  const handleFinishedWeight = (finishedWeight: string) => {
    // 半角数字に変換
    finishedWeight = finishedWeight.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(finishedWeight) && Number(finishedWeight) !== 0) {
      return
    }
    setFinishedWeight(finishedWeight)
  }
  const handleProcess = (process: string) => {
    setProcess(process)
  }
  const handleMemo = (memo: string) => {
    setMemo(memo)
  }
  const handleDisabled = () => {
    setIsDisabled(!isDisabled)
  }
  const handleDisplayUnit = () => {
    setDisplayPercent(!displayPercent ? true : false)
    setDisplayGram(!displayGram ? true : false)
  }
  const handleIsPreview = () => {
    // PCでのスクロール禁止
    document.addEventListener('mousewheel', scrollControl, { passive: false })
    // スマホでのタッチ操作でのスクロール禁止
    document.addEventListener('touchmove', scrollControl, { passive: false })
    setIsPreview(true)
  }
  const handleCloseIsPreview = () => {
    document.removeEventListener('mousewheel', scrollControl)
    document.removeEventListener('touchmove', scrollControl)
    setIsPreview(false)
  }
  const scrollControl = useCallback((event: any) => {
    event.preventDefault()
  }, [])

  const [canNotUseFillingNameList, setCanNotUseFillingNameList] = useState<
    string[]
  >([])
  const [canNotUseFillingCount, setCanNotUseFillingCount] = useState<number>(0)
  const [canNotUseDoughNameList, setCanNotUseDoughNameList] = useState<
    string[]
  >([])
  const [canNotUseDoughCount, setCanNotUseDoughCount] = useState<number>(0)
  const [canNotUseBreadNameList, setCanNotUseBreadNameList] = useState<
    string[]
  >([])
  const [canNotUseBreadCount, setCanNotUseBreadCount] = useState<number>(0)
  const setDisabledInfo = async () => {
    const fillingsPayload = await dispatch(getFillings())
    const fillings: DoughType[] = fillingsPayload.payload
    const fillingsWaitList: DoughType[] = []
    fillings.forEach((filling) => {
      if (filling.material_informations) {
        filling.material_informations.forEach((material_information) => {
          if (
            material_information.filling_ref &&
            material_information.filling_ref.id === fillingId
          ) {
            fillingsWaitList.push(filling)
          }
        })
      }
    })
    setCanNotUseFillingCount(fillingsWaitList.length)
    const fillingNameList: string[] = []
    fillingsWaitList.forEach((filling) => {
      fillingNameList.push(filling.pettern_name)
    })
    setCanNotUseFillingNameList(fillingNameList)
    const doughsPayload = await dispatch(getDoughs())
    const doughs: DoughType[] = doughsPayload.payload
    const doughsWaitList: DoughType[] = []
    doughs.forEach((dough) => {
      if (dough.material_informations) {
        dough.material_informations.forEach((material_information) => {
          if (
            material_information.filling_ref &&
            material_information.filling_ref.id === fillingId
          ) {
            doughsWaitList.push(dough)
          }
        })
      }
    })
    setCanNotUseDoughCount(doughsWaitList.length)
    const doughNameList: string[] = []
    doughsWaitList.forEach((dough) => {
      doughNameList.push(dough.pettern_name)
    })
    setCanNotUseDoughNameList(doughNameList)
    const recipesPayload = await dispatch(getRecipes())
    const recipes: RecipeType[] = recipesPayload.payload
    const recipesWaitList: RecipeType[] = []
    recipes.forEach((recipe) => {
      if (recipe.dough_material_informations) {
        recipe.dough_material_informations.forEach((material_information) => {
          if (
            material_information.filling_ref &&
            material_information.filling_ref.id === fillingId
          ) {
            recipesWaitList.push(recipe)
          }
        })
      }
      if (recipe.filling_material_informations) {
        recipe.filling_material_informations.forEach((material_information) => {
          if (
            material_information.filling_ref &&
            material_information.filling_ref.id === fillingId
          ) {
            recipesWaitList.push(recipe)
          }
        })
      }
    })
    setCanNotUseBreadCount(recipesWaitList.length)
    const recipeNameList: string[] = []
    await Promise.all(
      recipesWaitList.map(async (recipe) => {
        recipeNameList.push(recipe.recipe_name)
      }),
    )
    setCanNotUseBreadNameList(recipeNameList)

    if (
      fillingsWaitList.length > 0 ||
      doughsWaitList.length > 0 ||
      recipesWaitList.length > 0
    ) {
      return true
    } else {
      return false
    }
  }

  const handleAddFillingPattern = async () => {
    const newProductInformations = [...productInformations]
    if (
      newProductInformations.length === 1 &&
      newProductInformations[0].productName === '' &&
      newProductInformations[0].manufacturerName === '' &&
      newProductInformations[0].middleKneadPercent === '' &&
      newProductInformations[0].authenticKneadPercent === ''
    ) {
      newProductInformations.shift()
    }
    const fillingPayload = await dispatch(
      getFillingById({ id: fillingPattern }),
    )
    const filling: FillingType = fillingPayload.payload
    newProductInformations.push({
      productName: filling.pettern_name,
      manufacturerName: '',
      categoryName: 'フィリングパターン',
      middleKneadPercent: '',
      authenticKneadPercent: '',
      materialId: '',
      doughId: '',
      fillingId: filling.id,
      recipeId: '',
    })
    setProductInformations(newProductInformations)
  }

  const [petternNameErrorText, setPetternNameErrorText] = useState<
    string | null
  >(null)
  const [productInformationsErrorText, setProductInformationsErrorText] =
    useState<string | null>(null)
  const validation = () => {
    let isError = false
    if (!petternName) {
      isError = true
      setPetternNameErrorText('入力されておりません')
    }
    if (productInformations.length === 0) {
      isError = true
      setProductInformationsErrorText('入力されておりません')
    }

    productInformations.map(async (productInformation) => {
      if (
        !productInformation.doughId &&
        !productInformation.fillingId &&
        !productInformation.materialId &&
        !productInformation.recipeId &&
        !productInformation.productName &&
        !productInformation.manufacturerName &&
        !productInformation.authenticKneadPercent
      ) {
        // 全情報がない場合はバリデーション通過
        return
      }
      if (!productInformation.authenticKneadPercent) {
        // 本ごね情報がない
        setProductInformationsErrorText('一部の情報が入力されていません')
        isError = true
        return
      }
      if (
        (!Number(productInformation.middleKneadPercent) &&
          Number(productInformation.middleKneadPercent) !== 0) ||
        (!Number(productInformation.authenticKneadPercent) &&
          Number(productInformation.authenticKneadPercent) !== 0)
      ) {
        // 中ごね本ごね情報が不正
        setProductInformationsErrorText(
          '一部の中捏・本捏情報に正しくない情報が入力されています',
        )
        isError = true
        return
      }
      if (
        !productInformation.doughId &&
        !productInformation.fillingId &&
        !productInformation.materialId &&
        !productInformation.recipeId &&
        (!productInformation.productName ||
          !productInformation.manufacturerName)
      ) {
        // 未登録の原材料の場合で、原材料名とメーカーが入力されていない
        setProductInformationsErrorText('一部の情報が入力されていません')
        isError = true
        return
      }
    })

    if (isError) {
      window.scrollTo(0, 0)
      return false
    }
    return true
  }

  const { staff } = useSelector((state: RootState) => state.staffSlice)

  const handleSubmit = async (to: string, force?: boolean) => {
    const is_valid = validation()
    if (!is_valid) {
      return
    }
    if (isDisabled && fillingId && !force) {
      const isSetWaitList = await setDisabledInfo()
      if (isSetWaitList) {
        handleIsPreview()
        window.scrollTo(0, 0)
        return
      }
    }
    dispatch(setIsLoading({ isLoading: true }))

    // 登録されていない原材料がある場合はSlack送信
    slack.materialApply(
      productInformations,
      staff && staff.shopName ? staff.shopName : '',
      petternName,
      NotifyPettern.FILLING,
    )

    if (fillingId) {
      // Firestoreを更新
      await dispatch(
        updateFilling({
          id: fillingId,
          pettern_name: petternName,
          material_informations: productInformations,
          finished_weight:
            finishedWeight || finishedWeight === '0'
              ? Number(finishedWeight)
              : null,
          process: process,
          memo: memo,
          is_disabled: isDisabled,
          is_percent: displayPercent,
          is_gram: displayGram,
        }),
      )

      // 裏貼りシールステータスと原価を更新する
      const updateBackSealStatusAndCostDaseruno = httpsCallable(
        functions,
        'updateBackSealStatusAndCostDaseruno',
      )
      await updateBackSealStatusAndCostDaseruno({
        docId: fillingId,
        dataType: DataType.FILLING,
        shopId: staff?.shopId,
      })
    } else {
      // Firestoreに登録
      await dispatch(
        createFilling({
          pettern_name: petternName,
          material_informations: productInformations,
          finished_weight:
            finishedWeight || finishedWeight === '0'
              ? Number(finishedWeight)
              : null,
          process: process,
          memo: memo,
          is_disabled: isDisabled,
          is_percent: displayPercent,
          is_gram: displayGram,
        }),
      )
    }
    await dispatch(clearFilling())
    await dispatch(getFillings())

    if (force) {
      handleCloseIsPreview()
    }

    navigate(to)
    window.scrollTo(0, 0)
    dispatch(setIsLoading({ isLoading: false }))
  }

  const setFillingInfo = useCallback(
    async (fillingId: string | null, copyFillingId: string | null) => {
      dispatch(setIsLoading({ isLoading: true }))
      if (!staff) {
        navigate('/initload?path=' + path)
        window.scrollTo(0, 0)
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      await dispatch(clearFilling())
      const productInformationsData: ProductInformation[] = []
      let fillingPayload = null
      const getFillingId: string = fillingId
        ? fillingId
        : copyFillingId
        ? copyFillingId
        : ''
      if (!getFillingId) {
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      fillingPayload = await dispatch(getFillingById({ id: getFillingId }))
      if (fillingPayload && fillingPayload.payload) {
        const filling: FillingType = fillingPayload.payload
        const petternName: string = copyFillingId
          ? filling.pettern_name + '（コピー）'
          : filling.pettern_name
        setPetternName(petternName)
        if (filling.material_informations.length > 0) {
          await filling.material_informations.reduce((promise, fillingInfo) => {
            return promise.then(async () => {
              const materialId: string | null = fillingInfo.material_ref
                ? fillingInfo.material_ref.id
                  ? fillingInfo.material_ref.id
                  : // @ts-ignore
                    fillingInfo.material_ref._key.path.segments[6]
                : null
              const fillingId: string | null = fillingInfo.filling_ref
                ? fillingInfo.filling_ref.id
                  ? fillingInfo.filling_ref.id
                  : // @ts-ignore
                    fillingInfo.filling_ref._key.path.segments[6]
                : null

              if (materialId) {
                const materialPayload = await dispatch(
                  getMaterialById({ id: materialId }),
                )
                const material: MaterialType = materialPayload.payload
                if (!material) {
                  return
                }
                const materialSubCategoryPayload = await dispatch(
                  getMaterialSubCategoryById({
                    id: material.material_sub_category_ref.id,
                  }),
                )
                const materialSubCategory: MaterialSubCategoryType =
                  materialSubCategoryPayload.payload
                productInformationsData.push({
                  productName: material.product_name,
                  manufacturerName: material.manufacturer,
                  categoryName: materialSubCategory.name,
                  middleKneadPercent: String(fillingInfo.middle_knead_percent),
                  authenticKneadPercent: String(
                    fillingInfo.authentic_knead_percent,
                  ),
                  materialId: materialId,
                  doughId: '',
                  fillingId: '',
                  recipeId: '',
                })
              } else if (fillingId) {
                const fillingPayload = await dispatch(
                  getFillingById({ id: fillingId }),
                )
                const filling: FillingType = fillingPayload.payload
                productInformationsData.push({
                  productName: filling.pettern_name,
                  manufacturerName: '',
                  categoryName: 'フィリングパターン',
                  middleKneadPercent: String(fillingInfo.middle_knead_percent),
                  authenticKneadPercent: String(
                    fillingInfo.authentic_knead_percent,
                  ),
                  materialId: '',
                  doughId: '',
                  fillingId: fillingId,
                  recipeId: '',
                })
              } else {
                productInformationsData.push({
                  productName: fillingInfo.productName
                    ? fillingInfo.productName
                    : '',
                  manufacturerName: fillingInfo.manufacturerName
                    ? fillingInfo.manufacturerName
                    : '',
                  categoryName: '',
                  middleKneadPercent: String(fillingInfo.middle_knead_percent),
                  authenticKneadPercent: String(
                    fillingInfo.authentic_knead_percent,
                  ),
                  materialId: '',
                  doughId: '',
                  fillingId: '',
                  recipeId: '',
                })
              }
            })
          }, Promise.resolve())
        }
        setFinishedWeight(
          filling.finished_weight || filling.finished_weight === 0
            ? String(filling.finished_weight)
            : '',
        )
        setProcess(filling.process)
        setMemo(filling.memo)
        setIsDisabled(filling.is_disabled)
        if (filling.is_percent || filling.is_gram) {
          setDisplayPercent(filling.is_percent)
          setDisplayGram(filling.is_gram)
        } else {
          setDisplayPercent(false)
          setDisplayGram(true)
        }
      }
      setProductInformations(productInformationsData)
      dispatch(setIsLoading({ isLoading: false }))
    },
    [fillingId, copyFillingId],
  )

  useEffect(() => {
    setFillingInfo(fillingId, copyFillingId)
  }, [])

  useEffect(() => {
    // イベントの設定
    window.history.pushState(null, document.title)
    window.addEventListener('beforeunload', onUnload)
    window.addEventListener('popstate', onBrouserBack)

    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload)
      window.removeEventListener('popstate', onBrouserBack)
    }
  }, [])

  const onUnload = (e: any) => {
    e.preventDefault()
    e.returnValue = ''
  }

  const onBrouserBack = () => {
    if (window.confirm('このサイトから離れてもよろしいですか？')) {
      navigate(-2)
    }
  }

  return (
    <>
      <div className={styles.container}>
        <PageHedding
          title="フィリングパターンを作成"
          to={
            doughId && recipeBreadId
              ? '/dough/edit/' + doughId + '?recipeBreadId=' + recipeBreadId
              : doughId
              ? '/dough/edit/' + doughId
              : recipeBreadId
              ? '/bread/edit/recipe/' + recipeBreadId
              : '/filling'
          }
        />
        <div className={styles.bread_add_recipe_display_unit}>
          <div>
            <span className={styles.bread_add_recipe_display_unit_text}>
              表示単位
            </span>
          </div>
          <RadioButton
            isChecked={displayPercent}
            label="%"
            onChange={handleDisplayUnit}
          />
          <RadioButton
            isChecked={displayGram}
            label="g"
            onChange={handleDisplayUnit}
          />
        </div>
        <div className={styles.bread_add_recipe}>
          <div className={styles.bread_add_recipe_section}>フィリング</div>
        </div>
        <InputTextWithLightLabel
          labelName="フィリングパターン名"
          required={true}
          description=""
          line={1}
          type="text"
          size="full"
          value={petternName}
          placeholder="フィリングパターン名を入力"
          errorText={petternNameErrorText}
          onChange={(event) => handlePetternName(event.target.value)}
        />
        <div className={styles.bread_add_recipe_call_pattern}>
          <div className={styles.bread_add_recipe_call_pattern_select}>
            <SelectBoxWithLightLabel
              labelName="フィリングを呼び出す"
              description=""
              line={1}
              size="large2"
              value={fillingPattern}
              options={fillingList}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handleFillingPattern(event.target.value)
              }
            />
            <SubmitButton
              label="追加する"
              color="light_gray"
              size="small"
              icon="none"
              onClick={handleAddFillingPattern}
            />
          </div>
        </div>
        <div className={styles.bread_add_recipe_product_information}>
          <AddFormProductInformation
            formInfos={productInformations}
            unit={displayPercent ? '%' : 'g'}
            errorText={productInformationsErrorText}
            handleProductInformation={handleProductInformation}
          />
        </div>
        <div className={styles.bread_add_recipe_sum}>
          <InputTextAndUnitWithLightLabel
            labelName="合計"
            description=""
            line={1}
            type="text"
            size="small"
            unit={displayPercent ? '%' : 'g'}
            readOnly={true}
            value={productInformations.reduce(
              (sum, productInformation) =>
                String(
                  new Big(Number(productInformation.middleKneadPercent))
                    .plus(Number(productInformation.authenticKneadPercent))
                    .plus(Number(sum))
                    .toNumber(),
                ),
              '0',
            )}
            placeholder=""
          />
          <div className={styles.bread_add_recipe_sum_space}></div>
        </div>
        <div className={styles.bread_add_recipe_sum}>
          <InputTextAndUnitWithLightLabel
            labelName="完成後重量"
            description=""
            line={1}
            type="text"
            size="small"
            unit={displayPercent ? '%' : 'g'}
            value={finishedWeight}
            placeholder=""
            onChange={(event) => handleFinishedWeight(event.target.value)}
          />
          <div className={styles.bread_add_recipe_sum_space}></div>
        </div>
        <div className={styles.bread_add_recipe_memo}>
          <TextAreaWithLightLabel
            labelName="工程"
            description=""
            line={1}
            maxLength={1000}
            rows={16}
            size="full"
            value={process}
            placeholder=""
            onChange={(event) => handleProcess(event.target.value)}
          />
        </div>
        <div className={styles.bread_add_recipe_memo}>
          <TextAreaWithLightLabel
            labelName="メモ欄"
            description=""
            line={1}
            maxLength={1000}
            rows={8}
            size="full"
            value={memo}
            placeholder=""
            onChange={(event) => handleMemo(event.target.value)}
          />
        </div>

        {fillingId ? (
          <div className={styles.bread_add_recipe_disabled_check}>
            <div className={styles.bread_add_recipe_disabled_check_box}>
              <CheckBox
                isChecked={isDisabled}
                label=""
                size="semi_small"
                type="default"
                center={true}
                onChange={() => handleDisabled()}
              />
            </div>
            <div className={styles.bread_add_recipe_disabled_check_box}>
              このフィリングパターンを使用不可とする
            </div>
          </div>
        ) : (
          ''
        )}

        <div className={styles.bread_add_recipe_submit}>
          <SubmitButton
            label="キャンセルする"
            color="gray"
            size="large"
            icon="none"
            onClick={() =>
              navigate(
                doughId && recipeBreadId
                  ? '/dough/edit/' + doughId + '?recipeBreadId=' + recipeBreadId
                  : doughId
                  ? '/dough/edit/' + doughId
                  : recipeBreadId
                  ? '/bread/edit/recipe/' + recipeBreadId
                  : '/filling',
              )
            }
          />
          <SubmitButton
            label="保存する"
            color="orange"
            size="large"
            icon="plus"
            onClick={() =>
              handleSubmit(
                doughId && recipeBreadId
                  ? '/dough/edit/' + doughId + '?recipeBreadId=' + recipeBreadId
                  : doughId
                  ? '/dough/edit/' + doughId
                  : recipeBreadId
                  ? '/bread/edit/recipe/' + recipeBreadId
                  : '/filling',
              )
            }
          />
        </div>
      </div>
      {isPreview && (
        <div className={styles.modal_pattern_disabled}>
          <img
            className={styles.modal_pattern_disabled_image_confirm}
            src={deleteConfirmImage}
            alt="削除確認"
          ></img>
          <div className={styles.modal_pattern_disabled_text_box}>
            <div className={styles.modal_pattern_disabled_text_box_main}>
              このフィリングパターンを使用している商品が {canNotUseBreadCount}{' '}
              件検索されました。
            </div>
            <div className={styles.modal_pattern_disabled_text_box_main}>
              このフィリングパターンを使用している生地パターンが{' '}
              {canNotUseDoughCount} 件検索されました。
            </div>
            <div className={styles.modal_pattern_disabled_text_box_main}>
              このフィリングパターンを使用しているフィリングパターンが{' '}
              {canNotUseFillingCount} 件検索されました。
            </div>
            <div
              className={styles.modal_pattern_disabled_text_box_spacer}
            ></div>
            <div className={styles.modal_pattern_disabled_text_box_description}>
              使用不可にすると、このフィリングパターンを使用している商品の裏貼りシールが出力できなくなります。
            </div>
            <div className={styles.modal_pattern_disabled_text_box_description}>
              必ず、下記に表示している使用商品、使用生地パターン、使用フィリングパターンをご確認の上、使用不可に設定してください。
            </div>
          </div>
          <div className={styles.modal_pattern_disabled_text_detail}>
            <div
              className={styles.modal_pattern_disabled_text_detail_bar}
            ></div>
            <div className={styles.modal_pattern_disabled_text_detail_box}>
              <div
                className={styles.modal_pattern_disabled_text_detail_box_main}
              >
                このフィリングパターンを使用している商品の確認（
                {canNotUseBreadCount}
                件の商品を表示中）
              </div>
              <div
                className={
                  styles.modal_pattern_disabled_text_detail_box_description
                }
              >
                {canNotUseBreadNameList.join('・')}
              </div>
            </div>
          </div>
          <div className={styles.modal_pattern_disabled_text_detail}>
            <div
              className={styles.modal_pattern_disabled_text_detail_bar}
            ></div>
            <div className={styles.modal_pattern_disabled_text_detail_box}>
              <div
                className={styles.modal_pattern_disabled_text_detail_box_main}
              >
                このフィリングパターンを使用している生地パターンの確認（
                {canNotUseDoughCount}件の生地パターンを表示中）
              </div>
              <div
                className={
                  styles.modal_pattern_disabled_text_detail_box_description
                }
              >
                {canNotUseDoughNameList.join('・')}
              </div>
            </div>
          </div>
          <div className={styles.modal_pattern_disabled_text_detail}>
            <div
              className={styles.modal_pattern_disabled_text_detail_bar}
            ></div>
            <div className={styles.modal_pattern_disabled_text_detail_box}>
              <div
                className={styles.modal_pattern_disabled_text_detail_box_main}
              >
                このフィリングパターンを使用しているフィリングパターンの確認（
                {canNotUseFillingCount}件のフィリングパターンを表示中）
              </div>
              <div
                className={
                  styles.modal_pattern_disabled_text_detail_box_description
                }
              >
                {canNotUseFillingNameList.join('・')}
              </div>
            </div>
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="キャンセルする"
              color="orange_outlined"
              size="xmiddle"
              icon="none"
              onClick={handleCloseIsPreview}
            />
            <div style={{ marginLeft: 32 }}>
              <SubmitButton
                label="使用不可にする"
                color="orange"
                size="xmiddle"
                icon="none"
                onClick={() => handleSubmit('/filling', true)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BreadAddTemplate
