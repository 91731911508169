import { FC } from 'react'
import LoginLayout from 'components/Layout/LoginLayout'
import ResetPasswordMailTemplate from 'components/Templates/ResetPasswordMailTemplate'

const ResetPasswordMail: FC = () => {
  return (
    <LoginLayout title={'パスワード再設定メール'}>
      <ResetPasswordMailTemplate />
    </LoginLayout>
  )
}

export default ResetPasswordMail
