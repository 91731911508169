import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import PortalTemplate from 'components/Templates/PortalTemplate'

const Portal: FC = () => {
  return (
    <Layout title={'ポータルTOP'} page="Portal">
      <PortalTemplate />
    </Layout>
  )
}

export default Portal
