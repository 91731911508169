import axios from 'axios'
import { functions } from 'fb'
import { httpsCallable } from 'firebase/functions'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setIsLoading } from 'reducks/loading/slice'
import { ShopType, updateShop } from 'reducks/shop/slice'
import { StoreType, updateStore } from 'reducks/store/slice'
import { updateIsBillPaymentByStoreId } from 'reducks/shopSubscriptions/slice'
import { hiraToKata } from 'util/kana'

export const useShopEdit = () => {
  const dispatch = useDispatch<any>()
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const [shopName, setShopName] = useState<string>('')
  const [shopNameKana, setShopNameKana] = useState<string>('')
  const [factoryName, setFactoryName] = useState<string>('')
  const [officeName, setOfficeName] = useState<string>('')
  const [officerName, setOfficerName] = useState<string>('')
  const [zipCode, setZipCode] = useState<string>('')
  const [prefecture, setPrefecture] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [building, setBuilding] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [shopId, setShopId] = useState<string>('')
  const [storeId, setStoreId] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isCompany, setIsCompany] = useState<boolean>(true)
  const [isSameAddress, setIsSameAddress] = useState<boolean>(true)
  const [isBillPayment, setIsBillPayment] = useState<boolean>(false)

  // Validation
  const [shopNameErrorText, setShopNameErrorText] = useState<string | null>(
    null,
  )
  const [shopNameKanaErrorText, setShopNamaKanaErrorText] = useState<
    string | null
  >(null)
  const [factoryNameErrorText, setFactoryNameErrorText] = useState<
    string | null
  >(null)
  const [officeNameErrorText, setOfficeNameErrorText] = useState<string | null>(
    null,
  )
  const [officerNameErrorText, setOfficerNameErrorText] = useState<
    string | null
  >(null)
  const [zipCodeErrorText, setZipCodeErrorText] = useState<string | null>(null)
  const [zipCodeSearchErrorText, setZipCodeSearchErrorText] = useState<
    string | null
  >(null)
  const [prefectureErrorText, setPrefectureErrorText] = useState<string | null>(
    null,
  )
  const [cityErrorText, setCityErrorText] = useState<string | null>(null)
  const [addressErrorText, setAddressErrorText] = useState<string | null>(null)
  // const [buildingErrorText, setBuildingErrorText] = useState<string | null>(null)
  const [emailErrorText, setEmailErrorText] = useState<string | null>(null)
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState<
    string | null
  >(null)
  const [isSentEmail, setIsSentEmail] = useState(false)

  const searchAddress = useCallback(async () => {
    if (!zipCode.length) {
      setZipCodeErrorText('入力してください')
      return
    }
    if (zipCode.length !== 7) {
      setZipCodeErrorText('入力内容に誤りがあります')
      return
    }
    if (zipCode.length === 7) {
      try {
        await dispatch(setIsLoading({ isLoading: true }))
        const res = await axios.get(
          'https://zipcloud.ibsnet.co.jp/api/search',
          {
            params: {
              zipcode: zipCode,
            },
          },
        )
        if (res.data.results) {
          await dispatch(setIsLoading({ isLoading: false }))
          setZipCodeSearchErrorText('')
          const result = res.data.results[0]
          setPrefecture(result['address1'])
          setCity(result['address2'])
          setAddress(result['address3'])
          setBuilding('')
        } else {
          await dispatch(setIsLoading({ isLoading: false }))
          setZipCodeSearchErrorText('該当する住所がみつかりませんでした。')
        }
      } catch {
        alert('住所の取得に失敗しました。')
      }
    }
  }, [zipCode, dispatch])

  useEffect(() => {
    if (!!shopName) {
      setShopNameErrorText('')
    }
    if (!!shopNameKana) {
      setShopNamaKanaErrorText('')
    }
    if (!!factoryName) {
      setFactoryNameErrorText('')
    }
    if (!!officeName) {
      setOfficeNameErrorText('')
    }
    if (!!officerName) {
      setOfficerNameErrorText('')
    }
    if (!!zipCode) {
      setZipCodeErrorText('')
    }
    if (!!prefecture) {
      setPrefectureErrorText('')
    }
    if (!!city) {
      setCityErrorText('')
    }
    if (!!address) {
      setAddressErrorText('')
    }
    if (!!email) {
      setEmailErrorText('')
    }
    if (!!phoneNumber) {
      setPhoneNumberErrorText('')
    }
  }, [
    shopName,
    shopNameKana,
    factoryName,
    officeName,
    officerName,
    zipCode,
    prefecture,
    city,
    address,
    email,
    phoneNumber,
  ])

  const validation = useCallback(() => {
    let isError = false
    if (!shopName) {
      isError = true
      setShopNameErrorText('入力されておりません')
    }
    if (!shopNameKana) {
      isError = true
      setShopNamaKanaErrorText('入力されておりません')
    }
    if (!officerName) {
      isError = true
      setOfficerNameErrorText('入力されておりません')
    }
    if (!zipCode) {
      isError = true
      setZipCodeErrorText('入力されておりません')
    }
    if (!prefecture) {
      isError = true
      setPrefectureErrorText('入力されておりません')
    }
    if (!city) {
      isError = true
      setCityErrorText('入力されておりません')
    }
    if (!address) {
      isError = true
      setAddressErrorText('入力されておりません')
    }
    if (!email) {
      isError = true
      setEmailErrorText('入力されておりません')
    }
    if (!phoneNumber) {
      isError = true
      setPhoneNumberErrorText('入力されておりません')
    }
    if (isError) {
      window.scrollTo(0, 0)
      return false
    }
    return true
  }, [
    address,
    city,
    email,
    officerName,
    phoneNumber,
    prefecture,
    shopName,
    shopNameKana,
    zipCode,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleConfirmed = async () => {
    const is_valid = validation()
    if (!is_valid) {
      return
    }
    setIsConfirmed(true)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(
    async (is_resend?: boolean) => {
      const is_valid = validation()
      if (!is_valid) {
        return
      }
      const shopData: Partial<ShopType> = {
        name: isCompany ? officeName : '', //法人名
        responsible_person: officerName, //責任者名
        factory_name: factoryName,
      }
      const storeData: Partial<StoreType> = isSameAddress
        ? {
            address: {
              building,
              city,
              postal_code: zipCode,
              state: prefecture,
              street: address,
            },
            factory_address: null,
            name: shopName, //店舗名
            name_kana: hiraToKata(shopNameKana), //店舗名カナ
            personal_phone_number: phoneNumber,
          }
        : {
            factory_address: {
              building,
              city,
              postal_code: zipCode,
              state: prefecture,
              street: address,
            },
            name: shopName, //店舗名
            name_kana: hiraToKata(shopNameKana), //店舗名カナ
            personal_phone_number: phoneNumber,
          }
      if (!shopId && !isAdmin) return
      if (isAdmin && (!shopId || (shopId && is_resend))) {
        // 管理アカウントで店舗が選択されていないとき（店舗登録） or メール再送
        dispatch(setIsLoading({ isLoading: true }))
        //send email, create account,
        const inviteDaserunoStaffs = httpsCallable(
          functions,
          'inviteDaserunoStaffs',
        )
        inviteDaserunoStaffs({
          email,
          is_resend,
          shopData,
          storeData,
          isBillPayment,
        })
          .then((result) => {
            console.log(result)
            setIsSentEmail(true)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            dispatch(setIsLoading({ isLoading: false }))
          })
      } else {
        // 店舗情報更新
        dispatch(setIsLoading({ isLoading: true }))
        await dispatch(
          updateShop({
            shopData,
            shopId: shopId,
          }),
        )
        await dispatch(
          updateStore({
            storeData,
            storeId,
          }),
        )
        if (isAdmin && storeId) {
          // 請求書払いかどうかを更新
          await dispatch(
            updateIsBillPaymentByStoreId({
              storeId: storeId,
              isBillPayment: isBillPayment,
            }),
          )
        }
        setIsSentEmail(true)
        dispatch(setIsLoading({ isLoading: false }))
      }
    },
    [
      address,
      building,
      city,
      dispatch,
      email,
      factoryName,
      isAdmin,
      isCompany,
      isSameAddress,
      officeName,
      officerName,
      phoneNumber,
      prefecture,
      shopId,
      shopName,
      shopNameKana,
      storeId,
      validation,
      zipCode,
      isBillPayment,
    ],
  )

  return useMemo(
    () => ({
      shopName,
      setShopName,
      shopNameKana,
      setShopNameKana,
      factoryName,
      setFactoryName,
      officeName,
      setOfficeName,
      officerName,
      setOfficerName,
      zipCode,
      setZipCode,
      prefecture,
      setPrefecture,
      city,
      setCity,
      address,
      setAddress,
      building,
      setBuilding,
      email,
      setEmail,
      phoneNumber,
      setPhoneNumber,
      shopNameErrorText,
      shopNameKanaErrorText,
      factoryNameErrorText,
      officeNameErrorText,
      officerNameErrorText,
      zipCodeErrorText,
      prefectureErrorText,
      cityErrorText,
      addressErrorText,
      emailErrorText,
      phoneNumberErrorText,
      searchAddress,
      handleSubmit,
      handleConfirmed,
      isConfirmed,
      setIsConfirmed,
      zipCodeSearchErrorText,
      shopId,
      setShopId,
      setStoreId,
      isAdmin,
      setIsAdmin,
      isSentEmail,
      isCompany,
      setIsCompany,
      isSameAddress,
      setIsSameAddress,
      isBillPayment,
      setIsBillPayment,
    }),
    [
      shopName,
      setShopName,
      shopNameKana,
      setShopNameKana,
      factoryName,
      setFactoryName,
      officeName,
      setOfficeName,
      officerName,
      setOfficerName,
      zipCode,
      setZipCode,
      prefecture,
      setPrefecture,
      city,
      setCity,
      address,
      setAddress,
      building,
      setBuilding,
      email,
      setEmail,
      phoneNumber,
      setPhoneNumber,
      shopNameErrorText,
      shopNameKanaErrorText,
      factoryNameErrorText,
      officeNameErrorText,
      officerNameErrorText,
      zipCodeErrorText,
      prefectureErrorText,
      cityErrorText,
      addressErrorText,
      emailErrorText,
      phoneNumberErrorText,
      searchAddress,
      handleSubmit,
      handleConfirmed,
      isConfirmed,
      setIsConfirmed,
      zipCodeSearchErrorText,
      shopId,
      setShopId,
      setStoreId,
      isAdmin,
      setIsAdmin,
      isSentEmail,
      isCompany,
      setIsCompany,
      isSameAddress,
      setIsSameAddress,
      isBillPayment,
      setIsBillPayment,
    ],
  )
}
