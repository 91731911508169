import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import MaterialOperationTemplate from 'components/Templates/MaterialOperationTemplate'

const MaterialOperation: FC = () => {
  return (
    <Layout title={'原材料登録'} page="MaterialOperation">
      <MaterialOperationTemplate />
    </Layout>
  )
}

export default MaterialOperation
