import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import EditPaymentTemplate from 'components/Templates/EditPaymentTemplate'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
    'pk_test_1jzDEjKdh7nGxRYba6xsRfy600Hi4teGle',
)

const EditPaymentOperation: FC = () => {
  return (
    <Layout title={'支払方法の変更'} page="EditPayment">
      <Elements stripe={stripePromise}>
        <EditPaymentTemplate />
      </Elements>
    </Layout>
  )
}

export default EditPaymentOperation
