import { FC, useState, useEffect, useCallback } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Link } from 'react-router-dom'
import styles from 'styles/components/table.module.scss'
import CheckBox from 'components/Form/CheckBox'
import SubmitButton from 'components/Button/SubmitButton'
import StatusLabel from 'components/Label/StatusLabel'
import InputText from 'components/Form/InputText'
import noProduct from 'images/no-product.png'

type Props = {
  headers: TableHeader[]
  bodies: TableBody[][]
  handleSubmmit?: (to: string | undefined, deleteId: string | undefined) => void
  handleBreadIdList?: (breadIdList: string[]) => void
  handleHeaderCheck?: (headerCheck: boolean) => void
  handleFormUnit?: (
    breadId: string | undefined,
    type: string | undefined,
    value: string,
  ) => void
}

export type TableHeader = {
  category: string
  elementInfo: ElementInfo
}

export type TableBody = {
  category: string
  elementInfo: ElementInfo
  active: boolean
  color?: string
}

export type ElementInfo = {
  checkbox?: CheckBoxType
  text?: TextType
  image?: ImageType
  status?: StatusType
  button?: ButtonType
  link?: LinkType
  formUnit?: FormUnitType
  tooltip?: TooltipType
}

export type CheckBoxType = {
  isChecked: boolean
  label: string
  size: string
  type: string
  breadId?: string
  readOnly?: boolean
}

export type TextType = {
  label: string
  sortAscSubmit?: () => void
  sortDescSubmit?: () => void
}

export type ImageType = {
  src: string
  alt: string
}

export type StatusType = {
  name: string
  color: string
}

export type ButtonType = {
  label: string
  color: string
  size: string
  icon: string
  to?: string
  deleteId?: string
}

export type LinkType = {
  label: string
  to: string
}

export type FormUnitType = {
  value: string
  placeholder: string
  unit: string
  breadId: string
  key: string
  readOnly: boolean
}

export type TooltipType = {
  color: string
  icon: string
  value: string
  key: string
}

interface ViewToolTipType {
  [key: string]: boolean
}

const Table: FC<Props> = (props) => {
  const {
    headers,
    bodies,
    handleSubmmit,
    handleBreadIdList,
    handleHeaderCheck,
    handleFormUnit,
  } = props

  const [headersItems, setHeadersItems] = useState<TableHeader[]>([])
  const [bodiesItems, setBodiesItems] = useState<TableBody[][]>([])
  const [checkedBreadIdList, setCheckedBreadIdList] = useState<string[]>([])
  const [viewToolTip, setViewToolTip] = useState<ViewToolTipType>({})

  const handleMouseEnter = (key: string) => {
    const newViewToolTip: ViewToolTipType = {}
    newViewToolTip[key] = true
    setViewToolTip(newViewToolTip)
  }
  const handleMouseLeave = (key: string) => {
    const newViewToolTip: ViewToolTipType = {}
    newViewToolTip[key] = false
    setViewToolTip(newViewToolTip)
  }

  const categoryStyle = (
    category: string,
    is_salling: boolean,
    color: string | null = null,
  ) => {
    switch (category) {
      case 'check':
        return styles.dashboardTableBodyCol_check
      case 'thumbnail':
        return !is_salling
          ? styles.dashboardTableBodyCol_thumbnail_opacity
          : styles.dashboardTableBodyCol_thumbnail
      case 'name':
        return !is_salling
          ? styles.dashboardTableBodyCol_name_opacity
          : styles.dashboardTableBodyCol_name
      case 'long_name':
        return !is_salling
          ? styles.dashboardTableBodyCol_long_name_opacity
          : styles.dashboardTableBodyCol_long_name
      case 'semi_long_name':
        return !is_salling
          ? styles.dashboardTableBodyCol_semi_long_name_opacity
          : styles.dashboardTableBodyCol_semi_long_name
      case 'semi_long_name2':
        return !is_salling
          ? styles.dashboardTableBodyCol_semi_long_name2_opacity
          : styles.dashboardTableBodyCol_semi_long_name2
      case 'semi_long_link':
        return !is_salling
          ? styles.dashboardTableBodyCol_semi_long_name_opacity
          : styles.dashboardTableBodyCol_semi_long_name
      case 'link':
        return !is_salling
          ? styles.dashboardTableBodyCol_name_opacity
          : styles.dashboardTableBodyCol_name
      case 'price':
        return !is_salling
          ? styles.dashboardTableBodyCol_price_opacity
          : color === 'red'
          ? styles.dashboardTableBodyCol_price_red
          : color === 'blue'
          ? styles.dashboardTableBodyCol_price_blue
          : styles.dashboardTableBodyCol_price
      case 'status':
        return !is_salling
          ? styles.dashboardTableBodyCol_status_opacity
          : styles.dashboardTableBodyCol_status
      case 'icon':
        return styles.dashboardTableBodyCol_icon
      case 'form_unit':
        return styles.dashboardTableBodyCol_form_unit
      case 'tooltip':
        return styles.dashboardTableBodyCol_price
      default:
        return styles.dashboardTableBodyCol_name
    }
  }

  const handleChangeCheckBox = (
    indexRow: number,
    indexCol: number,
    isHeader: boolean,
    elementInfo: ElementInfo,
  ) => {
    if (elementInfo && elementInfo.checkbox && elementInfo.checkbox.readOnly) {
      return
    }
    if (isHeader) {
      const newHeaders = [...headersItems]
      if (
        newHeaders[indexRow].elementInfo.checkbox &&
        headersItems[indexRow].elementInfo.checkbox
      ) {
        // @ts-ignore
        newHeaders[indexRow].elementInfo.checkbox.isChecked = !headersItems[
          indexRow
        ].elementInfo.checkbox.isChecked
          ? true
          : false
        setHeadersItems(newHeaders)
        if (handleBreadIdList && handleHeaderCheck) {
          // チェックがついた場合はbodyを全体選択に、外れた場合はbodyのチェックを全て外す
          const newCheckedBreadIdList: string[] = []
          const newBodies = [...bodiesItems]
          // @ts-ignore
          if (newHeaders[indexRow].elementInfo.checkbox.isChecked) {
            newBodies.forEach((newBody, row) => {
              newBody.forEach((b, col) => {
                // チェックボックスである&チェックボックスの操作ができる商品を対象とする
                if (
                  b.category === 'check' &&
                  b.elementInfo &&
                  b.elementInfo.checkbox &&
                  !b.elementInfo.checkbox.readOnly
                ) {
                  // @ts-ignore
                  newBodies[row][col].elementInfo.checkbox.isChecked = true
                  newCheckedBreadIdList.push(
                    // @ts-ignore
                    newBodies[row][col].elementInfo.checkbox.breadId,
                  )
                }
              })
            })
            handleHeaderCheck(true)
          } else {
            newBodies.forEach((newBody, row) => {
              newBody.forEach((b, col) => {
                // チェックボックスである&チェックボックスの操作ができる商品を対象とする
                if (
                  b.category === 'check' &&
                  b.elementInfo &&
                  b.elementInfo.checkbox &&
                  !b.elementInfo.checkbox.readOnly
                ) {
                  // @ts-ignore
                  newBodies[row][col].elementInfo.checkbox.isChecked = false
                }
              })
            })
            handleHeaderCheck(false)
          }
          setBodiesItems(newBodies)
          setCheckedBreadIdList(newCheckedBreadIdList)
          handleBreadIdList(newCheckedBreadIdList)
        }
      }
    } else {
      const newBodies = [...bodiesItems]
      if (
        newBodies[indexRow][indexCol].elementInfo.checkbox &&
        bodiesItems[indexRow][indexCol].elementInfo.checkbox
      ) {
        // @ts-ignore
        newBodies[indexRow][indexCol].elementInfo.checkbox.isChecked =
          // @ts-ignore
          !bodiesItems[indexRow][indexCol].elementInfo.checkbox.isChecked
            ? true
            : false
        setBodiesItems(newBodies)
        if (
          handleBreadIdList &&
          // @ts-ignore
          newBodies[indexRow][indexCol].elementInfo.checkbox.breadId
        ) {
          const newCheckedBreadIdList = [...checkedBreadIdList]
          // @ts-ignore
          if (newBodies[indexRow][indexCol].elementInfo.checkbox.isChecked) {
            newCheckedBreadIdList.push(
              // @ts-ignore
              newBodies[indexRow][indexCol].elementInfo.checkbox.breadId,
            )
          } else {
            newCheckedBreadIdList.splice(
              newCheckedBreadIdList.indexOf(
                // @ts-ignore
                newBodies[indexRow][indexCol].elementInfo.checkbox.breadId,
              ),
              1,
            )
          }
          setCheckedBreadIdList(newCheckedBreadIdList)
          handleBreadIdList(newCheckedBreadIdList)
        }
      }
    }
  }

  const handleChangeFormUnit = (
    indexRow: number,
    indexCol: number,
    value: string,
  ) => {
    const newBodies = [...bodiesItems]
    if (
      newBodies[indexRow][indexCol].elementInfo.formUnit &&
      bodiesItems[indexRow][indexCol].elementInfo.formUnit
    ) {
      // @ts-ignore
      newBodies[indexRow][indexCol].elementInfo.formUnit.value = value
      setBodiesItems(newBodies)
      const breadId =
        newBodies[indexRow][indexCol].elementInfo.formUnit?.breadId
      const key = newBodies[indexRow][indexCol].elementInfo.formUnit?.key
      if (handleFormUnit) {
        handleFormUnit(breadId, key, value)
      }
    }
  }

  const categoryElement = (
    category: string,
    elementInfo: ElementInfo,
    indexRow: number,
    indexCol: number,
    isHeader: boolean,
  ) => {
    switch (category) {
      case 'check':
        return elementInfo.checkbox ? (
          <CheckBox
            isChecked={elementInfo.checkbox.isChecked}
            label={elementInfo.checkbox.label}
            size={elementInfo.checkbox.size}
            type={elementInfo.checkbox.type}
            readOnly={elementInfo.checkbox.readOnly}
            onChange={() =>
              handleChangeCheckBox(indexRow, indexCol, isHeader, elementInfo)
            }
          />
        ) : (
          ''
        )
      case 'thumbnail':
        return elementInfo.image ? (
          <img
            className={styles.dashboardTableBodyCol_thumbnail_image}
            src={elementInfo.image.src}
            alt={elementInfo.image.alt}
          ></img>
        ) : elementInfo.text ? (
          elementInfo.text.label
        ) : (
          ''
        )
      case 'name':
        return elementInfo.text ? elementInfo.text.label : ''
      case 'price':
        return elementInfo.text ? elementInfo.text.label : ''
      case 'long_name':
        return elementInfo.text ? elementInfo.text.label : ''
      case 'semi_long_name':
        return elementInfo.text ? elementInfo.text.label : ''
      case 'semi_long_name2':
        return elementInfo.text ? elementInfo.text.label : ''
      case 'semi_long_link':
        return elementInfo.link ? (
          <Link
            className={styles.dashboardTableBodyCol_link_text}
            to={elementInfo.link.to}
          >
            <span>{elementInfo.link.label}</span>
          </Link>
        ) : (
          ''
        )
      case 'link':
        return elementInfo.link ? (
          <Link
            className={styles.dashboardTableBodyCol_link_text}
            to={elementInfo.link.to}
          >
            <span>{elementInfo.link.label}</span>
          </Link>
        ) : (
          ''
        )
      case 'status':
        return elementInfo.status ? (
          <StatusLabel
            name={elementInfo.status.name}
            color={elementInfo.status.color}
          />
        ) : elementInfo.text ? (
          elementInfo.text.label
        ) : (
          ''
        )
      case 'icon':
        return elementInfo.button ? (
          <SubmitButton
            label={elementInfo.button.label}
            color={elementInfo.button.color}
            size={elementInfo.button.size}
            icon={elementInfo.button.icon}
            onClick={() =>
              handleSubmmit
                ? handleSubmmit(
                    elementInfo.button && elementInfo.button.to
                      ? elementInfo.button.to
                      : undefined,
                    elementInfo.button && elementInfo.button.deleteId
                      ? elementInfo.button.deleteId
                      : undefined,
                  )
                : {}
            }
          />
        ) : elementInfo.text ? (
          elementInfo.text.label
        ) : (
          ''
        )
      case 'form_unit':
        return elementInfo.formUnit ? (
          <div className={styles.flex}>
            <InputText
              required={false}
              type="text"
              size="in_table"
              rightAligned={true}
              value={elementInfo.formUnit.value}
              placeholder={elementInfo.formUnit.placeholder}
              readOnly={elementInfo.formUnit.readOnly}
              onChange={(event) =>
                handleChangeFormUnit(indexRow, indexCol, event.target.value)
              }
            />
            <div className={styles.margin_left}>
              {elementInfo.formUnit.unit}
            </div>
          </div>
        ) : elementInfo.text ? (
          elementInfo.text.label
        ) : (
          ''
        )
      case 'tooltip':
        return elementInfo.tooltip ? (
          <div
            className={styles.flex}
            onMouseEnter={() =>
              handleMouseEnter(
                elementInfo.tooltip ? elementInfo.tooltip.key : '',
              )
            }
            onMouseLeave={() =>
              handleMouseLeave(
                elementInfo.tooltip ? elementInfo.tooltip.key : '',
              )
            }
          >
            <img
              className={styles.dashboardTableBodyCol_icon_middle}
              src={elementInfo.tooltip.icon}
              alt={elementInfo.tooltip.value}
            ></img>

            {viewToolTip && viewToolTip[elementInfo.tooltip.key] ? (
              <div className={styles.tooltip}>
                {elementInfo.tooltip.value.split('<br>').map((text) => (
                  <div>{text}</div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )
      default:
        return ''
    }
  }

  const initTableInfo = useCallback(
    (headers: TableHeader[], bodies: TableBody[][]) => {
      setHeadersItems(headers)
      setBodiesItems(bodies)
    },
    [headers, bodies],
  )

  useEffect(() => {
    initTableInfo(headers, bodies)
  }, [headers, bodies])

  return (
    <table className={styles.dashboardTableContainer}>
      <tr className={styles.dashboardTableHeader}>
        <div className={styles.dashboardTableHeader_start}></div>
        {headers.map((header, index) => (
          <th className={categoryStyle(header.category, true)} key={index}>
            <div className={styles.flex2}>
              {categoryElement(
                header.category,
                header.elementInfo,
                index,
                0,
                true,
              )}
              {header.elementInfo.text?.sortAscSubmit &&
              header.elementInfo.text?.sortDescSubmit ? (
                <div className={styles.margin_left}>
                  <SubmitButton
                    label="△"
                    color="none"
                    size="icon"
                    icon="sort_asc"
                    onClick={() =>
                      header.elementInfo.text?.sortAscSubmit
                        ? header.elementInfo.text?.sortAscSubmit()
                        : {}
                    }
                  />
                  <SubmitButton
                    label="▽"
                    color="none"
                    size="icon"
                    icon="sort_desc"
                    onClick={() =>
                      header.elementInfo.text?.sortDescSubmit
                        ? header.elementInfo.text?.sortDescSubmit()
                        : {}
                    }
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </th>
        ))}
        <div className={styles.dashboardTableHeader_end}></div>
      </tr>
      <Scrollbars style={{ width: 1002, height: 484 }}>
        {bodies.map((bodyRow, i) => (
          <tr className={styles.dashboardTableBodyRow} key={i}>
            <div className={styles.dashboardTableBodyRow_start}></div>
            {bodyRow.map((bodyCol, j) => (
              <td
                className={categoryStyle(
                  bodyCol.category,
                  bodyCol.active,
                  bodyCol.color,
                )}
                key={j}
              >
                {categoryElement(
                  bodyCol.category,
                  bodyCol.elementInfo,
                  i,
                  j,
                  false,
                )}
              </td>
            ))}
            <div className={styles.dashboardTableBodyRow_end}></div>
          </tr>
        ))}
      </Scrollbars>
      {bodies.length === 0 ? (
        <img
          className={styles.dashboardTableBodyCol_no_product_image}
          src={noProduct}
          alt="商品がありませんでした"
        ></img>
      ) : (
        ''
      )}
    </table>
  )
}

export default Table
