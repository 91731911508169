import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import MembersTemplate from 'components/Templates/MembersTemplate'

const Members: FC = () => {
  return (
    <Layout title={'ユーザー権限'} page="Members">
      <MembersTemplate />
    </Layout>
  )
}

export default Members
