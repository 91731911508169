import { FC, useState, useEffect } from 'react'
import styles from 'styles/components/form.module.scss'
import RadioButtonAndInputTextWithLabel from 'components/Form/WithLabel/RadioButtonAndInputText'
import SubmitButton from 'components/Button/SubmitButton'

type Props = {
  formInfos: ConstituteMaterialNameInfo[]
  handleConstituteMaterialNameInfos: (
    item: ConstituteMaterialNameInfo[],
  ) => void
  errorText?: string | null
}

export type isGeneticallyInfo = {
  noSelection: boolean
  modified: boolean
  noDifference: boolean
}

export type ConstituteMaterialNameInfo = {
  constituteMaterialName: string
  isGenetically: isGeneticallyInfo
}

const AddFormConstituteMaterialNameInformation: FC<Props> = (props) => {
  const { formInfos, handleConstituteMaterialNameInfos, errorText } = props

  const [formItems, setFormItems] = useState<ConstituteMaterialNameInfo[]>([])

  const handleSetFormItems = (newItems: ConstituteMaterialNameInfo[]) => {
    setFormItems(newItems)
    handleConstituteMaterialNameInfos(newItems)
  }

  const handleAddButton = () => {
    const newItem = {
      constituteMaterialName: '',
      isGenetically: {
        noSelection: true,
        modified: false,
        noDifference: false,
      },
    }
    const newItems = [...formItems, newItem]

    handleSetFormItems(newItems)
  }

  const handleDeleteButton = (index: number) => {
    const newItems = [...formItems]
    // 情報は1つは残す
    if (newItems.length > 1) {
      newItems.splice(index, 1)
      handleSetFormItems(newItems)
    }
  }

  const handleChangeConstituteMaterialName = (
    index: number,
    constituteMaterialName: string,
  ) => {
    const newItems = [...formItems]
    newItems[index].constituteMaterialName = constituteMaterialName
    handleSetFormItems(newItems)
  }

  const handleChangeGenetically = (
    index: number,
    isGenetically: isGeneticallyInfo,
  ) => {
    const newItems = [...formItems]
    newItems[index].isGenetically = isGenetically
    handleSetFormItems(newItems)
  }

  useEffect(() => {
    setFormItems(formInfos)
  }, [formInfos])

  return (
    <div>
      {formItems.map((item, index) => (
        <div key={index}>
          {index > 0 ? <div className={styles.addform_line}></div> : ''}
          <div className={styles.addform}>
            <div className={styles.addform_form}>
              <RadioButtonAndInputTextWithLabel
                required={index === 0 ? true : false}
                labelName={index === 0 ? '構成原材料名' : ''}
                description={
                  index === 0
                    ? '原材料名を1つずつ入力をしてください。原材料は規格書の順番通り（上から、もしくは左から順）に入力をしてください。<br>※（乳成分を含む）などのアレルゲン情報は省いて記載してください。アレルゲンは別に選択する箇所があります。<br>※遺伝子組み換えの記載がある原材料、遺伝子組み換え不分別の記載がある原材料の場合にいずれかを選択をしてください。それ以外の場合は〝選択なし〟のままにしてください。'
                    : ''
                }
                line={1}
                radioSize="middle2"
                checkInfos={[
                  {
                    isChecked: item.isGenetically.noSelection,
                    label: '選択なし',
                    onChange: () =>
                      handleChangeGenetically(index, {
                        noSelection: true,
                        modified: false,
                        noDifference: false,
                      }),
                  },
                  {
                    isChecked: item.isGenetically.noDifference,
                    label: '不分別',
                    onChange: () =>
                      handleChangeGenetically(index, {
                        noSelection: false,
                        modified: false,
                        noDifference: true,
                      }),
                  },
                  {
                    isChecked: item.isGenetically.modified,
                    label: '遺伝子組換え',
                    onChange: () =>
                      handleChangeGenetically(index, {
                        noSelection: false,
                        modified: true,
                        noDifference: false,
                      }),
                  },
                ]}
                type="text"
                size="large"
                value={item.constituteMaterialName}
                placeholder="あいうえお"
                errorText={index === 0 ? errorText : ''}
                onChange={(event) =>
                  handleChangeConstituteMaterialName(index, event.target.value)
                }
              />
              {index > 0 ? (
                <div className={styles.addform_delete_button_2}>
                  <SubmitButton
                    label=""
                    color="white"
                    size="icon"
                    icon="delete_orange"
                    onClick={() => handleDeleteButton(index)}
                  />
                </div>
              ) : (
                <div className={styles.addform_space_box_icon}></div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className={styles.addform_add_button_2}>
        <SubmitButton
          label="項目を増やす"
          color="white2"
          size="small"
          icon="outline_plus"
          onClick={handleAddButton}
        />
      </div>
    </div>
  )
}

export default AddFormConstituteMaterialNameInformation
