import { combineReducers } from '@reduxjs/toolkit'
import { additiveMaterialSlice } from './additiveMaterial/slice'
import { additiveUseSlice } from './additiveUse/slice'
import { authSlice } from './auth/slice'
import { staffSlice } from './staff/slice'
import { breadSlice } from './bread/slice'
import { categorySlice } from './category/slice'
import { loadingSlice } from './loading/slice'
import { materialSlice } from './material/slice'
import { materialCategorySlice } from './materialCategory/slice'
import { materialSubCategorySlice } from './materialSubCategory/slice'
import { doughSlice } from './dough/slice'
import { fillingSlice } from './filling/slice'
import { recipeSlice } from './recipe/slice'
import { shopSlice } from './shop/slice'
import { storeSlice } from './store/slice'
import { businessSlice } from './business/slice'
import { materialCostSlice } from './materialCost/slice'
import { costSimulationSlice } from './costSimulation/slice'

export interface RootState {
  additiveMaterialSlice: ReturnType<typeof additiveMaterialSlice.reducer>
  additiveUseSlice: ReturnType<typeof additiveUseSlice.reducer>
  authSlice: ReturnType<typeof authSlice.reducer>
  staffSlice: ReturnType<typeof staffSlice.reducer>
  breadSlice: ReturnType<typeof breadSlice.reducer>
  categorySlice: ReturnType<typeof categorySlice.reducer>
  loadingSlice: ReturnType<typeof loadingSlice.reducer>
  materialSlice: ReturnType<typeof materialSlice.reducer>
  materialCategorySlice: ReturnType<typeof materialCategorySlice.reducer>
  materialSubCategorySlice: ReturnType<typeof materialSubCategorySlice.reducer>
  doughSlice: ReturnType<typeof doughSlice.reducer>
  fillingSlice: ReturnType<typeof fillingSlice.reducer>
  recipeSlice: ReturnType<typeof recipeSlice.reducer>
  shopSlice: ReturnType<typeof shopSlice.reducer>
  storeSlice: ReturnType<typeof storeSlice.reducer>
  businessSlice: ReturnType<typeof businessSlice.reducer>
  materialCostSlice: ReturnType<typeof materialCostSlice.reducer>
  costSimulationSlice: ReturnType<typeof costSimulationSlice.reducer>
}

export const reducers = combineReducers<RootState>({
  additiveMaterialSlice: additiveMaterialSlice.reducer,
  additiveUseSlice: additiveUseSlice.reducer,
  authSlice: authSlice.reducer,
  staffSlice: staffSlice.reducer,
  breadSlice: breadSlice.reducer,
  categorySlice: categorySlice.reducer,
  loadingSlice: loadingSlice.reducer,
  materialSlice: materialSlice.reducer,
  materialCategorySlice: materialCategorySlice.reducer,
  materialSubCategorySlice: materialSubCategorySlice.reducer,
  doughSlice: doughSlice.reducer,
  fillingSlice: fillingSlice.reducer,
  recipeSlice: recipeSlice.reducer,
  shopSlice: shopSlice.reducer,
  storeSlice: storeSlice.reducer,
  businessSlice: businessSlice.reducer,
  materialCostSlice: materialCostSlice.reducer,
  costSimulationSlice: costSimulationSlice.reducer,
})
