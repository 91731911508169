import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  DocumentReference,
  doc,
  addDoc,
  updateDoc,
} from 'firebase/firestore'

interface MaterialCategoryState {
  materialCategories: MaterialCategory[]
}
interface MaterialCategory {
  id: string
  name: string
  is_disabled: boolean
  material_category_ref: DocumentReference
}

export type MaterialCategoryType = MaterialCategory

const initialState = {
  materialCategories: [],
} as MaterialCategoryState

export const getMaterialCategories = createAsyncThunk<MaterialCategory[]>(
  'materialCategory/getMaterialCategories',
  async () => {
    const q = query(
      collection(db, 'material_categories'),
      where('is_disabled', '==', false),
      orderBy('order'),
    )
    const querySnapshot = await getDocs(q)
    const materialCategories: MaterialCategory[] = []
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const materialCategory = doc.data()
        materialCategories.push({
          id: doc.id,
          name: materialCategory.name,
          is_disabled: materialCategory.is_disabled,
          material_category_ref: materialCategory.material_category_ref,
        })
      }),
    )
    return materialCategories
  },
)

export const createMaterialCategories = createAsyncThunk<boolean>(
  'materialCategory/createMaterialCategories',
  async () => {
    const materialCategoryRef = collection(db, 'material_categories')
    const nameList = [
      '小麦粉・雑穀粉',
      'バター・乳製品・油脂・卵',
      'イースト・酵母',
      '砂糖・はちみつ・ジャム',
      '塩・スパイス・ハーブ',
      'チョコレート・ココア',
      'ナッツ(アーモンド・くるみ等)',
      '栗・芋・かぼちゃ・シード',
      'ドライフルーツ・野菜・果物',
      '精肉・肉加工品・ハム・ソーセージ',
      '魚介類・海産物・水産加工品',
      '酒・リキュール・水',
      '膨張剤・香料・色素・凝固剤・添加物・改良剤',
      '調味料',
      'フィリング',
      'その他',
    ]
    await Promise.all(
      nameList.map(async (name, index) => {
        const createData = {
          name: name,
          is_disabled: false,
          order: index,
        }
        const docRef: DocumentReference = await addDoc(
          materialCategoryRef,
          createData,
        )
        const materialCategoryDoc = doc(db, 'material_categories', docRef.id)
        const updateData = {
          material_category_ref: materialCategoryDoc,
        }
        await updateDoc(materialCategoryDoc, updateData)
      }),
    )
    return true
  },
)

export const materialCategorySlice: Slice<
  MaterialCategoryState,
  {},
  'materialCategory'
> = createSlice({
  name: 'materialCategory',
  initialState,
  reducers: {},
  extraReducers: {
    [getMaterialCategories.pending.type]: () => {},
    [getMaterialCategories.fulfilled.type]: (
      state,
      action: PayloadAction<MaterialCategory[]>,
    ) => {
      state.materialCategories = action.payload
    },
    [getMaterialCategories.rejected.type]: () => {},
  },
})

export default materialCategorySlice.reducer
