import { FC, useEffect, useState } from 'react'
import styles from 'styles/components/imagepreview.module.scss'
import imageCloseIcon from 'images/ImagePreview/image-close-icon.png'

type ImagePreviewProps = {
  file: File | null
  onClick: () => void
}

const ImagePreview: FC<ImagePreviewProps> = ({ file, onClick, ...props }) => {
  const [url, setUrl] = useState<string>('')
  // const isLoading = file && !url;

  useEffect(() => {
    if (!file) {
      return
    }

    let reader: FileReader | null = new FileReader()
    reader.onloadend = () => {
      const res = reader ? reader!.result : null
      if (res && typeof res === 'string') {
        setUrl(res)
      }
    }
    reader.readAsDataURL(file)

    return () => {
      reader = null
    }
  }, [file])

  return file ? (
    <div className={styles.previewImageContainer}>
      <button className={styles.button} type="button" onClick={onClick}>
        <img className={styles.icon} src={imageCloseIcon} alt={'x'} />
      </button>
      <img className={styles.image} src={url} alt={file.name} {...props} />
    </div>
  ) : null
}

export default ImagePreview
