import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import FoodNutritionLabelTemplate from 'components/Templates/FoodNutritionLabelTemplate'

const FoodNutritionLabelOperation: FC = () => {
  return (
    <Layout title={'食品栄養表示ラベル'} page="FoodNutritionLabel">
      <FoodNutritionLabelTemplate />
    </Layout>
  )
}

export default FoodNutritionLabelOperation
