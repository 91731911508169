import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import PriceCardTemplate from 'components/Templates/PriceCardTemplate'

const PriceCardOperation: FC = () => {
  return (
    <Layout title={'プライスカード'} page="PriceCard">
      <PriceCardTemplate />
    </Layout>
  )
}

export default PriceCardOperation
