import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import CostSimulationOperationTemplate from 'components/Templates/CostSimulationOperationTemplate'

const SimulationOperation: FC = () => {
  return (
    <Layout title={'原価シミュレーション登録'} page="CostSimulationOperation">
      <CostSimulationOperationTemplate />
    </Layout>
  )
}

export default SimulationOperation
