import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import MaterialCostOperationTemplate from 'components/Templates/MaterialCostOperationTemplate'

const MaterialCostOperation: FC = () => {
  return (
    <Layout title={'原価登録'} page="MaterialOperation">
      <MaterialCostOperationTemplate />
    </Layout>
  )
}

export default MaterialCostOperation
