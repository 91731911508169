// import useBreadcrumbs from 'use-react-router-breadcrumbs'
import Portal from 'pages/Portal'
import BreadAdd from 'pages/BreadAdd'
import BreadDetail from 'pages/BreadDetail'
import BreadAddRecipe from 'pages/BreadAddRecipe'
import BreadDashBoard from 'pages/BreadDashBoard'
import BreadCost from 'pages/BreadCost'
import Dough from 'pages/Dough'
import DoughOperation from 'pages/DoughOperation'
import Filling from 'pages/Filling'
import FillingOperation from 'pages/FillingOperation'
import Material from 'pages/Material'
import MaterialOperation from 'pages/MaterialOperation'
import MaterialDeleteConfirm from 'pages/MaterialDeleteConfirm'
import MaterialCostOperation from 'pages/MaterialCostOperation'
import Login from 'pages/Login'
import ResetPassword from 'pages/ResetPassword'
import Logout from 'pages/Logout'
import InitLoad from 'pages/InitLoad'
import MyPage from 'pages/MyPage'
import Account from 'pages/Account'
import PriceCard from 'pages/PriceCard'
import SacriAdminTool from 'pages/SacriAdminTool'
import FoodNutritionLabel from 'pages/FoodNutritionLabel'
import RegisterPlan from 'pages/RegisterPlan'
import EditPlan from 'pages/EditPlan'
import EditPayment from 'pages/EditPayment'
import PlanConfirm from 'pages/PlanConfirm'
import ResetPasswordMail from 'pages/ResetPasswordMail'
import ResetPasswordComplete from 'pages/ResetPasswordComplete'
import NewPassword from 'pages/NewPassword'
import Terms from 'pages/Terms'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import RegisterShop from 'pages/RegisterShop'
import EditShop from 'pages/EditShop'
import SetPassword from 'pages/SetPassword'
import Company from 'pages/Company'
import Law from 'pages/Law'
import Members from 'pages/Members'
import CostSimulationOperation from 'pages/CostSimulationOperation'
import CostSimulation from 'pages/CostSimulation'

export const Routes = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: <BreadDashBoard />,
        breadcrumb: 'ホーム',
      },
      {
        path: '/bread',
        element: <Portal />,
        breadcrumb: 'ポータル',
      },
      {
        path: '/bread/add',
        element: <BreadAdd />,
        breadcrumb: '商品登録',
      },
      {
        path: '/bread/edit/:id',
        element: <BreadAdd />,
        breadcrumb: '商品編集',
      },
      {
        path: '/bread/copy/:id',
        element: <BreadAdd />,
        breadcrumb: '商品登録',
      },
      {
        path: '/bread/detail/:id',
        element: <BreadDetail />,
        breadcrumb: '商品詳細',
      },
      {
        path: '/bread/edit/recipe/:id',
        element: <BreadAddRecipe />,
        breadcrumb: 'レシピ登録',
      },
      {
        path: '/bread/cost',
        element: <BreadCost />,
        breadcrumb: '原価一覧',
      },
      {
        path: '/dough',
        element: <Dough />,
        breadcrumb: '生地パターン',
      },
      {
        path: '/dough/add',
        element: <DoughOperation />,
        breadcrumb: '生地パターン作成',
      },
      {
        path: '/dough/edit/:id',
        element: <DoughOperation />,
        breadcrumb: '生地パターン編集',
      },
      {
        path: '/dough/copy/:id',
        element: <DoughOperation />,
        breadcrumb: '生地パターン作成',
      },
      {
        path: '/filling',
        element: <Filling />,
        breadcrumb: 'フィリングパターン',
      },
      {
        path: '/filling/add',
        element: <FillingOperation />,
        breadcrumb: 'フィリングパターン作成',
      },
      {
        path: '/filling/edit/:id',
        element: <FillingOperation />,
        breadcrumb: 'フィリングパターン編集',
      },
      {
        path: '/filling/copy/:id',
        element: <FillingOperation />,
        breadcrumb: 'フィリングパターン作成',
      },
      {
        path: '/material',
        element: <Material />,
        breadcrumb: '原材料一覧',
      },
      {
        path: '/material/add',
        element: <MaterialOperation />,
        breadcrumb: '原材料登録',
      },
      {
        path: '/material/edit/:id',
        element: <MaterialOperation />,
        breadcrumb: '原材料編集',
      },
      {
        path: '/material/delete/:id/confirm',
        element: <MaterialDeleteConfirm />,
        breadcrumb: '原材料削除確認',
      },
      {
        path: '/material/:id/cost',
        element: <MaterialCostOperation />,
        breadcrumb: '原価登録',
      },
      {
        path: '/login',
        element: <Login />,
        breadcrumb: 'ログイン',
      },
      {
        path: '/password/set',
        element: <SetPassword />,
        breadcrumb: 'パスワード設定',
      },
      {
        path: '/password/reset',
        element: <ResetPassword />,
        breadcrumb: 'パスワード再設定',
      },
      {
        path: '/password/reset/send',
        element: <ResetPasswordMail />,
        breadcrumb: 'パスワード再設定メール',
      },
      {
        path: '/password/reset/new',
        element: <NewPassword />,
        breadcrumb: '新しいパスワード',
      },
      {
        path: '/password/reset/complete',
        element: <ResetPasswordComplete />,
        breadcrumb: 'パスワード再設定完了',
      },
      {
        path: '/registerplan',
        element: <RegisterPlan />,
        breadcrumb: 'プラン登録',
      },
      {
        path: '/logout',
        element: <Logout />,
        breadcrumb: 'ログアウト',
      },
      {
        path: '/initload',
        element: <InitLoad />,
        breadcrumb: 'ローディング',
      },
      {
        path: '/mypage',
        element: <MyPage />,
        breadcrumb: 'マイページ',
      },
      {
        path: '/account',
        element: <Account />,
        breadcrumb: 'ユーザー設定',
      },
      {
        path: '/members',
        element: <Members />,
        breadcrumb: 'ユーザー権限',
      },
      {
        path: '/shop/register',
        element: <RegisterShop />,
        breadcrumb: '店舗入力',
      },
      {
        path: '/shop/edit',
        element: <EditShop />,
        breadcrumb: '店舗入力',
      },
      {
        path: '/edit_plan',
        element: <EditPlan />,
        breadcrumb: 'プラン編集',
      },
      {
        path: '/edit_payment',
        element: <EditPayment />,
        breadcrumb: '支払方法の変更',
      },
      {
        path: '/plan_confirm',
        element: <PlanConfirm />,
        breadcrumb: '現在のプラン',
      },
      {
        path: '/pricecard',
        element: <PriceCard />,
        breadcrumb: 'プライスカード',
      },
      {
        path: '/food_nutrition_label',
        element: <FoodNutritionLabel />,
        breadcrumb: '食品栄養表示ラベル',
      },
      {
        path: '/sacri_admin_tool',
        element: <SacriAdminTool />,
        breadcrumb: 'ツール',
      },
      {
        path: '/terms',
        element: <Terms />,
        breadcrumb: '利用規約',
      },
      {
        path: '/privacy_policy',
        element: <PrivacyPolicy />,
        breadcrumb: 'プライバシーポリシー',
      },
      {
        path: '/company',
        element: <Company />,
        breadcrumb: '運営会社',
      },
      {
        path: '/law',
        element: <Law />,
        breadcrumb: '特定商取引法に基づく表記',
      },
      {
        path: '/simulation/cost',
        element: <CostSimulation />,
        breadcrumb: '原価シミュレーション一覧',
      },
      {
        path: '/simulation/cost/add',
        element: <CostSimulationOperation />,
        breadcrumb: '原価シミュレーション登録',
      },
      {
        path: '/simulation/cost/edit/:id',
        element: <CostSimulationOperation />,
        breadcrumb: '原価シミュレーション登録',
      },
      {
        path: '/simulation/cost/copy/:id',
        element: <CostSimulationOperation />,
        breadcrumb: '原価シミュレーション登録',
      },
    ],
  },
]
