import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import TermsPageTemplate from 'components/Templates/TermsTemplate'
import { useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'

const TermsPage: FC = () => {
  const { uid } = useSelector((state: RootState) => state.authSlice)

  return !!uid ? (
    <Layout title={'利用規約'} page="TermsPage">
      <TermsPageTemplate />
    </Layout>
  ) : (
    <TermsPageTemplate />
  )
}

export default TermsPage
