import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  getDocs,
  DocumentReference,
  Timestamp,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  where,
  orderBy,
} from 'firebase/firestore'
import { ProductInformation } from 'components/Form/AddForm/ProductInformation'
import { BreadStatus } from 'reducks/bread/slice'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'

interface RecipeState {
  recipe: Recipe | null
  recipes: Recipe[]
}

interface Recipe {
  id: string
  dough_material_informations: MaterialInformation[]
  filling_material_informations: MaterialInformation[]
  recipe_material_informations: MaterialInformation[]
  amount_dough_before_baking: number
  loss_rate: number
  burnout_rate: number
  amount_dough_after_baking: number | null
  is_after_baking_percent: boolean
  is_after_baking_gram: boolean
  memo: string
  process: string
  is_percent: boolean
  is_gram: boolean
  bread_ref: DocumentReference
  recipe_name: string
  is_draft: boolean
  shop_ref: DocumentReference
  created_at: Timestamp | null
}

interface MaterialInformation {
  productName: string | null
  manufacturerName: string | null
  middle_knead_percent: number
  authentic_knead_percent: number
  material_ref: DocumentReference | null
  dough_ref: DocumentReference | null
  filling_ref: DocumentReference | null
  recipe_ref: DocumentReference | null
}

export type RecipeType = Recipe

export type MaterialInformationType = MaterialInformation

const initialState = {
  recipe: null,
  recipes: [],
} as RecipeState

export const getRecipes = createAsyncThunk<
  Recipe[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('recipe/getRecipes', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const recipes: Recipe[] = []
  const q = query(
    collection(db, 'recipes'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
  )
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const recipe = doc.data()
      recipes.push({
        id: doc.id,
        dough_material_informations: recipe.dough_material_informations,
        filling_material_informations: recipe.filling_material_informations,
        recipe_material_informations: recipe.recipe_material_informations,
        amount_dough_before_baking: recipe.amount_dough_before_baking,
        loss_rate: recipe.loss_rate,
        burnout_rate: recipe.burnout_rate,
        amount_dough_after_baking: recipe.amount_dough_after_baking,
        is_after_baking_percent: recipe.is_after_baking_percent,
        is_after_baking_gram: recipe.is_after_baking_gram,
        memo: recipe.memo,
        process: recipe.process,
        is_percent: recipe.is_percent,
        is_gram: recipe.is_gram,
        bread_ref: recipe.bread_ref,
        recipe_name: recipe.recipe_name,
        is_draft: recipe.is_draft,
        shop_ref: recipe.shop_ref,
        created_at: recipe.created_at,
      })
    }),
  )
  return recipes
})

export const getPublicRecipes = createAsyncThunk<
  Recipe[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('recipe/getPublicRecipes', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const recipes: Recipe[] = []
  const q = query(
    collection(db, 'recipes'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    orderBy('recipe_name'),
  )
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (document) => {
      const recipe = document.data()
      const breadQuery = doc(db, 'breads', recipe.bread_ref.id)
      const breadQuerySnapshot = await getDoc(breadQuery)
      const bread = breadQuerySnapshot.data()
      if (
        !bread ||
        bread.status === BreadStatus.DELETE ||
        bread.daseruno_status === BreadStatus.DRAFT ||
        bread.daseruno_recipe_status === BreadStatus.DRAFT
      ) {
        return
      }
      recipes.push({
        id: document.id,
        dough_material_informations: recipe.dough_material_informations,
        filling_material_informations: recipe.filling_material_informations,
        recipe_material_informations: recipe.recipe_material_informations,
        amount_dough_before_baking: recipe.amount_dough_before_baking,
        loss_rate: recipe.loss_rate,
        burnout_rate: recipe.burnout_rate,
        amount_dough_after_baking: recipe.amount_dough_after_baking,
        is_after_baking_percent: recipe.is_after_baking_percent,
        is_after_baking_gram: recipe.is_after_baking_gram,
        memo: recipe.memo,
        process: recipe.process,
        is_percent: recipe.is_percent,
        is_gram: recipe.is_gram,
        bread_ref: recipe.bread_ref,
        recipe_name: recipe.recipe_name,
        is_draft: recipe.is_draft,
        shop_ref: recipe.shop_ref,
        created_at: recipe.created_at,
      })
    }),
  )
  return recipes
})

export const getRecipeById = createAsyncThunk<
  Recipe | null,
  { id: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('recipe/getRecipeById', async ({ id }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const q = doc(db, 'recipes', id)
  const querySnapshot = await getDoc(q)
  const recipe = querySnapshot.data()
  if (!recipe) {
    return null
  }
  const recipeShopId = recipe.shop_ref.id
    ? recipe.shop_ref.id
    : // @ts-ignore
      recipe.shop_ref._key.path.segments[6]
  // 所属しているお店の商品情報しか取得できない
  if (recipeShopId !== staff.shopId) {
    return null
  }
  const response: Recipe = {
    id: querySnapshot.id,
    dough_material_informations: recipe.dough_material_informations,
    filling_material_informations: recipe.filling_material_informations,
    recipe_material_informations: recipe.recipe_material_informations,
    amount_dough_before_baking: recipe.amount_dough_before_baking,
    loss_rate: recipe.loss_rate,
    burnout_rate: recipe.burnout_rate,
    amount_dough_after_baking: recipe.amount_dough_after_baking,
    is_after_baking_percent: recipe.is_after_baking_percent,
    is_after_baking_gram: recipe.is_after_baking_gram,
    memo: recipe.memo,
    process: recipe.process,
    is_percent: recipe.is_percent,
    is_gram: recipe.is_gram,
    bread_ref: recipe.bread_ref,
    recipe_name: recipe.recipe_name,
    is_draft: recipe.is_draft,
    shop_ref: recipe.shop_ref,
    created_at: recipe.created_at,
  }
  return response
})

export const getRecipeByBreadId = createAsyncThunk<
  Recipe | null,
  { id: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('recipe/getRecipeByBreadId', async ({ id }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const q = query(
    collection(db, 'recipes'),
    where('bread_ref', '==', doc(db, 'breads', id)),
  )
  const querySnapshot = await getDocs(q)
  const recipe = querySnapshot.docs[0].data()
  if (!recipe) {
    return null
  }
  const recipeShopId = recipe.shop_ref.id
    ? recipe.shop_ref.id
    : // @ts-ignore
      recipe.shop_ref._key.path.segments[6]
  // 所属しているお店の商品情報しか取得できない
  if (recipeShopId !== staff.shopId) {
    return null
  }
  const response: Recipe = {
    id: querySnapshot.docs[0].id,
    dough_material_informations: recipe.dough_material_informations,
    filling_material_informations: recipe.filling_material_informations,
    recipe_material_informations: recipe.recipe_material_informations,
    amount_dough_before_baking: recipe.amount_dough_before_baking,
    loss_rate: recipe.loss_rate,
    burnout_rate: recipe.burnout_rate,
    amount_dough_after_baking: recipe.amount_dough_after_baking,
    is_after_baking_percent: recipe.is_after_baking_percent,
    is_after_baking_gram: recipe.is_after_baking_gram,
    memo: recipe.memo,
    process: recipe.process,
    is_percent: recipe.is_percent,
    is_gram: recipe.is_gram,
    bread_ref: recipe.bread_ref,
    recipe_name: recipe.recipe_name,
    is_draft: recipe.is_draft,
    shop_ref: recipe.shop_ref,
    created_at: recipe.created_at,
  }
  return response
})

// 管理者が使用する
export const getAllPublicRecipes = createAsyncThunk<
  Recipe[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('recipe/getAllPublicRecipes', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.isAdmin) {
    return null
  }
  const recipes: Recipe[] = []
  const q = query(collection(db, 'recipes'))
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (document) => {
      const recipe = document.data()
      const breadQuery = doc(db, 'breads', recipe.bread_ref.id)
      const breadQuerySnapshot = await getDoc(breadQuery)
      const bread = breadQuerySnapshot.data()
      if (
        !bread ||
        bread.status === BreadStatus.DELETE ||
        bread.daseruno_status === BreadStatus.DRAFT ||
        bread.daseruno_recipe_status === BreadStatus.DRAFT
      ) {
        return
      }
      recipes.push({
        id: document.id,
        dough_material_informations: recipe.dough_material_informations,
        filling_material_informations: recipe.filling_material_informations,
        recipe_material_informations: recipe.recipe_material_informations,
        amount_dough_before_baking: recipe.amount_dough_before_baking,
        loss_rate: recipe.loss_rate,
        burnout_rate: recipe.burnout_rate,
        amount_dough_after_baking: recipe.amount_dough_after_baking,
        is_after_baking_percent: recipe.is_after_baking_percent,
        is_after_baking_gram: recipe.is_after_baking_gram,
        memo: recipe.memo,
        process: recipe.process,
        is_percent: recipe.is_percent,
        is_gram: recipe.is_gram,
        bread_ref: recipe.bread_ref,
        recipe_name: recipe.recipe_name,
        is_draft: recipe.is_draft,
        shop_ref: recipe.shop_ref,
        created_at: recipe.created_at,
      })
    }),
  )
  return recipes
})

export const createRecipe = createAsyncThunk<
  string | null,
  {
    dough_material_informations: ProductInformation[] | null
    filling_material_informations: ProductInformation[] | null
    recipe_material_informations: ProductInformation[] | null
    amount_dough_before_baking: number
    loss_rate: number
    burnout_rate: number
    amount_dough_after_baking: number | null
    is_after_baking_percent: boolean
    is_after_baking_gram: boolean
    memo: string
    process: string
    is_percent: boolean
    is_gram: boolean
    bread_id: string
    recipe_name: string
    is_draft: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'recipe/createRecipe',
  async (
    {
      dough_material_informations,
      filling_material_informations,
      recipe_material_informations,
      amount_dough_before_baking,
      loss_rate,
      burnout_rate,
      amount_dough_after_baking,
      is_after_baking_percent,
      is_after_baking_gram,
      memo,
      process,
      is_percent,
      is_gram,
      bread_id,
      recipe_name,
      is_draft,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return null
    }
    // let isInputMaterial = false
    // let isAnalysisStatus = false
    const doughMaterialInformations: MaterialInformation[] = []
    if (dough_material_informations) {
      await Promise.all(
        dough_material_informations.map(async (info) => {
          // isInputMaterial = true
          let isAnalysis = false
          if (
            !info.productName &&
            !info.manufacturerName &&
            !info.middleKneadPercent &&
            !info.authenticKneadPercent &&
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            return
          }
          if (
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            isAnalysis = true
            // isAnalysisStatus = true
          }
          doughMaterialInformations.push({
            productName: isAnalysis ? info.productName : null,
            manufacturerName: isAnalysis ? info.manufacturerName : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const fillingMaterialInformations: MaterialInformation[] = []
    if (filling_material_informations) {
      await Promise.all(
        filling_material_informations.map(async (info) => {
          // isInputMaterial = true
          let isAnalysis = false
          if (
            !info.productName &&
            !info.manufacturerName &&
            !info.middleKneadPercent &&
            !info.authenticKneadPercent &&
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            return
          }
          if (
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            isAnalysis = true
            // isAnalysisStatus = true
          }
          fillingMaterialInformations.push({
            productName: isAnalysis ? info.productName : null,
            manufacturerName: isAnalysis ? info.manufacturerName : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const recipeMaterialInformations: MaterialInformation[] = []
    if (recipe_material_informations) {
      await Promise.all(
        recipe_material_informations.map(async (info) => {
          // isInputMaterial = true
          let isAnalysis = false
          if (
            !info.productName &&
            !info.manufacturerName &&
            !info.middleKneadPercent &&
            !info.authenticKneadPercent &&
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            return
          }
          if (
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            isAnalysis = true
            // isAnalysisStatus = true
          }
          recipeMaterialInformations.push({
            productName: isAnalysis ? info.productName : null,
            manufacturerName: isAnalysis ? info.manufacturerName : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const recipeRef = collection(db, 'recipes')
    const data = {
      dough_material_informations: doughMaterialInformations,
      filling_material_informations: fillingMaterialInformations,
      recipe_material_informations: recipeMaterialInformations,
      amount_dough_before_baking: amount_dough_before_baking,
      loss_rate: loss_rate,
      burnout_rate: burnout_rate,
      amount_dough_after_baking: amount_dough_after_baking,
      is_after_baking_percent: is_after_baking_percent,
      is_after_baking_gram: is_after_baking_gram,
      memo: memo,
      process: process,
      is_percent: is_percent,
      is_gram: is_gram,
      bread_ref: doc(db, 'breads', bread_id),
      shop_ref: doc(db, 'shops', staff.shopId),
      recipe_name: recipe_name,
      is_draft: is_draft,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    }
    const docRef = await addDoc(recipeRef, data)
    // breadステータスの更新
    const breadRef = doc(db, 'breads', bread_id)
    const breadData = {
      daseruno_recipe_status: is_draft ? BreadStatus.DRAFT : BreadStatus.PUBLIC,
    }
    await updateDoc(breadRef, breadData)
    return docRef.id
  },
)

export const updateRecipe = createAsyncThunk<
  string | null,
  {
    id: string
    dough_material_informations: ProductInformation[] | null
    filling_material_informations: ProductInformation[] | null
    recipe_material_informations: ProductInformation[] | null
    amount_dough_before_baking: number
    loss_rate: number
    burnout_rate: number
    amount_dough_after_baking: number | null
    is_after_baking_percent: boolean
    is_after_baking_gram: boolean
    memo: string
    process: string
    is_percent: boolean
    is_gram: boolean
    bread_id: string
    recipe_name: string
    is_draft: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'recipe/updateRecipe',
  async (
    {
      id,
      dough_material_informations,
      filling_material_informations,
      recipe_material_informations,
      amount_dough_before_baking,
      loss_rate,
      burnout_rate,
      amount_dough_after_baking,
      is_after_baking_percent,
      is_after_baking_gram,
      memo,
      process,
      is_percent,
      is_gram,
      bread_id,
      recipe_name,
      is_draft,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return null
    }
    const recipeRef = doc(db, 'recipes', id)
    const querySnapshot = await getDoc(recipeRef)
    const recipe = querySnapshot.data()
    if (!recipe) {
      return null
    }
    const recipeShopId = recipe.shop_ref.id
      ? recipe.shop_ref.id
      : // @ts-ignore
        recipe.shop_ref._key.path.segments[6]
    // 所属しているお店の商品情報しか更新できない
    if (recipeShopId !== staff.shopId) {
      return null
    }
    // let isInputMaterial = false
    // let isAnalysisStatus = false
    const doughMaterialInformations: MaterialInformation[] = []
    if (dough_material_informations) {
      await Promise.all(
        dough_material_informations.map(async (info) => {
          // isInputMaterial = true
          let isAnalysis = false
          if (
            !info.productName &&
            !info.manufacturerName &&
            !info.middleKneadPercent &&
            !info.authenticKneadPercent &&
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            return
          }
          if (
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            isAnalysis = true
            // isAnalysisStatus = true
          }
          doughMaterialInformations.push({
            productName: isAnalysis ? info.productName : null,
            manufacturerName: isAnalysis ? info.manufacturerName : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const fillingMaterialInformations: MaterialInformation[] = []
    if (filling_material_informations) {
      await Promise.all(
        filling_material_informations.map(async (info) => {
          // isInputMaterial = true
          let isAnalysis = false
          if (
            !info.productName &&
            !info.manufacturerName &&
            !info.middleKneadPercent &&
            !info.authenticKneadPercent &&
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            return
          }
          if (
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            isAnalysis = true
            // isAnalysisStatus = true
          }
          fillingMaterialInformations.push({
            productName: isAnalysis ? info.productName : null,
            manufacturerName: isAnalysis ? info.manufacturerName : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const recipeMaterialInformations: MaterialInformation[] = []
    if (recipe_material_informations) {
      await Promise.all(
        recipe_material_informations.map(async (info) => {
          // isInputMaterial = true
          let isAnalysis = false
          if (
            !info.productName &&
            !info.manufacturerName &&
            !info.middleKneadPercent &&
            !info.authenticKneadPercent &&
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            return
          }
          if (
            !info.materialId &&
            !info.doughId &&
            !info.fillingId &&
            !info.recipeId
          ) {
            isAnalysis = true
            // isAnalysisStatus = true
          }
          recipeMaterialInformations.push({
            productName: isAnalysis ? info.productName : null,
            manufacturerName: isAnalysis ? info.manufacturerName : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const data = {
      dough_material_informations: doughMaterialInformations,
      filling_material_informations: fillingMaterialInformations,
      recipe_material_informations: recipeMaterialInformations,
      amount_dough_before_baking: amount_dough_before_baking,
      loss_rate: loss_rate,
      burnout_rate: burnout_rate,
      amount_dough_after_baking: amount_dough_after_baking,
      is_after_baking_percent: is_after_baking_percent,
      is_after_baking_gram: is_after_baking_gram,
      memo: memo,
      process: process,
      is_percent: is_percent,
      is_gram: is_gram,
      bread_ref: doc(db, 'breads', bread_id),
      recipe_name: recipe_name,
      is_draft: is_draft,
      updated_at: serverTimestamp(),
    }
    await updateDoc(recipeRef, data)
    // breadステータスの更新
    const breadRef = doc(db, 'breads', bread_id)
    const breadData = {
      daseruno_recipe_status: is_draft ? BreadStatus.DRAFT : BreadStatus.PUBLIC,
    }
    await updateDoc(breadRef, breadData)
    return id
  },
)

export const recipeSlice: Slice<
  RecipeState,
  { clearRecipe(state: RecipeState): void },
  'recipe'
> = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    clearRecipe(state) {
      state.recipe = null
    },
  },
  extraReducers: {
    [getRecipeById.fulfilled.type]: (
      state,
      action: PayloadAction<Recipe | null>,
    ) => {
      if (action.payload !== null) {
        state.recipe = action.payload
      }
    },
  },
})

export const { clearRecipe } = recipeSlice.actions
export default recipeSlice.reducer
