import { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from 'styles/components/sidemenu.module.scss'

type Props = {
  title: string
  iconPath: string
  to: string
  selected: boolean
  imagePath?: string
  external?: boolean
}

const SideMenuLink: FC<Props> = (props) => {
  const { title, iconPath, to, selected = false, imagePath, external } = props

  return (
    <>
      {external ? (
        <a
          className={styles.sideMenuLink}
          target="_blank"
          href={to}
          rel="noreferrer"
        >
          <div className={styles.menuBox}>
            <div
              className={
                selected
                  ? styles.selectedMenuBarSelected
                  : styles.selectedMenuBar
              }
            ></div>
            <img
              className={styles.sideMenuIcon}
              src={iconPath}
              alt={title}
            ></img>
            {imagePath ? (
              <img
                className={styles.sideMenuImage}
                src={imagePath}
                alt={title}
              ></img>
            ) : (
              <p className={styles.sideMenuText}>{title}</p>
            )}
          </div>
        </a>
      ) : (
        <Link className={styles.sideMenuLink} to={to}>
          <div className={styles.menuBox}>
            <div
              className={
                selected
                  ? styles.selectedMenuBarSelected
                  : styles.selectedMenuBar
              }
            ></div>
            <img
              className={styles.sideMenuIcon}
              src={iconPath}
              alt={title}
            ></img>
            {imagePath ? (
              <img
                className={styles.sideMenuImage}
                src={imagePath}
                alt={title}
              ></img>
            ) : (
              <p className={styles.sideMenuText}>{title}</p>
            )}
          </div>
        </Link>
      )}
    </>
  )
}

export default SideMenuLink
