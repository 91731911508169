import { FC } from 'react'
import LoginLayout from 'components/Layout/LoginLayout'
import NewPasswordTemplate from 'components/Templates/NewPasswordTemplate'

const NewPassword: FC = () => {
  return (
    <LoginLayout title={'新しいパスワード'}>
      <NewPasswordTemplate />
    </LoginLayout>
  )
}

export default NewPassword
