import { FC } from 'react'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import { useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'

const TermsPageTemplate: FC = () => {
  const { uid } = useSelector((state: RootState) => state.authSlice)
  return (
    <div
      className={styles.container}
      style={!uid ? { marginLeft: 'auto', marginRight: 'auto' } : undefined}
    >
      {!!uid && <PageHedding title="利用規約" to="/mypage" />}
      <div className={styles.terms_privacy_policy}>
        <div className={styles.terms_privacy_policy_title}>利用規約</div>
        <div className={styles.terms_privacy_policy_text}>
          {`2023年3月1日制定
2023年6月1日改定
2023年10月5日改定
株式会社sacri
          
この利用規約（以下「本規約」といいます。）は、株式会社sacri（以下「当社」といいます。）が提供する「ダセルーノ」（以下「本サービス」といいます。）の利用に関する条件及び当社と本サービスの利用者（以下「利用者」といいます。）との間の権利義務関係を定めるものであり、当社と各利用者との間に別段の合意がない限り、本サービスの利用にあたっては本規約の定めが優先的に適用されます。
本サービスを利用していただくためには、本規約の全文をお読みいただき、本規約に同意いただく必要があります。また、本規約に記載の用語の定義、条件その他の内容は、特段の定めが無い限り、本サービスに関する本規約以外の規約及びプライバシーポリシー等（以下、「関連規約」といいます。）に適用されます。利用者の同意をもって本規約及び関連規約に基づき利用者と当社の間に成立する契約を「本契約」といいます。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          １. 用語の定義
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`本規約において、以下の各号の用語は、以下の各号に定める意味において使用されます。
`}
        </div>
        <ol>
          <li>
            {`「本サービス」とは、利用者が商品の製造、加工、輸入又は販売に関する事業における、当該商品のレシピ管理、栄養成分等の計算、当該商品に付す食品表示ラベルの出力、当該商品のプライスカードの作成、原価管理その他の関連する業務の効率の向上を図ることを目的として当社が「ダセルーノ」の名称で提供するサービスの総称をいいます。なお、利用者による本規約への同意後に当社がサービスの内容にアップデートその他の変更を行なった場合、変更後の内容を指すものとします。
          `}
          </li>
          <li>
            {`「対象事業」とは、商品の製造、加工、輸入又は販売に関する利用者自らの事業をいいます。
          `}
          </li>
          <li>
            {`「本目的」とは、対象事業における商品のレシピ管理、商品の栄養成分等の計算、商品に付す食品表示ラベルの出力、商品の販売に際して掲示するプライスカードの作成その他の対象事業に関連する業務の効率の向上を図ることをいいます。
          `}
          </li>
          <li>
            {`「利用者」とは、本サービスを利用する自然人または法人をいいます。
          `}
          </li>
          <li>
            {`「店舗」とは、利用者が対象事業を行う実店舗をいいます。
          `}
          </li>
          <li>
            {`「店舗アカウント」とは、本サービスにおいて利用者が登録するアカウントのことを意味します。
          `}
          </li>
          <li>
            {`	「入力情報」とは、本サービスの利用の過程において利用者が入力する、店舗又は商品に関するテキスト、データ、画像、動画等のあらゆるコンテンツ（商品のレシピを含みます。）を意味します。
          `}
          </li>
          <li>
            {`「知的財産権」とは、著作権（著作権法27条及び28条に規定する権利を含みます。）、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
          `}
          </li>
          <li>
            {`「本サービス利用契約」とは、当社と利用者の間で成立する、本規約、個別の利用規約等を内容とする、本サービスの利用に関する契約をいいます。
          `}
          </li>
          <li>
            {`「本サービス利用料金」とは、本規約、個別の利用規約等、当社ウェブサイト上への掲載その他当社所定の方法により定める当社が本サービスの全部若しくは一部に対する又は本サービスに付随して提供するサービスに対する対価をいいます。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          ２. 規約への同意
        </div>
        <ol>
          <li>
            {`利用者は、あらかじめ本規約に有効かつ取消不能な同意をしないかぎり、本サービスのいかなる機能をも利用することができません。
          `}
          </li>
          <li>
            {`利用者が本サービスの利用を開始した場合は、本規約に有効かつ取消不能な同意をしたものとみなされます。
          `}
          </li>
          <li>
            {`本サービスについて本規約のほかに個別の利用規約等が存在し、あるいは追加された場合は、利用者は本サービス利用にあたって当該利用規約等にも従う必要があります。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          ３. 規約の変更
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`当社は、当社が必要と認めた場合、本規約、本サービスの内容または本サービス提供の条件を変更できるものとします。この場合、変更後の本規約の内容及び効力発生部を当社所定の方法で周知し又は利用者に通知します。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          ４. 店舗アカウント登録
        </div>
        <ol>
          <li>
            {`本サービスの利用にあたって、利用者は店舗アカウントを登録することが必要となります。店舗アカウントの登録に当たっては、正確な情報を登録しなければなりません。
          `}
          </li>
          <li>
            {`利用者は、登録した店舗アカウント上の情報について変更の必要がある場合には、速やかに本サービス上で情報の変更を行う義務があります。
          `}
          </li>
          <li>
            {`利用者は、本サービスに登録された店舗アカウントに関する情報を第三者に対して譲渡、貸与または販売してはならず、かつパスワードは第三者に漏洩しないように自らの責任で厳重に管理する義務があります。
          `}
          </li>
          <li>
            {`利用者は、自身の店舗アカウントが不正に利用されているまたはその可能性がある場合には、速やかに当社に連絡する義務があります。かかる連絡が適時になされなかった場合に、利用者に生じた損害について当社は責任を負いかねます。
          `}
          </li>
          <li>
            {`利用者は、本サービスをダウンロード、インストールまたは利用する場合は、自らの責任と費用において、インターネットに接続するためのあらゆる機器、通信手段、ソフトウェア等を適切に設置及び操作しなければなりません。当社は、かかる設置及び操作等が原因で生じた不具合に対して一切、責任を負いません。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          ５. 本サービスの利用
        </div>
        <ol>
          <li>
            {`当社は、本規約に同意した利用者に対し、本規約の定めに従い、本目的の範囲内で本サービスを利用することを非独占的に許諾します。
          `}
          </li>
          <li>
            {`利用者は、本サービスを利用して本事業及びこれに付随関連する業務を実施するために必要な設備、機械、通信機器、材料、食材その他の物品、本サービスの利用に必要な通信環境その他の環境については、自らの責任と費用負担において用意するものとします。
          `}
          </li>
          <li>
            {`利用者は、本サービスを当社が別途定める推奨環境下で利用するものとします。利用者は、当社の定めた推奨環境以外の環境では、本件サービスの全部又は一部が利用できない場合があることを了承するものとします。
          `}
          </li>
          <li>
            {`利用者は、自らの責任と費用負担で本サービスを利用して対象事業を行う上で適用される法令その他の規制の遵守し、本サービスを使用する役員、従業員その他の第三者が本規約及び本サービスの関連マニュアル等の内容を正しく理解した上で本件サービスを適切に利用するために必要な指導等を行うものとします。
          `}
          </li>
          <li>
            {`本サービスに第三者が定める使用許諾条件を伴うソフトウェア（以下「第三者ソフトウェア」という。）が含まれている場合、当該第三者ソフトウェアの使用について、利用者は当該第三者の定める使用許諾条件に従うものとします。
          `}
          </li>
          <li>
            {`利用者は、商品のレシピを含む入力情報を本サービスの利用の過程で入力、登録又はアップロード等をする場合、食品表示法その他の法令を遵守するために必要な情報を自らの責任で入力しなければならず、過去の記録として残す機能を利用する場合を除き、常に最新の情報となるよう修正するものとします。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          ６. 知的財産権の帰属等
        </div>
        <ol>
          <li>
            {`本サービス又は当社が本サービスにおいて提供するテキスト、画像、映像、データ、プログラムその他一切のコンテンツに関する知的財産権その他の権利は、利用者又は当社以外の第三者に帰属する権利を除き、すべて当社または当社に対し利用を許諾した正当な権限を有する第三者に帰属します。
          `}
          </li>
          <li>
            {`利用者が本規約に基づいて当社より本サービス及び本サービス上のコンテンツの利用許諾を受けた場合、当該利用者は当社より許諾された範囲内において利用する権限を取得するのみであって、本サービス及び当該コンテンツに関する知的財産権その他一切の権利は利用者に移転することはありません。本サービス及び当該コンテンツの利用許諾を受けた利用者は、本サービス、当該コンテンツまたはこれらの二次的著作物を、特許権、実用新案権、意匠権または商標権を出願する目的または商用利用目的で使用することはできないものとします。
          `}
          </li>
          <li>
            {`入力情報に関する知的財産権その他の権利は、利用者自身がこれを作成または用意した場合、利用者自身または利用者に対し当該入力情報の利用を許諾した正当な権限を有する第三者に帰属します。入力情報を当社が作成または用意した場合(利用者が作成または用意した情報について、当該情報の入力等を行う業務を当社に対して委託した場合は除きます。)、かかる知的財産権その他の権利は当社に帰属します。
          `}
          </li>
          <li>
            {`利用者が当社に対し本サービスに関して提案又はフィードバック（以下「本件フィードバック」という。）を行う場合、利用者は、本件フィードバックの時点で、別段の契約・合意を要することなく、(i)自らが当社に対し、本件フィードバックを本サービス又は当社の他の商品若しくはサービスに使用及び組み入れ、本サービス又は当社の他の商品若しくはサービスを開発、複製、頒布する譲渡可能で非独占的な再許諾権付の権利を地域又は期間の制限なく許諾すること、並びに(ii)本件フィードバックを使用又は組み入れた本サービス及び当社の他の商品若しくはサービスに係る全ての権利が当社に帰属することをここに了承するものとします。
          `}
          </li>
          <li>
            {`利用者は、本件サービスが第三者の知的財産権等を侵害したとして権利主張を受けた場合、又はそのおそれがあると判断した場合、当社に対し、速やかに、その旨を書面により通知し、解決に向けて当社に協力し、その指示に従うものとする。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          ７. 本サービスの契約期間
        </div>
        <ol>
          <li style={{ whiteSpace: 'break-spaces' }}>
            {`契約成立と利用開始日について
利用者が、本規約に同意の上で、当社所定の方法で本サービスの利用を申し込み、当社がこれを承諾する旨の通知又は表示を行うことで、利用者と当社との間で本規約を内容とする本サービスの利用に関する契約が成立します。本サービスの利用に関する契約（以下「本サービス利用契約」という。）の契約成立日が利用開始日となります。`}
          </li>
          <li style={{ whiteSpace: 'break-spaces' }}>
            {`本サービス利用契約の契約期間について
本サービス利用契約の契約期間は1年間となります。`}
          </li>
          <li style={{ whiteSpace: 'break-spaces' }}>
            {`契約の自動更新
本サービス利用契約の契約期間満了日の前月末日までに利用者から退会のお申し出が無い場合、本サービス利用契約は、第8条第2項に定める支払いプランに応じて次の各号の期間、同一条件で更新されるものとし、以後も同様とします。
【ライトプラン】
(i)  月々払いの場合：1か月
(ii) 年払いの場合：1年間
【スタンダードプラン】
月々払い：1か月`}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          ８. 本サービス利用料金
        </div>
        <ol>
          <li>
            {`利用者が支払う本サービス利用料金の額は、本規約、個別の利用規約等、当社ウェブサイト上への掲載その他当社所定の方法により定めるものとします。
          `}
          </li>
          <li style={{ whiteSpace: 'break-spaces' }}>
            {`利用者は、本規約、個別の利用規約等、当社ウェブサイト上への掲載その他当社所定の方法において別途異なる支払方法が定められている場合を除き、次のいずれかの支払いプランにより、決済手数料及び本サービス利用料金を支払うものとします。
(i)  月々プラン
     本サービス利用料金を契約期間内で月毎に分割し、毎月末日に支払うプラン
(ii) 年割プラン
     本契約の契約成立日又は更新日が属する月の末日までに一括で支払うプラン`}
          </li>
          <li>
            {`利用者が本サービス利用契約の契約期間中に本サービスを退会し、その時点で支払いが完了していない本サービス利用料金がある場合、利用者は、退会した月の翌月末日までに契約期間における本サービス利用料金の残額を一括して支払うものとします。
          `}
          </li>
          <li>
            {`当社は、利用者が本サービス利用契約の契約期間中に本サービスを退会した場合であっても、利用者が既に支払った本サービス利用料金を返金する義務を負いません。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          ９. 本サービス内容の変更及びサポート
        </div>
        <ol>
          <li>
            {`当社は、当社の都合により、本サービスを全部または一部終了し、本サービスの
内容を全部または一部変更し、または本サービスについてのサポートを終了する
ことができます。この場合、当社は利用者に事前に通知するものとします。
          `}
          </li>
          <li>
            {`当社は、前項の措置によって、利用者が被った損害について、本規約に特別の定
めがない限り、一切の責任を負いません。
            `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          １０. 権利義務の譲渡禁止
        </div>
        <ol>
          <li>
            {`利用者は、本規約上の地位及び本規約に定める権利義務の全てまたは一部を第三者に譲渡、使用許諾、貸与、名義変更または質権設定、担保に供する等の処分をしてはなりません。また、利用者は、店舗アカウントを第三者に使用させてはなりません。
          `}
          </li>
          <li>
            {`当社は、利用者の店舗アカウントが第三者によって使用された場合に、当該利用者が被る損害に対して、一切責任を負いません。
          `}
          </li>
          <li>
            {`利用者は、前項の場合に生じる当社または第三者の損害を賠償する義務を負うものとします。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          １１. 事業譲渡等
        </div>
        <ol>
          <li>
            {`当社は、本サービスにかかる事業を第三者に譲渡した場合、または会社分割、合併等の組織再編があった場合（以下「事業譲渡等」といいます。）は、利用者と当社との間の契約（本規約を含む。）上の地位及び利用者が本サービスを利用するに当たって当社に提供した情報を当該第三者に譲渡することができます。
          `}
          </li>
          <li>
            {`前項の場合において、利用者は、事業譲渡等についてあらかじめ同意します。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          １２. 禁止事項
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`利用者は、本サービスの利用に際して、本規約及び関連規約の条項に個別に記載の禁止事項の他、以下に該当する行為及びそのおそれのある行為を行ってはなりません。
`}
        </div>
        <ol>
          <li>
            {`本規約に違反する行為
          `}
          </li>
          <li>
            {`本サービスを本目的以外に使用する行為
          `}
          </li>
          <li>
            {`当社が別途定め又は別途本サービス上で表示する利用態様に反する態様で本サービスを利用する行為
          `}
          </li>
          <li>
            {`法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為
          `}
          </li>
          <li>
            {`公の秩序または善良の風俗を害するおそれのある行為
          `}
          </li>
          <li>
            {`当社または第三者の知的財産権、肖像権、名誉権、プライバシー権、その他法令上または契約上の一切の権利を侵害する行為
          `}
          </li>
          <li>
            {`嫌がらせや誹謗中傷を内容とする表現を掲載する行為
          `}
          </li>
          <li>
            {`人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、その他反社会的な内容を含み他人に不快感を与える表現を掲載する行為
          `}
          </li>
          <li>
            {`当社または第三者になりすます行為または意図的に虚偽の情報を流布させる行為
          `}
          </li>
          <li>
            {`反社会的勢力に対する利益供与その他の協力行為
          `}
          </li>
          <li>
            {`宗教活動または宗教団体への勧誘行為
          `}
          </li>
          <li>
            {`他人の個人情報、登録情報、利用履歴情報などを、不正に収集し、開示しもしくは開示することを求め、掲載等または送信する行為
          `}
          </li>
          <li>
            {`本サービスに使用されているソフトウェア、技術、ノウハウ等について、逆アセンブル、逆コンパイル、リバースエンジニアリング、複製、改変、修正、第三者への頒布等をする行為
          `}
          </li>
          <li>
            {`本サービスに使用されているソフトウェアの派生ソフトウェアを作成する行為
          `}
          </li>
          <li>
            {`技術的手段を利用して本サービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、同様の質問を必要以上に繰り返す等、当社に対し不当な問い合わせまたは要求をする行為、その他当社による本サービスの提供または他の利用者による本サービスの利用を妨害し、これらに支障を与える行為
          `}
          </li>
          <li>
            {`本規約によって許諾された場合を除いて、本サービスにより提供されるいかなるコンテンツをも改変し、または商用利用する行為
          `}
          </li>
          <li>
            {`本規約に基づく利用条件に違反するコンテンツの利用行為
          `}
          </li>
          <li>
            {`売買目的、他者がその利用者名を使用できないようにする目的及び他人に成りすますなどの不正の目的をもってアカウントを作成する行為
          `}
          </li>
          <li>
            {`前記いずれかに該当する行為を準備、援助または助長する行為
          `}
          </li>
          <li>
            {`その他、当社が不適当と判断した行為
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          １３. 利用停止等
        </div>
        <ol>
          <li>
            {`当社は、利用者が次の事項のいずれかに該当した場合、その事由及び程度に応じて、当該利用者が入力した入力情報を削除もしくは非表示にし、当該利用者に対する本サービスまたは本サービスに関するサポートの提供を一時停止もしくは廃止し、当該利用者の店舗アカウントを削除し、又は本契約を解除すること（以下、「利用停止等」といいます。）があります。アカウントが削除された場合、それまで当該アカウントに関連して取得又は入力した各種情報も失われ、その後の利用もできなくなります。
          `}
            <ol>
              <li>
                {`禁止事項（前条各号に掲げる行為の他、本規約及び関連規約において個別に禁止する事項を含む）のうちいずれか一つに該当する行為又はそのおそれがある行為を行ったと当社が判断したとき
            `}
              </li>
              <li>
                {`本規約又は関連規約に定める義務の全部又は一部に違反したとき
            `}
              </li>
              <li>
                {`手形又は小切手を不渡としたとき、その他支払停止状態に至ったとき
            `}
              </li>
              <li>
                {`財産状態又は信用状態の悪化により差押、仮差押、仮処分、強制執行、競売を命じる裁判若しくは処分を受けたと又は租税公課を滞納し督促を受けたとき
            `}
              </li>
              <li>
                {`破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始その他法的倒産手続開始の申立を自ら行い若しくは第三者から受けたとき、又は解散、清算（特別清算を含む。）若しくは私的整理の手続に入ったとき
            `}
              </li>
              <li>
                {`監督官庁から営業停止若しくは営業免許又は営業登録の取消の処分を受けたとき
            `}
              </li>
            </ol>
          </li>
          <li>
            {`当社は、第三者の知的財産権、肖像権、パブリシティ権その他の権利が侵害された場合であって、当該第三者から当該権利侵害について申告を受けた場合には、当該侵害行為の内容、態様、明白性及び程度に応じて、当該侵害行為を構成する利用者の入力情報その他のコンテンツを、事前に当該利用者に通知した上でまたは事前に通知しないで削除する措置のうち、最も適切であると当社が判断する措置をとることができます。
          `}
          </li>
          <li>
            {`利用者が継続して6ヶ月以上の間、本サービスを利用しない場合、当社は当該利用者に対する本サービスまたは本サービスに関するサポートの提供を一時停止もしくは廃止し、または当該利用者のアカウントを削除することができるものとします。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          １４. 利用者の保証及び責任
        </div>
        <ol>
          <li>
            {`利用者は、当社に対し、本サービスに入力する入力情報その他のいかなるコンテンツについても、第三者の知的財産権、肖像権その他一切の権利を侵害していないことを保証します。
          `}
          </li>
          <li>
            {`利用者は、ご自身の責任において本サービスを利用し、本サービスを利用した行為及びその結果について一切の責任を負うものとします。
          `}
          </li>
          <li>
            {`当社は、利用者が本規約に違反して本サービスを利用していると認めた場合は、当社が必要かつ適切と判断する措置を講じます。ただし、当社は、本規約に違反する行為を防止しまたは是正する義務を負うものではありません。
          `}
          </li>
          <li>
            {`利用者が本規約に違反したことに起因して当社、役員、従業員、代理人、下請企業、販売会社、代理店又は一般消費者（以下併せて「補償対象者」という。）に何らかの損害が発生した場合、使用者は、補償対象者に対して、当該損害（当該違反に関連した紛争解決のための弁護士費用を含む）を補償し、当社に一切迷惑をかけないものとします。
          `}
          </li>
          <li>
            {`利用者が本サービスを利用する行為及びその結果によって当社が損害（合理的な弁護士費用を含む。）を被った場合、利用者は当社の請求に応じて直ちに当該損害を補償しなければなりません。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          １５. 当社の免責
        </div>
        <ol>
          <li>
            {`当社は、天災地変、輸送機関の事故、戦争、テロ又は内乱等の社会的混乱、公権力による行為、電力供給力不足、原材料（半導体を含む。）の供給力不足・停止、感染症の蔓延若しくはこれに対する措置（営業自粛要請、外出自粛要請等の強制力を伴わない国又は地方公共団体による措置及びこれを受けた施設管理者等による対策実施を含む）等、自己の責に帰さない不可抗力により、本規約の全部又は一部の履行が遅延、又は履行不可能となったときは、その責めを負わないものとします。
          `}
          </li>
          <li>
            {`当社は、利用者に対し、次に定める事項を明示的にも黙示的にも保証しません。
          `}
            <ol>
              <li>
                {`本サービス、本サービスの利用の結果または本サービスにおいて提供されるコンテンツ及びデータ等に事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないこと
            `}
              </li>
              <li>
                {`本サービスを利用して製造された商品、作成された食品表示ラベル等が安全性を有すること及び法令その他の規制に適合すること
            `}
              </li>
              <li>
                {`本サービスにエラー、バグ等の不具合がないこと
            `}
              </li>
              <li>
                {`本サービスを中断なく利用できること
            `}
              </li>
              <li>
                {`本サービスの利用が利用者及び第三者に損害を与えないこと
            `}
              </li>
              <li>
                {`本サービスが第三者の知的財産権を侵害していないこと
            `}
              </li>
            </ol>
          </li>
          <li>
            {`当社は、利用者が入力する入力情報の保存義務を負いません。
          `}
          </li>
          <li>
            {`当社は、本サービス又は本契約終了後の処理に起因して利用者に生じた損害について、当社の故意または重大な過失によって生じた場合を除き、責任を負いません。また、利用者に生じた損害のうち特別な事情から生じた損害（当社または利用者が損害発生につき予見し、または予見し得た場合を含みます。）について当社は一切の責任を負いません。
          `}
          </li>
          <li>
            {`当社は、本サービスのサポート提供の中断、停止、終了、利用不能または変更、利用者が本サービスにより掲載した店舗コンテンツの削除または消失、その他本サービスに関して利用者が被った損害につき、賠償する責任を一切負わないものとします。
          `}
          </li>
          <li>
            {`当社は、第三者が運営する決済サービス等本サービスと連携した他のサービス事業者が運営するサービス事業が中断し、停止し、終了し、利用不能となり、または変更されたことによって利用者が被った損害につき、賠償する責任及び当該サービス事業者に対して当該サービス事業の再開や是正を求める責任を一切負わないものとします。
          `}
          </li>
          <li>
            {`前各項の規定にかかわらず当社が損害賠償責任を負う場合といえども、当該損害賠償額は、利用者が当該損害が発生した契約期間中に当社に対して支払った本サービス利用料金の合計額を上限とします。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          １６. 保守点検等のための本サービスの停止等
        </div>
        <ol>
          <li>
            {`当社は、本サービス上の当社が適切と判断する場所に事前に掲示した上で、本サービスに関するシステム等の保守点検等のため、本サービスの全部または一部の提供を停止または中断することができます。
          `}
          </li>
          <li>
            {`前項にかかわらず、当社は、次の各号のいずれかに該当する場合は、利用者に対して事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができます。
          `}
            <ol>
              <li>
                {`本サービスに係るシステム等の保守点検等を緊急に行う場合
            `}
              </li>
              <li>
                {`本サービスに係るシステムに関連するコンピューター、通信回線等が事故により停止した場合
            `}
              </li>
              <li>
                {`地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
            `}
              </li>
              <li>
                {`その他、当社が停止または中断が必要であると判断した場合
            `}
              </li>
            </ol>
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          １７. 退会・契約終了後の処理
        </div>
        <ol>
          <li>
            {`利用者は、契約期間満了日の前月末までに当社が別途定める方法により、当社に対して通知することにより、本サービスの登録を抹消すること（以下「退会」といいます。）ができます。利用者が退会した場合、本契約は終了するものとします。なお、利用者が本サービス利用契約の契約期間の途中で退会した場合、退会した日が属する月の末日までに本サービス利用料金の残額をお支払いいただくものとします。また、既にお支払いいただいた本サービス利用料金がある場合でも、当社は利用者に対し、本サービス利用料金を返金する義務を負いません。
          `}
          </li>
          <li>
            {`利用者による退会その他の事由によって本契約が終了した場合、利用者は本サービスの利用を終了するものとし、以後、本サービスを利用することはできません。
          `}
          </li>
          <li>
            {`利用者による退会その他の事由によって本契約が終了した場合であっても、当該利用者が本サービスに登録したデータ等の情報については、本サービスの運営に必要な限りで当社が保有し利用します（当社の判断で消去することを含みます。）。ただし、当該利用者から別途当社が定める方法により削除の請求があった場合は、当該情報を削除することで本サービスの運営に支障が出る場合を除いて、削除に応じます。
          `}
          </li>
          <li>
            {`当社による契約終了後の処理に起因して利用者に損害が生じた場合であっても、当社が利用者に対して負う責任は、「15.当社の免責」に定めるとおりとします。
          `}
          </li>
          <li>
            {`退会した利用者が当社に対して債務を負っている場合、当該利用者は当該債務について何らの催告を必要とせずに期限の利益を喪失します。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          １８. 秘密保持
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`利用者は、本サービスの利用を通じて知りえた本サービスの技術情報その他当社の秘密情報を保持するものとし、当社の書面による同意なくして利用者以外の者に開示し、又は本規約が許容する目的以外の目的に使用してはならないものとします。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          １９. 反社会的勢力の排除
        </div>
        <ol>
          <li>
            {`利用者は、現在又は過去５年以内において、自己並びに自己の役員及び自己の経営を実質的に支配している者が、暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団その他これらに準ずる者（以下「反社会的勢力」という。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
          `}
            <ol>
              <li>
                {`反社会的勢力が経営を支配していると認められる関係を有すること
            `}
              </li>
              <li>
                {`反社会的勢力が経営に実質的に関与していると認められる関係を有すること
            `}
              </li>
              <li>
                {`自己若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力を利用していると認められる関係を有すること
            `}
              </li>
              <li>
                {`反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
            `}
              </li>
              <li>
                {`役員又は経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有すること
            `}
              </li>
            </ol>
          </li>
          <li>
            {`利用者は、自ら又は第三者を利用して、次の各号のいずれか一に該当する行為を行わないことを確約するものとします。
          `}
            <ol>
              <li>
                {`暴力的な要求行為
            `}
              </li>
              <li>
                {`法的な責任を超えた不当な要求行為
            `}
              </li>
              <li>
                {`取引に関して、脅迫的な言動をし、又は暴力を用いる行為
            `}
              </li>
              <li>
                {`風説を流布し、偽計を用い又は威力を用いて、相手方の信用を毀損し、又は相手方の業務を妨害する行為
            `}
              </li>
              <li>
                {`その他前各号に準ずる行為
            `}
              </li>
            </ol>
          </li>
          <li>
            {`当社は、利用者が前2項のいずれかに違反した場合、何等の催告を要することなく、直ちに本契約を解除することができるものとします。
          `}
          </li>
          <li>
            {`当社は、前項に基づく解除の結果利用者に損害が生じた場合であっても、これを賠償する一切の責任を負わないものとします。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          ２０. 存続条項
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`本契約の終了後といえども、3.規約の変更、10.権利義務の譲渡禁止、11.事業譲渡等、12.禁止事項、14.利用者の保証及び責任、15.当社の免責、17. 退会・契約終了後の処理、18.秘密保持、21.分離可能性、22.連絡方法、23.準拠法及び裁判管轄の定めは有効に存続する。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          ２１. 分離可能性
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`本規約のいずれかの条項またはその一部が法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          ２２. 連絡方法
        </div>
        <ol>
          <li>
            {`本サービスに関する当社から利用者への連絡は、本サービス上の適宜の場所への掲示その他、当社が適当と判断する方法により行います。
          `}
          </li>
          <li>
            {`本サービスに関する利用者から当社への連絡は、本サービス上の適宜の場所に設置するお問い合わせフォームの送信または当社が指定する方法により行っていただきます。
          `}
          </li>
          <li>
            {`当社は、前項に定める利用者からの連絡によって提供を受けた利用者の個人情報について、お問い合わせ等に対し回答する目的のために必要な範囲内でのみ使用し、その他の目的では使用いたしません。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          ２３. 準拠法及び裁判管轄
        </div>
        <ol>
          <li>
            {`本規約の準拠法は日本法とします。
          `}
          </li>
          <li>
            {`本規約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          `}
          </li>
        </ol>
        <div
          className={styles.terms_privacy_policy_text}
          style={{ marginTop: 60 }}
        >
          {`以上

`}
        </div>
      </div>
    </div>
  )
}

export default TermsPageTemplate
