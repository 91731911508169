import { FC } from 'react'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import { useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'

const LawPageTemplate: FC = () => {
  const { uid } = useSelector((state: RootState) => state.authSlice)
  return (
    <div
      className={styles.container}
      style={!uid ? { marginLeft: 'auto', marginRight: 'auto' } : undefined}
    >
      {!!uid && <PageHedding title="特定商取引法に基づく表記" to="/mypage" />}
      <div className={styles.terms_privacy_policy}>
        <div className={styles.terms_privacy_policy_title}>
          特定商取引法に基づく表記
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          提供するサービスの名称
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`ダセルーノ

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          販売業者の氏名または名称
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`株式会社sacri

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>代表者</div>
        <div className={styles.terms_privacy_policy_text}>
          {`大谷 具史

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>住所</div>
        <div className={styles.terms_privacy_policy_text}>
          {`東京都千代田区神田錦町二丁目2番地1
KANDA SQUARE 11F We Work

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          お問い合わせ
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`こちら（`}
          <a href="mailto:support@daseruno.zendesk.com">
            support@daseruno.zendesk.com
          </a>
          {`）からお問い合わせください。
※弊社電話番号はお客様からのご要望に基づき遅滞なく電子メールにて通知いたします。
弊社電話番号が必要なお客様は上記フォームよりお申し出ください。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          サービス利用料金
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`スタッフにお問い合わせください。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          サービス提供内容
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`トップページまたは別途資料に表示しております。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          支払いの方法
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`クレジットカード決済

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          サービス利用料金以外にかかる金額
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`・インターネット接続料金、通信料金及び電気料金、並びにそれらに対する消費税はユーザーの負担になります。
・ダセルーノを利用するデバイス、周辺機器の購入費用および付随する費用はユーザーの負担になります。
・決済にあたりクレジットカード会社所定の手数料が課せられることがあります。手数料の詳細はご利用のクレジットカード会社にお問い合わせください。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          サービス利用開始時期
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`サービスお申し込み後、直ちに利用準備をさせていただきます。準備が完了次第、サービスを利用頂くことが出来ます。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          解約又は退会の条件
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`・月々払いにおける解約の場合は、解約ご希望の前月にお知らせください。
・解約後、次回更新タイミング以降の請求は行われません。
・その他料金に関する情報はスタッフにお問い合わせください。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          返品・返金について
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`提供サービスの性質上、返品・交換は出来ません。中途解約による返金は承っておりません。

`}
        </div>
      </div>
    </div>
  )
}

export default LawPageTemplate
