import { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'styles/components/form.module.scss'
import SubmitButton from 'components/Button/SubmitButton'
import SelectBoxWithLabel from 'components/Form/WithLabel/SelectBox'
import InputTextWithLabel from 'components/Form/WithLabel/InputText'
import { getAdditiveUses } from 'reducks/additiveUse/slice'
import { RootState } from 'reducks/reducers'
import { SelectOption } from 'components/Form/SelectBox'

type Props = {
  formInfos: AdditiveUseInfo[]
  handleAdditiveUseInfos: (item: AdditiveUseInfo[]) => void
}

export type AdditiveUseInfo = {
  use: string
  substance: string
  disabled_material_name: boolean
}

const AddFormAdditiveUseInformation: FC<Props> = (props) => {
  const { formInfos, handleAdditiveUseInfos } = props
  const dispatch = useDispatch<any>()

  const [formItems, setFormItems] = useState<AdditiveUseInfo[]>([])
  const [additiveUseList, setAdditiveUseList] = useState<SelectOption[]>([])

  const handleSetFormItems = (newItems: AdditiveUseInfo[]) => {
    setFormItems(newItems)
    handleAdditiveUseInfos(newItems)
  }

  const handleAddButton = () => {
    const newItem = {
      use: '',
      substance: '',
      disabled_material_name: false,
    }
    const newItems = [...formItems, newItem]

    handleSetFormItems(newItems)
  }

  const handleDeleteButton = (index: number) => {
    const newItems = [...formItems]
    // 情報は1つは残す
    if (newItems.length > 1) {
      newItems.splice(index, 1)
      handleSetFormItems(newItems)
    }
  }

  const handleAdditiveUse = (index: number, additiveUse: string) => {
    const newItems = [...formItems]
    newItems[index].use = additiveUse
    handleSetFormItems(newItems)
  }

  const handleAdditiveSubstance = (
    index: number,
    additiveSubstance: string,
  ) => {
    const newItems = [...formItems]
    newItems[index].substance = additiveSubstance
    handleSetFormItems(newItems)
  }

  const { additiveUses } = useSelector(
    (state: RootState) => state.additiveUseSlice,
  )
  const createAdditiveUseList = useCallback(async () => {
    await dispatch(getAdditiveUses())
    // const categoriesPayload: CategoryType[] = categories.payload
    const additiveUseList: SelectOption[] = [
      {
        is_default: true,
        label: '----',
        value: '',
      },
    ]
    additiveUses.forEach((additiveUse) => {
      // TODO: リロード時(初回読み込み時)にFirestore参照型からidが取得できないための対応。根本原因を調査する。
      const id = additiveUse.additive_use_ref.id
        ? additiveUse.additive_use_ref.id
        : // @ts-ignore
          additiveUse.additive_use_ref._key.path.segments[6]
      additiveUseList.push({
        is_default: false,
        label: additiveUse.name,
        value: id,
      })
    })
    setAdditiveUseList(additiveUseList)
  }, [dispatch, additiveUses])
  useEffect(() => {
    createAdditiveUseList()
  }, [])

  useEffect(() => {
    setFormItems(formInfos)
  }, [formInfos])

  return (
    <div>
      {formItems.map((item, index) => (
        <div key={index}>
          {index > 0 ? <div className={styles.addform_line}></div> : ''}
          <SelectBoxWithLabel
            required={false}
            labelName="用途"
            description="添加物の用途を選択してください。該当する選択肢がない場合はお問合せください。<br>※用途と物質名がセットで記載されているものは、項目を増やすのではなく、次の物質名も一緒に入力してください。<br>※物質名がなく用途だけを記載するケースもあります。<br>※規格書の順番通りに入力をしてください。"
            line={1}
            size="large"
            value={item.use}
            options={additiveUseList}
            onChange={(event) => handleAdditiveUse(index, event.target.value)}
          />
          <div className={styles.addform}>
            <div className={styles.addform_form}>
              <InputTextWithLabel
                required={false}
                labelName="物質"
                description="添加物の物質名を規格書通りにご入力ください。<br>※用途の記載がなく物質名だけの場合もあります。<br>※規格書の順番通りに入力をしてください。"
                line={2}
                type="text"
                size="large"
                value={item.substance}
                placeholder=""
                onChange={(event) =>
                  handleAdditiveSubstance(index, event.target.value)
                }
              />
              {index > 0 ? (
                <div className={styles.addform_delete_button_2}>
                  <SubmitButton
                    label=""
                    color="white"
                    size="icon"
                    icon="delete_orange"
                    onClick={() => handleDeleteButton(index)}
                  />
                </div>
              ) : (
                <div className={styles.addform_space_box_icon}></div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className={styles.addform_add_button_2}>
        <SubmitButton
          label="項目を増やす"
          color="white2"
          size="small"
          icon="outline_plus"
          onClick={handleAddButton}
        />
      </div>
    </div>
  )
}

export default AddFormAdditiveUseInformation
