import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/label.module.scss'
import TextArea from 'components/Form/TextArea'
import InputLabel from 'components/Label/InputLabel'

type Props = {
  required: boolean
  labelName: string
  description: string
  labelPosition: string
  line: number
  maxLength: number
  rows: number
  size: string
  value: string
  placeholder: string
  errorText?: string | null
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
}

const TextAreaWithLabel: FC<Props> = (props) => {
  const {
    required,
    labelName,
    description,
    labelPosition,
    line,
    maxLength,
    rows,
    size,
    value,
    placeholder,
    errorText,
    onChange,
  } = props

  return (
    <div
      className={
        labelPosition === 'top'
          ? styles.labelFormTopContainer
          : styles.labelFormContainer
      }
    >
      <div className={labelPosition === 'top' ? styles.labelTopContainer : ''}>
        <InputLabel
          required={required}
          name={labelName}
          description={description}
          line={line}
        />
      </div>
      <div>
        <TextArea
          maxLength={maxLength}
          rows={rows}
          size={size}
          value={value}
          placeholder={placeholder}
          errorText={errorText}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
      </div>
    </div>
  )
}

export default TextAreaWithLabel
