import { FC } from 'react'
import styles from 'styles/components/form.module.scss'

type Props = {
  isChecked: boolean
  label: string
  size?: string
  width?: string
  onChange?: () => void
  style?: React.CSSProperties
  errorText?: string | null
}

const RadioButton: FC<Props> = (props) => {
  const { isChecked, label, size, width, onChange, style, errorText } = props

  const sizeRadioButtonStyle = (() => {
    switch (size) {
      case 'large':
        return styles.radioButtonStyle
      case 'small':
        return styles.radioButtonStyle_small
      case 'small_bold':
        return styles.radioButtonStyle_small
      default:
        return styles.radioButtonStyle
    }
  })()

  const sizeRadioButtonCheckMark = (() => {
    switch (size) {
      case 'large':
        return styles.radioButtonCheckMark
      case 'small':
        return styles.radioButtonCheckMark_small
      case 'small_bold':
        return styles.radioButtonCheckMark_small
      default:
        return styles.radioButtonCheckMark
    }
  })()

  const sizeRadioButtonText = (() => {
    switch (size) {
      case 'large':
        return styles.radioButtonText
      case 'small':
        return styles.radioButtonText_small
      case 'small_bold':
        return styles.radioButtonText_small_bold
      default:
        return styles.radioButtonText
    }
  })()

  const sizeRadioButtonTextWidth = (() => {
    switch (width) {
      case 'small':
        return styles.radioButtonTextWidth_small
      default:
        return ''
    }
  })()

  const sizeRadioButtonStyleClassName = `${sizeRadioButtonStyle}`
  const sizeRadioButtonCheckMarkClassName = `${sizeRadioButtonCheckMark}`
  const sizeRadioButtonTextClassName = `${sizeRadioButtonText} ${sizeRadioButtonTextWidth}`

  return (
    <div>
      <label className={styles.radioButtonContainer}>
        <div className={sizeRadioButtonStyleClassName} style={style}>
          <div
            className={isChecked ? sizeRadioButtonCheckMarkClassName : ''}
          ></div>
        </div>
        <input
          className={styles.radioButtonInput}
          checked={isChecked}
          type="radio"
          onChange={() => (onChange ? onChange() : null)}
        />
        <span className={sizeRadioButtonTextClassName}>{label}</span>
      </label>
      {errorText ? (
        <p className={styles.inputtext_error_text}>{errorText}</p>
      ) : (
        ''
      )}
    </div>
  )
}

export default RadioButton
