import { FC, useEffect, useState } from 'react'
import deleteIcon from 'images/delete-icon.png'
import styles from 'styles/components/template.module.scss'
import { PriceCardData } from '.'

type Props = {
  i: number
  data: {
    breadName: string
    count: number
    price: number
    description: string
    allergen: string
  }
  priceCardData: PriceCardData[]
  setPriceCardData: React.Dispatch<React.SetStateAction<PriceCardData[]>>
}

const CardItem: FC<Props> = ({ i, data, priceCardData, setPriceCardData }) => {
  const [breadName, setBreadName] = useState(data.breadName)
  const [count, setCount] = useState(data.count)
  const [price, setPrice] = useState(data.price)
  const [description, setDescription] = useState(data.description)
  const [allergen, setAllergen] = useState(data.allergen)

  useEffect(() => {
    if (
      priceCardData[i].breadName !== breadName ||
      priceCardData[i].count !== count ||
      priceCardData[i].price !== price ||
      priceCardData[i].description !== description ||
      priceCardData[i].allergen !== allergen
    ) {
      let changedData = priceCardData
      changedData[i].breadName = breadName
      changedData[i].count = count
      changedData[i].price = price
      changedData[i].description = description
      changedData[i].allergen = allergen
      setPriceCardData(changedData)
    }
  }, [
    breadName,
    count,
    price,
    description,
    allergen,
    priceCardData,
    i,
    setPriceCardData,
  ])

  return (
    <div
      className={`${styles.priceCard_table_row} ${
        i % 2 !== 0 && styles.priceCard_table_row_gray_opacity
      }`}
      key={i}
    >
      <div
        className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_bread_name}`}
      >
        <textarea
          className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_bread_name_border}`}
          value={breadName}
          onChange={(e) => setBreadName(e.target.value)}
        />
      </div>

      <div
        className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_count}`}
      >
        <input
          className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_count_border}`}
          value={count}
          onChange={(e) => setCount(Number(e.target.value))}
        />
        <div className={`${styles.priceCard_table_row_body_unit}`}>枚</div>
      </div>
      <div
        className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_price}`}
      >
        <input
          className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_price_border}`}
          value={price}
          onChange={(e) => setPrice(Number(e.target.value))}
        />
        <div className={`${styles.priceCard_table_row_body_unit}`}>円</div>
      </div>
      <div
        className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_description}`}
      >
        <textarea
          className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_description_border}`}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div
        className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_allergen}`}
      >
        <textarea
          className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_allergen_border}`}
          value={allergen}
          onChange={(e) => setAllergen(e.target.value)}
        />
      </div>
      <button
        className={`${styles.priceCard_table_row_body} ${styles.priceCard_table_row_delete}`}
        onClick={() => {}}
      >
        <img src={deleteIcon} alt="delete"></img>
      </button>
    </div>
  )
}

export default CardItem
