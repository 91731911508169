import { FC } from 'react'
import LoginLayout from 'components/Layout/LoginLayout'
import RegisterPlanTemplate from 'components/Templates/RegisterPlanTemplate'

const RegisterPlan: FC = () => {
  return (
    <LoginLayout title={'プラン登録'}>
      <RegisterPlanTemplate />
    </LoginLayout>
  )
}

export default RegisterPlan
