import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/label.module.scss'
import InputTextSuggest, {
  suggestInfo as suggestInfoType,
} from 'components/Form/InputTextSuggest'
import InputLabel from 'components/Label/InputLabel'

type Props = {
  required: boolean
  labelName: string
  description: string
  line: number
  type: string
  size: string
  margin?: string
  labelSize?: string
  value: string
  placeholder: string
  errorText?: string | null
  suggestType: string
  suggestList?: suggestInfo[]
  index?: number | null
  readOnly?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onInput?: () => void
  onSelect: (info: suggestInfo, index: number | null | undefined) => void
}

export type suggestInfo = suggestInfoType

const InputTextSuggestWithLabel: FC<Props> = (props) => {
  const {
    required,
    labelName,
    description,
    line,
    type,
    size,
    margin,
    labelSize,
    value,
    placeholder,
    errorText,
    suggestType,
    suggestList,
    index,
    readOnly,
    onChange,
    onInput,
    onSelect,
  } = props

  const labelFormContainerStyle = (() => {
    switch (margin) {
      case 'default':
        return styles.inputtext_suggest
      case 'semi_light':
        return styles.inputtext_suggest_semi_light
      case 'none':
        return styles.inputtext_suggest_none
      default:
        return styles.inputtext_suggest
    }
  })()

  const className = `${labelFormContainerStyle}`

  return (
    <div className={className}>
      <InputLabel
        required={required}
        name={labelName}
        description={description}
        line={line}
        size={labelSize}
      />
      <div>
        <InputTextSuggest
          required={required}
          type={type}
          size={size}
          value={value}
          placeholder={placeholder}
          errorText={errorText}
          suggestType={suggestType}
          suggestList={suggestList}
          index={index}
          readOnly={readOnly}
          onChange={(e) => (onChange ? onChange(e) : null)}
          onInput={onInput}
          onSelect={onSelect}
        />
      </div>
    </div>
  )
}

export default InputTextSuggestWithLabel
