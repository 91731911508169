import { FC, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import styles from 'styles/components/label.module.scss'
import SelectBox from 'components/Form/SelectBox'
import CheckBox from 'components/Form/CheckBox'
import LightLabel from 'components/Label/LightLabel'
import selectBoxCloseArrow from 'images/Form/select-box-close-arrow.png'

type Props = {
  labelName: string
  description: string
  line: number
  size: string
  // value: string
  checkInfos: checkInfo[]
  onFocus: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  onCheck: (checkinfo: checkInfo[]) => void
}

export type checkInfo = {
  isChecked: boolean
  label: string
  size: string
  type: string
  filter?: string
  // onChange: () => void
}

const SelectCheckBox2WithLightLabel: FC<Props> = (props) => {
  const {
    labelName,
    description,
    line,
    size,
    // value,
    checkInfos,
    onCheck,
  } = props

  const [displayCheckBox, setDisplayCheckBox] = useState<boolean>(false)
  const [checkboxItems, setCheckboxItems] = useState<checkInfo[]>(checkInfos)
  const [displaySelectBoxValue, setDisplaySelectBoxValue] = useState<string>('')
  const [displaySelectBoxPlaceholder, setDisplaySelectBoxPlaceholder] =
    useState<number>(1)

  const onSelectForcus = () => {
    setDisplayCheckBox(true)
  }

  const onSelectBlur = () => {
    setDisplayCheckBox(false)
  }

  const handleChangeCheckBox = (index: number) => {
    const newCheckboxItems =
      checkboxItems.length > 0 ? [...checkboxItems] : [...checkInfos]
    if (newCheckboxItems[index]) {
      newCheckboxItems[index].isChecked = !newCheckboxItems[index].isChecked
        ? true
        : false
      setCheckboxItems(newCheckboxItems)
      // checkboxItems[index].onChange()

      let newDisplaySelectBoxValue = ''
      newCheckboxItems.forEach((item) => {
        if (item.isChecked) {
          newDisplaySelectBoxValue =
            newDisplaySelectBoxValue + item.label + ' / '
        }
      })
      // 末尾の2文字を削除
      newDisplaySelectBoxValue = newDisplaySelectBoxValue.slice(0, -2)
      setDisplaySelectBoxValue(newDisplaySelectBoxValue)

      if (newDisplaySelectBoxValue.length === 0) {
        setDisplaySelectBoxPlaceholder(1)
      } else {
        setDisplaySelectBoxPlaceholder(0)
      }

      onCheck(newCheckboxItems)
    }
  }

  const sizeStyle = (() => {
    switch (size) {
      case 'default':
        return styles.selectCheckBoxModal
      case 'middle':
        return styles.selectCheckBoxModal_middle
      default:
        return styles.selectCheckBoxModal
    }
  })()

  const selectCheckBoxModalClassName = `${sizeStyle}`

  return (
    <div>
      <LightLabel name={labelName} description={description} line={line} />
      <div className={styles.selectCheckBoxContainer}>
        <SelectBox
          size={size}
          value={displaySelectBoxPlaceholder}
          options={[
            {
              is_default: true,
              label: 'カテゴリーを選択してください（複数選択可）',
              value: '1',
              color: 'gray',
            },
            {
              is_default: false,
              label: displaySelectBoxValue,
              value: '0',
            },
          ]}
          displayNone={displayCheckBox}
          onFocus={onSelectForcus}
        />
        {displayCheckBox ? (
          <div className={selectCheckBoxModalClassName}>
            <div className={styles.selectCheckBoxIconArea}>
              <img
                className={styles.selectCheckBoxIcon}
                src={selectBoxCloseArrow}
                alt="^"
                onClick={onSelectBlur}
              ></img>
            </div>
            <div className={styles.selectCheckBoxArea}>
              <Scrollbars
                style={{ width: size === 'middle' ? 298 : 600, height: 180 }}
              >
                {checkInfos.map((checkInfo, index) => (
                  <div
                    className={
                      checkInfo.label.length > 6
                        ? checkInfo.label.length > 20
                          ? styles.checkboxAndLabelContainerLong4
                          : styles.checkboxAndLabelContainerLong3
                        : styles.checkboxAndLabelContainerLong2
                    }
                    key={checkInfo.label}
                  >
                    <CheckBox
                      isChecked={checkInfo.isChecked}
                      label={checkInfo.label}
                      size={checkInfo.size}
                      type={checkInfo.type}
                      onChange={() => handleChangeCheckBox(index)}
                    />
                  </div>
                ))}
              </Scrollbars>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default SelectCheckBox2WithLightLabel
