import {
  FC,
  ChangeEvent,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import styles from 'styles/components/label.module.scss'
import InputText from 'components/Form/InputText'
import { RootState } from 'reducks/reducers'
import { getMaterials, MaterialType } from 'reducks/material/slice'
import { getStores } from 'reducks/store/slice'
import { getPublicRecipes, RecipeType } from 'reducks/recipe/slice'
import { getDoughs } from 'reducks/dough/slice'
import { getFillings } from 'reducks/filling/slice'

type Props = {
  required: boolean
  type: string
  size: string
  value: string
  placeholder: string
  errorText?: string | null
  suggestType: string
  suggestList?: suggestInfo[]
  index?: number | null
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onInput?: () => void
  onSelect: (info: suggestInfo, index: number | null | undefined) => void
  readOnly?: boolean
  style?: React.CSSProperties
}

export type suggestInfo = {
  id: string
  name: string
  search_word: string // サジェスト検索する際のフィルターワード
}

const InputTextSuggestWithLabel: FC<Props> = (props) => {
  const {
    required,
    type,
    size,
    value,
    placeholder,
    errorText,
    suggestType,
    suggestList,
    index,
    onChange,
    onInput,
    onSelect,
    readOnly,
    style,
  } = props

  const dispatch = useDispatch<any>()
  const [displaySuggest, setDisplaySuggest] = useState<boolean>(false)
  const [suggestMouseEnter, setSuggestMouseEnter] = useState<boolean>(false)
  const [suggestInputTextForcus, setSuggestInputTextForcus] =
    useState<boolean>(false)
  const [suggestListInfos, setSuggestListInfos] = useState<suggestInfo[]>([])
  const [suggestListMasters, setSuggestListMasters] = useState<suggestInfo[]>(
    [],
  )

  const onInputTextForcus = () => {
    const newSuggestList = [...suggestListInfos]
    setSuggestListInfos(newSuggestList)
    setSuggestInputTextForcus(true)
    setDisplaySuggest(true)
  }

  const onInputTextBlur = () => {
    setSuggestInputTextForcus(false)
    if (!suggestMouseEnter) {
      setDisplaySuggest(false)
    }
  }

  const onSuggestMouseEnter = () => {
    setSuggestMouseEnter(true)
  }

  const onSuggestMouseLeave = () => {
    setSuggestMouseEnter(false)
  }

  const onSuggestClick = (info: suggestInfo) => {
    onSelect(info, index)
    setDisplaySuggest(false)
    onSuggestMouseLeave()
  }

  const toHiragana = (text: string) => {
    return text.replace(/[\u30A1-\u30FA]/g, (ch) =>
      String.fromCharCode(ch.charCodeAt(0) - 0x60),
    )
  }

  useEffect(() => {
    const newSuggestList = suggestListMasters.filter(
      (text) =>
        toHiragana(text.search_word)
          .toLowerCase()
          .indexOf(toHiragana(value).toLowerCase()) > -1,
    )
    setSuggestListInfos(newSuggestList)
    if (suggestInputTextForcus) {
      if (newSuggestList.length === 0) {
        setDisplaySuggest(false)
      } else {
        setDisplaySuggest(true)
      }
    }
  }, [value])

  const { materials } = useSelector((state: RootState) => state.materialSlice)
  const createMaterialList = useCallback(async () => {
    const materialPayload = await dispatch(getMaterials())
    const materials: MaterialType[] = materialPayload.payload
    // const categoriesPayload: CategoryType[] = categories.payload
    const materialManufacturerSuggestList: suggestInfo[] = []
    const materialManufacturerCheckList: string[] = []
    const productNameSuggestList: suggestInfo[] = []
    const productNameCheckList: string[] = []
    materials.forEach((material) => {
      if (!materialManufacturerCheckList.includes(material.manufacturer)) {
        materialManufacturerSuggestList.push({
          id: material.id,
          name: material.manufacturer,
          search_word: material.manufacturer,
        })
        materialManufacturerCheckList.push(material.manufacturer)
      }
      if (!productNameCheckList.includes(material.product_name)) {
        productNameSuggestList.push({
          id: material.id,
          name: material.product_name + ' ' + material.manufacturer,
          search_word:
            material.product_name +
            ',' +
            material.manufacturer +
            ',' +
            material.product_name_kana +
            ',' +
            material.notation_fluctuation.join(','),
        })
        productNameCheckList.push(
          material.product_name + ' ' + material.manufacturer,
        )
      }
    })
    if (suggestType === 'material_manufacturer') {
      setSuggestListMasters(materialManufacturerSuggestList)
      setSuggestListInfos(materialManufacturerSuggestList)
    } else if (suggestType === 'material_product_name') {
      setSuggestListMasters(productNameSuggestList)
      setSuggestListInfos(productNameSuggestList)
    }
  }, [dispatch, materials, suggestType])

  const { stores } = useSelector((state: RootState) => state.storeSlice)
  const createStoreList = useCallback(async () => {
    await dispatch(getStores())
    const storeNameSuggestList: suggestInfo[] = []
    const storeNameCheckList: string[] = []
    stores.forEach((store) => {
      if (!storeNameCheckList.includes(store.name)) {
        storeNameSuggestList.push({
          id: store.store_id,
          name: store.name,
          search_word: store.name,
        })
        storeNameCheckList.push(store.name)
      }
    })
    if (suggestType === 'store_name') {
      setSuggestListMasters(storeNameSuggestList)
      setSuggestListInfos(storeNameSuggestList)
    }
  }, [dispatch, stores, suggestType])

  const { recipes } = useSelector((state: RootState) => state.recipeSlice)
  const createRecipeList = useCallback(async () => {
    const recipePayload = await dispatch(getPublicRecipes())
    const recipes = recipePayload.payload
    const recipeNameSuggestList: suggestInfo[] = []
    const recipeNameCheckList: string[] = []
    recipes.forEach((recipe: RecipeType) => {
      if (!recipeNameCheckList.includes(recipe.recipe_name)) {
        recipeNameSuggestList.push({
          id: recipe.id,
          name: recipe.recipe_name,
          search_word: recipe.recipe_name,
        })
        recipeNameCheckList.push(recipe.recipe_name)
      }
    })
    setSuggestListMasters(recipeNameSuggestList)
    setSuggestListInfos(recipeNameSuggestList)
  }, [dispatch, recipes, suggestType])

  const { doughs } = useSelector((state: RootState) => state.doughSlice)
  const createDoughList = useCallback(async () => {
    await dispatch(getDoughs())
    const doughNameSuggestList: suggestInfo[] = []
    const doughNameCheckList: string[] = []
    doughs.forEach((dough) => {
      if (!doughNameCheckList.includes(dough.pettern_name)) {
        doughNameSuggestList.push({
          id: dough.id,
          name: dough.pettern_name,
          search_word: dough.pettern_name,
        })
        doughNameCheckList.push(dough.pettern_name)
      }
    })
    setSuggestListMasters(doughNameSuggestList)
    setSuggestListInfos(doughNameSuggestList)
  }, [dispatch, doughs, suggestType])

  const { fillings } = useSelector((state: RootState) => state.fillingSlice)
  const createFillingList = useCallback(async () => {
    await dispatch(getFillings())
    const fillingNameSuggestList: suggestInfo[] = []
    const fillingNameCheckList: string[] = []
    fillings.forEach((filling) => {
      if (!fillingNameCheckList.includes(filling.pettern_name)) {
        fillingNameSuggestList.push({
          id: filling.id,
          name: filling.pettern_name,
          search_word: filling.pettern_name,
        })
        fillingNameCheckList.push(filling.pettern_name)
      }
    })
    setSuggestListMasters(fillingNameSuggestList)
    setSuggestListInfos(fillingNameSuggestList)
  }, [dispatch, fillings, suggestType])

  useLayoutEffect(() => {
    createMaterialList()
    if (suggestType === 'store_name') {
      createStoreList()
    }
    if (suggestType === 'recipe_name') {
      createRecipeList()
    }
    if (suggestType === 'dough_name') {
      createDoughList()
    }
    if (suggestType === 'filling_name') {
      createFillingList()
    }
  }, [suggestType])

  useEffect(() => {
    if (suggestList) {
      setSuggestListMasters(suggestList)
      setSuggestListInfos(suggestList)
    }
  }, [suggestList])

  return (
    <div>
      <InputText
        required={required}
        type={type}
        size={size}
        value={value}
        placeholder={placeholder}
        rightAligned={false}
        errorText={errorText}
        onChange={(e) => (onChange ? onChange(e) : null)}
        onInput={onInput}
        onFocus={onInputTextForcus}
        onBlur={onInputTextBlur}
        readOnly={readOnly}
        style={style}
      />
      {displaySuggest && !readOnly ? (
        <div className={styles.inputtext_suggest_pulldown}>
          <Scrollbars style={{ width: 430, height: 150 }}>
            {suggestListInfos.map((suggestText) => (
              <div
                key={suggestText.name}
                className={styles.inputtext_suggest_text}
                onClick={() => onSuggestClick(suggestText)}
                onMouseEnter={onSuggestMouseEnter}
                onMouseLeave={onSuggestMouseLeave}
              >
                {suggestText.name}
              </div>
            ))}
          </Scrollbars>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default InputTextSuggestWithLabel
