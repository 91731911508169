import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import DoughOperationTemplate from 'components/Templates/DoughOperationTemplate'

const DoughOperation: FC = () => {
  return (
    <Layout title={'生地パターンを作成'} page="DoughOperation">
      <DoughOperationTemplate />
    </Layout>
  )
}

export default DoughOperation
