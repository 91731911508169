import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  DocumentReference,
  doc,
  addDoc,
  updateDoc,
  getDoc,
} from 'firebase/firestore'

interface AdditiveUseState {
  additiveUses: AdditiveUse[]
}
interface AdditiveUse {
  name: string
  is_disabled: boolean
  additive_use_ref: DocumentReference
  disabled_material_name: boolean
}

export type AdditiveUseType = AdditiveUse

export const NOT_DISPLAY_MATERIAL_NAME_ADDITIVE_USE_LIST = [
  'イーストフード',
  'ガムベース',
  'かんすい',
  '酵素',
  '光沢剤',
  '香料',
  '酸味料',
  '調味料',
  '豆腐用凝固剤',
  '苦味料',
  '乳化剤',
  'pH調整剤',
  '膨張剤',
  '軟化剤',
]

const initialState = {
  additiveUses: [],
} as AdditiveUseState

export const getAdditiveUses = createAsyncThunk<AdditiveUse[]>(
  'additiveUse/getAdditiveUses',
  async () => {
    const q = query(
      collection(db, 'additive_uses'),
      where('is_disabled', '==', false),
      orderBy('order'),
    )
    const querySnapshot = await getDocs(q)
    const additiveUses: AdditiveUse[] = []
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const additiveUse = doc.data()
        additiveUses.push({
          name: additiveUse.name,
          is_disabled: additiveUse.is_disabled,
          additive_use_ref: additiveUse.additive_use_ref,
          disabled_material_name: additiveUse.disabled_material_name,
        })
      }),
    )
    return additiveUses
  },
)

export const getAdditiveUseById = createAsyncThunk<
  AdditiveUse | null,
  { id: string }
>('additiveUse/getAdditiveUseById', async ({ id }) => {
  const additiveUseQuery = doc(db, 'additive_uses', id)
  const additiveUseQuerySnapshot = await getDoc(additiveUseQuery)
  const additiveUse = additiveUseQuerySnapshot.data()
  if (!additiveUse) {
    return null
  }
  const additiveUseResponse: AdditiveUse = {
    name: additiveUse.name,
    is_disabled: additiveUse.is_disabled,
    additive_use_ref: additiveUse.additive_use_ref,
    disabled_material_name: additiveUse.disabled_material_name,
  }
  return additiveUseResponse
})

export const createAdditiveUses = createAsyncThunk<boolean>(
  'additiveUse/createAdditiveUses',
  async () => {
    const additiveUseRef = collection(db, 'additive_uses')
    const nameList = [
      '甘味料',
      '着色料',
      '保存料',
      '増粘剤',
      '安定剤',
      'ゲル化剤',
      '糊料',
      '酸化防止剤',
      '発色剤',
      '漂白剤',
      '防かび剤',
      '防ばい剤',
      '乳化剤',
      '膨張剤',
      '調味料',
      '酸味料',
      '苦味料',
      '光沢剤',
      'ガムベース',
      '栄養強化剤',
      '製造用剤等',
      'かんすい',
      '結着剤',
      '消泡剤',
      '抽出溶剤',
      '豆腐用凝固剤',
      '日持向上剤',
      '離型剤',
      'ろ過助剤',
      '香料',
      'pH調整剤',
      '結着防止剤',
      'イーストフード',
      '酵素',
      '軟化剤',
      '加工助剤',
    ]
    await Promise.all(
      nameList.map(async (name, index) => {
        const disabledMaterialName =
          NOT_DISPLAY_MATERIAL_NAME_ADDITIVE_USE_LIST.includes(name)
            ? true
            : false
        const createData = {
          name: name,
          is_disabled: false,
          order: index,
          disabled_material_name: disabledMaterialName,
        }
        const docRef: DocumentReference = await addDoc(
          additiveUseRef,
          createData,
        )
        const additiveUseDoc = doc(db, 'additive_uses', docRef.id)
        const updateData = {
          additive_use_ref: additiveUseDoc,
        }
        await updateDoc(additiveUseDoc, updateData)
      }),
    )
    return true
  },
)

export const additiveUseSlice: Slice<AdditiveUseState, {}, 'additiveUse'> =
  createSlice({
    name: 'additiveUse',
    initialState,
    reducers: {},
    extraReducers: {
      [getAdditiveUses.pending.type]: () => {},
      [getAdditiveUses.fulfilled.type]: (
        state,
        action: PayloadAction<AdditiveUse[]>,
      ) => {
        state.additiveUses = action.payload
      },
      [getAdditiveUses.rejected.type]: () => {},
    },
  })

export default additiveUseSlice.reducer
