import { FC } from 'react'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import sacriLogo from 'images/sacri.svg'
import { useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'

const CompanyPageTemplate: FC = () => {
  const { uid } = useSelector((state: RootState) => state.authSlice)
  return (
    <div
      className={styles.container}
      style={!uid ? { marginLeft: 'auto', marginRight: 'auto' } : undefined}
    >
      {!!uid && <PageHedding title="運営会社" to="/mypage" />}
      <div className={styles.company}>
        <img
          src={sacriLogo}
          alt="sacriロゴ"
          className={styles.company_logo_image}
        />
        <div className={styles.company_label}>Mission</div>
        <div className={styles.company_label_text}>
          お店とお客さまを繋ぎ、豊かな毎日を創る。
        </div>
        <div className={styles.company_label}>Vision</div>
        <div className={styles.company_label_text}>
          「売れる」「買える」を当たり前に。
        </div>
        <div className={styles.terms_privacy_policy_title}>会社情報</div>
        <div className={styles.company_table}>
          <div className={styles.company_table_content}>
            <div className={styles.company_table_content_left}>社号</div>
            <div className={styles.company_table_content_right}>
              株式会社sacri（英文社名:sacri,Inc)
            </div>
          </div>
          <div className={styles.company_table_content}>
            <div className={styles.company_table_content_left}>代表者</div>
            <div className={styles.company_table_content_right}>大谷 具史</div>
          </div>
          <div className={styles.company_table_content}>
            <div className={styles.company_table_content_left}>設立</div>
            <div className={styles.company_table_content_right}>2019年12月</div>
          </div>
          <div className={styles.company_table_content}>
            <div className={styles.company_table_content_left}>事業内容</div>
            <div className={styles.company_table_content_right}>
              インターネットビジネスの企画・開発・運営
            </div>
          </div>
          <div className={styles.company_table_content}>
            <div className={styles.company_table_content_left}>所在地</div>
            <div className={styles.company_table_content_right}>
              東京都千代田区神田錦町二丁目2番地1KANDA SQUARE 11F We Work
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyPageTemplate
