import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/button.module.scss'
import uploadIcon from 'images/Button/upload-icon.png'

type Props = {
  label: string
  description: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const FileUploadButton: FC<Props> = (props) => {
  const { label, description, onChange } = props

  return (
    <div className={styles.fileUploadContainer}>
      <label className={styles.fileUploadLabel}>
        <input
          className={styles.fileUploadInput}
          type="file"
          onChange={(e) => (onChange ? onChange(e) : null)}
          multiple
        />
        <img
          className={styles.fileUploadIcon}
          src={uploadIcon}
          alt="アップロード"
        ></img>
        <span className={styles.fileUploadText}>{label}</span>
      </label>
      <span className={styles.fileUploadDescription}>{description}</span>
    </div>
  )
}

export default FileUploadButton
