import { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import SubmitButton from 'components/Button/SubmitButton'
import Table, { TableBody } from 'components/Table/Table'
import {
  clearFilling,
  getFillings,
  getFillingById,
  FillingType,
} from 'reducks/filling/slice'
import { RootState } from 'reducks/reducers'

const BreadDashBoardTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const [fillingList, setFillingList] = useState<TableBody[][]>([])
  const [searchWord, setSearchWord] = useState<string>('')

  const handleSearchWord = (searchWord: string) => {
    setSearchWord(searchWord)
    filter({ inputSearchWord: searchWord })
  }

  const { fillings } = useSelector((state: RootState) => state.fillingSlice)
  const { staff } = useSelector((state: RootState) => state.staffSlice)
  const dispatchGetmaterials = useCallback(async () => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    const fillings = await dispatch(getFillings())
    createFillingList(fillings.payload)
  }, [dispatch])
  const createFillingList = useCallback(
    async (fillings: FillingType[]) => {
      const fillingList: TableBody[][] = []
      fillings.forEach((filling) => {
        fillingList.push([
          {
            category: 'long_name',
            active: true,
            elementInfo: {
              text: {
                label: filling.pettern_name,
              },
            },
          },
          {
            category: 'status',
            active: true,
            elementInfo: {
              status: {
                name: filling.is_disabled ? '使用不可' : '使用可能',
                color: filling.is_disabled ? 'gray' : 'red',
              },
            },
          },
          {
            category: 'icon',
            active: true,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size: 'icon',
                icon: 'edit_gray',
                to: '/filling/edit/' + filling.id,
              },
            },
          },
          {
            category: 'icon',
            active: true,
            elementInfo: {
              button: {
                label: '',
                color: 'white',
                size: 'icon',
                icon: 'copy_gray',
                to: '/filling/copy/' + filling.id,
              },
            },
          },
          // {
          //   category: 'icon',
          //   elementInfo: {
          //     button: {
          //       label: '',
          //       color: 'white',
          //       size: 'icon',
          //       icon: 'delete_gray',
          //     },
          //   },
          // },
        ])
      })
      setFillingList(fillingList)
    },
    [fillings],
  )

  useEffect(() => {
    dispatchGetmaterials()
  }, [])

  const filter = (payload: { inputSearchWord: string }) => {
    let newFillings = [...fillings]
    let newSearchWord = payload.inputSearchWord
    if (newSearchWord) {
      newFillings = newFillings.filter(
        (filling) =>
          filling.pettern_name
            .replace(/\s+/g, '')
            .toLowerCase()
            .indexOf(newSearchWord.replace(/\s+/g, '').toLowerCase()) > -1,
      )
    }
    createFillingList(newFillings)
  }

  const handleSubmmit = async (to: string | undefined) => {
    if (to) {
      const id = to.split('/filling/edit/')[1]
        ? to.split('/filling/edit/')[1]
        : to.split('/filling/copy/')[1]
        ? to.split('/filling/copy/')[1]
        : null
      if (id) {
        await dispatch(getFillingById({ id: id }))
        navigate(to)
      }
    }
  }

  const toAddPage = async () => {
    await dispatch(clearFilling())
    navigate('/filling/add')
  }

  return (
    <div className={styles.container}>
      <PageHedding title="フィリングパターン" to="" />
      <div className={styles.pattern}>
        <div className={styles.pattern_head}>
          <InputTextWithLightLabel
            labelName="フリーワード検索"
            description=""
            line={1}
            type="text"
            size="xlarge2"
            value={searchWord}
            placeholder="検索ワードを入力"
            onChange={(event) => handleSearchWord(event.target.value)}
          />
          <SubmitButton
            label="パターンを登録する"
            color="orange"
            size="xlarge"
            icon="plus"
            onClick={() => toAddPage()}
          />
        </div>
        <div className={styles.pattern_table}>
          <Table
            headers={[
              {
                category: 'long_name',
                elementInfo: {
                  text: {
                    label: 'フィリングパターン名',
                  },
                },
              },
              {
                category: 'status',
                elementInfo: {
                  text: {
                    label: '使用可/不可',
                  },
                },
              },
              {
                category: 'icon',
                elementInfo: {
                  text: {
                    label: '編集',
                  },
                },
              },
              {
                category: 'icon',
                elementInfo: {
                  text: {
                    label: '複製',
                  },
                },
              },
              // {
              //   category: 'icon',
              //   elementInfo: {
              //     text: {
              //       label: '削除',
              //     },
              //   },
              // },
            ]}
            bodies={fillingList}
            handleSubmmit={handleSubmmit}
          />
        </div>
      </div>
    </div>
  )
}

export default BreadDashBoardTemplate
