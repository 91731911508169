import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/label.module.scss'
import RadioButton from 'components/Form/RadioButton'
import InputText from 'components/Form/InputText'
import InputLabel from 'components/Label/InputLabel'

type Props = {
  required: boolean
  labelName: string
  description: string
  line: number
  radioSize?: string
  checkInfos: checkInfo[]
  type: string
  size: string
  value: string
  placeholder: string
  errorText?: string | null
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onInput?: () => void
}

export type checkInfo = {
  isChecked: boolean
  label: string
  onChange?: () => void
}

const RadioButtonAndInputTextWithLabel: FC<Props> = (props) => {
  const {
    required,
    labelName,
    description,
    line,
    radioSize,
    checkInfos,
    type,
    size,
    value,
    placeholder,
    errorText,
    onChange,
    onInput,
  } = props

  const sizeStyle = (() => {
    switch (radioSize) {
      case 'large':
        return styles.radio_large
      case 'middle':
        return styles.radio_middle
      case 'middle2':
        return styles.radio_middle2
      default:
        return styles.radio_middle
    }
  })()

  const className = `${sizeStyle}`

  return (
    <div>
      <div className={styles.labelFormContainer}>
        <InputLabel
          required={required}
          name={labelName}
          description={description}
          line={line}
        />
        <InputText
          required={required}
          type={type}
          value={value}
          size={size}
          placeholder={placeholder}
          rightAligned={false}
          errorText={errorText}
          onChange={(e) => (onChange ? onChange(e) : null)}
          onInput={onInput}
        />
      </div>
      <div className={styles.labelFormContainerSub}>
        <InputLabel required={false} name="" description="" line={1} />
        <div className={styles.radioButtonContainer}>
          {checkInfos.map((checkInfo) => (
            <div className={className} key={checkInfo.label}>
              <RadioButton
                isChecked={checkInfo.isChecked}
                label={checkInfo.label}
                onChange={() =>
                  checkInfo.onChange ? checkInfo.onChange() : null
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RadioButtonAndInputTextWithLabel
