import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import FillingTemplate from 'components/Templates/FillingTemplate'

const Filling: FC = () => {
  return (
    <Layout title={'フィリングパターン'} page="Filling">
      <FillingTemplate />
    </Layout>
  )
}

export default Filling
