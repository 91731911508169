import { FC } from 'react'
import styles from 'styles/components/attention.module.scss'
import attentionIcon from 'images/Attention/attention-icon.png'

type Props = {
  description: string
}

const PageHeadAttention: FC<Props> = (props) => {
  const { description } = props

  return (
    <>
      <div className={styles.page_head_attention}>
        <div className={styles.page_head_attention_title}>
          <img
            className={styles.page_head_attention_title_icon}
            src={attentionIcon}
            alt="注意"
          ></img>
          <p className={styles.page_head_attention_title_text}>ご注意</p>
        </div>
        <div className={styles.page_head_attention_description}>
          {description}
        </div>
      </div>
    </>
  )
}

export default PageHeadAttention
