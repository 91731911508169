import { FC } from 'react'
import styles from 'styles/components/template.module.scss'
import SubmitButton from 'components/Button/SubmitButton'
import { DataType, UnitType } from 'reducks/business/slice'

type Props = {
  nameMinusWidth?: number
  handleAddButton?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
    unitType: UnitType.GRAM | UnitType.PERCENT,
  ) => void
  dataType?: DataType
  params?: (number | DataType | boolean)[]
  unitType: UnitType.GRAM | UnitType.PERCENT
}

const BreadDetailAddButton: FC<Props> = (props) => {
  const { nameMinusWidth, handleAddButton, dataType, params, unitType } = props

  return (
    <div className={`${styles.bread_detail_table_add_button}`}>
      <div
        className={`${styles.bread_detail_table_add_button}`}
        style={{ width: 388 - Number(nameMinusWidth) }}
      >
        <SubmitButton
          label="項目を増やす"
          color="white2"
          size="small"
          icon="outline_plus"
          onClick={() =>
            handleAddButton ? handleAddButton(dataType, params, unitType) : {}
          }
        />
      </div>
    </div>
  )
}

export default BreadDetailAddButton
