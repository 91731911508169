import { FC } from 'react'
import { useSelector } from 'react-redux'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import MyPageLink from 'components/Button/MyPageLink'
import { Link } from 'react-router-dom'
import { RootState } from 'reducks/reducers'

const PortalTemplate: FC = () => {
  const { staff } = useSelector((state: RootState) => state.staffSlice)
  return (
    <div className={styles.container}>
      <PageHedding title="マイページ" to="/initload" />
      <div className={styles.mypage}>
        {staff?.isAdmin ? (
          ''
        ) : (
          <MyPageLink label="ユーザー設定" to="/account" icon="user" />
        )}
        <MyPageLink label="店舗情報" to="/shop/edit" icon="shop" />
        {staff?.isAdmin && !staff.shopId ? (
          ''
        ) : (
          <MyPageLink
            label="ご利用プランの管理"
            to="/plan_confirm"
            icon="plan"
          />
        )}
        {staff?.shopId ? (
          <>
            <MyPageLink label="生地パターン一覧" to="/dough" icon="dough" />
            <MyPageLink
              label="フィリングパターン一覧"
              to="/filling"
              icon="filling"
            />
            <MyPageLink label="ユーザー権限" to="/members" icon="people" />
          </>
        ) : (
          ''
        )}
      </div>
      <div className={styles.mypage_docs}>
        <Link className={styles.mypage_doc_links} to={'/terms'}>
          利用規約
        </Link>
        <Link className={styles.mypage_doc_links} to={'/privacy_policy'}>
          プライバシーポリシー
        </Link>
        <Link className={styles.mypage_doc_links} to={'/law'}>
          特定商取引法に基づく表記
        </Link>
        <Link className={styles.mypage_doc_links} to={'/company'}>
          運営会社
        </Link>
      </div>
    </div>
  )
}

export default PortalTemplate
