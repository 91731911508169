import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { db } from 'fb'
import {
  doc,
  getDoc,
  setDoc,
  query,
  collection,
  getDocs,
  DocumentReference,
  where,
} from 'firebase/firestore'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'

interface StoreState {
  store: StoreType | null
  stores: StoreType[]
}

export type StoreType = {
  address: {
    building: string
    city: string
    postal_code: string
    state: string
    street: string
  }
  factory_address?: {
    building: string
    city: string
    postal_code: string
    state: string
    street: string
  } | null
  description: string
  is_sacri_station: boolean
  name: string
  name_kana: string
  phone_number: string
  personal_phone_number?: string
  store_id: string
  shop_ref: DocumentReference
}

const initialState = {
  store: null,
  stores: [],
} as StoreState

export const getStores = createAsyncThunk<StoreType[], void>(
  'store/getStores',
  async (_) => {
    const stores: StoreType[] = []
    const q = query(collection(db, 'stores'), where('status', '!=', 'deleted'))
    const querySnapshot = await getDocs(q)
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const store = doc.data()
        stores.push({
          address: {
            building: store.address?.building || '',
            city: store.address?.city || '',
            postal_code: store.address?.postal_code || '',
            state: store.address?.state || '',
            street: store.address?.street || '',
          },
          description: store.description,
          is_sacri_station: store.is_sacri_station,
          name: store.name,
          name_kana: store.name_kana,
          phone_number: store.phone_number,
          personal_phone_number: store.personal_phone_number,
          store_id: doc.id,
          shop_ref: store.shop_ref,
        })
      }),
    )
    return stores
  },
)

export const getStoreById = createAsyncThunk<
  StoreType | null,
  { storeId: string }
>('store/getStoreById', async ({ storeId }) => {
  const storeDocRef = doc(db, 'stores', storeId)
  const storeDocSnap = await getDoc(storeDocRef)
  const store = storeDocSnap.data()
  if (!store) return null
  const res: StoreType = {
    address: {
      building: store.address.building,
      city: store.address.city,
      postal_code: store.address.postal_code,
      state: store.address.state,
      street: store.address.street,
    },
    factory_address: {
      building: store.factory_address?.building || '',
      city: store.factory_address?.city || '',
      postal_code: store.factory_address?.postal_code || '',
      state: store.factory_address?.state || '',
      street: store.factory_address?.street || '',
    },
    description: store.description,
    is_sacri_station: store.is_sacri_station,
    name: store.name,
    name_kana: store.name_kana,
    phone_number: store.phone_number,
    personal_phone_number: store.personal_phone_number,
    store_id: storeId,
    shop_ref: store.shop_ref,
  }
  return res
})

export const getStoreByShopId = createAsyncThunk<
  StoreType | null,
  { shopId: string }
>('store/getStoreByShopId', async ({ shopId }) => {
  const q = query(
    collection(db, 'stores'),
    where('shop_ref', '==', doc(db, 'shops', shopId)),
  )
  const querySnapshot = await getDocs(q)
  const store = querySnapshot.docs[0].data()
  if (!store) {
    return null
  }
  const res: StoreType = {
    address: {
      building: store.address.building,
      city: store.address.city,
      postal_code: store.address.postal_code,
      state: store.address.state,
      street: store.address.street,
    },
    description: store.description,
    is_sacri_station: store.is_sacri_station,
    name: store.name,
    name_kana: store.name_kana,
    phone_number: store.phone_number,
    personal_phone_number: store.personal_phone_number,
    store_id: querySnapshot.docs[0].id,
    shop_ref: store.shop_ref,
  }
  return res
})

export const updateStore = createAsyncThunk<
  string | null,
  {
    storeData: Partial<StoreType>
    storeId: string
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('store/updateStore', async ({ storeData, storeId }) => {
  const storeRef = doc(db, 'stores', storeId)
  await setDoc(storeRef, storeData, { merge: true })
  return ''
})

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    clearShop(state) {
      state.store = null
    },
  },
  extraReducers: {
    [getStores.fulfilled.type]: (
      state,
      action: PayloadAction<StoreType[] | null>,
    ) => {
      if (action.payload !== null) {
        state.stores = action.payload
      }
    },
    [getStoreById.fulfilled.type]: (
      state,
      action: PayloadAction<StoreType | null>,
    ) => {
      if (action.payload !== null) {
        state.store = action.payload
      }
    },
  },
})

export const { clearShop } = storeSlice.actions
export default storeSlice.reducer
