import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import EditPlanTemplate from 'components/Templates/EditPlanTemplate'

const EditPlanOperation: FC = () => {
  return (
    <Layout title={'プラン編集'} page="EditPlan">
      <EditPlanTemplate />
    </Layout>
  )
}

export default EditPlanOperation
