import { FC } from 'react'
import LoginLayout from 'components/Layout/LoginLayout'
import LoginTemplate from 'components/Templates/LoginTemplate'

const Login: FC = () => {
  return (
    <LoginLayout title={'ログイン'}>
      <LoginTemplate />
    </LoginLayout>
  )
}

export default Login
