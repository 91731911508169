import { FC, useState } from 'react'
import styles from 'styles/components/label.module.scss'
import SelectBox from 'components/Form/SelectBox'
import CheckBox from 'components/Form/CheckBox'
import LightLabel from 'components/Label/LightLabel'
import selectBoxCloseArrow from 'images/Form/select-box-close-arrow.png'

type Props = {
  labelName: string
  description: string
  line: number
  size: string
  value: string
  displaySelectBoxValue: string
  checkInfos: checkInfo[]
  onFocus: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void
}

export type checkInfo = {
  isChecked: boolean
  label: string
  size: string
  type: string
  onChange: () => void
}

const SelectCheckBoxWithLightLabel: FC<Props> = (props) => {
  const {
    labelName,
    description,
    line,
    size,
    value,
    displaySelectBoxValue,
    checkInfos,
  } = props

  const [displayCheckBox, setDisplayCheckBox] = useState<boolean>(false)
  // const [checkboxItems, setCheckboxItems] = useState<checkInfo[]>(checkInfos)
  // const [displaySelectBoxValue, setDisplaySelectBoxValue] = useState<string>('')
  // const [displaySelectBoxPlaceholder, setDisplaySelectBoxPlaceholder] =
  //   useState<number>(1)

  const onSelectForcus = () => {
    setDisplayCheckBox(true)
  }

  const onSelectBlur = () => {
    setDisplayCheckBox(false)
  }

  // const handleChangeCheckBox = (index: number) => {
  //   const newCheckboxItems = [...checkboxItems]
  //   if (newCheckboxItems[index] && checkboxItems[index]) {
  //     newCheckboxItems[index].isChecked = !checkboxItems[index].isChecked
  //       ? true
  //       : false
  //     setCheckboxItems(newCheckboxItems)
  //     console.log("handleChangeCheckBox")
  //     console.log(index)
  //     checkboxItems[index].onChange()

  //     let newDisplaySelectBoxValue = ''
  //     newCheckboxItems.forEach((item) => {
  //       if (item.isChecked) {
  //         newDisplaySelectBoxValue =
  //           newDisplaySelectBoxValue + item.label + ' / '
  //       }
  //     })
  //     // 末尾の2文字を削除
  //     newDisplaySelectBoxValue = newDisplaySelectBoxValue.slice(0, -2)
  //     setDisplaySelectBoxValue(newDisplaySelectBoxValue)

  //     if (newDisplaySelectBoxValue.length === 0) {
  //       setDisplaySelectBoxPlaceholder(1)
  //     } else {
  //       setDisplaySelectBoxPlaceholder(0)
  //     }
  //   }
  // }

  return (
    <div>
      <LightLabel name={labelName} description={description} line={line} />
      <div className={styles.selectCheckBoxContainer}>
        <SelectBox
          size={size}
          value={value}
          options={[
            {
              is_default: true,
              label: 'カテゴリーを選択してください（複数選択可）',
              value: '1',
              color: 'gray',
            },
            {
              is_default: false,
              label: displaySelectBoxValue,
              value: '0',
            },
          ]}
          displayNone={displayCheckBox}
          onFocus={onSelectForcus}
        />
        {displayCheckBox ? (
          <div className={styles.selectCheckBoxModal}>
            <div className={styles.selectCheckBoxIconArea}>
              <img
                className={styles.selectCheckBoxIcon}
                src={selectBoxCloseArrow}
                alt="^"
                onClick={onSelectBlur}
              ></img>
            </div>
            <div className={styles.selectCheckBoxArea}>
              {checkInfos.map((checkInfo) => (
                <div
                  className={
                    checkInfo.label.length > 6
                      ? styles.checkboxAndLabelContainerLong3
                      : styles.checkboxAndLabelContainerLong2
                  }
                  key={checkInfo.label}
                >
                  <CheckBox
                    isChecked={checkInfo.isChecked}
                    label={checkInfo.label}
                    size={checkInfo.size}
                    type={checkInfo.type}
                    onChange={() => checkInfo.onChange()}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default SelectCheckBoxWithLightLabel
