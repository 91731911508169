import { FC } from 'react'
import styles from 'styles/components/label.module.scss'
import CheckBox from 'components/Form/CheckBox'
import InputLabel from 'components/Label/InputLabel'

type Props = {
  required: boolean
  labelName: string
  description: string
  labelPosition: string
  line: number
  checkInfos: checkInfo[]
  labelTextWrap?: boolean
}

export type checkInfo = {
  isChecked: boolean
  label: string
  size: string
  type: string
  onChange?: () => void
  readOnly?: boolean
}

const CheckBoxWithLabel: FC<Props> = (props) => {
  const {
    required,
    labelName,
    description,
    labelPosition,
    line,
    checkInfos,
    labelTextWrap,
  } = props
  // const [checkboxItems, setCheckboxItems] = useState<checkInfo[]>(checkInfos)

  // const handleChangeCheckBox = (index: number, onChange: () => void) => {
  //   const newCheckboxItems = [...checkboxItems]
  //   if (newCheckboxItems[index] && checkboxItems[index]) {
  //     newCheckboxItems[index].isChecked = !checkboxItems[index].isChecked
  //       ? true
  //       : false
  //     setCheckboxItems(newCheckboxItems)
  //   }
  //   onChange()
  // }

  return (
    <div
      className={
        labelPosition === 'top'
          ? styles.labelFormTopContainer
          : styles.labelFormContainer
      }
    >
      <div className={labelPosition === 'top' ? styles.labelTopContainer : ''}>
        <InputLabel
          required={required}
          name={labelName}
          description={description}
          line={line}
          labelTextWrap={labelTextWrap}
        />
      </div>
      <div className={styles.checkboxContainer}>
        {checkInfos.map((checkInfo) => (
          <div
            className={
              checkInfo.label.length > 2
                ? checkInfo.label.length > 3
                  ? checkInfo.label.length > 6
                    ? styles.checkboxAndLabelContainerLong3
                    : styles.checkboxAndLabelContainerLong2
                  : styles.checkboxAndLabelContainerLong
                : styles.checkboxAndLabelContainer
            }
            key={checkInfo.label}
          >
            <CheckBox
              isChecked={checkInfo.isChecked}
              label={checkInfo.label}
              size={checkInfo.size}
              type={checkInfo.type}
              onChange={() =>
                checkInfo.onChange ? checkInfo.onChange() : null
              }
              readOnly={checkInfo.readOnly}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CheckBoxWithLabel
