import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  DocumentReference,
  doc,
  addDoc,
  updateDoc,
  getDoc,
} from 'firebase/firestore'

interface MaterialSubCategoryState {
  materialSubCategories: MaterialSubCategory[]
}
interface MaterialSubCategory {
  id: string
  name: string
  is_disabled: boolean
  material_category_ref: DocumentReference
  material_sub_category_ref: DocumentReference
}

export type MaterialSubCategoryType = MaterialSubCategory

const initialState = {
  materialSubCategories: [],
} as MaterialSubCategoryState

export const getMaterialSubCategories = createAsyncThunk<MaterialSubCategory[]>(
  'materialSubCategory/getMaterialSubCategories',
  async () => {
    const q = query(
      collection(db, 'material_sub_categories'),
      where('is_disabled', '==', false),
      orderBy('order'),
    )
    const querySnapshot = await getDocs(q)
    const materialSubCategories: MaterialSubCategory[] = []
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const materialSubCategory = doc.data()
        materialSubCategories.push({
          id: doc.id,
          name: materialSubCategory.name,
          is_disabled: materialSubCategory.is_disabled,
          material_category_ref: materialSubCategory.material_category_ref,
          material_sub_category_ref:
            materialSubCategory.material_sub_category_ref,
        })
      }),
    )
    return materialSubCategories
  },
)

export const getMaterialSubCategoryById = createAsyncThunk<
  MaterialSubCategory | null,
  { id: string }
>('materialSubCategory/getMaterialSubCategoryById', async ({ id }) => {
  const q = doc(db, 'material_sub_categories', id)
  const querySnapshot = await getDoc(q)
  const materialSubCategory = querySnapshot.data()
  if (!materialSubCategory) {
    return null
  }
  const response: MaterialSubCategory = {
    id: querySnapshot.id,
    name: materialSubCategory.name,
    is_disabled: materialSubCategory.is_disabled,
    material_category_ref: materialSubCategory.material_category_ref,
    material_sub_category_ref: materialSubCategory.material_sub_category_ref,
  }
  return response
})

export const createMaterialSubCategories = createAsyncThunk<boolean>(
  'materialSubCategory/createMaterialSubCategories',
  async () => {
    const nameList = {
      '小麦粉・雑穀粉': [
        '小麦粉',
        '全粒粉',
        'ライ麦',
        '米粉',
        '雑穀',
        'コーンスターチ',
        'ふすま粉',
      ],
      'バター・乳製品・油脂・卵': [
        'バター',
        '生クリーム',
        'チーズ',
        'マーガリン',
        'ショートニング',
        '牛乳',
        'スキムミルク（脱脂粉乳）',
        'ヨーグルト',
        'れん乳（コンデンスミルク・エバミルク）',
        '全脂粉乳',
        'バターミルクパウダー',
        '油',
        '卵',
      ],
      'イースト・酵母': ['ドライイースト', '生イースト', '酵母'],
      '砂糖・はちみつ・ジャム': [
        '砂糖',
        'はちみつ',
        'シロップ',
        'ジャム',
        'スプレッド',
      ],
      '塩・スパイス・ハーブ': ['塩', 'スパイス', 'ハーブ'],
      'チョコレート・ココア': ['チョコレート', 'ココア・カカオ'],
      'ナッツ(アーモンド・くるみ等)': [
        'アーモンド',
        'くるみ・ビーカン',
        'ピスタチオ・ヘーゼル',
        'カシューナッツ・マカダミア',
        'ココナッツ',
        'その他ナッツ',
      ],
      '栗・芋・かぼちゃ・シード': [
        '小豆',
        'ごま',
        'かぼちゃ',
        '栗',
        '芋',
        'シード(種・実)',
        'あんこ',
      ],
      'ドライフルーツ・野菜・果物': [
        'ドライフルーツ',
        'パウダー・フレーク・ペースト',
        '果汁',
        '漬込みフルーツ',
        'フルーツピューレ',
        '野菜',
        '果物(生)',
      ],
      '精肉・肉加工品・ハム・ソーセージ': [
        '精肉',
        'ハム',
        'ソーセージ',
        '肉加工品',
      ],
      '魚介類・海産物・水産加工品': ['魚介類', '海産物', '水産加工品'],
      '酒・リキュール・水': [
        'ブランデー',
        'ラム',
        'リキュール',
        'その他酒',
        '水',
      ],
      '膨張剤・香料・色素・凝固剤・添加物・改良剤': [
        'ベーキングパウダー・膨張剤・重曹',
        '香料',
        '色素',
        'その他添加物',
        '凝固剤',
        '改良剤',
      ],
      調味料: [
        'ソース',
        '醤油',
        '味噌',
        'マヨネーズ',
        '酢',
        'マスタード・からし',
        'だし',
      ],
      フィリング: ['カレー'],
      その他: ['その他'],
    }

    const materialSubCategoryRef = collection(db, 'material_sub_categories')
    const materialCategoryRef = collection(db, 'material_categories')
    await Promise.all(
      Object.keys(nameList).map(async (name) => {
        const q = query(materialCategoryRef, where('name', '==', name))
        const querySnapshot = await getDocs(q)
        const materialCategoryId = querySnapshot.docs[0].id
        const materialCategoryDoc = doc(
          db,
          'material_categories',
          materialCategoryId,
        )
        await Promise.all(
          // @ts-ignore
          nameList[name].map(async (name2, index) => {
            const createData = {
              name: name2,
              is_disabled: false,
              order: index,
              material_category_ref: materialCategoryDoc,
            }
            const docRef: DocumentReference = await addDoc(
              materialSubCategoryRef,
              createData,
            )
            const materialSubCategoryDoc = doc(
              db,
              'material_sub_categories',
              docRef.id,
            )
            const updateData = {
              material_sub_category_ref: materialSubCategoryDoc,
            }
            await updateDoc(materialSubCategoryDoc, updateData)
          }),
        )
      }),
    )
    return true
  },
)

export const materialSubCategorySlice: Slice<
  MaterialSubCategoryState,
  {},
  'materialSubCategory'
> = createSlice({
  name: 'materialSubCategory',
  initialState,
  reducers: {},
  extraReducers: {
    [getMaterialSubCategories.pending.type]: () => {},
    [getMaterialSubCategories.fulfilled.type]: (
      state,
      action: PayloadAction<MaterialSubCategory[]>,
    ) => {
      state.materialSubCategories = action.payload
    },
    [getMaterialSubCategories.rejected.type]: () => {},
  },
})

export default materialSubCategorySlice.reducer
