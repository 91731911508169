import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/label.module.scss'
import InputText from 'components/Form/InputText'
import InputLabel from 'components/Label/InputLabel'

type Props = {
  required: boolean
  labelName: string
  description: string
  line: number
  type: string
  size: string
  margin?: string
  value: string | number
  placeholder: string
  unit: string
  unitDescription?: string
  errorText?: string | null
  labelSize?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onInput?: () => void
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
}

const InputTextAndUnitWithLabel: FC<Props> = (props) => {
  const {
    required,
    labelName,
    description,
    line,
    type,
    size,
    margin,
    value,
    placeholder,
    unit,
    unitDescription,
    errorText,
    labelSize,
    onChange,
    onInput,
    onBlur,
  } = props

  const labelFormContainerStyle = (() => {
    switch (margin) {
      case 'default':
        return styles.labelFormContainer
      case 'narrow':
        return styles.labelFormContainerSub
      case 'min':
        return styles.labelFormContainerMin
      default:
        return styles.labelFormContainer
    }
  })()

  const className = `${labelFormContainerStyle}`

  return (
    <div className={className}>
      <InputLabel
        required={required}
        name={labelName}
        description={description}
        line={line}
        size={labelSize}
      />
      <div>
        <InputText
          required={required}
          type={type}
          size={size}
          value={value}
          placeholder={placeholder}
          rightAligned={true}
          errorText={errorText}
          onChange={(e) => (onChange ? onChange(e) : null)}
          onInput={onInput}
          onBlur={onBlur}
        />
      </div>
      <div className={styles.inputAndUnitTextContainer}>
        <span>{unit}</span>
        <br></br>
        {unitDescription}
      </div>
    </div>
  )
}

export default InputTextAndUnitWithLabel
