import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/label.module.scss'
import FileUploadButton from 'components/Button/FileUploadButton'
import InputLabel from 'components/Label/InputLabel'

type Props = {
  required: boolean
  labelName: string
  description: string
  line: number
  buttonLabelName: string
  buttonDescription: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const FileUploadButtonWithLabel: FC<Props> = (props) => {
  const {
    required,
    labelName,
    description,
    line,
    buttonLabelName,
    buttonDescription,
    onChange,
  } = props

  return (
    <div className={styles.labelFormContainer}>
      <InputLabel
        required={required}
        name={labelName}
        description={description}
        line={line}
      />
      <div>
        <FileUploadButton
          label={buttonLabelName}
          description={buttonDescription}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
      </div>
    </div>
  )
}

export default FileUploadButtonWithLabel
