import { FC, useState } from 'react'
// import { useDispatch } from 'react-redux'
import styles from 'styles/components/template.module.scss'
import SubmitButton from 'components/Button/SubmitButton'
import { useNavigate } from 'react-router-dom'
import Logo from 'components/Icons/Logo'
import InputText from 'components/Form/InputText'
// import { RootState } from 'reducks/reducers'
// import { AppDispatch } from 'index'

const NewPasswordTemplate: FC = () => {
  // const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  // const { roles } = useSelector((state: RootState) => state.staffSlice)

  const handleSubmit = async () => {
    // await dispatch(signIn({ email }))
    navigate('/initload')
  }

  return (
    <div className={styles.login_container}>
      <div className={styles.login_logo}>
        <Logo width={368} />
      </div>
      <div className={styles.login_form_container}>
        <div
          className={styles.login_description_text}
          style={{ fontWeight: 'bold' }}
        >{`新しいパスワードを入力し、「再設定する」ボタンを押してください。`}</div>
        <div className={styles.login_form_container_row}>
          <p className={styles.login_form_container_text}>新パスワード</p>
          <InputText
            required={true}
            type="text"
            size="xlarge"
            value={password}
            placeholder=""
            rightAligned={false}
            isLogin={true}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div className={styles.login_form_container_row}>
          <p
            className={styles.login_form_container_text}
            style={{ whiteSpace: 'break-spaces' }}
          >{`パスワードの
再入力`}</p>
          <InputText
            required={true}
            type="text"
            size="xlarge"
            value={passwordConfirm}
            placeholder=""
            rightAligned={false}
            isLogin={true}
            onChange={(event) => setPasswordConfirm(event.target.value)}
          />
        </div>
      </div>
      <div className={styles.login_button_container} style={{ marginTop: 40 }}>
        <SubmitButton
          label="再設定する"
          color="orange"
          size="semilarge"
          icon="none"
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  )
}

export default NewPasswordTemplate
