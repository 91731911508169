import { FC } from 'react'
import styles from 'styles/components/label.module.scss'
import SelectBox, { SelectOption } from 'components/Form/SelectBox'
import InputLabel from 'components/Label/InputLabel'

type Props = {
  required: boolean
  labelName: string
  description: string
  line: number
  size: string
  value: string | number
  options: SelectOption[]
  errorText?: string | null
  margin?: string
  labelSize?: string
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const SelectBoxWithLabel: FC<Props> = (props) => {
  const {
    required,
    labelName,
    description,
    line,
    size,
    value,
    options,
    errorText,
    margin,
    labelSize,
    onChange,
  } = props

  const labelFormContainerStyle = (() => {
    switch (margin) {
      case 'default':
        return styles.labelFormContainer
      case 'narrow':
        return styles.labelFormContainerSub
      case 'min':
        return styles.labelFormContainerMin
      default:
        return styles.labelFormContainer
    }
  })()

  const className = `${labelFormContainerStyle}`

  return (
    <div className={className}>
      <InputLabel
        required={required}
        name={labelName}
        description={description}
        line={line}
        size={labelSize}
      />
      <div>
        <SelectBox
          size={size}
          value={value}
          options={options}
          errorText={errorText}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
      </div>
    </div>
  )
}

export default SelectBoxWithLabel
