import { FC } from 'react'
import styles from 'styles/components/label.module.scss'

type Props = {
  name: string
  color: string
}

const StatusLabel: FC<Props> = (props) => {
  const { name, color } = props

  const colorStyle = (() => {
    switch (color) {
      case 'blue':
        return styles.status_label_blue
      case 'green':
        return styles.status_label_green
      case 'red':
        return styles.status_label_red
      case 'gray':
        return styles.status_label_gray
      default:
        return styles.status_label_gray
    }
  })()

  const className = `${colorStyle}`

  return <div className={className}>{name}</div>
}

export default StatusLabel
