import { FC } from 'react'
import styles from 'styles/components/template.module.scss'
import SubmitButton from 'components/Button/SubmitButton'
import BackEditHedding from './BackEditHeadding'
import LabelWithBorder from './LabelWithBorder'
import { useNavigate } from 'react-router-dom'

type Props = {
  shopName: string
  shopNameErrorText: string | null
  setShopName: React.Dispatch<React.SetStateAction<string>>
  shopNameKana: string
  shopNameKanaErrorText: string | null
  setShopNameKana: React.Dispatch<React.SetStateAction<string>>
  factoryName: string
  factoryNameErrorText: string | null
  setFactoryName: React.Dispatch<React.SetStateAction<string>>
  officeName: string
  officeNameErrorText: string | null
  setOfficeName: React.Dispatch<React.SetStateAction<string>>
  officerName: string
  officerNameErrorText: string | null
  setOfficerName: React.Dispatch<React.SetStateAction<string>>
  zipCode: string
  zipCodeErrorText: string | null
  setZipCode: React.Dispatch<React.SetStateAction<string>>
  prefecture: string
  prefectureErrorText: string | null
  setPrefecture: React.Dispatch<React.SetStateAction<string>>
  city: string
  cityErrorText: string | null
  setCity: React.Dispatch<React.SetStateAction<string>>
  address: string
  addressErrorText: string | null
  setAddress: React.Dispatch<React.SetStateAction<string>>
  building: string
  setBuilding: React.Dispatch<React.SetStateAction<string>>
  email: string
  emailErrorText: string | null
  setEmail: React.Dispatch<React.SetStateAction<string>>
  phoneNumber: string
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
  phoneNumberErrorText: string | null
  searchAddress: () => void
  handleSubmit: (is_resend?: boolean) => Promise<void>
  setIsConfirmed: React.Dispatch<React.SetStateAction<boolean>>
  isRegister: boolean
  isAdmin: boolean
  isSentEmail: boolean
  isCompany: boolean
  isSameAddress: boolean
  isBillPayment: boolean
  shopId: string
}
const ConfirmedContainer: FC<Props> = ({
  shopName,
  shopNameKana,
  officeName,
  officerName,
  zipCode,
  prefecture,
  city,
  address,
  building,
  email,
  phoneNumber,
  handleSubmit,
  setIsConfirmed,
  isRegister,
  isAdmin,
  isSentEmail,
  isCompany,
  isSameAddress,
  isBillPayment,
  shopId,
}) => {
  const navigate = useNavigate()
  return (
    <>
      {!isRegister && (
        <BackEditHedding
          title="店舗入力確認"
          handleClick={() => setIsConfirmed(false)}
        />
      )}
      <div
        style={{
          marginTop: 56,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <LabelWithBorder label="店舗名" value={shopName} width={480} />
        <LabelWithBorder
          label="店舗名"
          subLabel="（ふりがな）"
          value={shopNameKana}
          width={480}
        />
      </div>

      <div
        style={{
          marginTop: 56,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {isCompany && (
          <LabelWithBorder
            label="法人名"
            subLabel=""
            value={officeName}
            width={480}
          />
        )}
        <LabelWithBorder
          label="責任者名"
          subLabel=""
          value={officerName}
          width={480}
        />
      </div>

      <div style={{ marginTop: 56 }}>
        <LabelWithBorder
          label={isSameAddress ? 'お店・製造所の住所' : '製造所の住所'}
          subLabel=""
          value={`${zipCode}
${prefecture}
${city}
${address}
${building}
          `}
          width="100%"
        />
      </div>

      <div
        style={{
          marginTop: 56,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <LabelWithBorder
          label="メールアドレス"
          subLabel=""
          value={email}
          width={480}
        />
        <LabelWithBorder
          label="電話番号"
          subLabel="（日中連絡が取れる番号をお願いいたします。）"
          value={phoneNumber}
          width={480}
        />
      </div>

      {isAdmin ? (
        <div style={{ display: 'flex', marginTop: 54 }}>
          <LabelWithBorder
            label="請求書払い"
            subLabel=""
            value={isBillPayment ? 'する' : 'しない'}
            width={480}
          />
        </div>
      ) : (
        ''
      )}

      <div className={styles.shopInfoButtonContainer}>
        <SubmitButton
          label="修正する"
          color="gray"
          size="large"
          icon="none"
          onClick={() => setIsConfirmed(false)}
        />
        <div style={{ marginLeft: 40 }}>
          <SubmitButton
            label={isAdmin && !shopId ? '店舗を登録する' : '店舗情報を更新する'}
            color="orange"
            size="large"
            icon="plus"
            onClick={() => handleSubmit()}
          />
        </div>
      </div>

      {isSentEmail && (
        <div className={styles.modal_account}>
          <div className={styles.modal_account_text}>
            {isAdmin && !shopId
              ? '招待メールを送信しました'
              : '店舗情報を更新しました'}
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="マイページに戻る"
              color="orange"
              size="xmiddle"
              icon="none"
              onClick={() => {
                navigate(
                  isAdmin && !shopId
                    ? '/initload'
                    : isRegister
                    ? '/registerplan'
                    : '/',
                )
                window.scrollTo(0, 0)
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ConfirmedContainer
