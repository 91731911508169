import { httpsCallable } from 'firebase/functions'
import { functions } from 'fb/index'
import { ProductInformation } from 'components/Form/AddForm/ProductInformation'
import { SupplierType } from 'reducks/materialCost/slice'

export enum NotifyPettern {
  BIRTHPLACE = 'dough',
  FILLING = 'filling',
  RECIPE = 'recipe',
}

const slack = {
  sendMessage: async (message: string) => {
    const notifySlackDaseruno = httpsCallable(functions, 'notifySlackDaseruno')
    await notifySlackDaseruno({ message })
  },

  materialApply: async (
    productInformations: ProductInformation[],
    shopName: string,
    petternName: string,
    pettern: NotifyPettern,
  ) => {
    let petternKey = ''
    if (pettern === NotifyPettern.BIRTHPLACE) {
      petternKey = '生地パターン名'
    }
    if (pettern === NotifyPettern.FILLING) {
      petternKey = 'フィリングパターン名'
    }
    if (pettern === NotifyPettern.RECIPE) {
      petternKey = '商品名'
    }
    await Promise.all(
      productInformations.map(async (productInformation) => {
        if (
          !productInformation.doughId &&
          !productInformation.fillingId &&
          !productInformation.materialId &&
          !productInformation.recipeId &&
          productInformation.productName
        ) {
          const message =
            '<!channel> 以下の原材料がユーザーから申請されました。原材料情報の登録を行なってください。\n 原材料名: ' +
            productInformation.productName +
            '\n 製造所名: ' +
            productInformation.manufacturerName +
            '\n\n以下の店舗・情報に紐づいています。原材料情報の修正も併せて行なってください。\n店舗名: ' +
            shopName +
            '\n ' +
            petternKey +
            ': ' +
            petternName +
            '\n'
          await slack.sendMessage(message)
        }
      }),
    )
  },
  internalCapacityApply: async (
    supplieries: SupplierType[],
    shopName: string,
    productName: string,
  ) => {
    await Promise.all(
      supplieries.map(async (supplier) => {
        if (
          !supplier.internal_capacity &&
          supplier.internal_capacity_manual_input
        ) {
          const message =
            '<!channel> 以下の内容量がユーザーから申請されました。内容量情報の登録を行なってください。\n 原材料名: ' +
            productName +
            '\n 内容量: ' +
            supplier.internal_capacity_manual_input +
            '\n\n以下の店舗・情報に紐づいています。内容量情報の修正も併せて行なってください。\n店舗名: ' +
            shopName +
            '\n '
          await slack.sendMessage(message)
        }
      }),
    )
  },
}

export default slack
