import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import MaterialTemplate from 'components/Templates/MaterialTemplate'

const Material: FC = () => {
  return (
    <Layout title={'原材料一覧'} page="Material">
      <MaterialTemplate />
    </Layout>
  )
}

export default Material
