import { FC } from 'react'
import LoginLayout from 'components/Layout/LoginLayout'
import SetPasswordTemplate from 'components/Templates/SetPasswordTemplate'

const SetPassword: FC = () => {
  return (
    <LoginLayout title={'パスワード設定'}>
      <SetPasswordTemplate />
    </LoginLayout>
  )
}

export default SetPassword
