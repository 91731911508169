import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import FillingOperationTemplate from 'components/Templates/FillingOperationTemplate'

const FillingOperation: FC = () => {
  return (
    <Layout title={'フィリングパターンを作成'} page="FillingOperation">
      <FillingOperationTemplate />
    </Layout>
  )
}

export default FillingOperation
