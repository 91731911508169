import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import MaterialDeleteConfirmTemplate from 'components/Templates/MaterialDeleteConfirmTemplate'

const Material: FC = () => {
  return (
    <Layout title={'原材料削除確認'} page="MaterialDeleteConfirm">
      <MaterialDeleteConfirmTemplate />
    </Layout>
  )
}

export default Material
