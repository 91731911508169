import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import AccountTemplate from 'components/Templates/AccountTemplate'

const Account: FC = () => {
  return (
    <Layout title={'ユーザー設定'} page="Account">
      <AccountTemplate />
    </Layout>
  )
}

export default Account
