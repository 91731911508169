import { FC } from 'react'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import { useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'

const PrivacyPolicyPageTemplate: FC = () => {
  const { uid } = useSelector((state: RootState) => state.authSlice)
  return (
    <div
      className={styles.container}
      style={!uid ? { marginLeft: 'auto', marginRight: 'auto' } : undefined}
    >
      {!!uid && <PageHedding title="プライバシーポリシー" to="/mypage" />}
      <div className={styles.terms_privacy_policy}>
        <div className={styles.terms_privacy_policy_title}>
          プライバシーポリシー
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`2023年3月1日制定
株式会社sacri
          
このプライバシーポリシー（以下「本プライバシーポリシー」といいます。）は、株式会社sacri（以下「当社」といいます。）が提供するサービスである「ダセルーノ利用者」（以下「本サービス」といいます。）の利用に関して、当社が取得する利用者（以下「利用者」といいます。）の情報の取り扱い方針を定めるものです。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          1. 定義等
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`本プライバシーポリシーにおいて、「個人情報」とは、個人情報の保護に関する法律第2条第1項により定義される個人情報を意味するものとします。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          2. 取得する情報及びその利用目的
        </div>
        <ol>
          <li>
            {`当社は、本サービスにおいて、以下の各号に定める情報（以下「利用者情報」といいます。）を、以下の各号に定める場合に、各号に定める目的で利用するために、利用者から取得し、または将来的に取得する可能性があります。また、利用者情報は「利用目的」欄に掲げる当社以外の第三者と共有されることがあります。
          `}
            <table>
              <tbody>
                <tr>
                  <th>利用者情報</th>
                  <th>取得時</th>
                  <th>利用目的</th>
                </tr>
                <tr>
                  <td>
                    利用者の氏名（法人の場合は法人名及び代表者ないし担当者の氏名）、郵便番号、住所（店舗所在地）、メールアドレス、電話番号等
                  </td>
                  <td>本サービス登録時、入力情報の入力時</td>
                  <td>
                    利用者に連絡するため、その他利用者の情報を当社が管理するため
                  </td>
                </tr>
                <tr>
                  <td>
                    IPアドレス、利用者ID、タイムスタンプ、その他利用者の本サービスへのアクセスに係る情報
                  </td>
                  <td>本サービスへのアクセス時</td>
                  <td>
                    利用者の設定の保存等利便性向上のため、セッションの維持・保護等のため、利用者の利用環境分析による本サービス改善のため、その他本サービスの不正利用の防止のため
                  </td>
                </tr>
                <tr>
                  <td>利用者名義のクレジットカード情報その他決済情報</td>
                  <td>クレジットカード情報の登録時</td>
                  <td>
                    本サービス利用料金の支払いのため。なお、クレジットカード情報はストライプジャパン株式会社の運営する決済代行サービス「Stripe」によって保持され、当社は取得しません。「Stripe」の利用規約およびプライバシーポリシーについては同社のウェブサイト（https://stripe.com/jp/legal
                    ）にてご確認ください。
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            {`当社は、本サービスの改良等を目的として本サービスの利用頻度等を測定するために、外部サービスであるGoogle Analyticsを利用し、デバイスのセッション情報、スクリーンの閲覧履歴、位置情報、イベントログ、属性データ等の匿名のトラフィックデータを収集しています。この行為により利用者個人が特定されることはありません。Google Analyticsの収集する情報およびその取扱いについては、同社のプライバシーポリシーおよび下記の「GOOGLE のサービスを使用するサイトやアプリから収集した情報の GOOGLE による使用」のページをご参照ください。
https://policies.google.com/technologies/partner-sites?hl=ja
          `}
          </li>
          <li>
            {`当社は、本サービスのエラーを検知するため、外部サービスである「Sentry」を利用しており、デバイス情報（端末・OSなど）、イベントログ、プログラムエラー情報等を収集しています。「Sentry」の収集する情報およびその取扱いについては、同サービスのウェブサイト（https://sentry.io/terms/）にてご確認ください。
          `}
          </li>
          <li>
            {`当社は、本サービスを開発するため、外部サービスである「Expo Go」を利用し、デバイス情報等を収集しています。「Expo Go」の収集する情報およびその取扱いについては、同サービスのウェブサイト（https://expo.dev/terms/）にてご確認ください。
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          3. 第三者への提供
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`当社は、利用者情報のうち個人情報に該当するものについて、以下の各号に定める場合を除いて、第三者に提供しません。
`}
        </div>
        <ol>
          <li>
            {`あらかじめ利用者の同意を得た場合
          `}
          </li>
          <li>
            {`法令に基づく場合
          `}
          </li>
          <li>
            {`人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
          `}
          </li>
          <li>
            {`公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
          `}
          </li>
          <li>
            {`国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
          `}
          </li>
          <li>
            {`事業譲渡等によって当社の事業の承継が行われる場合
          `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          4. 開示及び訂正等
        </div>
        <ol>
          <li>
            {`利用者は、当社に対して、個人情報保護法その他の法令等に基づき当社が保有する保有個人データ（個人情報保護法第２条第６項に定義する「保有個人データ」をいいます。以下同様とします）の開示を、当社が別途指定する手続きによって、請求することができます。ただし、次の各号の場合はこの限りではありません。
          `}
            <ol>
              <li>
                {`利用者本人が請求していることが確認できない場合
              `}
              </li>
              <li>
                {`利用者本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
              `}
              </li>
              <li>
                {`当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
              `}
              </li>
              <li>
                {`他の法令に違反することとなる場合
              `}
              </li>
            </ol>
          </li>
          <li>
            {`利用者は、当社に対して、個人情報保護法その他の法令等に基づき、当社が保有する当該利用者の保有個人データの内容が事実でない場合は、当社が別途指定する手続きによって、当該保有個人データの内容の訂正、追加又は削除（以下「訂正等」といいます。）を請求することができます。この場合、当社は当該保有個人データの利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、当該保有個人データの内容の訂正等を行います。
          `}
          </li>
          <li>
            {`利用者は、当社に対して、個人情報保護法その他の法令等に基づき、個人保有データが、利用目的の範囲を超えて取り扱われている場合、違法もしくは不当な行為を助長し、もしくは誘発するおそれがある方法により個人情報を利用している場合、不正の手段により取得されたものである場合、その他法令に定める場合、当社が別途指定する手続きによって、その利用の停止、消去または第三者への提供禁止（以下、「利用停止等」といいます。）を請求することができます。この場合、当社は、違反を是正するために必要な限度で、遅滞なく、当該保有個人データの利用停止等を行います。ただし、当該保有個人データの利用停止等に多額の費用を要する場合その他の利用停止等を行うことが困難な場合であって、本人の権利利益を保護するため必要なこれに代わるべき措置をとるときは、この限りではありません。
          `}
          </li>
          <li>
            {`利用者は、当社に対して、利用者自身が識別される保有個人データが個人情報保護法第２7条第１項（第三者提供の制限）又は第２8条（外国にある第三者への提供の制限）の規定に違反して第三者に提供されているときは、当社が別途指定する手続によって、当該保有個人データの第三者への提供の停止を請求することができます。当社は、当該請求を受けた場合であって、当該請求に理由があることが判明したときは、遅滞なく、当該保有個人データの第三者への提供を停止します。ただし、当該保有個人データの第三者への提供の停止に多額の費用を要する場合その他の第三者への提供を停止することが困難な場合であって、本人の権利利益を保護するため必要なこれに代わるべき措置をとるときは、この限りではありません。
          `}
          </li>
          <li>
            {`前４項に定める請求をする場合は、第８条に定めるお問い合せ窓口までご連絡ください。また、前4項の手続にあたって、当社は、利用者本人による請求であることを確認するために必要な限度で、利用者の身分証明にかかる書類等の写しの提出を求めることができます。
            `}
          </li>
        </ol>
        <div className={styles.terms_privacy_policy_content_title}>
          5. アカウント維持等に必要な個人情報について
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`メールアドレス等の本サービスにおける店舗アカウントの維持、本サービス利用に必須の個人情報について、利用者がその提供に同意しない場合は、本サービスを利用することはできません。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          6. 本サービスのアカウント削除
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`利用者が店舗アカウントを削除した場合は、当社は、利用規約及び本プライバシーポリシーに従って当該利用者の利用者情報を適切に処理いたします。

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          7. お問い合わせ窓口
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`本プライバシーポリシーに関するご質問、保有個人データの開示等の請求、苦情その他のお問い合せは、以下のメールアドレスまでお願い致します。
support@daseruno.zendesk.com

`}
        </div>
        <div className={styles.terms_privacy_policy_content_title}>
          8.個人情報取扱事業者
        </div>
        <div className={styles.terms_privacy_policy_text}>
          {`株式会社sacri
東京都千代田区神田錦町二丁目2番地1
KANDA SQUARE 11F We Work
代表取締役　大谷具史


`}
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyPageTemplate
