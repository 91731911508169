import {
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import type { User } from 'firebase/auth'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { auth } from 'fb/index'
import { setAuth } from 'reducks/auth/slice'
import { getStaff } from 'reducks/staff/slice'
import { getStoreById } from 'reducks/store/slice'
import { getShopSubscriptionById } from 'reducks/shopSubscriptions/slice'

export type UserType = User | null

export type AuthContextProps = {
  user: UserType
}

export type AuthProps = {
  children: ReactNode
}

const AuthContext = createContext<Partial<AuthContextProps>>({})

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }: AuthProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch<any>()
  const [user, setUser] = useState<UserType>(null)
  const isAvailableForViewing =
    location.pathname === '/about' ||
    location.pathname === '/login' ||
    location.pathname.indexOf('/password/reset') === 0 ||
    location.pathname.indexOf('/password/set') === 0 ||
    location.pathname.indexOf('/terms') === 0 ||
    location.pathname.indexOf('/privacy_policy') === 0 ||
    location.pathname.indexOf('/company') === 0 ||
    location.pathname.indexOf('/law') === 0
  const value = {
    user,
  }

  useEffect(() => {
    const authStateChanged = onAuthStateChanged(auth, async (user) => {
      setUser(user)
      if (user) {
        await dispatch(setAuth({ uid: user.uid }))
        const staff = await dispatch(getStaff({ uid: user.uid }))
        if (!staff) {
          signOut(auth).then(() => {
            navigate('/login')
            return
          })
        }
        const store = await dispatch(
          getStoreById({ storeId: staff.payload.storeId }),
        )
        const shopSubscription = await dispatch(
          getShopSubscriptionById({
            storeId: staff.payload.storeId,
          }),
        )
        // スーパー管理者じゃない
        if (staff.payload?.isAdmin === false) {
          if (!store.payload?.personal_phone_number) {
            // 電話番号未登録の場合
            if (
              location.pathname !== '/shop/register' &&
              location.pathname !== '/registerplan' &&
              !isAvailableForViewing
            ) {
              navigate('/shop/register')
            }
          } else if (
            !shopSubscription.payload?.stripe_daseruno_subscription_id &&
            !shopSubscription.payload
              ?.stripe_daseruno_subscription_schedule_id &&
            !shopSubscription.payload?.is_bill_payment
          ) {
            // プラン未登録の場合
            if (
              location.pathname !== '/shop/register' &&
              location.pathname !== '/registerplan' &&
              !isAvailableForViewing
            ) {
              navigate('/registerplan')
            }
          } else if (!shopSubscription.payload?.is_daseruno_standard_plan) {
            // ライトプランの場合
            if (
              location.pathname === '/bread/cost' ||
              location.pathname.indexOf('/material') !== -1
            ) {
              // 原材料配下ページや原価確認ページには訪れることができない
              navigate('/initload')
            }
          } else {
            // 店舗情報・プラン共にOK
          }
        }
      }
      !user && !isAvailableForViewing && navigate('/login')
    })
    return () => {
      authStateChanged()
    }
  })

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
