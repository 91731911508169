import { FC, ReactNode } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
// import styles from 'styles/components/layout.module.scss'
import ogp from 'images/ogp.jpg'

type Props = {
  children: ReactNode
  title: string
}

const LoginLayout: FC<Props> = (props) => {
  const { children, title } = props

  return (
    <>
      <HelmetProvider>
        <Helmet
          title={title + ' | ダセルーノ for sacri'}
          meta={[
            { name: 'description', content: 'ダセルーノ for sacri' },
            { property: 'og:title', content: 'ダセルーノ for sacri' },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: '/' },
            { property: 'og:image', content: ogp },
            { property: 'og:description', content: 'ダセルーノ for sacri' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:image', content: ogp },
            { name: 'robots', content: 'noindex nofollow' },
          ]}
        />
        <div>
          <main>
            <section>{children}</section>
          </main>
        </div>
      </HelmetProvider>
    </>
  )
}

export default LoginLayout
