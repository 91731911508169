import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/label.module.scss'
import InputText from 'components/Form/InputText'
import LightLabel from 'components/Label/LightLabel'

type Props = {
  labelName: string
  subLabelName?: string
  description: string
  line: number
  type: string
  size: string
  value: string
  placeholder: string
  unit: string
  readOnly?: boolean
  required?: boolean
  inputBoxRight?: boolean
  inputBoxCenter?: boolean
  isUnitEnd?: boolean
  errorText?: string | null
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onInput?: () => void
}

const InputTextAndUnitWithLightLabel: FC<Props> = (props) => {
  const {
    labelName,
    subLabelName,
    description,
    line,
    type,
    size,
    value,
    placeholder,
    unit,
    readOnly,
    required,
    inputBoxRight,
    inputBoxCenter,
    isUnitEnd,
    errorText,
    onChange,
    onInput,
  } = props

  const lightLabelInputContainerStyle = (() => {
    if (inputBoxRight) {
      return styles.lightLabelInputContainer_end
    }
    if (inputBoxCenter) {
      return styles.lightLabelInputContainer_center
    }
    if (isUnitEnd) {
      return styles.lightLabelInputContainer_vertical_end
    }
    return styles.lightLabelInputContainer
  })()

  return (
    <div>
      {subLabelName ? (
        <div className={styles.lightLabelInputContainer_between}>
          <LightLabel
            name={labelName}
            description={description}
            line={line}
            size={size}
            required={required}
          />
          <div className={styles.lightLabelInputContainer_margin_unit}>
            <LightLabel
              name={subLabelName}
              description={''}
              line={line}
              size={size}
            />
          </div>
        </div>
      ) : (
        <LightLabel
          name={labelName}
          description={description}
          line={line}
          size={size}
          required={required}
        />
      )}
      <div className={lightLabelInputContainerStyle}>
        <InputText
          required={required ? required : false}
          type={type}
          size={size}
          value={value}
          placeholder={placeholder}
          rightAligned={true}
          readOnly={readOnly}
          errorText={errorText}
          onChange={(e) => (onChange ? onChange(e) : null)}
          onInput={onInput}
        />
        <div
          className={
            isUnitEnd
              ? styles.inputAndUnitTextContainer_end
              : unit.match(/^[A-Za-z0-9]*$/) && unit.length === 1
              ? styles.inputAndUnitTextContainer_single_byte
              : styles.inputAndUnitTextContainer
          }
        >
          <span>{unit}</span>
        </div>
      </div>
    </div>
  )
}

export default InputTextAndUnitWithLightLabel
