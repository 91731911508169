import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import SubmitButton from 'components/Button/SubmitButton'
import Logo from 'components/Icons/Logo'
import RadioButton from 'components/Form/RadioButton'
import PlanColumn from 'components/Plan/PlanColumn'
import {
  Elements,
  useElements,
  useStripe,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'
import { getShopById } from 'reducks/shop/slice'
import { auth, functions } from 'fb/index'
import { setIsLoading } from 'reducks/loading/slice'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { CardSecurityCode } from 'images/CardSecurityCode'
import { getShopSubscriptionById } from 'reducks/shopSubscriptions/slice'
import { signOut } from 'firebase/auth'
import standardPlanTitle from 'images/standard-plan-title.png'
import paymentImage from 'images/payment-image.png'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
    'pk_test_1jzDEjKdh7nGxRYba6xsRfy600Hi4teGle',
)

const RegisterPlanTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const location = useLocation()
  const path = location.pathname
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const [plan, setPlan] = useState<
    'liteMonth' | 'liteYear' | 'standardMonth' | 'standardYear'
  >('standardMonth')
  const [email, setEmail] = useState('')
  const [cardData, setCardData] = useState('')
  const [isDone, setIsDone] = useState(false)
  const [failedMsg, setFailedMsg] = useState('')
  const [isModal, setIsModal] = useState(false)
  const navigate = useNavigate()
  const { staff } = useSelector((state: RootState) => state.staffSlice)
  const dispatchGetShop = useCallback(
    async (shopId: string) => {
      const token = await auth.currentUser?.getIdToken()
      const shop = await dispatch(getShopById({ id: shopId }))
      // カード情報取得
      if (shop.payload.stripe_payment_method_id) {
        axios
          .post(
            `${process.env.REACT_APP_FUNCTIONS_URL}/apiRetrieveCard`,
            {
              payment_method_id: shop.payload.stripe_payment_method_id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
              },
            },
          )
          .then((result) => {
            if (
              !!result.data.card.last4 &&
              !!result.data.card.exp_year &&
              !!shop.payload.stripe_payment_method_id &&
              !!shop.payload.stripe_customer_id
            ) {
              setCardData(
                `**** **** **** ${result.data.card.last4}|${result.data.card.exp_year}/${result.data.card.exp_month}|${shop.payload.stripe_payment_method_id}|${shop.payload.stripe_customer_id}`,
              )
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            dispatch(setIsLoading({ isLoading: false }))
          })
      } else {
        dispatch(setIsLoading({ isLoading: false }))
      }
    },
    [dispatch],
  )
  const apiCreateStripeSubscriptionDaseruno = useCallback(
    async (
      email: string,
      paymentMethodId: string,
      plan: string,
      paymentCustomerId: string,
      shopId?: string | null,
      storeId?: string | null,
    ) => {
      await dispatch(setIsLoading({ isLoading: true }))
      /** Subscription作成(既存カード) */
      // デバッグモードの場合は即時課金される
      const apiCreateStripeSubscriptionDaseruno =
        process.env.REACT_APP_NODE_ENV === 'development' && query.get('debug')
          ? httpsCallable(functions, 'apiCreateStripeSubscriptionDaserunoDebug')
          : httpsCallable(functions, 'apiCreateStripeSubscriptionDaseruno')
      apiCreateStripeSubscriptionDaseruno({
        email,
        paymentMethodId,
        plan,
        shopId,
        storeId,
        paymentCustomerId,
      })
        .then(async (result: HttpsCallableResult<any>) => {
          console.log(result.data)
          setIsDone(true)
        })
        .catch((error) => {
          setFailedMsg(error.message)
          console.log(error.message)
        })
        .finally(() => dispatch(setIsLoading({ isLoading: false })))
    },
    [dispatch],
  )
  const checkIsRegister = useCallback(
    async (storeId: string | null, isAdmin: boolean) => {
      if (!storeId) return
      const shopSubscription = await dispatch(
        getShopSubscriptionById({
          storeId: storeId,
        }),
      )
      if (
        !!shopSubscription.payload?.stripe_daseruno_subscription_id ||
        !!shopSubscription.payload?.stripe_daseruno_subscription_schedule_id ||
        isAdmin
      ) {
        navigate('/')
      }
    },
    [dispatch, navigate],
  )
  const handleSignOut = useCallback(() => {
    signOut(auth).then(() => {
      navigate('/login')
    })
  }, [navigate])
  useEffect(() => {
    if (!staff) {
      const addPath = query.get('debug')
        ? path + '?debug=' + query.get('debug')
        : path
      navigate('/initload?path=' + addPath)
      window.scrollTo(0, 0)
      return
    }
    if (staff?.shopId) {
      checkIsRegister(staff.storeId, staff.isAdmin) // 登録済みの場合はリダイレクト
      dispatch(setIsLoading({ isLoading: true }))
      dispatchGetShop(staff.shopId)
      setEmail(staff.email)
    }
  }, [staff])
  return (
    <div className={styles.registerplan_container}>
      <div className={styles.login_logo}>
        <Logo width={300} />
      </div>
      <p className={styles.registerplan_section_title}>お支払い方法登録</p>
      <div
        className={styles.registerplan_plan_box}
        style={{ borderColor: '#fff', width: 978 }}
      >
        <div className={styles.registerplan_standard_plan}>
          <img
            className={styles.registerplan_standard_plan_title_image}
            src={standardPlanTitle}
            alt="スタンダードプラン"
          />
          <p className={styles.registerplan_standard_plan_title}>
            スタンダードプラン
          </p>
        </div>

        <div className={styles.registerplan_standard_plan_section_header}>
          原価 & 売価管理
        </div>
        <div className={styles.registerplan_standard_plan_flex}>
          <div className={styles.registerplan_standard_plan_flex_item}>
            <PlanColumn mainText="商品別原価管理機能" />
          </div>
          <div className={styles.registerplan_standard_plan_flex_item}>
            <PlanColumn mainText="売価サジェスト機能" />
          </div>
          <div className={styles.registerplan_standard_plan_flex_item}>
            <PlanColumn
              mainText="請求書取り込み代行"
              subText="(無料キャンペーン中)"
              color="red"
            />
          </div>
        </div>

        <div className={styles.registerplan_standard_plan_section_header}>
          栄養成分表示算出
        </div>
        <div className={styles.registerplan_standard_plan_flex}>
          <div className={styles.registerplan_standard_plan_flex_item}>
            <PlanColumn mainText="レシピ管理機能" />
          </div>
          <div className={styles.registerplan_standard_plan_flex_item}>
            <PlanColumn
              mainText="レシピ登録代行"
              subText="(無料キャンペーン中)"
              color="red"
            />
          </div>
          <div className={styles.registerplan_standard_plan_flex_item}>
            <PlanColumn mainText="栄養成分値算出機能" />
          </div>
          <div className={styles.registerplan_standard_plan_flex_item}>
            <PlanColumn mainText="裏貼シール出力機能" />
          </div>
        </div>
        <div className={styles.registerplan_standard_plan_price}>
          <span className={styles.registerplan_standard_plan_price_text_medium}>
            月額
          </span>
          <span className={styles.registerplan_standard_plan_price_text_large}>
            18,000
          </span>
          <span className={styles.registerplan_standard_plan_price_text_medium}>
            円（税抜）
          </span>
          <div className={styles.registerplan_standard_plan_price_border}></div>
        </div>
        <p className={styles.registerplan_standard_plan}>
          （税込価格 19,800円）
        </p>
        <p
          className={styles.registerplan_standard_plan}
          style={{ color: '#ED7C7C' }}
        >
          ※契約期間は最低1年間となります。
        </p>
      </div>
      <div className={styles.registerplan_standard_plan_payment}>
        <img
          className={styles.registerplan_standard_plan_payment_image}
          src={paymentImage}
          alt="支払い"
        />
      </div>
      {process.env.REACT_APP_NODE_ENV === 'development' &&
      query.get('debug') ? (
        <div style={{ display: 'flex', marginTop: 80 }}>
          <div
            className={styles.registerplan_plan_box_container}
            onClick={() => {
              setPlan('liteMonth')
            }}
          >
            <div
              className={styles.registerplan_plan_box}
              style={{ borderColor: plan === 'liteMonth' ? '#F4A460' : '#fff' }}
            >
              <p className={styles.registerplan_plan_box_head}>
                月々ライトプラン
              </p>
              <p
                className={styles.registerplan_plan_box_text}
                style={{ marginTop: 16 }}
              >
                年合計：117,600円（税込）
              </p>
              <p
                className={styles.registerplan_plan_box_text}
                style={{
                  fontSize: 60,
                  fontWeight: 'bold',
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                9,800<span style={{ fontSize: 20 }}>円/月（税込）</span>
              </p>
              <p className={styles.registerplan_plan_box_text}>
                月々で分割払いしたい方にオススメ！
              </p>
            </div>
            <RadioButton
              style={{ marginTop: 24 }}
              isChecked={plan === 'liteMonth'}
              label=""
              onChange={() => {
                setPlan('liteMonth')
              }}
            />
          </div>
          <div
            className={styles.registerplan_plan_box_container}
            style={{ marginLeft: 40 }}
            onClick={() => {
              setPlan('liteYear')
            }}
          >
            <div
              className={styles.registerplan_plan_box}
              style={{ borderColor: plan === 'liteYear' ? '#F4A460' : '#fff' }}
            >
              <p className={styles.registerplan_plan_box_head}>
                年割ライトプラン
              </p>
              <p
                className={styles.registerplan_plan_box_text}
                style={{ marginTop: 16 }}
              >
                一括：107,760円（税込）
              </p>
              <p
                className={styles.registerplan_plan_box_text}
                style={{
                  fontSize: 60,
                  fontWeight: 'bold',
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                8,980<span style={{ fontSize: 20 }}>円/月（税込）</span>
              </p>
              <p
                className={styles.registerplan_plan_box_text}
                style={{ color: '#ED7C7C' }}
              >
                ※12ヶ月分をまとめてのお支払いとなります。
              </p>
              <div className={styles.registerplan_plan_box_circle}>
                1ヶ月分
                <br />
                お得！
              </div>
            </div>
            <RadioButton
              style={{ marginTop: 24 }}
              isChecked={plan === 'liteYear'}
              label=""
              onChange={() => {
                setPlan('liteYear')
              }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {!cardData ? (
        <>
          <p className={styles.registerplan_section_title}>お支払い方法登録</p>
          <p className={styles.registerplan_section_subtitle}>
            月額基本利用料のお支払いのためのクレジットカード情報をご入力ください。
            <br />
            入力が終わりましたら、「登録する」ボタンを押してください。
          </p>
          <Elements stripe={stripePromise}>
            <PaymentForm
              plan={plan}
              email={email}
              storeId={staff?.storeId}
              shopId={staff?.shopId}
              setIsDone={setIsDone}
              setFailedMsg={setFailedMsg}
              setIsModal={setIsModal}
              handleSignOut={handleSignOut}
            />
          </Elements>
        </>
      ) : (
        <>
          <p className={styles.registerplan_section_title}>お支払い方法</p>
          <div>
            <div className={styles.registerplan_plan_box_input_label}>
              カード情報
            </div>
            <div
              className={styles.registerplan_plan_box_input}
              style={{ width: 600, display: 'flex', color: 'gray' }}
            >
              <div>{cardData.split('|')[0]}</div>
              <div style={{ marginLeft: 'auto' }}>{cardData.split('|')[1]}</div>
            </div>
          </div>
          <div
            className={styles.login_button_container}
            style={{ marginTop: 64 }}
          >
            <SubmitButton
              label="登録する"
              color="orange"
              size="semilarge"
              icon="none"
              onClick={() =>
                apiCreateStripeSubscriptionDaseruno(
                  email,
                  cardData.split('|')[2],
                  plan,
                  cardData.split('|')[3],
                  staff?.shopId,
                  staff?.storeId,
                )
              }
            />
          </div>
          <div
            className={styles.login_button_container}
            style={{ marginTop: 16 }}
          >
            <SubmitButton
              label="ログアウト"
              color="orange_outlined"
              size="semilarge"
              icon="none"
              onClick={handleSignOut}
            />
          </div>
        </>
      )}
      {isDone && (
        <div className={styles.modal_account_front}>
          <div className={styles.modal_account_text}>
            プランの登録が完了しました
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="トップへ進む"
              color="orange"
              size="xmiddle"
              icon="none"
              onClick={() => {
                navigate('/')
                window.scrollTo(0, 0)
              }}
            />
          </div>
        </div>
      )}
      {!!failedMsg && (
        <div className={styles.modal_account}>
          <div
            className={styles.modal_account_text}
            style={{ color: '#ED7C7C' }}
          >
            プランの登録に失敗しました
          </div>
          <div
            className={styles.modal_account_sub_text}
            style={{ color: '#ED7C7C' }}
          >
            {failedMsg}
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="戻る"
              color="white"
              size="xmiddle"
              icon="none"
              onClick={() => {
                setFailedMsg('')
                window.location.reload()
              }}
            />
          </div>
        </div>
      )}
      {isModal && <CardCvcModal setIsModal={setIsModal} />}
    </div>
  )
}

type PaymentFormProps = {
  plan: 'liteMonth' | 'liteYear' | 'standardMonth' | 'standardYear'
  email: string
  storeId?: string | null
  shopId?: string | null
  setIsDone: (e: boolean) => void
  setFailedMsg: (e: string) => void
  setIsModal: (e: boolean) => void
  handleSignOut: () => void
}
const PaymentForm: FC<PaymentFormProps> = ({
  plan,
  email,
  storeId,
  shopId,
  setIsDone,
  setFailedMsg,
  setIsModal,
  handleSignOut,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch<any>()
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const handleSubmitPayment = async () => {
    if (!stripe || !elements || !storeId) return

    const cardNumberElement = elements.getElement(CardNumberElement)

    if (!cardNumberElement) return

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    })

    if (error) {
      console.log('[error]', error)
    } else {
      dispatch(setIsLoading({ isLoading: true }))
      /** Subscription作成(新規カード) */
      const apiCreateStripeSubscriptionDaseruno =
        process.env.REACT_APP_NODE_ENV === 'development' && query.get('debug')
          ? httpsCallable(functions, 'apiCreateStripeSubscriptionDaserunoDebug')
          : httpsCallable(functions, 'apiCreateStripeSubscriptionDaseruno')
      apiCreateStripeSubscriptionDaseruno({
        email,
        paymentMethodId: paymentMethod.id,
        plan,
        shopId,
        storeId,
        isStandard: plan === 'standardMonth',
      })
        .then((result: any) => {
          console.log(result.data)
          setIsDone(true)
        })
        .catch((error) => {
          console.log(error)
          setFailedMsg(error.message)
        })
        .finally(() => dispatch(setIsLoading({ isLoading: false })))
    }
  }
  return (
    <>
      <div>
        <div className={styles.registerplan_plan_box_input_label}>
          カード番号
        </div>
        <div
          className={styles.registerplan_plan_box_input}
          style={{ width: 600 }}
        >
          <CardNumberElement />
        </div>
      </div>
      <div style={{ display: 'flex', width: 600, marginTop: 32 }}>
        <div>
          <div className={styles.registerplan_plan_box_input_label}>
            有効期限
          </div>
          <div
            className={styles.registerplan_plan_box_input}
            style={{ width: 290 }}
          >
            <CardExpiryElement />
          </div>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <div className={styles.registerplan_plan_box_input_label}>
            セキュリティコード
          </div>
          <div
            className={styles.registerplan_plan_box_input}
            style={{ width: 290 }}
          >
            <CardCvcElement />
          </div>

          <div className={styles.login_link_container}>
            <div
              className={styles.login_link_container_text}
              onClick={() => setIsModal(true)}
            >
              <p style={{ fontSize: 12 }}>セキュリティコードとは</p>
            </div>
          </div>
        </div>
      </div>
      <p
        className={styles.registerplan_section_subtitle}
        style={{ marginTop: 64 }}
      >
        マイページ内「
        <span style={{ fontWeight: 'bold' }}>ご利用プランの管理</span>
        」からお支払い方法の変更や領収書の発行ができます。
      </p>
      <div className={styles.login_button_container} style={{ marginTop: 64 }}>
        <SubmitButton
          label="登録する"
          color="orange"
          size="semilarge"
          icon="none"
          onClick={handleSubmitPayment}
        />
      </div>
      <div className={styles.login_button_container} style={{ marginTop: 16 }}>
        <SubmitButton
          label="ログアウト"
          color="orange_outlined"
          size="semilarge"
          icon="none"
          onClick={handleSignOut}
        />
      </div>
    </>
  )
}

type CardCvcModalProps = {
  setIsModal: (e: boolean) => void
}
export const CardCvcModal: FC<CardCvcModalProps> = ({ setIsModal }) => {
  return (
    <div className={styles.modal_card_security_code_account}>
      <CardSecurityCode width={400} />
      <div className={styles.modal_card_security_code_account_text}>
        ※ JCB・Visa・Mastercardの場合、
        <span style={{ color: '#F3616F' }}>カードの裏面に3桁の数字</span>
        でセキュリティコードが記載されています。裏面署名欄の右上をご確認ください。
      </div>
      <div className={styles.modal_card_security_code_account_text}>
        ※ American Expressの場合、
        <span style={{ color: '#F3616F' }}>カードの表面に4桁の数字</span>
        で記載されています。主にカード番号の右上に印刷されています。
      </div>
      <div className={styles.modalButtonContainer}>
        <SubmitButton
          label="閉じる"
          color="orange"
          size="xmiddle"
          icon="none"
          onClick={() => setIsModal(false)}
        />
      </div>
    </div>
  )
}

export default RegisterPlanTemplate
