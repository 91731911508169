import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import format from 'date-fns/format'
import { db, storage } from 'fb/index'
import {
  collection,
  query,
  where,
  getDocs,
  DocumentReference,
  DocumentData,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
  and,
  or,
} from 'firebase/firestore'
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'

interface StaffState {
  staff: Staff | null
  staffs: StaffType[] | null
  adminShopId: string | null
  adminStoreId: string | null
}
export interface Staff {
  email: string
  isAdmin: boolean
  isStandardPlan: boolean
  mails: Mail[]
  roles: Role[]
  ds_roles: Role[]
  uid: string
  imageUrl: string | null
  shopId: string | null
  shopName: string | null
  shopAddress: string | null
  responsiblePersion: string | null
  storeId: string | null
  storeName: string | null
  storeAddress: string | null
  manufacturerAddress: string | null
  storePhoneNumber: string | null
  invitation_expired_at?: Timestamp
  isDeleted: boolean | null
}
export interface StaffType {
  email: string
  invitation_expired_at?: Timestamp
  is_admin: boolean
  roles: Role[]
  ds_roles: Role[]
  mails: Mail[]
  uid: string
}
interface Mail {
  store_ref: DocumentReference
}
interface Role {
  role: number
  store_ref: DocumentReference
}

const initialState = {
  staff: null,
} as StaffState

export const getStaff = createAsyncThunk<
  Staff | null,
  { uid: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('staff/getStaff', async ({ uid }, thunkApi) => {
  const { adminShopId, adminStoreId } = thunkApi.getState().staffSlice
  const q = query(collection(db, 'staffs'), where('uid', '==', uid))
  const querySnapshot = await getDocs(q)
  // 最初の1件のみ取得すればよい想定
  const staff = querySnapshot.docs[0].data()
  let isStandardPlan = false
  let shopId = null
  let shopName = null
  let shopAddress = null
  let responsiblePersion = null
  let storeId = null
  let storeName = null
  let storeAddress = null
  let manufacturerAddress = null
  let storePhoneNumber = null
  let personalPhoneNumber = null
  let email = staff.email
  let roles = staff.roles
  let dsRoles = staff.ds_roles
  let invitationExpiredAt = null
  let isDeleted = staff.is_daseruno_deleted
  if (!staff) {
    return null
  }
  if (!staff.is_admin) {
    for (const role of staff.roles as Role[]) {
      // TODO: リロード時(初回読み込み時)にFirestore参照型からidが取得できないための対応。根本原因を調査する。
      storeId = role.store_ref.id
        ? role.store_ref.id
        : // @ts-ignore
          role.store_ref._key.path.segments[6]
      if (storeId) {
        const storeDocRef = doc(db, 'stores', storeId)
        const storeDocSnap = await getDoc(storeDocRef)
        const store = storeDocSnap.data()
        const shopSubscriptionQuery = query(
          collection(db, 'shop_subscriptions'),
          and(
            where('store_ref', '==', storeDocRef),
            or(
              where('stripe_daseruno_subscription_id', '!=', ''),
              where('is_daseruno_standard_plan', '==', true),
            ),
          ),
        )
        const shopSubscriptionQuerySnapshot = await getDocs(
          shopSubscriptionQuery,
        )
        if (shopSubscriptionQuerySnapshot.docs.length > 0) {
          const shopSubscription = shopSubscriptionQuerySnapshot.docs[0].data()
          isStandardPlan = shopSubscription.is_daseruno_standard_plan
            ? true
            : false
        }
        if (store) {
          storeName = store.name
          storeAddress =
            store.address.state +
            store.address.city +
            store.address.street +
            store.address.building
          if (store.factory_address) {
            manufacturerAddress =
              store.factory_address.state +
              store.factory_address.city +
              store.factory_address.street +
              store.factory_address.building
          }
          storePhoneNumber = store.phone_number
          personalPhoneNumber = store.personal_phone_number
          shopId = store.shop_ref.id
            ? store.shop_ref.id
            : // @ts-ignore
              store.shop_ref._key.path.segments[6]
          const shopDocRef = doc(db, 'shops', shopId)
          const shopDocSnap = await getDoc(shopDocRef)
          const shop = shopDocSnap.data()
          if (shop) {
            shopName = shop.name
            shopAddress = shop.contact
            responsiblePersion = shop.responsible_person
            break
          }
        }
      }
    }
  } else {
    if (adminShopId) {
      const shopDocRef = doc(db, 'shops', adminShopId)
      const shopDocSnap = await getDoc(shopDocRef)
      const shop = shopDocSnap.data()
      if (shop) {
        shopId = adminShopId
        shopName = shop.name
        shopAddress = shop.contact
        responsiblePersion = shop.responsible_person
        let store = null
        if (adminStoreId) {
          storeId = adminStoreId
          const storeDocRef = doc(db, 'stores', storeId)
          const storeDocSnap = await getDoc(storeDocRef)
          store = storeDocSnap.data()
        } else {
          const q = query(
            collection(db, 'stores'),
            where('shop_ref', '==', doc(db, 'shops', adminShopId)),
            where('status', '!=', 'deleted'),
          )
          const querySnapshot = await getDocs(q)
          // 1データのみ紐づいているはず
          store = querySnapshot.docs[0].data()
          storeId = querySnapshot.docs[0].id
        }
        if (store) {
          storeName = store.name
          storeAddress =
            store.address.state +
            store.address.city +
            store.address.street +
            store.address.building
          if (store.factory_address) {
            manufacturerAddress =
              store.factory_address.state +
              store.factory_address.city +
              store.factory_address.street +
              store.factory_address.building
          }
          storePhoneNumber = store.phone_number
          personalPhoneNumber = store.personal_phone_number
        }
        if (storeId) {
          const shopSubscriptionQuery = query(
            collection(db, 'shop_subscriptions'),
            and(
              where('store_ref', '==', doc(db, 'stores', storeId)),
              or(
                where('stripe_daseruno_subscription_id', '!=', ''),
                where('is_daseruno_standard_plan', '==', true),
              ),
            ),
          )
          const shopSubscriptionQuerySnapshot = await getDocs(
            shopSubscriptionQuery,
          )
          if (shopSubscriptionQuerySnapshot.docs.length > 0) {
            const shopSubscription =
              shopSubscriptionQuerySnapshot.docs[0].data()
            isStandardPlan = shopSubscription.is_daseruno_standard_plan
              ? true
              : false
          }
          const q = query(
            collection(db, 'staffs'),
            where('roles', 'array-contains', {
              role: 9,
              store_ref: doc(db, 'stores', storeId),
            }),
          )
          const querySnapshot = await getDocs(q)
          if (querySnapshot.docs.length > 0) {
            const selectedStaff = querySnapshot.docs[0].data()
            email = selectedStaff.email
            roles = selectedStaff.roles
            invitationExpiredAt = selectedStaff.invitation_expired_at
          } else {
            // データがない場合は別権限で取得し直す
            const q = query(
              collection(db, 'staffs'),
              where('roles', 'array-contains', {
                role: 5,
                store_ref: doc(db, 'stores', storeId),
              }),
            )
            const querySnapshot = await getDocs(q)
            // ここで取得できなければ店舗に紐づくアカウントがない
            if (querySnapshot.docs.length > 0) {
              const selectedStaff = querySnapshot.docs[0].data()
              email = selectedStaff.email
              roles = selectedStaff.roles
              invitationExpiredAt = selectedStaff.invitation_expired_at
            }
          }
        }
      }
    }
  }
  return {
    email: email,
    isAdmin: staff.is_admin,
    isStandardPlan: isStandardPlan,
    mails: staff.mails,
    roles: roles,
    ds_roles: dsRoles,
    uid: staff.uid,
    imageUrl: staff.image_url,
    shopId: shopId,
    shopName: shopName,
    shopAddress: shopAddress,
    storeId: storeId,
    storeName: storeName,
    storeAddress: storeAddress,
    responsiblePersion: responsiblePersion,
    manufacturerAddress: manufacturerAddress,
    storePhoneNumber: storePhoneNumber,
    personalPhoneNumber: personalPhoneNumber,
    invitationExpiredAt: invitationExpiredAt,
    isDeleted: isDeleted,
  }
})

export const getStaffById = createAsyncThunk<Staff | null, { id: string }>(
  'staff/getStaffById',
  async ({ id }) => {
    const query = doc(db, 'staffs', id)
    const querySnapshot = await getDoc(query)
    const staff = querySnapshot.data()
    if (!staff) {
      return null
    }
    // このメソッドはstaffコレクションの中身を確かめる用途のため、それ以外の項目はnullとして返す
    const response: Staff = {
      email: staff.email,
      isAdmin: staff.is_admin,
      isStandardPlan: false,
      mails: staff.mails,
      roles: staff.roles,
      ds_roles: staff.ds_roles,
      uid: staff.uid,
      imageUrl: staff.image_url,
      shopId: null,
      shopName: null,
      shopAddress: null,
      responsiblePersion: null,
      storeId: null,
      storeName: null,
      storeAddress: null,
      manufacturerAddress: null,
      storePhoneNumber: null,
      isDeleted: staff.is_daseruno_deleted,
    }
    return response
  },
)

export const getStaffs = createAsyncThunk<
  StaffType[] | null,
  { uid: string; storeId: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('staff/getStaffs', async ({ uid, storeId }) => {
  const staffs: StaffType[] = []
  const storeRef = doc(db, 'stores', storeId)
  const staffsQuery = query(collection(db, 'staffs'))
  const staffsQuerySnapshot = await getDocs(staffsQuery)
  await Promise.all(
    staffsQuerySnapshot.docs.map(async (doc) => {
      const data = doc.data()
      const {
        email,
        invitation_expired_at,
        is_admin,
        roles,
        mails,
        uid,
        ds_roles,
      } = data
      // スーパー管理者は除外
      if (data.is_admin) return
      // 削除フラグがあるものは除外
      if (data.is_daseruno_deleted) return

      if (data.roles.some((role: Role) => storeRef.id === role.store_ref.id)) {
        staffs.push({
          email,
          invitation_expired_at,
          is_admin,
          roles,
          mails,
          uid,
          ds_roles,
        })
      }
    }),
  )
  // 操作中のユーザーを配列の先頭にする
  const sortedStaffs = staffs.reduce((prev: StaffType[], staff: StaffType) => {
    if (staff.uid === uid) {
      return [staff, ...prev]
    } else {
      return [...prev, staff]
    }
  }, [])
  return sortedStaffs
})

export const uploadStaffImage = createAsyncThunk<
  string | null,
  {
    image: File
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('staff/uploadStaffImage', async ({ image }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff) {
    return null
  }
  const fileName = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
  const fileExtension = image.type.split('/')[1]
  const storageRef = ref(
    storage,
    `staffs/${staff.uid}/${fileName}.${fileExtension}`,
  )
  await uploadBytesResumable(storageRef, image)
  const downloadUrl = await getDownloadURL(storageRef)
  return downloadUrl
})

export const removeStaffImage = createAsyncThunk<
  void,
  {
    image_url: string
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('staff/removeStaffImage', async ({ image_url }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff) {
    return
  }
  const decodeUrl = decodeURIComponent(image_url)
  const refFileNameMatch = decodeUrl.match('.+/(.+?)([\\?#;].*)?$')
  if (!refFileNameMatch) {
    return
  }
  const refFileName = refFileNameMatch[1]
  const storageRef = ref(storage, `staffs/${staff.uid}/${refFileName}`)
  await deleteObject(storageRef)
})

export const updateImageUrl = createAsyncThunk<
  void,
  {
    image_url: string
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('staff/updateImageUrl', async ({ image_url }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff) {
    return
  }
  const docRef = doc(db, 'staffs', staff.uid)
  await updateDoc(docRef, {
    image_url: image_url,
  })
})

export const deleteStaff = createAsyncThunk<
  void,
  {
    uid: string
  }
>('staff/deleteStaff', async ({ uid }) => {
  const docRef = doc(db, 'staffs', uid)
  await updateDoc(docRef, {
    is_daseruno_deleted: true,
  })
})

export const getMailsEmptyStaffs = createAsyncThunk<DocumentData[], void>(
  'staff/getMailsEmptyStaffs',
  async (_) => {
    const q = query(collection(db, 'staffs'))
    const querySnapshot = await getDocs(q)
    const staffs: DocumentData[] = []
    console.log('start')
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const staff = doc.data()
        if (!staff.mails) {
          console.log(staff)
          staffs.push(staff)
        }
      }),
    )
    console.log('end')
    return staffs
  },
)

// export const getOwnShopId = createAsyncThunk<
//   string | null,
//   void,
//   {
//     dispatch: AppDispatch
//     state: RootState
//   }
// >(
//   'staff/getOwnStoreId',
//   async (_, thunkApi) => {
//     const { staff } = thunkApi.getState().staffSlice
//     let shopId = null
//     if (staff) {
//       await Promise.all(
//         staff.roles.map(async (role) => {
//           // TODO: リロード時(初回読み込み時)にFirestore参照型からidが取得できないための対応。根本原因を調査する。
//           const storeId = role.store_ref.id
//           ? role.store_ref.id
//           : // @ts-ignore
//             role.store_ref._key.path.segments[6]
//           if (storeId) {
//             const docRef = doc(db, 'stores', storeId)
//             const storeDocSnap = await getDoc(docRef)
//             const store = storeDocSnap.data()
//             if (store) {
//               // TODO
//               shopId = store.shop_ref.id
//                 ? store.shop_ref.id
//                 : // @ts-ignore
//                   store.shop_ref._key.path.segments[6]
//             }
//           }
//         }),
//       )
//     }
//     return shopId
//   },
// )

export const staffSlice: Slice<
  StaffState,
  {
    setAdminShopId(state: StaffState, action: any): void
    setAdminStoreId(state: StaffState, action: any): void
  },
  'staff'
> = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setAdminShopId(state, action) {
      state.adminShopId = action.payload
    },
    setAdminStoreId(state, action) {
      state.adminStoreId = action.payload
    },
  },
  extraReducers: {
    [getStaff.pending.type]: () => {},
    [getStaff.fulfilled.type]: (state, action: PayloadAction<Staff>) => {
      state.staff = action.payload
    },
    [getStaff.rejected.type]: () => {},
    [getStaffs.pending.type]: () => {},
    [getStaffs.fulfilled.type]: (state, action: PayloadAction<Staff>) => {
      state.staff = action.payload
    },
    [getStaffs.rejected.type]: () => {},
  },
})

export const { setAdminShopId, setAdminStoreId } = staffSlice.actions
export default staffSlice.reducer
