import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import LawPageTemplate from 'components/Templates/LawTemplate'
import { useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'

const LawPage: FC = () => {
  const { uid } = useSelector((state: RootState) => state.authSlice)
  return !!uid ? (
    <Layout title={'特定商取引法に基づく表記'} page="LawPage">
      <LawPageTemplate />
    </Layout>
  ) : (
    <LawPageTemplate />
  )
}

export default LawPage
