import { FC, MouseEvent } from 'react'
import styles from 'styles/components/button.module.scss'
import buttonPlusIcon from 'images/Button/plus-icon.png'
import buttonOutlinePlusIcon from 'images/Button/outline-plus-icon.png'
import buttonEditIcon from 'images/Button/edit-icon.png'
import buttonDeleteIcon from 'images/Button/delete-icon.png'
import buttonCopyIcon from 'images/Button/copy-icon.png'
import editIcon from 'images/edit-icon.png'
import deleteIcon from 'images/delete-icon.png'
import copyIcon from 'images/copy-icon.png'
import costInputIcon from 'images/cost-input-icon.png'
import webIcon from 'images/web-icon.png'
import nutritionFactsIcon from 'images/nutrition-facts-icon.png'
import packageIcon from 'images/package-icon.png'
import standardsDocIcon from 'images/standards-doc-icon.png'
import deleteOrangeIcon from 'images/delete-orange-icon.png'
import visibleIcon from 'images/visible-icon.png'
import invisibleIcon from 'images/invisible-icon.png'
import sortAscIcon from 'images/sort-asc-icon.png'
import sortDescIcon from 'images/sort-desc-icon.png'

type Props = {
  label: string
  color: string
  size: string
  icon: string
  textPosition?: string
  onClick: (
    event?:
      | MouseEvent<HTMLButtonElement, MouseEvent>
      | MouseEvent<HTMLButtonElement>
      | undefined,
  ) => void
}

const SubmitButton: FC<Props> = (props) => {
  const { label, color, size, icon, textPosition, onClick } = props

  const sizeStyle = (() => {
    switch (size) {
      case 'icon':
        return styles.button_icon
      case 'icon_unclickable':
        return styles.button_icon_unclickable
      case 'small':
        return styles.button_small
      case 'xsmall':
        return styles.button_xsmall
      case 'middle':
        return styles.button_middle
      case 'xmiddle':
        return styles.button_xmiddle
      case 'semilarge':
        return styles.button_semilarge
      case 'large':
        return styles.button_large
      case 'xlarge':
        return styles.button_xlarge
      case 'rectangle_small':
        return styles.button_rectangle_small
      case 'rectangle_middle':
        return styles.button_rectangle_middle
      default:
        return styles.button_large
    }
  })()

  const colorStyle = (() => {
    switch (color) {
      case 'orange':
        return styles.button_orange
      case 'orange_outlined':
        return styles.button_orange_outlined
      case 'gray':
        return styles.button_gray
      case 'light_gray':
        return styles.button_light_gray
      case 'white':
        return styles.button_white
      case 'white2':
        return styles.button_white2
      case 'none':
        return styles.button_none
      default:
        return styles.button_orange
    }
  })()

  const baseStyle = (() => {
    return !icon || icon === 'none'
      ? styles.button
      : label
      ? styles.button_with_icon
      : styles.button
  })()

  const iconImage = (() => {
    switch (icon) {
      case 'plus':
        return (
          <img
            className={styles.button_xlarge_image}
            src={buttonPlusIcon}
            alt="+"
          ></img>
        )
      case 'outline_plus':
        return (
          <img
            className={styles.button_small_image}
            src={buttonOutlinePlusIcon}
            alt="+"
          ></img>
        )
      case 'edit':
        return (
          <img
            className={styles.button_large_image}
            src={buttonEditIcon}
            alt="edit"
          ></img>
        )
      case 'delete':
        return (
          <img
            className={styles.button_large_image}
            src={buttonDeleteIcon}
            alt="delete"
          ></img>
        )
      case 'copy':
        return (
          <img
            className={styles.button_large_image}
            src={buttonCopyIcon}
            alt="copy"
          ></img>
        )
      case 'edit_gray':
        return (
          <img
            className={styles.button_large_image}
            src={editIcon}
            alt="edit"
          ></img>
        )
      case 'delete_gray':
        return (
          <img
            className={styles.button_large_image}
            src={deleteIcon}
            alt="delete"
          ></img>
        )
      case 'copy_gray':
        return (
          <img
            className={styles.button_large_image}
            src={copyIcon}
            alt="copy"
          ></img>
        )
      case 'cost_input_gray':
        return (
          <img
            className={styles.button_large_image}
            src={costInputIcon}
            alt="cost_input"
          ></img>
        )
      case 'web_gray':
        return (
          <img
            className={styles.button_large_image}
            src={webIcon}
            alt="web"
          ></img>
        )
      case 'nutrition_facts_gray':
        return (
          <img
            className={styles.button_large_image}
            src={nutritionFactsIcon}
            alt="栄養成分表"
          ></img>
        )
      case 'package_gray':
        return (
          <img
            className={styles.button_large_image}
            src={packageIcon}
            alt="パッケージ"
          ></img>
        )
      case 'standards_doc_gray':
        return (
          <img
            className={styles.button_large_image}
            src={standardsDocIcon}
            alt="規格書"
          ></img>
        )
      case 'delete_orange':
        return (
          <img
            className={styles.button_large_image}
            src={deleteOrangeIcon}
            alt="delete_orange"
          ></img>
        )
      case 'visible':
        return (
          <img
            className={styles.button_visible_image}
            src={visibleIcon}
            alt="visible"
          ></img>
        )
      case 'invisible':
        return (
          <img
            className={styles.button_invisible_image}
            src={invisibleIcon}
            alt="invisible"
          ></img>
        )
      case 'sort_asc':
        return (
          <img
            className={styles.button_sort_image}
            src={sortAscIcon}
            alt="sort_asc"
          ></img>
        )
      case 'sort_desc':
        return (
          <img
            className={styles.button_sort_image}
            src={sortDescIcon}
            alt="sort_desc"
          ></img>
        )
      default:
        return
    }
  })()

  const className = `${baseStyle} ${sizeStyle} ${colorStyle}`

  return (
    <button
      className={className}
      onClick={(e: MouseEvent<HTMLButtonElement>) => onClick(e)}
    >
      {iconImage ? (
        <div
          className={
            size === 'xlarge'
              ? styles.button_xlarge_image_space
              : size === 'small'
              ? styles.button_small_image_space
              : styles.button_large_image_space
          }
        ></div>
      ) : (
        ''
      )}
      {iconImage}
      <div
        className={
          iconImage && size === 'xlarge'
            ? styles.button_xlarge_image_label_box
            : size === 'small'
            ? styles.button_small_image_label_box
            : textPosition === 'right'
            ? styles.button_large_image_label_box_right
            : styles.button_large_image_label_box
        }
      >
        {label}
      </div>
    </button>
  )
}

export default SubmitButton
