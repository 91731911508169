import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import InitLoadTemplate from 'components/Templates/InitLoadTemplate'

const InitLoad: FC = () => {
  return (
    <Layout title={'ローディング'} page="InitLoad">
      <InitLoadTemplate />
    </Layout>
  )
}

export default InitLoad
