import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  DocumentReference,
  doc,
  addDoc,
  updateDoc,
} from 'firebase/firestore'

interface AdditiveMaterialState {
  additiveMaterials: AdditiveMaterial[]
}
interface AdditiveMaterial {
  name: string
  is_disabled: boolean
  additive_use_ref: DocumentReference
  additive_material_ref: DocumentReference
}

export type AdditiveMaterialType = AdditiveMaterial

const initialState = {
  additiveMaterials: [],
} as AdditiveMaterialState

export const getAdditiveMaterials = createAsyncThunk<AdditiveMaterial[]>(
  'additiveMaterial/getAdditiveMaterials',
  async () => {
    const q = query(
      collection(db, 'additive_materials'),
      where('is_disabled', '==', false),
      orderBy('order'),
    )
    const querySnapshot = await getDocs(q)
    const additiveMaterials: AdditiveMaterial[] = []
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const additiveMaterial = doc.data()
        additiveMaterials.push({
          name: additiveMaterial.name,
          is_disabled: additiveMaterial.is_disabled,
          additive_use_ref: additiveMaterial.additive_use_ref,
          additive_material_ref: additiveMaterial.additive_material_ref,
        })
      }),
    )
    return additiveMaterials
  },
)

export const createAdditiveMaterials = createAsyncThunk<boolean>(
  'additiveMaterial/createAdditiveMaterials',
  async () => {
    const nameList = {
      甘味料: [
        'アステルパーム',
        'アステルパーム・L－フェニルアラニン化合物',
        'アセスルファムカリウム',
        'カンゾウ抽出物',
        'カンゾウエキス',
        'グリチルリチン',
        'リコリス抽出物',
        'キシリトール',
        'サッカリン',
        'サッカリンナトリウム',
        'ステビア',
        'ステビア抽出物',
        'ステビア末',
        'ステビオサイド',
        'レバウディオサイド',
        'D-ソルビトール',
        'ソルビトール',
        'ソルビット',
      ],
      着色料: [
        'アナトー色素',
        'アナトー',
        'カロチノイド',
        'カロチノイド色素',
        'カロテノイド',
        'カロテノイド色素',
        'ウコン色素',
        'クルクミン',
        'ターメリック色素',
        'カラメルI',
        'カラメルII',
        'カラメルIII',
        'カラメルIV',
        'カラメル',
        'カラメル色素',
        'イモカロテン',
        'ディナリエラカロテン',
        'ニンジンカロテン',
        '抽出カロチン',
        'カロチン色素',
        'カロチノイド色素',
        'クチナシ青色素',
        'クチナシ赤色素',
        'クチナシ黄色素',
        'クチナシ',
        'クチナシ色素',
        'コチニール色素',
        'カルミン酸色素',
        'カルミン酸',
        'コチニール',
        '食用タール系色素',
        '食用赤色2号',
        '食用赤色3号',
        '食用赤色40号',
        '食用赤色102号',
        '食用赤色104号',
        '食用赤色105号',
        '食用赤色106号',
        '食用黄色4号',
        '食用黄色5号',
        '食用緑色3号',
        '食用青色1号',
        '食用青色2号',
        '銅クロロフィル',
        '銅クロロフィリンナトリウム',
        'ベニコウジ色素',
        'モナスカス色素',
        '紅麹',
        'モナスカス',
        'ベニバナ赤色素',
        'カーサマス赤色素',
        'ベニバナ黄色素',
        'カーサマス黄色素',
        'フラボノイド色素',
        '紅花色素',
      ],
      保存料: [
        '安息香酸',
        '安息香酸ナトリウム',
        '安息香酸Na',
        'しらこたん白抽出物',
        'しらこたん白',
        'しらこ分解物',
        'プロタミン',
        '核蛋白',
        'ソルビン酸',
        'ソルビン酸カリウム',
        'ソルビン酸K',
        'プロピオン酸',
        'プロピオン酸カルシウム',
        'プロピオン酸Ca',
        'プロピオン酸ナトリウム',
        'プロピオン酸Na',
        'ポリリジン',
        'ε-ポリリジン',
      ],
      増粘剤: [
        'カラギナン',
        '加工ユーケマ藻類',
        '精製カラギナン',
        'ユーケマ藻末',
        'カルボキシメチルセルロースナトリウム',
        'CMC',
        'キサンタンガム',
        'グァーガム',
        'グァー',
        'グァルガム',
        'グァーフラワー',
        'ペクチン',
        'アルギン酸',
        'ローカストビーンガム',
        '加工デンプン',
        '増粘多糖類',
      ],
      安定剤: [
        'カラギナン',
        '加工ユーケマ藻類',
        '精製カラギナン',
        'ユーケマ藻末',
        'カルボキシメチルセルロースナトリウム',
        'CMC',
        'キサンタンガム',
        'グァーガム',
        'グァー',
        'グァルガム',
        'グァーフラワー',
        'ペクチン',
        'アルギン酸',
        'ローカストビーンガム',
      ],
      ゲル化剤: [
        'カラギナン',
        '加工ユーケマ藻類',
        '精製カラギナン',
        'ユーケマ藻末',
        'カルボキシメチルセルロースナトリウム',
        'CMC',
        'キサンタンガム',
        'グァーガム',
        'グァー',
        'グァルガム',
        'グァーフラワー',
        'ペクチン',
        'アルギン酸',
        'ローカストビーンガム',
      ],
      糊料: [
        'カラギナン',
        '加工ユーケマ藻類',
        '精製カラギナン',
        'ユーケマ藻末',
        'カルボキシメチルセルロースナトリウム',
        'CMC',
        'キサンタンガム',
        'グァーガム',
        'グァー',
        'グァルガム',
        'グァーフラワー',
        'ペクチン',
        'アルギン酸',
        'ローカストビーンガム',
      ],
      酸化防止剤: [
        'Ｌ-アスコルビン酸',
        'ビタミンＣ',
        'Ｖ．Ｃ',
        'エリソルビン酸',
        'イソアスコルビン酸',
        'カテキン',
        'ジブチルヒドロキシトルエン',
        'ＢＨＴ',
        'トコフェロール',
        'ビタミンＥ',
        'Ｖ．Ｅ',
        'ブチルヒドロキシアニソール',
        'ＢＨＡ',
        '亜硫酸塩',
      ],
      発色剤: [
        '亜硝酸ナトリウム',
        '亜硝酸Ｎａ',
        '硝酸カリウム',
        '硝酸Ｋ',
        '硝酸ナトリウム',
        '硝酸Na',
      ],
      漂白剤: [
        '亜塩素酸ナトリウム',
        '亜硫酸ナトリウム',
        '亜硫酸Ｎａ',
        '亜硫酸塩',
        '亜硫酸ソーダ',
        '二酸化硫黄',
      ],
      防かび剤: [
        'イマザリル',
        'オルトフェニルフェノール',
        'ＯＰＰ',
        'オルトフェニルフェノールナトリウム',
        'チアベンダゾール',
        'ＴＢＺ',
        'フルジオキソニル',
      ],
      防ばい剤: [
        'イマザリル',
        'オルトフェニルフェノール',
        'ＯＰＰ',
        'オルトフェニルフェノールナトリウム',
        'チアベンダゾール',
        'ＴＢＺ',
        'フルジオキソニル',
      ],
      乳化剤: [
        'グリセリン脂肪酸エステル',
        'グリセリンエステル',
        'サポニン',
        'キラヤ抽出物',
        'ダイズサポニン',
        'チャ種子サポニン',
        'ショ糖脂肪酸エステル',
        'ショ糖エステル',
        'レシチン',
        '植物レシチン',
        '卵黄レシチン',
        '大豆由来',
        'レシチン（大豆由来）',
      ],
      膨張剤: [
        '炭酸水素ナトリウム',
        '重曹',
        '炭酸水素Ｎａ',
        '重炭酸Ｎａ',
        'グルコノデルタラクトン',
        'グルコノラクトン',
        '硫酸アルミニウムカリウム',
        'ミョウバン',
      ],
      調味料: [
        'アミノ酸',
        '核酸',
        '有機酸',
        '無機塩',
        'アミノ酸等',
        '核酸等',
        '有機酸等',
        '無機塩等',
      ],
      酸味料: ['クエン酸', 'L-酒石酸', '乳酸'],
      苦味料: [
        'カフェイン（抽出物）',
        'カフェイン',
        'ナリンジン',
        'ニガヨモギ抽出物',
      ],
      光沢剤: [
        'シェラック',
        '白シェラック',
        '精製シェラック',
        'パラフィンワックス',
        'ミツロウ',
      ],
      ガムベース: ['酢酸ビニル樹脂', 'ジェルトン', 'ポンチアナック', 'チクル'],
      栄養強化剤: [
        'L-アスコルビン酸、エルゴカルシフェロール、β-カロテン',
        'エルゴカルシフェロール',
        'β-カロテン',
        '亜鉛塩類',
        '塩化カルシウム',
        '塩化第二鉄',
        'L-アスパラギン酸ナトリウム',
        'DL-アラニン',
        'L-イソロイシン',
      ],
      製造用剤等: ['プロピレングリコール'],
      かんすい: [
        '炭酸カリウム',
        '炭酸ナトリウム',
        'リン酸水素二ナトリウム',
        'ピロリン酸四ナトリウム',
      ],
      結着剤: [
        'リン酸ナトリウム',
        'リン酸二カリウム',
        '正リン酸塩',
        'ポリリン酸ナトリウム',
        'メタリン酸ナトリウム',
        '重合リン酸塩',
      ],
      消泡剤: ['シリコーン', 'シリコーン樹脂'],
      抽出溶剤: ['ヘキサン', 'アセトン'],
      豆腐用凝固剤: [
        '凝固剤',
        '塩化カルシウム',
        '硫酸カルシウム',
        'グルコノデルタラクトン',
      ],
      日持向上剤: ['酢酸', 'グリシン', 'リゾチーム'],
      離型剤: ['流動パラフィン'],
      ろ過助剤: [
        '二酸化ケイ素',
        '酸性白土',
        'カオリン',
        'ベントナイト',
        'タルク',
        '砂',
        'ケイソウ土',
      ],
      香料: ['アセト酢酸エチル', 'アセトフェノン', 'アニスアルデヒド'],
      // "pH調整剤": [],
    }

    const additiveMaterialRef = collection(db, 'additive_materials')
    const additiveUseRef = collection(db, 'additive_uses')
    await Promise.all(
      Object.keys(nameList).map(async (name) => {
        const q = query(additiveUseRef, where('name', '==', name))
        const querySnapshot = await getDocs(q)
        const additiveUsesId = querySnapshot.docs[0].id
        const additiveUseDoc = doc(db, 'additive_uses', additiveUsesId)
        await Promise.all(
          // @ts-ignore
          nameList[name].map(async (name2, index) => {
            const createData = {
              name: name2,
              is_disabled: false,
              order: index,
              additive_use_ref: additiveUseDoc,
            }
            const docRef: DocumentReference = await addDoc(
              additiveMaterialRef,
              createData,
            )
            const additiveMaterialDoc = doc(db, 'additive_materials', docRef.id)
            const updateData = {
              additive_material_ref: additiveMaterialDoc,
            }
            await updateDoc(additiveMaterialDoc, updateData)
          }),
        )
      }),
    )
    return true
  },
)

export const additiveMaterialSlice: Slice<
  AdditiveMaterialState,
  {},
  'additiveMaterial'
> = createSlice({
  name: 'additiveMaterial',
  initialState,
  reducers: {},
  extraReducers: {
    [getAdditiveMaterials.pending.type]: () => {},
    [getAdditiveMaterials.fulfilled.type]: (
      state,
      action: PayloadAction<AdditiveMaterial[]>,
    ) => {
      state.additiveMaterials = action.payload
    },
    [getAdditiveMaterials.rejected.type]: () => {},
  },
})

export default additiveMaterialSlice.reducer
