import { FC } from 'react'
import styles from 'styles/components/box.module.scss'

type Props = {
  label: string
  value: number
  unit: string
}

const LossRateBox: FC<Props> = (props) => {
  const { label, value, unit } = props

  return (
    <>
      <div className={styles.loss_rate_box}>
        <div className={styles.loss_rate_box_label}>{label}</div>
        <div className={styles.loss_rate_box_value}>
          {String(value)}
          {unit}
        </div>
      </div>
    </>
  )
}

export default LossRateBox
