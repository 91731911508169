import { FC } from 'react'
import styles from 'styles/components/template.module.scss'
import { useShopEdit } from 'hooks/useShopEdit'
import EditContainer from './EditContainer'
import ConfirmedContainer from './ConfirmedContainer'

type Props = {
  isRegister: boolean
}
const ShopInfoTemplate: FC<Props> = ({ isRegister }) => {
  const {
    shopName,
    setShopName,
    shopNameKana,
    setShopNameKana,
    factoryName,
    setFactoryName,
    officeName,
    setOfficeName,
    officerName,
    setOfficerName,
    zipCode,
    setZipCode,
    prefecture,
    setPrefecture,
    city,
    setCity,
    address,
    setAddress,
    building,
    setBuilding,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    shopNameErrorText,
    shopNameKanaErrorText,
    factoryNameErrorText,
    officeNameErrorText,
    officerNameErrorText,
    zipCodeErrorText,
    prefectureErrorText,
    cityErrorText,
    addressErrorText,
    emailErrorText,
    phoneNumberErrorText,
    searchAddress,
    isConfirmed,
    handleConfirmed,
    handleSubmit,
    setIsConfirmed,
    zipCodeSearchErrorText,
    shopId,
    setShopId,
    setStoreId,
    isAdmin,
    setIsAdmin,
    isSentEmail,
    isCompany,
    setIsCompany,
    isSameAddress,
    setIsSameAddress,
    isBillPayment,
    setIsBillPayment,
  } = useShopEdit()

  return (
    <div className={styles.container}>
      {isConfirmed ? (
        <ConfirmedContainer
          handleSubmit={handleSubmit}
          isSentEmail={isSentEmail}
          shopName={shopName}
          shopNameErrorText={shopNameErrorText}
          setShopName={setShopName}
          shopNameKana={shopNameKana}
          shopNameKanaErrorText={shopNameKanaErrorText}
          setShopNameKana={setShopNameKana}
          factoryName={factoryName}
          factoryNameErrorText={factoryNameErrorText}
          setFactoryName={setFactoryName}
          officeName={officeName}
          officeNameErrorText={officeNameErrorText}
          setOfficeName={setOfficeName}
          officerName={officerName}
          officerNameErrorText={officerNameErrorText}
          setOfficerName={setOfficerName}
          zipCode={zipCode}
          zipCodeErrorText={zipCodeErrorText}
          setZipCode={setZipCode}
          prefecture={prefecture}
          prefectureErrorText={prefectureErrorText}
          setPrefecture={setPrefecture}
          city={city}
          cityErrorText={cityErrorText}
          setCity={setCity}
          address={address}
          setAddress={setAddress}
          addressErrorText={addressErrorText}
          building={building}
          setBuilding={setBuilding}
          email={email}
          setEmail={setEmail}
          emailErrorText={emailErrorText}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phoneNumberErrorText={phoneNumberErrorText}
          searchAddress={searchAddress}
          setIsConfirmed={setIsConfirmed}
          isRegister={isRegister}
          isAdmin={isAdmin}
          isCompany={isCompany}
          isSameAddress={isSameAddress}
          isBillPayment={isBillPayment}
          shopId={shopId}
        />
      ) : (
        <EditContainer
          handleSubmit={handleSubmit}
          isSentEmail={isSentEmail}
          handleConfirmed={handleConfirmed}
          shopName={shopName}
          shopNameErrorText={shopNameErrorText}
          setShopName={setShopName}
          shopNameKana={shopNameKana}
          shopNameKanaErrorText={shopNameKanaErrorText}
          setShopNameKana={setShopNameKana}
          setFactoryName={setFactoryName}
          officeName={officeName}
          officeNameErrorText={officeNameErrorText}
          setOfficeName={setOfficeName}
          officerName={officerName}
          officerNameErrorText={officerNameErrorText}
          setOfficerName={setOfficerName}
          zipCode={zipCode}
          zipCodeErrorText={zipCodeErrorText}
          setZipCode={setZipCode}
          prefecture={prefecture}
          prefectureErrorText={prefectureErrorText}
          setPrefecture={setPrefecture}
          city={city}
          cityErrorText={cityErrorText}
          setCity={setCity}
          address={address}
          setAddress={setAddress}
          addressErrorText={addressErrorText}
          building={building}
          setBuilding={setBuilding}
          email={email}
          setEmail={setEmail}
          emailErrorText={emailErrorText}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phoneNumberErrorText={phoneNumberErrorText}
          searchAddress={searchAddress}
          zipCodeSearchErrorText={zipCodeSearchErrorText}
          setShopId={setShopId}
          setStoreId={setStoreId}
          isRegister={isRegister}
          setIsAdmin={setIsAdmin}
          isCompany={isCompany}
          setIsCompany={setIsCompany}
          isSameAddress={isSameAddress}
          setIsSameAddress={setIsSameAddress}
          isBillPayment={isBillPayment}
          setIsBillPayment={setIsBillPayment}
        />
      )}
    </div>
  )
}

export default ShopInfoTemplate
