import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  getDocs,
  DocumentReference,
  Timestamp,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  where,
  orderBy,
} from 'firebase/firestore'
import { ProductInformation } from 'components/Form/AddForm/ProductInformation'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'

interface FillingState {
  filling: Filling | null
  fillings: Filling[]
}

interface Filling {
  id: string
  pettern_name: string
  material_informations: MaterialInformation[]
  finished_weight: number | null
  process: string
  memo: string
  is_disabled: boolean
  is_percent: boolean
  is_gram: boolean
  shop_ref: DocumentReference
  created_at: Timestamp | null
}

interface MaterialInformation {
  productName: string | null
  manufacturerName: string | null
  middle_knead_percent: number
  authentic_knead_percent: number
  material_ref: DocumentReference | null
  dough_ref: DocumentReference | null
  filling_ref: DocumentReference | null
  recipe_ref: DocumentReference | null
}

export type FillingType = Filling

export type MaterialInformationType = MaterialInformation

const initialState = {
  filling: null,
  fillings: [],
} as FillingState

export const getFillings = createAsyncThunk<
  Filling[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('filling/getFillings', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const fillings: Filling[] = []
  const q = query(
    collection(db, 'fillings'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    orderBy('pettern_name'),
  )
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const filling = doc.data()
      fillings.push({
        id: doc.id,
        pettern_name: filling.pettern_name,
        material_informations: filling.material_informations,
        finished_weight: filling.finished_weight,
        process: filling.process,
        memo: filling.memo,
        is_disabled: filling.is_disabled,
        is_percent: filling.is_percent,
        is_gram: filling.is_gram,
        shop_ref: filling.shop_ref,
        created_at: filling.created_at,
      })
    }),
  )
  return fillings
})

export const getAvailableFillings = createAsyncThunk<
  Filling[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('filling/getAvailableFillings', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const fillings: Filling[] = []
  const q = query(
    collection(db, 'fillings'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    where('is_disabled', '==', false),
    orderBy('pettern_name'),
  )
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const filling = doc.data()
      fillings.push({
        id: doc.id,
        pettern_name: filling.pettern_name,
        material_informations: filling.material_informations,
        finished_weight: filling.finished_weight,
        process: filling.process,
        memo: filling.memo,
        is_disabled: filling.is_disabled,
        is_percent: filling.is_percent,
        is_gram: filling.is_gram,
        shop_ref: filling.shop_ref,
        created_at: filling.created_at,
      })
    }),
  )
  return fillings
})

export const getFillingById = createAsyncThunk<
  Filling | null,
  { id: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('filling/getFillingById', async ({ id }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const q = doc(db, 'fillings', id)
  const querySnapshot = await getDoc(q)
  const filling = querySnapshot.data()
  if (!filling) {
    return null
  }
  const fillingShopId = filling.shop_ref.id
    ? filling.shop_ref.id
    : // @ts-ignore
      filling.shop_ref._key.path.segments[6]
  // 所属しているお店の商品情報しか取得できない
  if (fillingShopId !== staff.shopId) {
    return null
  }
  const response: Filling = {
    id: querySnapshot.id,
    pettern_name: filling.pettern_name,
    material_informations: filling.material_informations,
    finished_weight: filling.finished_weight,
    process: filling.process,
    memo: filling.memo,
    is_disabled: filling.is_disabled,
    is_percent: filling.is_percent,
    is_gram: filling.is_gram,
    shop_ref: filling.shop_ref,
    created_at: filling.created_at,
  }
  return response
})

// 管理者が使用する
export const getAllFillings = createAsyncThunk<
  Filling[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('filling/getAllFillings', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.isAdmin) {
    return null
  }
  const fillings: Filling[] = []
  const q = query(collection(db, 'fillings'))
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const filling = doc.data()
      fillings.push({
        id: doc.id,
        pettern_name: filling.pettern_name,
        material_informations: filling.material_informations,
        finished_weight: filling.finished_weight,
        process: filling.process,
        memo: filling.memo,
        is_disabled: filling.is_disabled,
        is_percent: filling.is_percent,
        is_gram: filling.is_gram,
        shop_ref: filling.shop_ref,
        created_at: filling.created_at,
      })
    }),
  )
  return fillings
})

export const createFilling = createAsyncThunk<
  string | null,
  {
    pettern_name: string
    material_informations: ProductInformation[] | null
    finished_weight: number | null
    process: string
    memo: string
    is_disabled: boolean
    is_percent: boolean
    is_gram: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'filling/createFilling',
  async (
    {
      pettern_name,
      material_informations,
      finished_weight,
      process,
      memo,
      is_disabled,
      is_percent,
      is_gram,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return null
    }
    const materialInformations: MaterialInformation[] = []
    if (material_informations) {
      await Promise.all(
        material_informations.map(async (info) => {
          materialInformations.push({
            productName:
              !info.materialId &&
              !info.doughId &&
              !info.fillingId &&
              !info.recipeId
                ? info.productName
                : null,
            manufacturerName:
              !info.materialId &&
              !info.doughId &&
              !info.fillingId &&
              !info.recipeId
                ? info.manufacturerName
                : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const fillingRef = collection(db, 'fillings')
    const data = {
      pettern_name: pettern_name,
      material_informations: materialInformations,
      finished_weight: finished_weight,
      process: process,
      memo: memo,
      is_disabled: is_disabled,
      is_percent: is_percent,
      is_gram: is_gram,
      shop_ref: doc(db, 'shops', staff.shopId),
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    }
    const docRef = await addDoc(fillingRef, data)
    return docRef.id
  },
)

export const updateFilling = createAsyncThunk<
  string | null,
  {
    id: string
    pettern_name: string
    material_informations: ProductInformation[] | null
    finished_weight: number | null
    process: string
    memo: string
    is_disabled: boolean
    is_percent: boolean
    is_gram: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'filling/updateFilling',
  async (
    {
      id,
      pettern_name,
      material_informations,
      finished_weight,
      process,
      memo,
      is_disabled,
      is_percent,
      is_gram,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return null
    }
    const fillingRef = doc(db, 'fillings', id)
    const querySnapshot = await getDoc(fillingRef)
    const filling = querySnapshot.data()
    if (!filling) {
      return null
    }
    const fillingShopId = filling.shop_ref.id
      ? filling.shop_ref.id
      : // @ts-ignore
        filling.shop_ref._key.path.segments[6]
    // 所属しているお店の商品情報しか更新できない
    if (fillingShopId !== staff.shopId) {
      return null
    }
    const materialInformations: MaterialInformation[] = []
    if (material_informations) {
      await Promise.all(
        material_informations.map(async (info) => {
          materialInformations.push({
            productName:
              !info.materialId &&
              !info.doughId &&
              !info.fillingId &&
              !info.recipeId
                ? info.productName
                : null,
            manufacturerName:
              !info.materialId &&
              !info.doughId &&
              !info.fillingId &&
              !info.recipeId
                ? info.manufacturerName
                : null,
            middle_knead_percent: Number(info.middleKneadPercent),
            authentic_knead_percent: Number(info.authenticKneadPercent),
            material_ref: info.materialId
              ? doc(db, 'materials', info.materialId)
              : null,
            dough_ref: info.doughId ? doc(db, 'doughs', info.doughId) : null,
            filling_ref: info.fillingId
              ? doc(db, 'fillings', info.fillingId)
              : null,
            recipe_ref: info.recipeId
              ? doc(db, 'recipes', info.recipeId)
              : null,
          })
        }),
      )
    }
    const data = {
      pettern_name: pettern_name,
      material_informations: materialInformations,
      finished_weight: finished_weight,
      process: process,
      memo: memo,
      is_disabled: is_disabled,
      is_percent: is_percent,
      is_gram: is_gram,
      updated_at: serverTimestamp(),
    }
    await updateDoc(fillingRef, data)
    return id
  },
)

export const fillingSlice: Slice<
  FillingState,
  { clearFilling(state: FillingState): void },
  'filling'
> = createSlice({
  name: 'filling',
  initialState,
  reducers: {
    clearFilling(state) {
      state.filling = null
    },
  },
  extraReducers: {
    [getFillings.pending.type]: () => {},
    [getFillings.fulfilled.type]: (
      state,
      action: PayloadAction<Filling[] | null>,
    ) => {
      if (action.payload !== null) {
        state.fillings = action.payload
      }
    },
    [getFillings.rejected.type]: () => {},
    [getFillingById.fulfilled.type]: (
      state,
      action: PayloadAction<Filling | null>,
    ) => {
      if (action.payload !== null) {
        state.filling = action.payload
      }
    },
  },
})

export const { clearFilling } = fillingSlice.actions
export default fillingSlice.reducer
