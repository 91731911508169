import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import CompanyPageTemplate from 'components/Templates/CompanyTemplate'
import { useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'

const CompanyPage: FC = () => {
  const { uid } = useSelector((state: RootState) => state.authSlice)

  return !!uid ? (
    <Layout title={'運営会社'} page="CompanyPage">
      <CompanyPageTemplate />
    </Layout>
  ) : (
    <CompanyPageTemplate />
  )
}

export default CompanyPage
