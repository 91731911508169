import { FC, useState } from 'react'
import styles from 'styles/components/label.module.scss'
import inputToolTipIcon from 'images/Form/input-tooltip-icon.png'

type Props = {
  required: boolean
  name: string
  description: string
  line: number
  labelTextWrap?: boolean
  size?: string
}

const InputLabel: FC<Props> = (props) => {
  const { required, name, description, labelTextWrap, size } = props
  const [viewToolTip, setViewToolTip] = useState<boolean>(false)

  const handleMouseEnter = () => {
    setViewToolTip(true)
  }
  const handleMouseLeave = () => {
    setViewToolTip(false)
  }

  const sizeStyle = (() => {
    switch (size) {
      case 'large':
        return styles.labelContainer_large
      case 'small':
        return styles.labelContainer_small
      case 'minimum':
        return styles.labelContainer_minimum
      default:
        return styles.labelContainer
    }
  })()

  const className = `${sizeStyle}`

  return (
    <div className={className}>
      {required && <div className={styles.requiredLabel}>必須</div>}
      {!required && <div className={styles.optionalLabel}></div>}
      <div
        className={
          size === 'large'
            ? styles.labelText3
            : labelTextWrap
            ? styles.labelText2
            : styles.labelText
        }
      >
        {name}
      </div>
      {description ? (
        <div
          className={styles.inputToolTipContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            className={
              // line === 3
              //   ? styles.inputToolTipIconLine3
              //   : line === 2
              //   ? styles.inputToolTipIconLine2
              //   : styles.inputToolTipIcon
              name.length > 15
                ? styles.inputToolTipIconLine3
                : name.length > 6
                ? styles.inputToolTipIconLine2
                : styles.inputToolTipIcon
            }
            src={inputToolTipIcon}
            alt="?"
          ></img>
        </div>
      ) : (
        ''
      )}
      {viewToolTip ? (
        <div className={styles.inputToolTip}>
          {description.split('<br>').map((text) => (
            <div>{text}</div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default InputLabel
