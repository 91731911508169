import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { setAuth } from 'reducks/auth/slice'
import { getStaff } from 'reducks/staff/slice'
import { getCategories } from 'reducks/category/slice'
import { getBreads } from 'reducks/bread/slice'
import { getDoughs } from 'reducks/dough/slice'
import { getFillings } from 'reducks/filling/slice'
import { auth } from 'fb/index'
import { getStoreById } from 'reducks/store/slice'
import { getShopSubscriptionById } from 'reducks/shopSubscriptions/slice'

const InitLoadTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const path = query.get('path')
  useEffect(() => {
    const authStateChanged = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await dispatch(setAuth({ uid: user.uid }))
        const staff = await dispatch(getStaff({ uid: user.uid }))
        const store = await dispatch(
          getStoreById({ storeId: staff.payload.storeId }),
        )
        const shopSubscription = await dispatch(
          getShopSubscriptionById({
            storeId: staff.payload.storeId,
          }),
        )
        await dispatch(getBreads())
        await dispatch(getDoughs())
        await dispatch(getFillings())
        await dispatch(getCategories())
        if (staff.payload?.isAdmin) {
          navigate('/')
          return
        }
        if (path) {
          navigate(path)
          return
        }
        if (
          !!store.payload?.personal_phone_number &&
          (!!shopSubscription.payload?.stripe_daseruno_subscription_id ||
            !!shopSubscription.payload
              ?.stripe_daseruno_subscription_schedule_id ||
            !!shopSubscription.payload?.is_bill_payment)
        ) {
          navigate('/')
        } else if (!store.payload?.personal_phone_number) {
          navigate('/shop/register')
        } else {
          navigate('/registerplan')
        }
      }
    })
    return () => {
      authStateChanged()
    }
  }, [])
  return <></>
}

export default InitLoadTemplate
