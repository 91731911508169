import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import PrivacyPolicyPageTemplate from 'components/Templates/PrivacyPolicyTemplate'
import { useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'

const PrivacyPolicyPage: FC = () => {
  const { uid } = useSelector((state: RootState) => state.authSlice)

  return !!uid ? (
    <Layout title={'プライバシーポリシー'} page="PrivacyPolicyPage">
      <PrivacyPolicyPageTemplate />
    </Layout>
  ) : (
    <PrivacyPolicyPageTemplate />
  )
}

export default PrivacyPolicyPage
