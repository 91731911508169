import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  DocumentReference,
} from 'firebase/firestore'

interface CategoryState {
  categories: Category[]
}
interface Category {
  name: string
  is_disabled: boolean
  category_ref: DocumentReference
}

export type CategoryType = Category

const initialState = {
  categories: [],
} as CategoryState

export const getCategories = createAsyncThunk<Category[]>(
  'category/getCategories',
  async () => {
    const q = query(
      collection(db, 'categories'),
      where('is_disabled', '==', false),
      orderBy('order'),
    )
    const querySnapshot = await getDocs(q)
    const categories: Category[] = []
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const category = doc.data()
        categories.push({
          name: category.name,
          is_disabled: category.is_disabled,
          category_ref: category.category_ref,
        })
      }),
    )
    return categories
  },
)

export const categorySlice: Slice<CategoryState, {}, 'category'> = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: {
    [getCategories.pending.type]: () => {},
    [getCategories.fulfilled.type]: (
      state,
      action: PayloadAction<Category[]>,
    ) => {
      state.categories = action.payload
    },
    [getCategories.rejected.type]: () => {},
  },
})

export default categorySlice.reducer
