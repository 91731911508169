import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/form.module.scss'

type Props = {
  maxLength: number
  rows: number
  size: string
  value: string
  placeholder: string
  errorText?: string | null
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
}

const TextArea: FC<Props> = (props) => {
  const { maxLength, rows, size, value, placeholder, errorText, onChange } =
    props

  const errorStyle = (() => {
    return errorText ? styles.textarea_error : styles.textarea
  })()

  const sizeStyle = (() => {
    switch (size) {
      case 'full':
        return styles.textarea_full
      case 'large':
        return styles.textarea_large
      default:
        return styles.textarea_large
    }
  })()

  const className = `${errorStyle} ${sizeStyle}`

  return (
    <div>
      <textarea
        className={className}
        maxLength={maxLength}
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={(e) => (onChange ? onChange(e) : null)}
      />
      {errorText ? (
        <p className={styles.textarea_error_text}>{errorText}</p>
      ) : (
        ''
      )}
    </div>
  )
}

export default TextArea
