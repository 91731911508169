import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLabel from 'components/Form/WithLabel/InputText'
import RadioButtonWithLabel from 'components/Form/WithLabel/RadioButton'
import InputTextAndUnitWithLabel from 'components/Form/WithLabel/InputTextAndUnit'
import TextAreaWithLightLabel from 'components/Form/WithLightLabel/TextArea'
import SelectBox from 'components/Form/SelectBox'
import InputText from 'components/Form/InputText'
import InputTextSuggestWithLightLabel from 'components/Form/WithLightLabel/InputTextSuggest'
import InputTextAndUnitWithLightLabel from 'components/Form/WithLightLabel/InputTextAndUnit'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import { suggestInfo } from 'components/Form/WithLabel/InputTextSuggest'
import BreadDetailBoxPrimaryV2 from 'components/Table/BreadDetailBoxPrimaryV2'
import clearCrossIcon from 'images/Form/clear-cross-icon.png'
import RadioButton from 'components/Form/RadioButton'
import SubmitButton from 'components/Button/SubmitButton'
import {
  getMaterialCostInformation,
  getMaterialInformation,
  getFinalCost,
  BreadDetailPetternInformationType,
  UnitType,
  CostInfo,
} from 'reducks/business/slice'
import { getRecipeById, RecipeType } from 'reducks/recipe/slice'
import {
  MaterialInformationType,
  getDoughById,
  DoughType,
} from 'reducks/dough/slice'
import { FillingType, getFillingById } from 'reducks/filling/slice'
import { DataType } from 'reducks/business/slice'
import {
  createCostSimulation,
  updateCostSimulation,
  clearCostSimulation,
  getCostSimulationById,
  CostSimulationType,
} from 'reducks/costSimulation/slice'
import { setIsLoading } from 'reducks/loading/slice'
import { RootState } from 'reducks/reducers'
import Big from 'big.js'
import _ from 'lodash'

interface CalcAllCostResult {
  finalCost: number | null
  noPackageingCost: number | null
  packagingCostPrice: number | null
  costRate: number | null
  amountDoughBeforeBaking: string | null
}
export type CalcAllCostResultType = CalcAllCostResult

const CostSimulationOperationTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  // 更新の場合か確認
  const path = location.pathname
  const costSimulationId = path.split('/simulation/cost/edit/')[1]
    ? path.split('/simulation/cost/edit/')[1]
    : null
  const copyCostSimulationId = path.split('/simulation/cost/copy/')[1]
    ? path.split('/simulation/cost/copy/')[1]
    : null
  const [breadDisplayPercent, setBreadDisplayPercent] = useState<boolean>(true)
  const [breadDisplayGram, setBreadDisplayGram] = useState<boolean>(false)
  const [costSimulationName, setCostSimulationName] = useState<string>('')
  const [recipeName, setRecipeName] = useState<string>('')
  const [doughName, setDoughName] = useState<string>('')
  const [fillingName, setFillingName] = useState<string>('')
  const [suggestRecipe, setSuggestRecipe] = useState<suggestInfo | null>(null)
  const [suggestDough, setSuggestDough] = useState<suggestInfo | null>(null)
  const [suggestFilling, setSuggestFilling] = useState<suggestInfo | null>(null)
  const [recipePetternInfos, setRecipePetternInfos] = useState<
    BreadDetailPetternInformationType[]
  >([])
  const [doughPetternInfos, setDoughPetternInfos] = useState<
    BreadDetailPetternInformationType[]
  >([])
  const [fillingPetternInfos, setFillingPetternInfos] = useState<
    BreadDetailPetternInformationType[]
  >([])
  // const [allMaterialInfos, setAllMaterialInfos] = useState<
  //   BreadDetailPetternInformationType[]
  // >([])
  const [amountDoughBeforeBaking, setAmountDoughBeforeBaking] =
    useState<string>('')
  const [lossRate, setLossRate] = useState<string>('')
  const [memo, setMemo] = useState<string>('')
  const [isPreviewInternalCapacity, setIsPreviewInternalCapacity] =
    useState<boolean>(false)
  const [isPreviewParams, setIsPreviewParams] = useState<
    (number | DataType | boolean)[] | undefined
  >(undefined)
  const [isPreviewDataType, setIsPreviewDataType] = useState<
    DataType | undefined
  >(undefined)
  const [internalCapacityCount, setInternalCapacityCount] =
    useState<string>('1')
  const [internalCapacityUnit, setInternalCapacityUnit] = useState<string>('g')
  const [numberItem, setNumberItem] = useState<number>(1)
  const [numberItemUnit, setNumberItemUnit] = useState<string>('個')
  const [costPrice, setCostPrice] = useState<string | null>(null)
  const [taxIncludedCostPrice, setTaxIncludedCostPrice] = useState<
    string | null
  >(null)
  const [tax, setTax] = useState<number>(8)
  const [internalCapacityMemo, setInternalCapacityMemo] = useState<string>('')
  const [yieldRate, setYieldRate] = useState<number>(100)
  const [percentageOfUse, setPercentageOfUse] = useState<number>(0)
  const [calcSellingPrice, setCalcSellingPrice] = useState<number>(0)
  const [calcCostPrice, setCalcCostPrice] = useState<number>(0)
  const [calcMaterialCostPrice, setCalcMaterialCostPrice] = useState<number>(0)
  const [calcPackagingCostPrice, setCalcPackagingCostPrice] =
    useState<number>(0)
  const [calcCostRate, setCalcCostRate] = useState<number>(0)
  const [calcAmountDoughBeforeBaking, setCalcAmountDoughBeforeBaking] =
    useState<number>(0)
  const [costSimulationNameErrorText, setCostSimulationNameErrorText] =
    useState<string | null>('')
  const [internalCapacityCountErrorText, setInternalCapacityCountErrorText] =
    useState<string>('')
  const [costPriceErrorText, setCostPriceErrorText] = useState<string>('')
  const [yieldRateErrorText, setYieldRateErrorText] = useState<string | null>(
    null,
  )
  const [
    amountDoughBeforeBakingErrorText,
    setAmountDoughBeforeBakingErrorText,
  ] = useState<string | null>(null)
  const [lossRateErrorText, setLossRateErrorText] = useState<string | null>(
    null,
  )

  const handleRecipeSuggest = (info: suggestInfo) => {
    setSuggestRecipe(info)
    setRecipeName(info.name)
  }

  const handleChangeRecipeName = (name: string) => {
    setRecipeName(name)
  }

  const clearRecipeSuggest = () => {
    setSuggestRecipe(null)
    setRecipeName('')
  }

  const handleDoughSuggest = (info: suggestInfo) => {
    setSuggestDough(info)
    setDoughName(info.name)
  }

  const handleChangeDoughName = (name: string) => {
    setDoughName(name)
  }

  const clearDoughSuggest = () => {
    setSuggestDough(null)
    setDoughName('')
  }

  const handleFillingSuggest = (info: suggestInfo) => {
    setSuggestFilling(info)
    setFillingName(info.name)
  }

  const handleChangeFillingName = (name: string) => {
    setFillingName(name)
  }

  const clearFillingSuggest = () => {
    setSuggestFilling(null)
    setFillingName('')
  }

  const handleBreadDisplayUnit = () => {
    setBreadDisplayPercent(!breadDisplayPercent ? true : false)
    setBreadDisplayGram(!breadDisplayGram ? true : false)
  }

  const handleCalcAmountDoughbeforeBaking = () => {
    if (!breadDisplayGram) {
      return
    }
    const recipeGramSum = recipePetternInfos.reduce(
      (sum, recipeProductInformation) =>
        new Big(Number(recipeProductInformation.middleKneadPercent))
          .plus(Number(recipeProductInformation.authenticKneadPercent))
          .plus(Number(sum))
          .toNumber(),
      0,
    )
    const doughGramSum = doughPetternInfos.reduce(
      (sum, doughProductInformation) =>
        new Big(Number(doughProductInformation.middleKneadPercent))
          .plus(Number(doughProductInformation.authenticKneadPercent))
          .plus(Number(sum))
          .toNumber(),
      0,
    )
    const fillingGramSum = fillingPetternInfos.reduce(
      (sum, fillingProductInformation) =>
        new Big(Number(fillingProductInformation.middleKneadPercent))
          .plus(Number(fillingProductInformation.authenticKneadPercent))
          .plus(Number(sum))
          .toNumber(),
      0,
    )
    setAmountDoughBeforeBaking(
      String(
        new Big(Number(recipeGramSum))
          .plus(Number(doughGramSum))
          .plus(Number(fillingGramSum))
          .toNumber(),
      ),
    )
  }

  const setPettern = async () => {
    dispatch(setIsLoading({ isLoading: true }))
    let recipeTotalAmount: number = 0
    // let recipeMiddleKneadAmount: number = 0
    // let recipeAuthenticKneadAmount: number = 0
    let doughTotalAmount: number = 0
    // let doughMiddleKneadAmount: number = 0
    // let doughAuthenticKneadAmount: number = 0
    let fillingTotalAmount: number = 0
    // let fillingMiddleKneadAmount: number = 0
    // let fillingAuthenticKneadAmount: number = 0
    let recipeInfo: RecipeType | null = null
    let doughInfo: DoughType | null = null
    let fillingInfo: FillingType | null = null
    if (suggestRecipe) {
      // 設定されたレシピの詳細情報を取得
      const recipeInfoPayload = await dispatch(
        getRecipeById({ id: suggestRecipe.id }),
      )
      recipeInfo = recipeInfoPayload.payload
      if (recipeInfo) {
        // 合計量
        await Promise.all(
          recipeInfo.recipe_material_informations.map(
            async (material_information: MaterialInformationType) => {
              recipeTotalAmount +=
                material_information.middle_knead_percent +
                material_information.authentic_knead_percent
              // recipeMiddleKneadAmount +=
              //   material_information.middle_knead_percent
              // recipeAuthenticKneadAmount +=
              //   material_information.authentic_knead_percent
            },
          ),
        )
        await Promise.all(
          recipeInfo.dough_material_informations.map(
            async (material_information: MaterialInformationType) => {
              recipeTotalAmount +=
                material_information.middle_knead_percent +
                material_information.authentic_knead_percent
              // recipeMiddleKneadAmount +=
              //   material_information.middle_knead_percent
              // recipeAuthenticKneadAmount +=
              //   material_information.authentic_knead_percent
            },
          ),
        )
        await Promise.all(
          recipeInfo.filling_material_informations.map(
            async (material_information: MaterialInformationType) => {
              recipeTotalAmount +=
                material_information.middle_knead_percent +
                material_information.authentic_knead_percent
              // recipeMiddleKneadAmount +=
              //   material_information.middle_knead_percent
              // recipeAuthenticKneadAmount +=
              //   material_information.authentic_knead_percent
            },
          ),
        )
      }
    }
    if (suggestDough) {
      // 設定された生地パターンの詳細情報を取得
      const doughInfoPayload = await dispatch(
        getDoughById({ id: suggestDough.id }),
      )
      doughInfo = doughInfoPayload.payload
      // 生地パターンの合計量
      if (doughInfo) {
        await Promise.all(
          doughInfo.material_informations.map(
            async (material_information: MaterialInformationType) => {
              doughTotalAmount +=
                material_information.middle_knead_percent +
                material_information.authentic_knead_percent
              // doughMiddleKneadAmount +=
              //   material_information.middle_knead_percent
              // doughAuthenticKneadAmount +=
              //   material_information.authentic_knead_percent
            },
          ),
        )
      }
    }
    if (suggestFilling) {
      // 設定された生地パターンの詳細情報を取得
      const fillingInfoPayload = await dispatch(
        getFillingById({ id: suggestFilling.id }),
      )
      fillingInfo = fillingInfoPayload.payload
      // フィリングパターンの合計量
      if (fillingInfo) {
        await Promise.all(
          fillingInfo.material_informations.map(
            async (material_information: MaterialInformationType) => {
              fillingTotalAmount +=
                material_information.middle_knead_percent +
                material_information.authentic_knead_percent
              // fillingMiddleKneadAmount +=
              //   material_information.middle_knead_percent
              // fillingAuthenticKneadAmount +=
              //   material_information.authentic_knead_percent
            },
          ),
        )
      }
    }
    // percentageOfUseを算出
    let totalAmount = 0
    recipePetternInfos.forEach(
      (recipePetternInfo: BreadDetailPetternInformationType) => {
        totalAmount +=
          recipePetternInfo.middleKneadPercent +
          recipePetternInfo.authenticKneadPercent
      },
    )
    doughPetternInfos.forEach(
      (doughPetternInfo: BreadDetailPetternInformationType) => {
        totalAmount +=
          doughPetternInfo.middleKneadPercent +
          doughPetternInfo.authenticKneadPercent
      },
    )
    fillingPetternInfos.forEach(
      (fillingPetternInfo: BreadDetailPetternInformationType) => {
        totalAmount +=
          fillingPetternInfo.middleKneadPercent +
          fillingPetternInfo.authenticKneadPercent
      },
    )
    const recipePercentageOfUse =
      recipeTotalAmount /
      (recipeTotalAmount + doughTotalAmount + fillingTotalAmount + totalAmount)
    const doughPercentageOfUse =
      doughTotalAmount /
      (recipeTotalAmount + doughTotalAmount + fillingTotalAmount + totalAmount)
    const fillingPercentageOfUse =
      fillingTotalAmount /
      (recipeTotalAmount + doughTotalAmount + fillingTotalAmount + totalAmount)

    let allMaterialInfo: BreadDetailPetternInformationType[] = []
    const newRecipePetternInfos = _.cloneDeep(recipePetternInfos)
    const newDoughPetternInfos = _.cloneDeep(doughPetternInfos)
    const newFillingPetternInfos = _.cloneDeep(fillingPetternInfos)
    if (suggestRecipe && recipeInfo) {
      const childRecipeRecipePetternInfos: BreadDetailPetternInformationType[] =
        []
      const childRecipeDoughPetternInfos: BreadDetailPetternInformationType[] =
        []
      const childRecipeFillingPetternInfos: BreadDetailPetternInformationType[] =
        []
      await recipeInfo.recipe_material_informations.reduce(
        (
          promise: Promise<void>,
          material_information: MaterialInformationType,
        ) => {
          return promise.then(async () => {
            const info = await getMaterialInformation(
              material_information,
              recipeInfo && recipeInfo.is_gram
                ? UnitType.GRAM
                : UnitType.PERCENT,
              recipeTotalAmount,
              recipePercentageOfUse,
              Number(amountDoughBeforeBaking),
              recipeInfo && recipeInfo.shop_ref,
              null,
              recipeInfo && recipeInfo.id,
              false,
              allMaterialInfo,
            )
            if (!info) {
              return
            }
            childRecipeRecipePetternInfos.push(info)
          })
        },
        Promise.resolve(),
      )
      await recipeInfo.dough_material_informations.reduce(
        (
          promise: Promise<void>,
          material_information: MaterialInformationType,
        ) => {
          return promise.then(async () => {
            const info = await getMaterialInformation(
              material_information,
              recipeInfo && recipeInfo.is_gram
                ? UnitType.GRAM
                : UnitType.PERCENT,
              recipeTotalAmount,
              recipePercentageOfUse,
              Number(amountDoughBeforeBaking),
              recipeInfo && recipeInfo.shop_ref,
              null,
              recipeInfo && recipeInfo.id,
              false,
              allMaterialInfo,
            )
            if (!info) {
              return
            }
            childRecipeDoughPetternInfos.push(info)
          })
        },
        Promise.resolve(),
      )
      await recipeInfo.filling_material_informations.reduce(
        (
          promise: Promise<void>,
          material_information: MaterialInformationType,
        ) => {
          return promise.then(async () => {
            const info = await getMaterialInformation(
              material_information,
              recipeInfo && recipeInfo.is_gram
                ? UnitType.GRAM
                : UnitType.PERCENT,
              recipeTotalAmount,
              recipePercentageOfUse,
              Number(amountDoughBeforeBaking),
              recipeInfo && recipeInfo.shop_ref,
              null,
              recipeInfo && recipeInfo.id,
              false,
              allMaterialInfo,
            )
            if (!info) {
              return
            }
            childRecipeFillingPetternInfos.push(info)
          })
        },
        Promise.resolve(),
      )
      const recipePetternInfo: BreadDetailPetternInformationType = {
        productName: recipeInfo.recipe_name,
        category: '商品',
        categoryId: null,
        dataType: DataType.RECIPE,
        manufacturerName: null,
        middleKneadPercent: 0,
        authenticKneadPercent: 0,
        allPercent: 0,
        unitType:
          recipeInfo && recipeInfo.is_gram ? UnitType.GRAM : UnitType.PERCENT,
        energy: null,
        protein: null,
        lipid: null,
        carbohydrate: null,
        saltEquivalent: null,
        materialId: null,
        materialName: null,
        originName: null,
        additive: null,
        baseAllergens: null,
        additiveAllergens: null,
        percentageOfUse: recipePercentageOfUse,
        parentFillingId: null,
        parentRecipeId: null,
        callInRecipe: false,
        supplierCostPrice: null,
        supplierTax: null,
        internalCapacityMemo: null,
        yieldRate: 100,
        internalCapacityCount: null,
        internalCapacityUnit: null,
        numberItem: null,
        numberItemUnit: null,
        unitPrice1g: null,
        cost: null,
        burnout_rate: null,
        amount_dough_after_baking: null,
        is_after_baking_percent: null,
        is_after_baking_gram: null,
        notCalculatedReasons: null,
        recipes: childRecipeRecipePetternInfos,
        doughs: childRecipeDoughPetternInfos,
        fillings: childRecipeFillingPetternInfos,
        materials: null,
        alls: null,
      }
      newRecipePetternInfos.push(recipePetternInfo)
      setRecipePetternInfos(newRecipePetternInfos)
    }
    if (suggestDough && doughInfo) {
      const childDoughPetternInfos: BreadDetailPetternInformationType[] = []
      await doughInfo.material_informations.reduce(
        (
          promise: Promise<void>,
          material_information: MaterialInformationType,
        ) => {
          return promise.then(async () => {
            const info = await getMaterialInformation(
              material_information,
              doughInfo && doughInfo.is_gram ? UnitType.GRAM : UnitType.PERCENT,
              doughTotalAmount,
              doughPercentageOfUse,
              Number(amountDoughBeforeBaking),
              doughInfo && doughInfo.shop_ref,
              null,
              null,
              false,
              allMaterialInfo,
            )
            if (!info) {
              return
            }
            childDoughPetternInfos.push(info)
          })
        },
        Promise.resolve(),
      )
      const parentDoughPetternInfo: BreadDetailPetternInformationType = {
        productName: doughInfo.pettern_name,
        category: '生地パターン',
        categoryId: null,
        dataType: DataType.DOUGH,
        manufacturerName: null,
        middleKneadPercent: 0,
        authenticKneadPercent: 0,
        allPercent: 0,
        unitType:
          doughInfo && doughInfo.is_gram ? UnitType.GRAM : UnitType.PERCENT,
        energy: null,
        protein: null,
        lipid: null,
        carbohydrate: null,
        saltEquivalent: null,
        materialId: null,
        materialName: null,
        originName: null,
        additive: null,
        baseAllergens: null,
        additiveAllergens: null,
        percentageOfUse: doughPercentageOfUse,
        parentFillingId: null,
        parentRecipeId: null,
        callInRecipe: false,
        supplierCostPrice: null,
        supplierTax: null,
        internalCapacityMemo: null,
        yieldRate: 100,
        internalCapacityCount: null,
        internalCapacityUnit: null,
        numberItem: null,
        numberItemUnit: null,
        unitPrice1g: null,
        cost: null,
        burnout_rate: null,
        amount_dough_after_baking: null,
        is_after_baking_percent: null,
        is_after_baking_gram: null,
        notCalculatedReasons: null,
        recipes: null,
        doughs: childDoughPetternInfos,
        fillings: null,
        materials: null,
        alls: null,
      }
      newDoughPetternInfos.push(parentDoughPetternInfo)
      setDoughPetternInfos(newDoughPetternInfos)
    }
    if (suggestFilling && fillingInfo) {
      const childFillingPetternInfos: BreadDetailPetternInformationType[] = []
      await fillingInfo.material_informations.reduce(
        (
          promise: Promise<void>,
          material_information: MaterialInformationType,
        ) => {
          return promise.then(async () => {
            const info = await getMaterialInformation(
              material_information,
              fillingInfo && fillingInfo.is_gram
                ? UnitType.GRAM
                : UnitType.PERCENT,
              fillingTotalAmount,
              fillingPercentageOfUse,
              Number(amountDoughBeforeBaking),
              fillingInfo && fillingInfo.shop_ref,
              fillingInfo && fillingInfo.id,
              null,
              false,
              allMaterialInfo,
            )
            if (!info) {
              return
            }
            childFillingPetternInfos.push(info)
          })
        },
        Promise.resolve(),
      )
      const parentFillingPetternInfo: BreadDetailPetternInformationType = {
        productName: fillingInfo.pettern_name,
        category: 'フィリングパターン',
        categoryId: null,
        dataType: DataType.FILLING,
        manufacturerName: null,
        middleKneadPercent: 0,
        authenticKneadPercent: 0,
        allPercent: 0,
        unitType:
          fillingInfo && fillingInfo.is_gram ? UnitType.GRAM : UnitType.PERCENT,
        energy: null,
        protein: null,
        lipid: null,
        carbohydrate: null,
        saltEquivalent: null,
        materialId: null,
        materialName: null,
        originName: null,
        additive: null,
        baseAllergens: null,
        additiveAllergens: null,
        percentageOfUse: fillingPercentageOfUse,
        parentFillingId: null,
        parentRecipeId: null,
        callInRecipe: false,
        supplierCostPrice: null,
        supplierTax: null,
        internalCapacityMemo: null,
        yieldRate: 100,
        internalCapacityCount: null,
        internalCapacityUnit: null,
        numberItem: null,
        numberItemUnit: null,
        unitPrice1g: null,
        cost: null,
        burnout_rate: null,
        amount_dough_after_baking: null,
        is_after_baking_percent: null,
        is_after_baking_gram: null,
        notCalculatedReasons: null,
        recipes: null,
        doughs: null,
        fillings: childFillingPetternInfos,
        materials: null,
        alls: null,
      }
      newFillingPetternInfos.push(parentFillingPetternInfo)
      setFillingPetternInfos(newFillingPetternInfos)
    }
    dispatch(setIsLoading({ isLoading: false }))
  }

  const calcAllMaterialInfos = (
    petternInformation: BreadDetailPetternInformationType,
  ) => {
    let allMaterialInfo: BreadDetailPetternInformationType[] = []
    if (petternInformation.recipes) {
      petternInformation.recipes
        .filter((recipe) => recipe.materialId)
        .forEach((recipe) => {
          allMaterialInfo.push(recipe)
        })
      petternInformation.recipes
        .filter((recipe) => !recipe.materialId)
        .forEach((recipe) => {
          allMaterialInfo = allMaterialInfo.concat(calcAllMaterialInfos(recipe))
        })
    }
    if (petternInformation.doughs) {
      petternInformation.doughs
        .filter((dough) => dough.materialId)
        .forEach((dough) => {
          allMaterialInfo.push(dough)
        })
      petternInformation.doughs
        .filter((dough) => !dough.materialId)
        .forEach((dough) => {
          allMaterialInfo = allMaterialInfo.concat(calcAllMaterialInfos(dough))
        })
    }
    if (petternInformation.fillings) {
      petternInformation.fillings
        .filter((filling) => filling.materialId)
        .forEach((filling) => {
          allMaterialInfo.push(filling)
        })
      petternInformation.fillings
        .filter((filling) => !filling.materialId)
        .forEach((filling) => {
          allMaterialInfo = allMaterialInfo.concat(
            calcAllMaterialInfos(filling),
          )
        })
    }
    if (petternInformation.materials) {
      petternInformation.materials.forEach((filling) => {
        allMaterialInfo.push(filling)
      })
    }
    if (petternInformation.materialId) {
      allMaterialInfo.push(petternInformation)
    }
    return allMaterialInfo
  }

  const calcCost = async (
    inputRecipePetternInfos?: BreadDetailPetternInformationType[],
    inputDoughPetternInfos?: BreadDetailPetternInformationType[],
    inputFillingPetternInfos?: BreadDetailPetternInformationType[],
  ) => {
    const calcRecipePetternInfos = inputRecipePetternInfos
      ? _.cloneDeep(inputRecipePetternInfos)
      : _.cloneDeep(recipePetternInfos)
    const calcDoughPetternInfos = inputDoughPetternInfos
      ? _.cloneDeep(inputDoughPetternInfos)
      : _.cloneDeep(doughPetternInfos)
    const calcFillingPetternInfos = inputFillingPetternInfos
      ? _.cloneDeep(inputFillingPetternInfos)
      : _.cloneDeep(fillingPetternInfos)
    let allMaterialInfos: BreadDetailPetternInformationType[] = []
    await Promise.all(
      calcRecipePetternInfos.map((recipe) => {
        allMaterialInfos = allMaterialInfos.concat(calcAllMaterialInfos(recipe))
        return allMaterialInfos
      }),
    )
    await Promise.all(
      calcDoughPetternInfos.map((dough) => {
        allMaterialInfos = allMaterialInfos.concat(calcAllMaterialInfos(dough))
        return allMaterialInfos
      }),
    )
    await Promise.all(
      calcFillingPetternInfos.map((filling) => {
        allMaterialInfos = allMaterialInfos.concat(
          calcAllMaterialInfos(filling),
        )
        return allMaterialInfos
      }),
    )
    // 原価計算
    const finalCostInfo = await getFinalCost(
      allMaterialInfos,
      Number(amountDoughBeforeBaking),
      Number(lossRate) / 100,
      calcPackagingCostPrice,
    )
    const finalCost = finalCostInfo.finalCost
      ? String(Math.floor((finalCostInfo.finalCost * 10) / 10).toFixed(1))
      : ''
    handleCalcCostPrice(finalCost)
    const noPackageingCost = finalCostInfo.noPackageingCost
      ? String(
          Math.floor((finalCostInfo.noPackageingCost * 10) / 10).toFixed(1),
        )
      : ''
    handleCalcMaterialCostPrice(noPackageingCost)
    const costRate =
      calcSellingPrice && finalCost
        ? Math.floor(
            (Number(finalCost) /
              // TODO: UIからはcalcSellingPriceには税込金額が入力される。ゆくゆく税別で取り扱った方が良い
              // (Number(calcSellingPrice) * TAX)) *
              Number(calcSellingPrice)) *
              100,
          )
        : null
    handleCalcCostRate(costRate ? String(costRate) : '')
    handleCalcAmountDoughBeforeBaking(String(amountDoughBeforeBaking))

    return {
      finalCost: Number(finalCost),
      noPackageingCost: Number(noPackageingCost),
      packagingCostPrice: calcPackagingCostPrice,
      costRate: costRate,
      amountDoughBeforeBaking: amountDoughBeforeBaking,
    }
  }

  const addNewRecipe = () => {
    const newRecipePetternInfos = _.cloneDeep(recipePetternInfos)
    const parentRecipePetternInfo: BreadDetailPetternInformationType = {
      productName: '',
      category: '',
      categoryId: null,
      dataType: DataType.MATERIAL,
      manufacturerName: null,
      middleKneadPercent: 0,
      authenticKneadPercent: 0,
      allPercent: 0,
      unitType: UnitType.GRAM,
      energy: null,
      protein: null,
      lipid: null,
      carbohydrate: null,
      saltEquivalent: null,
      materialId: 'manualInput',
      materialName: null,
      originName: null,
      additive: null,
      baseAllergens: null,
      additiveAllergens: null,
      percentageOfUse: 0,
      parentFillingId: null,
      parentRecipeId: null,
      callInRecipe: false,
      supplierCostPrice: null,
      supplierTax: null,
      internalCapacityMemo: null,
      yieldRate: 100,
      internalCapacityCount: null,
      internalCapacityUnit: null,
      numberItem: null,
      numberItemUnit: null,
      unitPrice1g: null,
      cost: null,
      burnout_rate: null,
      amount_dough_after_baking: null,
      is_after_baking_percent: null,
      is_after_baking_gram: null,
      notCalculatedReasons: null,
      recipes: null,
      doughs: null,
      fillings: null,
      materials: null,
      alls: null,
    }
    newRecipePetternInfos.push(parentRecipePetternInfo)
    setRecipePetternInfos(newRecipePetternInfos)
  }

  const addNewDough = () => {
    const newDoughPetternInfos = _.cloneDeep(doughPetternInfos)
    const parentDoughPetternInfo: BreadDetailPetternInformationType = {
      productName: '',
      category: '',
      categoryId: null,
      dataType: DataType.MATERIAL,
      manufacturerName: null,
      middleKneadPercent: 0,
      authenticKneadPercent: 0,
      allPercent: 0,
      unitType: UnitType.GRAM,
      energy: null,
      protein: null,
      lipid: null,
      carbohydrate: null,
      saltEquivalent: null,
      materialId: 'manualInput',
      materialName: null,
      originName: null,
      additive: null,
      baseAllergens: null,
      additiveAllergens: null,
      percentageOfUse: 0,
      parentFillingId: null,
      parentRecipeId: null,
      callInRecipe: false,
      supplierCostPrice: null,
      supplierTax: null,
      internalCapacityMemo: null,
      yieldRate: 100,
      internalCapacityCount: null,
      internalCapacityUnit: null,
      numberItem: null,
      numberItemUnit: null,
      unitPrice1g: null,
      cost: null,
      burnout_rate: null,
      amount_dough_after_baking: null,
      is_after_baking_percent: null,
      is_after_baking_gram: null,
      notCalculatedReasons: null,
      recipes: null,
      doughs: null,
      fillings: null,
      materials: null,
      alls: null,
    }
    newDoughPetternInfos.push(parentDoughPetternInfo)
    setDoughPetternInfos(newDoughPetternInfos)
  }

  const addNewFilling = () => {
    const newFillingPetternInfos = _.cloneDeep(fillingPetternInfos)
    const parentFillingPetternInfo: BreadDetailPetternInformationType = {
      productName: '',
      category: '',
      categoryId: null,
      dataType: DataType.MATERIAL,
      manufacturerName: null,
      middleKneadPercent: 0,
      authenticKneadPercent: 0,
      allPercent: 0,
      unitType: UnitType.GRAM,
      energy: null,
      protein: null,
      lipid: null,
      carbohydrate: null,
      saltEquivalent: null,
      materialId: 'manualInput',
      materialName: null,
      originName: null,
      additive: null,
      baseAllergens: null,
      additiveAllergens: null,
      percentageOfUse: 0,
      parentFillingId: null,
      parentRecipeId: null,
      callInRecipe: false,
      supplierCostPrice: null,
      supplierTax: null,
      internalCapacityMemo: null,
      yieldRate: 100,
      internalCapacityCount: null,
      internalCapacityUnit: null,
      numberItem: null,
      numberItemUnit: null,
      unitPrice1g: null,
      cost: null,
      burnout_rate: null,
      amount_dough_after_baking: null,
      is_after_baking_percent: null,
      is_after_baking_gram: null,
      notCalculatedReasons: null,
      recipes: null,
      doughs: null,
      fillings: null,
      materials: null,
      alls: null,
    }
    newFillingPetternInfos.push(parentFillingPetternInfo)
    setFillingPetternInfos(newFillingPetternInfos)
  }

  const addEditDig = (
    targetPetternInfo: BreadDetailPetternInformationType,
    params: (number | DataType | boolean)[],
  ): BreadDetailPetternInformationType => {
    const targetDataType = params.shift()
    const targetDataIsMaterial = params.shift()
    const targetDataTypeNum = params.shift()
    if (
      targetDataType === undefined ||
      targetDataIsMaterial === undefined ||
      targetDataTypeNum === undefined ||
      typeof targetDataIsMaterial !== 'boolean' ||
      typeof targetDataTypeNum !== 'number'
    ) {
      // 引数で指定されたtargetPetternInfoをそのまま返す
      return targetPetternInfo
    }
    let newTargetPetternInfo =
      targetDataType === DataType.RECIPE && targetPetternInfo.recipes
        ? targetPetternInfo.recipes.filter(
            (recipe) =>
              (targetDataIsMaterial === true && recipe.materialId) ||
              (targetDataIsMaterial === false && !recipe.materialId),
          )[targetDataTypeNum]
        : targetDataType === DataType.DOUGH && targetPetternInfo.doughs
        ? targetPetternInfo.doughs.filter(
            (dough) =>
              (targetDataIsMaterial === true && dough.materialId) ||
              (targetDataIsMaterial === false && !dough.materialId),
          )[targetDataTypeNum]
        : targetDataType === DataType.FILLING && targetPetternInfo.fillings
        ? targetPetternInfo.fillings.filter(
            (filling) =>
              (targetDataIsMaterial === true && filling.materialId) ||
              (targetDataIsMaterial === false && !filling.materialId),
          )[targetDataTypeNum]
        : targetDataType === DataType.MATERIAL && targetPetternInfo.materials
        ? targetPetternInfo.materials[targetDataTypeNum]
        : null
    if (newTargetPetternInfo === null) {
      // 想定された値が渡されなければ引数で指定されたtargetPetternInfoをそのまま返す
      return targetPetternInfo
    }
    // 下層の情報を指定する必要があれば辿って取得
    newTargetPetternInfo = addEditDig(newTargetPetternInfo, params)
    return newTargetPetternInfo
  }

  const handleAddButton = (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
    unitType: UnitType.GRAM | UnitType.PERCENT,
  ) => {
    if (dataType === undefined || params === undefined) {
      return
    }
    const newPetternInfos =
      dataType === DataType.RECIPE
        ? _.cloneDeep(recipePetternInfos)
        : dataType === DataType.DOUGH
        ? _.cloneDeep(doughPetternInfos)
        : dataType === DataType.FILLING
        ? _.cloneDeep(fillingPetternInfos)
        : null
    if (newPetternInfos === null) {
      return
    }
    const firstNum = params.shift()
    if (firstNum === undefined || typeof firstNum !== 'number') {
      return
    }
    let addTargetPetternInfo = newPetternInfos[firstNum]
    addTargetPetternInfo = addEditDig(addTargetPetternInfo, params)
    // 空の原材料情報を追加する
    if (addTargetPetternInfo.materials === null) {
      addTargetPetternInfo['materials'] = []
    }
    addTargetPetternInfo.materials.push({
      productName: '',
      category: '',
      categoryId: null,
      manufacturerName: null,
      dataType: DataType.DOUGH,
      middleKneadPercent: 0,
      authenticKneadPercent: 0,
      allPercent: 0,
      unitType: unitType,
      energy: null,
      protein: null,
      lipid: null,
      carbohydrate: null,
      saltEquivalent: null,
      materialId: null,
      materialName: null,
      originName: null,
      additive: null,
      baseAllergens: null,
      additiveAllergens: null,
      percentageOfUse: 0,
      parentFillingId: null,
      parentRecipeId: null,
      callInRecipe: false,
      supplierCostPrice: null,
      supplierTax: null,
      internalCapacityMemo: null,
      yieldRate: 100,
      internalCapacityCount: null,
      internalCapacityUnit: null,
      numberItem: null,
      numberItemUnit: null,
      unitPrice1g: null,
      cost: null,
      burnout_rate: null,
      amount_dough_after_baking: null,
      is_after_baking_percent: null,
      is_after_baking_gram: null,
      notCalculatedReasons: null,
      recipes: null,
      doughs: null,
      fillings: null,
      materials: null,
      alls: null,
    })
    if (dataType === DataType.RECIPE) {
      setRecipePetternInfos(newPetternInfos)
    }
    if (dataType === DataType.DOUGH) {
      setDoughPetternInfos(newPetternInfos)
    }
    if (dataType === DataType.FILLING) {
      setFillingPetternInfos(newPetternInfos)
    }
  }

  const handleEditRecord = (
    dataType: DataType | undefined,
    key:
      | 'productName'
      | 'manufacturerName'
      | 'materialId'
      | 'middleKneadPercent'
      | 'authenticKneadPercent'
      | 'supplierCostPrice'
      | 'supplierTax'
      | 'internalCapacityMemo'
      | 'yieldRate'
      | 'percentageOfUse'
      | 'internalCapacityCount'
      | 'internalCapacityUnit'
      | 'numberItem'
      | 'numberItemUnit'
      | 'unitPrice1g'
      | 'notCalculatedReasons'
      | 'cost',
    value: any,
    params: (number | DataType | boolean)[] | undefined,
    inputPetternInfos: BreadDetailPetternInformationType[] | null = null,
  ) => {
    if (dataType === undefined || params === undefined) {
      return []
    }
    const newPetternInfos = inputPetternInfos
      ? _.cloneDeep(inputPetternInfos)
      : dataType === DataType.RECIPE
      ? _.cloneDeep(recipePetternInfos)
      : dataType === DataType.DOUGH
      ? _.cloneDeep(doughPetternInfos)
      : dataType === DataType.FILLING
      ? _.cloneDeep(fillingPetternInfos)
      : null
    if (newPetternInfos === null) {
      return []
    }
    const firstNum = params.shift()
    if (firstNum === undefined || typeof firstNum !== 'number') {
      return []
    }
    let editTargetPetternInfo = newPetternInfos[firstNum]
    const newParams = [...params]
    editTargetPetternInfo = addEditDig(editTargetPetternInfo, newParams)
    // @ts-ignore
    editTargetPetternInfo[key] = value

    if (dataType === DataType.RECIPE) {
      setRecipePetternInfos(newPetternInfos)
    }
    if (dataType === DataType.DOUGH) {
      setDoughPetternInfos(newPetternInfos)
    }
    if (dataType === DataType.FILLING) {
      setFillingPetternInfos(newPetternInfos)
    }
    return newPetternInfos
  }

  const handleGetRecord = (
    dataType: DataType | undefined,
    key:
      | 'productName'
      | 'manufacturerName'
      | 'middleKneadPercent'
      | 'authenticKneadPercent'
      | 'supplierCostPrice'
      | 'supplierTax'
      | 'internalCapacityMemo'
      | 'yieldRate'
      | 'percentageOfUse'
      | 'internalCapacityCount'
      | 'internalCapacityUnit'
      | 'numberItem'
      | 'numberItemUnit'
      | 'unitPrice1g'
      | 'cost',
    params: (number | DataType | boolean)[] | undefined,
    inputPetternInfos: BreadDetailPetternInformationType[] | null = null,
  ) => {
    if (dataType === undefined || params === undefined) {
      return null
    }
    const newPetternInfos = inputPetternInfos
      ? _.cloneDeep(inputPetternInfos)
      : dataType === DataType.RECIPE
      ? _.cloneDeep(recipePetternInfos)
      : dataType === DataType.DOUGH
      ? _.cloneDeep(doughPetternInfos)
      : dataType === DataType.FILLING
      ? _.cloneDeep(fillingPetternInfos)
      : null
    if (newPetternInfos === null) {
      return null
    }
    const firstNum = params.shift()
    if (firstNum === undefined || typeof firstNum !== 'number') {
      return null
    }
    console.log(20240915999, newPetternInfos)
    let targetPetternInfo = newPetternInfos[firstNum]
    targetPetternInfo = addEditDig(targetPetternInfo, params)
    return targetPetternInfo[key]
  }

  const handleDeleteButton = async (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => {
    if (dataType === undefined || params === undefined) {
      return
    }
    const newPetternInfos =
      dataType === DataType.RECIPE
        ? _.cloneDeep(recipePetternInfos)
        : dataType === DataType.DOUGH
        ? _.cloneDeep(doughPetternInfos)
        : dataType === DataType.FILLING
        ? _.cloneDeep(fillingPetternInfos)
        : null
    if (newPetternInfos === null) {
      return
    }
    const firstNum = params.shift()
    if (firstNum === undefined || typeof firstNum !== 'number') {
      return
    }
    const deleteTargetDataTypeNum = params.pop()
    const deleteTargetDataIsMaterial = params.pop()
    const deleteTargetDataType = params.pop()
    if (
      deleteTargetDataTypeNum === undefined ||
      deleteTargetDataIsMaterial === undefined ||
      deleteTargetDataType === undefined ||
      typeof deleteTargetDataIsMaterial !== 'boolean' ||
      typeof deleteTargetDataTypeNum !== 'number'
    ) {
      // 何もしない
      return
    }
    let deleteTargetPetternInfo = newPetternInfos[firstNum]
    deleteTargetPetternInfo = addEditDig(deleteTargetPetternInfo, params)
    if (
      deleteTargetDataType === DataType.RECIPE &&
      deleteTargetPetternInfo.recipes
    ) {
      // delete deleteTargetPetternInfo.recipes.filter(
      //   (recipe) => recipe.materialId,
      // )[deleteTargetDataTypeNum]
      let petternRecipes = deleteTargetPetternInfo.recipes.filter(
        (recipe) => !recipe.materialId,
      )
      let targetRecipes = deleteTargetPetternInfo.recipes.filter(
        (recipe) => recipe.materialId,
      )
      targetRecipes = targetRecipes.filter(
        (_, i) => i !== deleteTargetDataTypeNum,
      )
      deleteTargetPetternInfo.recipes = [
        ..._.cloneDeep(targetRecipes),
        ..._.cloneDeep(petternRecipes),
      ]
    }
    if (
      deleteTargetDataType === DataType.DOUGH &&
      deleteTargetPetternInfo.doughs
    ) {
      // delete deleteTargetPetternInfo.doughs.filter(
      //   (dough) => dough.materialId,
      // )[deleteTargetDataTypeNum]
      let petternDoughs = deleteTargetPetternInfo.doughs.filter(
        (dough) => !dough.materialId,
      )
      let targetDoughs = deleteTargetPetternInfo.doughs.filter(
        (dough) => dough.materialId,
      )
      targetDoughs = targetDoughs.filter(
        (_, i) => i !== deleteTargetDataTypeNum,
      )
      deleteTargetPetternInfo.doughs = [
        ..._.cloneDeep(targetDoughs),
        ..._.cloneDeep(petternDoughs),
      ]
    }
    if (
      deleteTargetDataType === DataType.FILLING &&
      deleteTargetPetternInfo.fillings
    ) {
      // delete deleteTargetPetternInfo.fillings.filter(
      //   (filling) => filling.materialId,
      // )[deleteTargetDataTypeNum]
      let petternFillings = deleteTargetPetternInfo.fillings.filter(
        (filling) => !filling.materialId,
      )
      let targetFillings = deleteTargetPetternInfo.fillings.filter(
        (filling) => filling.materialId,
      )
      targetFillings = targetFillings.filter(
        (_, i) => i !== deleteTargetDataTypeNum,
      )
      deleteTargetPetternInfo.fillings = [
        ..._.cloneDeep(targetFillings),
        ..._.cloneDeep(petternFillings),
      ]
    }
    if (
      deleteTargetDataType === DataType.MATERIAL &&
      deleteTargetPetternInfo.materials
    ) {
      let targetMaterials = deleteTargetPetternInfo.materials.filter(
        (_, i) => i !== deleteTargetDataTypeNum,
      )
      deleteTargetPetternInfo.materials = [..._.cloneDeep(targetMaterials)]
    }

    if (dataType === DataType.RECIPE) {
      setRecipePetternInfos(newPetternInfos)
    }
    if (dataType === DataType.DOUGH) {
      setDoughPetternInfos(newPetternInfos)
    }
    if (dataType === DataType.FILLING) {
      setFillingPetternInfos(newPetternInfos)
    }

    // const calcResult = await calcPercentageOfUse(
    //   dataType === DataType.RECIPE ? newPetternInfos : undefined,
    //   dataType === DataType.DOUGH ? newPetternInfos : undefined,
    //   dataType === DataType.FILLING ? newPetternInfos : undefined,
    // )
    // const new2PetternInfos =
    //   dataType === DataType.RECIPE && calcResult.calc2RecipePetternInfos
    //     ? calcResult.calc2RecipePetternInfos
    //     : dataType === DataType.DOUGH && calcResult.calc2DoughPetternInfos
    //     ? calcResult.calc2DoughPetternInfos
    //     : dataType === DataType.FILLING && calcResult.calc2FillingPetternInfos
    //     ? calcResult.calc2FillingPetternInfos
    //     : newPetternInfos

    // // 各原材料ごとの原価計算
    // const calcRecipePetternInfos = await calcMaterialRecordCost(
    //   dataType === DataType.RECIPE ? new2PetternInfos : null,
    //   DataType.RECIPE,
    //   [],
    // )
    // const calcDoughPetternInfos = await calcMaterialRecordCost(
    //   dataType === DataType.DOUGH ? new2PetternInfos : null,
    //   DataType.DOUGH,
    //   [],
    // )
    // const calcFillingPetternInfos = await calcMaterialRecordCost(
    //   dataType === DataType.FILLING ? new2PetternInfos : null,
    //   DataType.FILLING,
    //   [],
    // )

    // if (dataType === DataType.RECIPE) {
    //   setRecipePetternInfos(calcRecipePetternInfos)
    // }
    // if (dataType === DataType.DOUGH) {
    //   setDoughPetternInfos(calcDoughPetternInfos)
    // }
    // if (dataType === DataType.FILLING) {
    //   setFillingPetternInfos(calcFillingPetternInfos)
    // }

    // // 最終的な原価計算
    // await calcAllCost(
    //   calcRecipePetternInfos,
    //   calcDoughPetternInfos,
    //   calcFillingPetternInfos,
    // )
  }

  const handleIsPreview = async (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => {
    window.scrollTo(0, 0)
    setIsPreviewInternalCapacity(true)
    setIsPreviewDataType(dataType)
    setIsPreviewParams(params)

    // 使用割合を再計算
    await calcPercentageOfUse()

    let newParams = params !== undefined ? [...params] : []
    console.log(202409150101, dataType, newParams)
    const costPrice = handleGetRecord(dataType, 'supplierCostPrice', newParams)
    console.log(202409150102, dataType, newParams, costPrice)
    handleCostPrice(costPrice || costPrice === 0 ? String(costPrice) : '')

    newParams = params !== undefined ? [...params] : []
    const tax = handleGetRecord(dataType, 'supplierTax', newParams)
    handleTax(tax ? Number(tax) : 8)

    newParams = params !== undefined ? [...params] : []
    const internalCapacityMemo = handleGetRecord(
      dataType,
      'internalCapacityMemo',
      newParams,
    )
    handleInternalCapacityMemo(
      internalCapacityMemo ? String(internalCapacityMemo) : '',
    )

    newParams = params !== undefined ? [...params] : []
    const yieldRate = handleGetRecord(dataType, 'yieldRate', newParams)
    handleYieldRate(yieldRate ? String(yieldRate) : '100')

    newParams = params !== undefined ? [...params] : []
    const percentageOfUse = handleGetRecord(
      dataType,
      'percentageOfUse',
      newParams,
    )
    handlePercentageOfUse(percentageOfUse ? Number(percentageOfUse) : 0)

    newParams = params !== undefined ? [...params] : []
    console.log(202409150201, dataType, newParams)
    const internalCapacityCount = handleGetRecord(
      dataType,
      'internalCapacityCount',
      newParams,
    )
    console.log(202409150202, dataType, newParams, internalCapacityCount)
    handleInternalCapacityCount(
      internalCapacityCount ? String(internalCapacityCount) : '1',
    )

    newParams = params !== undefined ? [...params] : []
    console.log(202409150301, dataType, newParams)
    const internalCapacityUnit = handleGetRecord(
      dataType,
      'internalCapacityUnit',
      newParams,
    )
    console.log(202409150302, dataType, newParams, internalCapacityUnit)
    handleInternalCapacityUnit(
      internalCapacityUnit ? String(internalCapacityUnit) : 'g',
    )

    newParams = params !== undefined ? [...params] : []
    const numberItem = handleGetRecord(dataType, 'numberItem', newParams)
    handleNumberItem(numberItem ? String(numberItem) : '1')

    newParams = params !== undefined ? [...params] : []
    const numberItemUnit = handleGetRecord(
      dataType,
      'numberItemUnit',
      newParams,
    )
    handleNumberItemUnit(numberItemUnit ? String(numberItemUnit) : '個')
  }

  const handleIsPreviewCancel = () => {
    setIsPreviewInternalCapacity(false)
    setIsPreviewDataType(undefined)
    setIsPreviewParams(undefined)

    handleCostPrice('')
    handleTaxIncludedCostPrice('')
    handleTax(8)
    handleInternalCapacityMemo('')
    handleYieldRate('100')
    handleInternalCapacityCount('')
    handleInternalCapacityUnit('')
    handleNumberItem('')
    handleNumberItemUnit('')
  }

  const handleIsPreviewSubmit = async () => {
    setIsPreviewInternalCapacity(false)

    let newIsPreviewParams =
      isPreviewParams !== undefined ? [...isPreviewParams] : []
    let petternInfos = handleEditRecord(
      isPreviewDataType,
      'supplierCostPrice',
      costPrice,
      newIsPreviewParams,
    )

    newIsPreviewParams =
      isPreviewParams !== undefined ? [...isPreviewParams] : []
    petternInfos = handleEditRecord(
      isPreviewDataType,
      'supplierTax',
      tax,
      newIsPreviewParams,
      petternInfos,
    )

    newIsPreviewParams =
      isPreviewParams !== undefined ? [...isPreviewParams] : []
    petternInfos = handleEditRecord(
      isPreviewDataType,
      'internalCapacityMemo',
      internalCapacityMemo,
      newIsPreviewParams,
      petternInfos,
    )

    newIsPreviewParams =
      isPreviewParams !== undefined ? [...isPreviewParams] : []
    petternInfos = handleEditRecord(
      isPreviewDataType,
      'yieldRate',
      yieldRate,
      newIsPreviewParams,
      petternInfos,
    )

    newIsPreviewParams =
      isPreviewParams !== undefined ? [...isPreviewParams] : []
    petternInfos = handleEditRecord(
      isPreviewDataType,
      'internalCapacityCount',
      internalCapacityCount,
      newIsPreviewParams,
      petternInfos,
    )

    newIsPreviewParams =
      isPreviewParams !== undefined ? [...isPreviewParams] : []
    petternInfos = handleEditRecord(
      isPreviewDataType,
      'internalCapacityUnit',
      internalCapacityUnit,
      newIsPreviewParams,
      petternInfos,
    )

    newIsPreviewParams =
      isPreviewParams !== undefined ? [...isPreviewParams] : []
    petternInfos = handleEditRecord(
      isPreviewDataType,
      'numberItem',
      numberItem,
      newIsPreviewParams,
      petternInfos,
    )

    newIsPreviewParams =
      isPreviewParams !== undefined ? [...isPreviewParams] : []
    petternInfos = handleEditRecord(
      isPreviewDataType,
      'numberItemUnit',
      numberItemUnit,
      newIsPreviewParams,
      petternInfos,
    )

    let unitAdjustment = 1
    if (internalCapacityUnit === 'kg') {
      unitAdjustment = 1000
    } else if (internalCapacityUnit === 'mL') {
      unitAdjustment = 1
    } else if (internalCapacityUnit === 'L') {
      unitAdjustment = 1000
    }
    const unitPrice1g = costPrice
      ? (Number(costPrice) * (tax / 100 + 1)) /
        (yieldRate / 100) /
        Number(internalCapacityCount) /
        numberItem /
        unitAdjustment
      : null

    newIsPreviewParams =
      isPreviewParams !== undefined ? [...isPreviewParams] : []
    petternInfos = handleEditRecord(
      isPreviewDataType,
      'unitPrice1g',
      unitPrice1g,
      newIsPreviewParams,
      petternInfos,
    )

    let calcTargetPercentageOfUse = percentageOfUse

    if (!calcTargetPercentageOfUse) {
      const calcResult = await calcPercentageOfUse(
        isPreviewDataType === DataType.RECIPE ? petternInfos : undefined,
        isPreviewDataType === DataType.DOUGH ? petternInfos : undefined,
        isPreviewDataType === DataType.FILLING ? petternInfos : undefined,
      )
      petternInfos =
        isPreviewDataType === DataType.RECIPE &&
        calcResult.calc2RecipePetternInfos
          ? calcResult.calc2RecipePetternInfos
          : isPreviewDataType === DataType.DOUGH &&
            calcResult.calc2DoughPetternInfos
          ? calcResult.calc2DoughPetternInfos
          : isPreviewDataType === DataType.FILLING &&
            calcResult.calc2FillingPetternInfos
          ? calcResult.calc2FillingPetternInfos
          : petternInfos

      newIsPreviewParams =
        isPreviewParams !== undefined ? [...isPreviewParams] : []
      calcTargetPercentageOfUse = Number(
        handleGetRecord(
          isPreviewDataType,
          'percentageOfUse',
          newIsPreviewParams,
        ),
      )
    }

    // TODO: コストの保存 フィリングパターンの完成後受領を考慮するか否か
    if (unitPrice1g || unitPrice1g === 0) {
      const cost =
        Number(amountDoughBeforeBaking) *
        calcTargetPercentageOfUse *
        unitPrice1g
      newIsPreviewParams =
        isPreviewParams !== undefined ? [...isPreviewParams] : []
      petternInfos = handleEditRecord(
        isPreviewDataType,
        'cost',
        cost,
        newIsPreviewParams,
        petternInfos,
      )
    }

    setIsPreviewDataType(undefined)
    setIsPreviewParams(undefined)

    const calcResult = await calcPercentageOfUse(
      isPreviewDataType === DataType.RECIPE ? petternInfos : undefined,
      isPreviewDataType === DataType.DOUGH ? petternInfos : undefined,
      isPreviewDataType === DataType.FILLING ? petternInfos : undefined,
    )
    petternInfos =
      isPreviewDataType === DataType.RECIPE &&
      calcResult.calc2RecipePetternInfos
        ? calcResult.calc2RecipePetternInfos
        : isPreviewDataType === DataType.DOUGH &&
          calcResult.calc2DoughPetternInfos
        ? calcResult.calc2DoughPetternInfos
        : isPreviewDataType === DataType.FILLING &&
          calcResult.calc2FillingPetternInfos
        ? calcResult.calc2FillingPetternInfos
        : petternInfos

    // 各原材料ごとの原価計算
    await calcMaterialRecordCost(
      isPreviewDataType === DataType.RECIPE ? petternInfos : null,
      DataType.RECIPE,
      [],
    )
    await calcMaterialRecordCost(
      isPreviewDataType === DataType.DOUGH ? petternInfos : null,
      DataType.DOUGH,
      [],
    )
    await calcMaterialRecordCost(
      isPreviewDataType === DataType.FILLING ? petternInfos : null,
      DataType.FILLING,
      [],
    )
    handleTaxIncludedCostPrice('')
  }

  const handleInternalCapacityCount = (internalCapacityCount: string) => {
    // 半角数字に変換
    internalCapacityCount = internalCapacityCount.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(internalCapacityCount) && Number(internalCapacityCount) !== 0) {
      return
    }
    setInternalCapacityCount(internalCapacityCount)
  }

  const handleValidateInternalCapacityCount = (
    internalCapacityCount: string,
  ) => {
    // 0は入力できない
    if (internalCapacityCount !== '' && Number(internalCapacityCount) === 0) {
      setInternalCapacityCountErrorText('内容量0は設定できません')
      return
    } else {
      setInternalCapacityCountErrorText('')
    }
  }

  const handleInternalCapacityUnit = (internalCapacityUnit: string) => {
    setInternalCapacityUnit(internalCapacityUnit)
  }

  const handleNumberItem = (numberItem: string) => {
    // 半角数字に変換
    numberItem = numberItem.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(numberItem) && Number(numberItem) !== 0) {
      return
    }
    setNumberItem(Number(numberItem))
  }

  const handleNumberItemUnit = (numberItemUnit: string) => {
    setNumberItemUnit(numberItemUnit)
  }

  const handleCostPrice = (costPrice: string) => {
    // 半角数字に変換
    costPrice = costPrice.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(costPrice) && Number(costPrice) !== 0) {
      return
    }
    setCostPrice(costPrice)
  }

  const handleTaxIncludedCostPrice = (taxIncludedCostPrice: string) => {
    // 半角数字に変換
    taxIncludedCostPrice = taxIncludedCostPrice.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(taxIncludedCostPrice) && Number(taxIncludedCostPrice) !== 0) {
      return
    }
    setTaxIncludedCostPrice(taxIncludedCostPrice)
  }

  const handleTaxIncludedCostPriceToCostPrice = (
    taxIncludedCostPrice: string,
  ) => {
    // 半角数字に変換
    taxIncludedCostPrice = taxIncludedCostPrice.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(taxIncludedCostPrice) && Number(taxIncludedCostPrice) !== 0) {
      return
    }
    const costPrice = String(
      Math.floor(
        new Big(Number(taxIncludedCostPrice)).div(1 + tax / 100).toNumber() *
          100000,
      ) / 100000,
    )
    setCostPrice(costPrice)
  }

  const handleValidateCostPrice = (costPrice: string) => {
    // 必須入力
    if (!costPrice) {
      setCostPriceErrorText('入力してください')
      return
    } else {
      setCostPriceErrorText('')
    }
    // 数値形式
    if (!Number(costPrice) && Number(costPrice) !== 0) {
      setCostPriceErrorText('数値形式で入力してください')
      return
    } else {
      setCostPriceErrorText('')
    }
  }

  const handleTax = (tax: number) => {
    setTax(tax)
  }

  const handleYieldRate = (yieldRate: string) => {
    // 半角数字に変換
    yieldRate = yieldRate.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(yieldRate) && Number(yieldRate) !== 0) {
      return
    }
    setYieldRate(Number(yieldRate))
    setYieldRateErrorText(null)
  }

  const handleInternalCapacityMemo = (internalCapacityMemo: string) => {
    setInternalCapacityMemo(internalCapacityMemo)
  }

  const handlePercentageOfUse = (percentageOfUse: number) => {
    setPercentageOfUse(percentageOfUse)
  }

  const { staff, adminShopId } = useSelector(
    (state: RootState) => state.staffSlice,
  )

  const calcPercentageOfUse = async (
    inputRecipePetternInfos?: BreadDetailPetternInformationType[],
    inputDoughPetternInfos?: BreadDetailPetternInformationType[],
    inputFillingPetternInfos?: BreadDetailPetternInformationType[],
  ) => {
    const calcRecipePetternInfos = inputRecipePetternInfos
      ? _.cloneDeep(inputRecipePetternInfos)
      : _.cloneDeep(recipePetternInfos)
    const calcDoughPetternInfos = inputDoughPetternInfos
      ? _.cloneDeep(inputDoughPetternInfos)
      : _.cloneDeep(doughPetternInfos)
    const calcFillingPetternInfos = inputFillingPetternInfos
      ? _.cloneDeep(inputFillingPetternInfos)
      : _.cloneDeep(fillingPetternInfos)

    // percentageOfUseを算出
    let totalAmount = 0
    let recipeTotalAmount = 0
    let doughTotalAmount = 0
    let fillingTotalAmount = 0
    calcRecipePetternInfos.forEach(
      (recipePetternInfo: BreadDetailPetternInformationType) => {
        recipeTotalAmount +=
          Number(recipePetternInfo.middleKneadPercent) +
          Number(recipePetternInfo.authenticKneadPercent)
        totalAmount +=
          Number(recipePetternInfo.middleKneadPercent) +
          Number(recipePetternInfo.authenticKneadPercent)
      },
    )
    calcDoughPetternInfos.forEach(
      (doughPetternInfo: BreadDetailPetternInformationType) => {
        doughTotalAmount +=
          Number(doughPetternInfo.middleKneadPercent) +
          Number(doughPetternInfo.authenticKneadPercent)
        totalAmount +=
          Number(doughPetternInfo.middleKneadPercent) +
          Number(doughPetternInfo.authenticKneadPercent)
      },
    )
    calcFillingPetternInfos.forEach(
      (fillingPetternInfo: BreadDetailPetternInformationType) => {
        fillingTotalAmount +=
          Number(fillingPetternInfo.middleKneadPercent) +
          Number(fillingPetternInfo.authenticKneadPercent)
        totalAmount +=
          Number(fillingPetternInfo.middleKneadPercent) +
          Number(fillingPetternInfo.authenticKneadPercent)
      },
    )
    const recipePercentageOfUse = recipeTotalAmount / totalAmount
    const doughPercentageOfUse = doughTotalAmount / totalAmount
    const fillingPercentageOfUse = fillingTotalAmount / totalAmount

    // 各原材料ごとの使用割合計算
    const calc2RecipePetternInfos = await calcMaterialRecordPercentageOfUse(
      calcRecipePetternInfos,
      DataType.RECIPE,
      [],
      recipePercentageOfUse,
    )
    const calc2DoughPetternInfos = await calcMaterialRecordPercentageOfUse(
      calcDoughPetternInfos,
      DataType.DOUGH,
      [],
      doughPercentageOfUse,
    )
    const calc2FillingPetternInfos = await calcMaterialRecordPercentageOfUse(
      calcFillingPetternInfos,
      DataType.FILLING,
      [],
      fillingPercentageOfUse,
    )
    return {
      calc2RecipePetternInfos,
      calc2DoughPetternInfos,
      calc2FillingPetternInfos,
    }
  }

  const calcAllCost = async (
    inputRecipePetternInfos?: BreadDetailPetternInformationType[],
    inputDoughPetternInfos?: BreadDetailPetternInformationType[],
    inputFillingPetternInfos?: BreadDetailPetternInformationType[],
    onlyRecordCalc?: boolean,
    amountDoughBeforeBaking?: number,
  ) => {
    const calcRecipePetternInfos = inputRecipePetternInfos
      ? _.cloneDeep(inputRecipePetternInfos)
      : _.cloneDeep(recipePetternInfos)
    const calcDoughPetternInfos = inputDoughPetternInfos
      ? _.cloneDeep(inputDoughPetternInfos)
      : _.cloneDeep(doughPetternInfos)
    const calcFillingPetternInfos = inputFillingPetternInfos
      ? _.cloneDeep(inputFillingPetternInfos)
      : _.cloneDeep(fillingPetternInfos)

    const calc2PetternInfos = await calcPercentageOfUse(
      calcRecipePetternInfos,
      calcDoughPetternInfos,
      calcFillingPetternInfos,
    )

    // 各原材料ごとの原価計算
    const calc3RecipePetternInfos = await calcMaterialRecordCost(
      calc2PetternInfos.calc2RecipePetternInfos,
      DataType.RECIPE,
      [],
      null,
      amountDoughBeforeBaking ? amountDoughBeforeBaking : undefined,
    )
    const calc3DoughPetternInfos = await calcMaterialRecordCost(
      calc2PetternInfos.calc2DoughPetternInfos,
      DataType.DOUGH,
      [],
      null,
      amountDoughBeforeBaking ? amountDoughBeforeBaking : undefined,
    )
    const calc3FillingPetternInfos = await calcMaterialRecordCost(
      calc2PetternInfos.calc2FillingPetternInfos,
      DataType.FILLING,
      [],
      null,
      amountDoughBeforeBaking ? amountDoughBeforeBaking : undefined,
    )

    if (onlyRecordCalc) {
      // 合計原価の計算はしない
      return {
        finalCost: null,
        noPackageingCost: null,
        packagingCostPrice: null,
        costRate: null,
        amountDoughBeforeBaking: null,
      }
    }

    // 最終的な原価計算
    const res: CalcAllCostResult = await calcCost(
      calc3RecipePetternInfos,
      calc3DoughPetternInfos,
      calc3FillingPetternInfos,
    )

    return res
  }

  const calcMaterialRecordCost = async (
    inputPetternInfos: BreadDetailPetternInformationType[] | null = null,
    dataType: DataType,
    params: (number | DataType | boolean)[],
    parentInputPetternInfos: BreadDetailPetternInformationType[] | null = null,
    inputAmountDoughBeforeBaking?: number,
    topCall: boolean = true,
    prevDataType: DataType | null = null,
  ) => {
    const setCost = async (
      params: (number | DataType | boolean)[],
      parentDataType: DataType,
      materialDataType: DataType,
      isMaterial: boolean,
      index: number,
      materialId: string | null,
      inputPetternInfos: BreadDetailPetternInformationType[] | null = null,
    ) => {
      let newParams = [...params, materialDataType, isMaterial, index]
      const calcaAmountDoughBeforeBaking = inputAmountDoughBeforeBaking
        ? inputAmountDoughBeforeBaking
        : amountDoughBeforeBaking
      const percentageOfUse = handleGetRecord(
        parentDataType,
        'percentageOfUse',
        newParams,
        inputPetternInfos,
      )
      let costInfo: CostInfo = {
        supplierCostPrice: null,
        supplierTax: null,
        internalCapacityMemo: null,
        yieldRate: 100,
        internalCapacityCount: null,
        internalCapacityUnit: null,
        numberItem: null,
        numberItemUnit: null,
        unitPrice1g: null,
        cost: null,
        notCalculatedReasons: null,
      }
      // stateがある場合は取得する
      newParams = [...params, materialDataType, isMaterial, index]
      const costPrice = handleGetRecord(
        dataType,
        'supplierCostPrice',
        newParams,
        inputPetternInfos,
      )
      if (costPrice === null && materialId) {
        // stateになければDBから計算
        costInfo = await getMaterialCostInformation(
          staff?.shopId,
          materialId,
          Number(percentageOfUse),
          Number(calcaAmountDoughBeforeBaking),
        )
        // stateを更新
        newParams = [...params, materialDataType, isMaterial, index]
        inputPetternInfos = handleEditRecord(
          parentDataType,
          'supplierCostPrice',
          costInfo.supplierCostPrice,
          newParams,
          inputPetternInfos,
        )
        newParams = [...params, materialDataType, isMaterial, index]
        inputPetternInfos = handleEditRecord(
          parentDataType,
          'supplierTax',
          costInfo.supplierTax,
          newParams,
          inputPetternInfos,
        )
        newParams = [...params, materialDataType, isMaterial, index]
        inputPetternInfos = handleEditRecord(
          parentDataType,
          'yieldRate',
          costInfo.yieldRate,
          newParams,
          inputPetternInfos,
        )
        newParams = [...params, materialDataType, isMaterial, index]
        inputPetternInfos = handleEditRecord(
          parentDataType,
          'internalCapacityCount',
          costInfo.internalCapacityCount,
          newParams,
          inputPetternInfos,
        )
        newParams = [...params, materialDataType, isMaterial, index]
        inputPetternInfos = handleEditRecord(
          parentDataType,
          'internalCapacityUnit',
          costInfo.internalCapacityUnit,
          newParams,
          inputPetternInfos,
        )
        newParams = [...params, materialDataType, isMaterial, index]
        inputPetternInfos = handleEditRecord(
          parentDataType,
          'numberItem',
          costInfo.numberItem,
          newParams,
          inputPetternInfos,
        )
      } else {
        // stateがある場合はシミュレーションとして追加された原材料。stateに保存されているので取得する
        let newParams = [...params, materialDataType, isMaterial, index]
        const costPrice = handleGetRecord(
          dataType,
          'supplierCostPrice',
          newParams,
          inputPetternInfos,
        )

        newParams = [...params, materialDataType, isMaterial, index]
        const tax = handleGetRecord(
          dataType,
          'supplierTax',
          newParams,
          inputPetternInfos,
        )

        newParams = [...params, materialDataType, isMaterial, index]
        const yieldRate = handleGetRecord(
          dataType,
          'yieldRate',
          newParams,
          inputPetternInfos,
        )

        newParams = [...params, materialDataType, isMaterial, index]
        const internalCapacityCount = handleGetRecord(
          dataType,
          'internalCapacityCount',
          newParams,
          inputPetternInfos,
        )

        newParams = [...params, materialDataType, isMaterial, index]
        const internalCapacityUnit = handleGetRecord(
          dataType,
          'internalCapacityUnit',
          newParams,
          inputPetternInfos,
        )

        newParams = [...params, materialDataType, isMaterial, index]
        const numberItem = handleGetRecord(
          dataType,
          'numberItem',
          newParams,
          inputPetternInfos,
        )

        newParams = [...params, materialDataType, isMaterial, index]
        const numberItemUnit = handleGetRecord(
          dataType,
          'numberItemUnit',
          newParams,
          inputPetternInfos,
        )

        newParams = [...params, materialDataType, isMaterial, index]
        const unitPrice1g = handleGetRecord(
          dataType,
          'unitPrice1g',
          newParams,
          inputPetternInfos,
        )

        newParams = [...params, materialDataType, isMaterial, index]
        const cost = handleGetRecord(
          dataType,
          'cost',
          newParams,
          inputPetternInfos,
        )

        costInfo = {
          supplierCostPrice: costPrice ? Number(costPrice) : 0,
          supplierTax: tax ? Number(tax) : 8,
          internalCapacityMemo: internalCapacityMemo
            ? String(internalCapacityMemo)
            : '',
          yieldRate: yieldRate ? Number(yieldRate) : 100,
          internalCapacityCount: internalCapacityCount
            ? Number(internalCapacityCount)
            : 1,
          internalCapacityUnit: internalCapacityUnit
            ? String(internalCapacityUnit)
            : 'g',
          numberItem: numberItem ? Number(numberItem) : 1,
          numberItemUnit: numberItemUnit ? String(numberItemUnit) : '個',
          unitPrice1g: unitPrice1g ? Number(unitPrice1g) : 0,
          cost: cost ? Number(cost) : 0,
          notCalculatedReasons: null,
        }
      }

      let unitAdjustment = 1
      if (costInfo.internalCapacityUnit === 'kg') {
        unitAdjustment = 1000
      } else if (costInfo.internalCapacityUnit === 'mL') {
        unitAdjustment = 1
      } else if (costInfo.internalCapacityUnit === 'L') {
        unitAdjustment = 1000
      }
      const unitPrice1g =
        Number(costInfo.supplierCostPrice) ||
        Number(costInfo.supplierCostPrice) === 0
          ? (Number(costInfo.supplierCostPrice) *
              (Number(costInfo.supplierTax) / 100 + 1)) /
            (Number(costInfo.yieldRate) / 100) /
            Number(costInfo.internalCapacityCount) /
            Number(costInfo.numberItem) /
            unitAdjustment
          : null

      newParams = [...params, materialDataType, isMaterial, index]
      inputPetternInfos = handleEditRecord(
        parentDataType,
        'unitPrice1g',
        unitPrice1g,
        newParams,
        inputPetternInfos,
      )
      if (unitPrice1g || unitPrice1g === 0) {
        const cost =
          Number(calcaAmountDoughBeforeBaking) *
          Number(percentageOfUse) *
          Number(unitPrice1g)
        let newParams = [...params, materialDataType, isMaterial, index]
        inputPetternInfos = handleEditRecord(
          parentDataType,
          'cost',
          cost,
          newParams,
          inputPetternInfos,
        )
        newParams = [...params, materialDataType, isMaterial, index]
        inputPetternInfos = handleEditRecord(
          parentDataType,
          'notCalculatedReasons',
          null,
          newParams,
          inputPetternInfos,
        )
      }
      return inputPetternInfos
    }
    let processPetternInfos =
      inputPetternInfos !== null ? _.cloneDeep(inputPetternInfos) : null
    if (processPetternInfos === null) {
      return []
    }
    let newParentInputPetternInfos = parentInputPetternInfos
      ? _.cloneDeep(parentInputPetternInfos)
      : _.cloneDeep(processPetternInfos)
    for (const [i, petternInfo] of processPetternInfos.entries()) {
      const localParams = [...params]
      if (localParams.length === 0) {
        localParams.push(i)
      }
      if (!topCall && prevDataType && prevDataType !== DataType.MATERIAL) {
        localParams.push(prevDataType, false, i)
      }
      if (petternInfo.recipes) {
        for (const [j, recipe] of petternInfo.recipes
          .filter((recipe) => recipe.materialId)
          .entries()) {
          const newParams = [...localParams]
          newParentInputPetternInfos = await setCost(
            newParams,
            dataType,
            DataType.RECIPE,
            true,
            j,
            recipe.materialId,
            newParentInputPetternInfos,
          )
        }
        for (const [j, recipe] of petternInfo.recipes
          .filter((recipe) => !recipe.materialId)
          .entries()) {
          if (recipe.recipes && recipe.recipes.length > 0) {
            const newParams = [...localParams, DataType.RECIPE, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              recipe.recipes,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.RECIPE,
            )
          }
          if (recipe.doughs && recipe.doughs.length > 0) {
            const newParams = [...localParams, DataType.RECIPE, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              recipe.doughs,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.DOUGH,
            )
          }
          if (recipe.fillings && recipe.fillings.length > 0) {
            const newParams = [...localParams, DataType.RECIPE, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              recipe.fillings,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.FILLING,
            )
          }
          if (recipe.materials && recipe.materials.length > 0) {
            const newParams = [...localParams, DataType.RECIPE, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              recipe.materials,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.MATERIAL,
            )
          }
        }
      }
      if (petternInfo.doughs) {
        for (const [j, dough] of petternInfo.doughs
          .filter((dough) => dough.materialId)
          .entries()) {
          const newParams = [...localParams]
          newParentInputPetternInfos = await setCost(
            newParams,
            dataType,
            DataType.DOUGH,
            true,
            j,
            dough.materialId,
            newParentInputPetternInfos,
          )
        }
        for (const [j, dough] of petternInfo.doughs
          .filter((dough) => !dough.materialId)
          .entries()) {
          if (dough.recipes && dough.recipes.length > 0) {
            const newParams = [...localParams, DataType.DOUGH, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              dough.recipes,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.RECIPE,
            )
          }
          if (dough.doughs && dough.doughs.length > 0) {
            const newParams = [...localParams, DataType.DOUGH, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              dough.doughs,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.DOUGH,
            )
          }
          if (dough.fillings && dough.fillings.length > 0) {
            const newParams = [...localParams, DataType.DOUGH, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              dough.fillings,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.FILLING,
            )
          }
          if (dough.materials && dough.materials.length > 0) {
            const newParams = [...localParams, DataType.DOUGH, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              dough.materials,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.MATERIAL,
            )
          }
        }
      }
      if (petternInfo.fillings) {
        for (const [j, filling] of petternInfo.fillings
          .filter((filling) => filling.materialId)
          .entries()) {
          const newParams = [...localParams]
          newParentInputPetternInfos = await setCost(
            newParams,
            dataType,
            DataType.FILLING,
            true,
            j,
            filling.materialId,
            newParentInputPetternInfos,
          )
        }
        for (const [j, filling] of petternInfo.fillings
          .filter((filling) => !filling.materialId)
          .entries()) {
          if (filling.recipes && filling.recipes.length > 0) {
            const newParams = [...localParams, DataType.FILLING, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              filling.recipes,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.RECIPE,
            )
          }
          if (filling.doughs && filling.doughs.length > 0) {
            const newParams = [...localParams, DataType.FILLING, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              filling.doughs,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.DOUGH,
            )
          }
          if (filling.fillings && filling.fillings.length > 0) {
            const newParams = [...localParams, DataType.FILLING, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              filling.fillings,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.FILLING,
            )
          }
          if (filling.materials && filling.materials.length > 0) {
            const newParams = [...localParams, DataType.FILLING, false, j]
            newParentInputPetternInfos = await calcMaterialRecordCost(
              filling.materials,
              dataType,
              newParams,
              newParentInputPetternInfos,
              inputAmountDoughBeforeBaking,
              false,
              DataType.MATERIAL,
            )
          }
        }
      }
      if (petternInfo.materials) {
        for (const [i, material] of petternInfo.materials.entries()) {
          const newParams = [...localParams]
          newParentInputPetternInfos = await setCost(
            newParams,
            dataType,
            DataType.MATERIAL,
            true,
            i,
            material.materialId,
            newParentInputPetternInfos,
          )
        }
      }
      if (petternInfo.materialId || petternInfo.manufacturerName) {
        const newParams = [...localParams]
        newParentInputPetternInfos = await setCost(
          newParams,
          dataType,
          DataType.MATERIAL,
          true,
          i,
          petternInfo.materialId ? petternInfo.materialId : null,
          newParentInputPetternInfos,
        )
      }
    }
    return newParentInputPetternInfos
  }

  const calcMaterialRecordPercentageOfUse = async (
    inputPetternInfos: BreadDetailPetternInformationType[] | null = null,
    dataType: DataType,
    params: (number | DataType | boolean)[],
    parentPercentageOfUse: number,
    parentInputPetternInfos: BreadDetailPetternInformationType[] | null = null,
    topCall: boolean = true,
    prevDataType: DataType | null = null,
    parentAmount: number | null = null,
  ) => {
    const setPercentageOfUse = async (
      params: (number | DataType | boolean)[],
      parentDataType: DataType,
      parentPercentageOfUse: number,
      inputPetternInfos: BreadDetailPetternInformationType[] | null = null,
    ) => {
      let newParams = [...params]
      const res = handleEditRecord(
        parentDataType,
        'percentageOfUse',
        parentPercentageOfUse,
        newParams,
        inputPetternInfos,
      )
      return res
    }
    let processPetternInfos =
      inputPetternInfos !== null ? _.cloneDeep(inputPetternInfos) : null
    if (processPetternInfos === null) {
      return []
    }
    let totalAmount = 0
    // 全体重量の算出
    for (const [_, petternInfo] of processPetternInfos.entries()) {
      totalAmount +=
        Number(petternInfo.middleKneadPercent) +
        Number(petternInfo.authenticKneadPercent)
    }
    let newParentInputPetternInfos = parentInputPetternInfos
      ? _.cloneDeep(parentInputPetternInfos)
      : _.cloneDeep(processPetternInfos)
    for (const [i, petternInfo] of processPetternInfos.entries()) {
      let materialAmount = 0
      if (petternInfo.recipes) {
        petternInfo.recipes.forEach(async (recipe) => {
          materialAmount +=
            Number(recipe.middleKneadPercent) +
            Number(recipe.authenticKneadPercent)
        })
      }
      if (petternInfo.doughs) {
        petternInfo.doughs.forEach(async (dough) => {
          materialAmount +=
            Number(dough.middleKneadPercent) +
            Number(dough.authenticKneadPercent)
        })
      }
      if (petternInfo.fillings) {
        petternInfo.fillings.forEach(async (filling) => {
          materialAmount +=
            Number(filling.middleKneadPercent) +
            Number(filling.authenticKneadPercent)
        })
      }
      if (petternInfo.materials) {
        petternInfo.materials.forEach(async (material) => {
          materialAmount +=
            Number(material.middleKneadPercent) +
            Number(material.authenticKneadPercent)
        })
      }
      if (petternInfo.materialId || petternInfo.manufacturerName) {
        materialAmount +=
          Number(petternInfo.middleKneadPercent) +
          Number(petternInfo.authenticKneadPercent)
      }
      const ownAmount =
        Number(petternInfo.middleKneadPercent) +
        Number(petternInfo.authenticKneadPercent)
      const localParams = [...params]
      if (localParams.length === 0) {
        localParams.push(i)
      }
      if (!topCall && prevDataType && prevDataType !== DataType.MATERIAL) {
        localParams.push(prevDataType, false, i)
      }
      if (parentAmount) {
        totalAmount = parentAmount
      }
      if (petternInfo.recipes) {
        for (const [j, recipe] of petternInfo.recipes
          .filter((recipe) => recipe.materialId)
          .entries()) {
          const newParams = [...localParams, DataType.RECIPE, true, j]
          newParentInputPetternInfos = await setPercentageOfUse(
            newParams,
            dataType,
            ((ownAmount / totalAmount) *
              parentPercentageOfUse *
              (Number(recipe.middleKneadPercent) +
                Number(recipe.authenticKneadPercent))) /
              materialAmount,
            newParentInputPetternInfos,
          )
        }
        for (const [j, recipe] of petternInfo.recipes
          .filter((recipe) => !recipe.materialId)
          .entries()) {
          let parentAmount = 0
          if (recipe.alls) {
            recipe.alls.forEach(async (all) => {
              parentAmount +=
                Number(all.middleKneadPercent) +
                Number(all.authenticKneadPercent)
            })
          }
          if (recipe.recipes && recipe.recipes.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.RECIPE, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                recipe.recipes,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(recipe.middleKneadPercent) +
                    Number(recipe.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.RECIPE,
                parentAmount,
              )
          }
          if (recipe.doughs && recipe.doughs.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.RECIPE, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                recipe.doughs,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(recipe.middleKneadPercent) +
                    Number(recipe.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.DOUGH,
                parentAmount,
              )
          }
          if (recipe.fillings && recipe.fillings.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.RECIPE, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                recipe.fillings,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(recipe.middleKneadPercent) +
                    Number(recipe.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.FILLING,
                parentAmount,
              )
          }
          if (recipe.materials && recipe.materials.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.RECIPE, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                recipe.materials,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(recipe.middleKneadPercent) +
                    Number(recipe.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.MATERIAL,
                parentAmount,
              )
          }
          const newParams = [...localParams]
          newParentInputPetternInfos = await setPercentageOfUse(
            newParams,
            dataType,
            ((ownAmount / totalAmount) *
              parentPercentageOfUse *
              (Number(recipe.middleKneadPercent) +
                Number(recipe.authenticKneadPercent))) /
              materialAmount,
            newParentInputPetternInfos,
          )
        }
      }
      if (petternInfo.doughs) {
        for (const [j, dough] of petternInfo.doughs
          .filter((dough) => dough.materialId)
          .entries()) {
          const newParams = [...localParams, DataType.DOUGH, true, j]
          newParentInputPetternInfos = await setPercentageOfUse(
            newParams,
            dataType,
            ((ownAmount / totalAmount) *
              parentPercentageOfUse *
              (Number(dough.middleKneadPercent) +
                Number(dough.authenticKneadPercent))) /
              materialAmount,
            newParentInputPetternInfos,
          )
        }
        for (const [j, dough] of petternInfo.doughs
          .filter((dough) => !dough.materialId)
          .entries()) {
          let parentAmount = 0
          if (dough.alls) {
            dough.alls.forEach(async (all) => {
              parentAmount +=
                Number(all.middleKneadPercent) +
                Number(all.authenticKneadPercent)
            })
          }
          if (dough.recipes && dough.recipes.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.DOUGH, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                dough.recipes,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(dough.middleKneadPercent) +
                    Number(dough.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.RECIPE,
                parentAmount,
              )
          }
          if (dough.doughs && dough.doughs.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.DOUGH, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                dough.doughs,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(dough.middleKneadPercent) +
                    Number(dough.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.DOUGH,
                parentAmount,
              )
          }
          if (dough.fillings && dough.fillings.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.DOUGH, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                dough.fillings,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(dough.middleKneadPercent) +
                    Number(dough.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.FILLING,
                parentAmount,
              )
          }
          if (dough.materials && dough.materials.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.DOUGH, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                dough.materials,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(dough.middleKneadPercent) +
                    Number(dough.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.MATERIAL,
                parentAmount,
              )
          }

          const newParams = [...localParams]
          newParentInputPetternInfos = await setPercentageOfUse(
            newParams,
            dataType,
            ((ownAmount / totalAmount) *
              parentPercentageOfUse *
              (Number(dough.middleKneadPercent) +
                Number(dough.authenticKneadPercent))) /
              materialAmount,
            newParentInputPetternInfos,
          )
        }
      }
      if (petternInfo.fillings) {
        for (const [j, filling] of petternInfo.fillings
          .filter((filling) => filling.materialId)
          .entries()) {
          const newParams = [...localParams, DataType.FILLING, true, j]
          newParentInputPetternInfos = await setPercentageOfUse(
            newParams,
            dataType,
            ((ownAmount / totalAmount) *
              parentPercentageOfUse *
              (Number(filling.middleKneadPercent) +
                Number(filling.authenticKneadPercent))) /
              materialAmount,
            newParentInputPetternInfos,
          )
        }
        for (const [j, filling] of petternInfo.fillings
          .filter((filling) => !filling.materialId)
          .entries()) {
          let parentAmount = 0
          if (filling.alls) {
            filling.alls.forEach(async (all) => {
              parentAmount +=
                Number(all.middleKneadPercent) +
                Number(all.authenticKneadPercent)
            })
          }
          if (filling.recipes && filling.recipes.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.FILLING, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                filling.recipes,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(filling.middleKneadPercent) +
                    Number(filling.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.RECIPE,
                parentAmount,
              )
          }
          if (filling.doughs && filling.doughs.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.FILLING, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                filling.doughs,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(filling.middleKneadPercent) +
                    Number(filling.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.DOUGH,
                parentAmount,
              )
          }
          if (filling.fillings && filling.fillings.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.FILLING, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                filling.fillings,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(filling.middleKneadPercent) +
                    Number(filling.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.FILLING,
                parentAmount,
              )
          }
          if (filling.materials && filling.materials.length > 0) {
            const newParams = [...localParams]
            newParams.push(DataType.FILLING, false, j)
            newParentInputPetternInfos =
              await calcMaterialRecordPercentageOfUse(
                filling.materials,
                dataType,
                newParams,
                ((ownAmount / totalAmount) *
                  parentPercentageOfUse *
                  (Number(filling.middleKneadPercent) +
                    Number(filling.authenticKneadPercent))) /
                  materialAmount,
                newParentInputPetternInfos,
                false,
                DataType.MATERIAL,
                parentAmount,
              )
          }
          const newParams = [...localParams]
          newParentInputPetternInfos = await setPercentageOfUse(
            newParams,
            dataType,
            ((ownAmount / totalAmount) *
              parentPercentageOfUse *
              (Number(filling.middleKneadPercent) +
                Number(filling.authenticKneadPercent))) /
              materialAmount,
            newParentInputPetternInfos,
          )
        }
      }
      if (petternInfo.materials) {
        for (const [j, material] of petternInfo.materials.entries()) {
          const newParams = [...localParams, DataType.MATERIAL, true, j]
          newParentInputPetternInfos = await setPercentageOfUse(
            newParams,
            dataType,
            ((ownAmount / totalAmount) *
              parentPercentageOfUse *
              (Number(material.middleKneadPercent) +
                Number(material.authenticKneadPercent))) /
              materialAmount,
            newParentInputPetternInfos,
          )
        }
      }
      if (petternInfo.materialId || petternInfo.manufacturerName) {
        const newParams = [...localParams, DataType.MATERIAL, true, i]
        newParentInputPetternInfos = await setPercentageOfUse(
          newParams,
          dataType,
          ((ownAmount / totalAmount) *
            parentPercentageOfUse *
            (Number(petternInfo.middleKneadPercent) +
              Number(petternInfo.authenticKneadPercent))) /
            materialAmount,
          newParentInputPetternInfos,
        )
      }
      if (!petternInfo.materialId) {
        const newParams = [...localParams]
        newParentInputPetternInfos = await setPercentageOfUse(
          newParams,
          dataType,
          ((ownAmount / totalAmount) *
            parentPercentageOfUse *
            (Number(petternInfo.middleKneadPercent) +
              Number(petternInfo.authenticKneadPercent))) /
            materialAmount,
          newParentInputPetternInfos,
        )
      }
    }
    return newParentInputPetternInfos
  }

  const handleCostSimulationName = async (costSimulationName: string) => {
    setCostSimulationName(costSimulationName)
    setCostSimulationNameErrorText(null)
  }
  const handleAmountDoughBeforeBaking = async (
    amountDoughBeforeBaking: string,
  ) => {
    // 半角数字に変換
    amountDoughBeforeBaking = amountDoughBeforeBaking.replace(
      /[０-９]/g,
      (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      },
    )
    if (
      !Number(amountDoughBeforeBaking) &&
      Number(amountDoughBeforeBaking) !== 0
    ) {
      return
    }
    setAmountDoughBeforeBaking(amountDoughBeforeBaking)
    setAmountDoughBeforeBakingErrorText(null)

    // await calcPercentageOfUse()

    // // // 各原材料ごとの原価計算
    // await calcMaterialRecordCost(null, DataType.RECIPE, [])
    // await calcMaterialRecordCost(null, DataType.DOUGH, [])
    // await calcMaterialRecordCost(null, DataType.FILLING, [])

    // 最終的な原価計算
    // await calcAllCost(
    //   recipePetternInfos,
    //   doughPetternInfos,
    //   fillingPetternInfos,
    //   true,
    //   Number(amountDoughBeforeBaking),
    // )
  }
  const handleLossRate = async (lossRate: string) => {
    // 半角数字に変換
    lossRate = lossRate.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(lossRate) && Number(lossRate) !== 0) {
      return
    }
    setLossRate(lossRate)
    setLossRateErrorText(null)

    // await calcPercentageOfUse()

    // // 各原材料ごとの原価計算
    // await calcMaterialRecordCost(null, DataType.RECIPE, [])
    // await calcMaterialRecordCost(null, DataType.DOUGH, [])
    // await calcMaterialRecordCost(null, DataType.FILLING, [])

    // 最終的な原価計算
    // await calcAllCost()
  }

  const handleMemo = (memo: string) => {
    setMemo(memo)
  }

  const handleCalcSellingPrice = async (calcSellingPrice: string) => {
    setCalcSellingPrice(Number(calcSellingPrice))

    // 最終的な原価計算
    // await calcAllCost()
  }

  const handleCalcCostPrice = (calcCostPrice: string) => {
    setCalcCostPrice(Number(calcCostPrice))
  }

  const handleCalcMaterialCostPrice = (calcMaterialCostPrice: string) => {
    setCalcMaterialCostPrice(Number(calcMaterialCostPrice))
  }

  const handleCalcPackagingCostPrice = async (
    calcPackagingCostPrice: string,
  ) => {
    setCalcPackagingCostPrice(Number(calcPackagingCostPrice))

    // 最終的な原価計算
    // await calcAllCost()
  }

  const handleCalcCostRate = (calcCostRate: string) => {
    setCalcCostRate(Number(calcCostRate))
  }

  const handleCalcAmountDoughBeforeBaking = (
    calcAmountDoughBeforeBaking: string,
  ) => {
    setCalcAmountDoughBeforeBaking(Number(calcAmountDoughBeforeBaking))
  }

  const handleBoxDeleteButton = async (
    index: number | undefined,
    dataType: DataType | undefined,
  ) => {
    if (index === undefined || dataType === undefined) {
      return
    }

    let targetPetternInfos = null
    if (dataType === DataType.RECIPE) {
      targetPetternInfos = _.cloneDeep(recipePetternInfos)
      targetPetternInfos.splice(index, 1)
      setRecipePetternInfos(targetPetternInfos)
    }
    if (dataType === DataType.DOUGH) {
      targetPetternInfos = _.cloneDeep(doughPetternInfos)
      targetPetternInfos.splice(index, 1)
      setDoughPetternInfos(targetPetternInfos)
    }
    if (dataType === DataType.FILLING) {
      targetPetternInfos = _.cloneDeep(fillingPetternInfos)
      targetPetternInfos.splice(index, 1)
      setFillingPetternInfos(targetPetternInfos)
    }
    if (targetPetternInfos === null) {
      return
    }

    // const calcResult = await calcPercentageOfUse(
    //   dataType === DataType.RECIPE ? targetPetternInfos : undefined,
    //   dataType === DataType.DOUGH ? targetPetternInfos : undefined,
    //   dataType === DataType.FILLING ? targetPetternInfos : undefined,
    // )
    // const newPetternInfos =
    //   isPreviewDataType === DataType.RECIPE &&
    //   calcResult.calc2RecipePetternInfos
    //     ? calcResult.calc2RecipePetternInfos
    //     : isPreviewDataType === DataType.DOUGH &&
    //       calcResult.calc2DoughPetternInfos
    //     ? calcResult.calc2DoughPetternInfos
    //     : isPreviewDataType === DataType.FILLING &&
    //       calcResult.calc2FillingPetternInfos
    //     ? calcResult.calc2FillingPetternInfos
    //     : targetPetternInfos

    // // 各原材料ごとの原価計算
    // const calcRecipePetternInfos = await calcMaterialRecordCost(
    //   dataType === DataType.RECIPE ? newPetternInfos : null,
    //   DataType.RECIPE,
    //   [],
    // )
    // const calcDoughPetternInfos = await calcMaterialRecordCost(
    //   dataType === DataType.DOUGH ? newPetternInfos : null,
    //   DataType.DOUGH,
    //   [],
    // )
    // const calcFillingPetternInfos = await calcMaterialRecordCost(
    //   dataType === DataType.FILLING ? newPetternInfos : null,
    //   DataType.FILLING,
    //   [],
    // )

    // if (dataType === DataType.RECIPE) {
    //   setRecipePetternInfos(calcRecipePetternInfos)
    // }
    // if (dataType === DataType.DOUGH) {
    //   setDoughPetternInfos(calcDoughPetternInfos)
    // }
    // if (dataType === DataType.FILLING) {
    //   setFillingPetternInfos(calcFillingPetternInfos)
    // }

    // await calcAllCost(
    //   calcRecipePetternInfos,
    //   calcDoughPetternInfos,
    //   calcFillingPetternInfos,
    // )
  }

  const handleSubmit = async () => {
    if (!costSimulationName) {
      setCostSimulationNameErrorText('入力してください')
      window.scrollTo(0, 0)
      return
    }
    if (!amountDoughBeforeBaking) {
      setAmountDoughBeforeBakingErrorText('入力してください')
      window.scrollTo(0, 0)
      return
    }

    dispatch(setIsLoading({ isLoading: true }))

    const calcResult = await calcAllCost()
    if (costSimulationId) {
      // 更新
      await dispatch(
        updateCostSimulation({
          id: costSimulationId,
          name: costSimulationName,
          recipes: recipePetternInfos,
          doughs: doughPetternInfos,
          fillings: fillingPetternInfos,
          amount_dough_before_baking: Number(amountDoughBeforeBaking),
          loss_rate: lossRate ? Number(lossRate) : 0,
          memo: memo,
          selling_price: calcSellingPrice ? Number(calcSellingPrice) : 0,
          material_cost: calcResult?.noPackageingCost
            ? Number(calcResult?.noPackageingCost)
            : 0,
          packaging_cost: calcResult?.packagingCostPrice
            ? Number(calcResult?.packagingCostPrice)
            : 0,
          cost_rate: calcResult?.costRate ? Number(calcResult?.costRate) : 0,
          is_percent: breadDisplayPercent,
          is_gram: breadDisplayGram,
        }),
      )
    } else {
      // 登録
      await dispatch(
        createCostSimulation({
          recipes: recipePetternInfos,
          name: costSimulationName,
          doughs: doughPetternInfos,
          fillings: fillingPetternInfos,
          amount_dough_before_baking: Number(amountDoughBeforeBaking),
          loss_rate: lossRate ? Number(lossRate) : 0,
          memo: memo,
          selling_price: calcSellingPrice ? Number(calcSellingPrice) : 0,
          material_cost: calcResult?.noPackageingCost
            ? Number(calcResult?.noPackageingCost)
            : 0,
          packaging_cost: calcResult?.packagingCostPrice
            ? Number(calcResult?.packagingCostPrice)
            : 0,
          cost_rate: calcResult?.costRate ? Number(calcResult?.costRate) : 0,
          is_percent: breadDisplayPercent,
          is_gram: breadDisplayGram,
        }),
      )
    }
    dispatch(setIsLoading({ isLoading: false }))
    window.scrollTo(0, 0)
    navigate('/simulation/cost')
  }

  const setInfo = async (
    costSimulationId: string | null,
    copyCostSimulationId: string | null,
  ) => {
    dispatch(setIsLoading({ isLoading: true }))
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      dispatch(setIsLoading({ isLoading: false }))
      return
    }
    // 使用できないプラン or スーパー管理者じゃなければ使用できない
    if (
      (staff?.isAdmin === true && adminShopId) ||
      (staff?.isAdmin === false && staff.isStandardPlan === true)
    ) {
      //
    } else {
      navigate('/initload')
      window.scrollTo(0, 0)
      return
    }

    await dispatch(clearCostSimulation())
    let costSimulationPayload = null
    const getCostSimulationId: string = costSimulationId
      ? costSimulationId
      : copyCostSimulationId
      ? copyCostSimulationId
      : ''
    if (!getCostSimulationId) {
      dispatch(setIsLoading({ isLoading: false }))
      return
    }
    costSimulationPayload = await dispatch(
      getCostSimulationById({ id: getCostSimulationId }),
    )
    if (costSimulationPayload && costSimulationPayload.payload) {
      const costSimulation: CostSimulationType = costSimulationPayload.payload
      const defaultName = copyCostSimulationId
        ? costSimulation.name + '(コピー)'
        : costSimulation.name
      setCostSimulationName(defaultName)
      setRecipePetternInfos(costSimulation.recipes)
      setDoughPetternInfos(costSimulation.doughs)
      setFillingPetternInfos(costSimulation.fillings)
      setAmountDoughBeforeBaking(
        String(costSimulation.amount_dough_before_baking),
      )
      setLossRate(String(costSimulation.loss_rate))
      setMemo(costSimulation.memo)
      setCalcSellingPrice(costSimulation.selling_price)
      setCalcMaterialCostPrice(costSimulation.material_cost)
      setCalcPackagingCostPrice(costSimulation.packaging_cost)
      setCalcCostRate(costSimulation.cost_rate)
      setCalcCostPrice(
        Number(costSimulation.material_cost) +
          Number(costSimulation.packaging_cost),
      )
      setCalcAmountDoughBeforeBaking(costSimulation.amount_dough_before_baking)
      setBreadDisplayPercent(costSimulation.is_percent)
      setBreadDisplayGram(costSimulation.is_gram)
    }
    dispatch(setIsLoading({ isLoading: false }))
  }

  useEffect(() => {
    setInfo(costSimulationId, copyCostSimulationId)
  }, [])

  return (
    <>
      <div className={styles.container}>
        <PageHedding title="シミュレーションを登録" to={'/simulation/cost'} />
        <div className={styles.bread_add_recipe_display_unit}>
          <div>
            <span className={styles.bread_add_recipe_display_unit_text}>
              表示単位
            </span>
          </div>
          <RadioButton
            isChecked={breadDisplayPercent}
            label="%"
            onChange={handleBreadDisplayUnit}
          />
          <RadioButton
            isChecked={breadDisplayGram}
            label="g"
            onChange={handleBreadDisplayUnit}
          />
        </div>
        <div className={styles.simulation_select}>
          <InputTextWithLightLabel
            labelName="シミュレーション名"
            required={true}
            description=""
            line={2}
            type="text"
            size="large"
            value={costSimulationName}
            placeholder=""
            errorText={costSimulationNameErrorText}
            onChange={(event) => handleCostSimulationName(event.target.value)}
          />
        </div>
        <div className={styles.simulation_select}>
          <div className={styles.simulation_select_box}>
            <InputTextSuggestWithLightLabel
              labelName="商品を呼び出す"
              description=""
              line={1}
              type="text"
              size="semimiddle"
              value={recipeName}
              placeholder=""
              suggestType="recipe_name"
              onSelect={handleRecipeSuggest}
              onChange={(event) => handleChangeRecipeName(event.target.value)}
              readOnly={suggestRecipe ? true : false}
            />
            {suggestRecipe ? (
              <div onClick={clearRecipeSuggest}>
                <img
                  className={styles.simulation_select_box_clear}
                  src={clearCrossIcon}
                  alt="x"
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={styles.simulation_select_box}>
            <InputTextSuggestWithLightLabel
              labelName="生地パターンを呼び出す"
              description=""
              line={1}
              type="text"
              size="semimiddle"
              value={doughName}
              placeholder=""
              suggestType="dough_name"
              onSelect={handleDoughSuggest}
              onChange={(event) => handleChangeDoughName(event.target.value)}
              readOnly={suggestDough ? true : false}
            />
            {suggestDough ? (
              <div onClick={clearDoughSuggest}>
                <img
                  className={styles.simulation_select_box_clear}
                  src={clearCrossIcon}
                  alt="x"
                />
              </div>
            ) : (
              ''
            )}
            <div className={styles.simulation_select_button_text_type}>
              <SubmitButton
                label="生地を新規登録"
                color="white2"
                size="large"
                icon="outline_plus"
                textPosition="right"
                onClick={addNewDough}
              />
            </div>
          </div>
          <div className={styles.simulation_select_box}>
            <InputTextSuggestWithLightLabel
              labelName="フィリングパターンを呼び出す"
              description=""
              line={1}
              type="text"
              size="semimiddle"
              value={fillingName}
              placeholder=""
              suggestType="filling_name"
              onSelect={handleFillingSuggest}
              onChange={(event) => handleChangeFillingName(event.target.value)}
              readOnly={suggestFilling ? true : false}
            />
            {suggestFilling ? (
              <div onClick={clearFillingSuggest}>
                <img
                  className={styles.simulation_select_box_clear}
                  src={clearCrossIcon}
                  alt="x"
                />
              </div>
            ) : (
              ''
            )}
            <div className={styles.simulation_select_button_text_type}>
              <SubmitButton
                label="フィリングを新規登録"
                color="white2"
                size="large"
                icon="outline_plus"
                textPosition="right"
                onClick={addNewFilling}
              />
            </div>
          </div>
          <div className={styles.simulation_select_button}>
            <SubmitButton
              label="追加する"
              color="orange"
              size="middle"
              icon="plus"
              onClick={setPettern}
            />
          </div>
        </div>
        <div className={styles.bread_detail_table}>
          {recipePetternInfos && recipePetternInfos.length > 0 ? (
            <>
              <div className={styles.bread_detail_table_tab}>商品</div>
              <>
                {recipePetternInfos.map((recipe, i) => (
                  <>
                    <div className={styles.bread_detail_table_container}>
                      <BreadDetailBoxPrimaryV2
                        petternInformation={recipe}
                        editable={true}
                        handleAddButton={handleAddButton}
                        handleEditRecord={handleEditRecord}
                        handleDeleteButton={handleDeleteButton}
                        handleIsPreview={handleIsPreview}
                        dataType={DataType.RECIPE}
                        params={[i]}
                        parentUnitType={
                          breadDisplayGram ? UnitType.GRAM : UnitType.PERCENT
                        }
                        isOpen={true}
                        handleBoxDeleteButton={handleBoxDeleteButton}
                        boxDeleteIndex={i}
                      />
                    </div>
                    <div
                      className={styles.bread_detail_table_margin_bottom}
                    ></div>
                  </>
                ))}
                <div className={styles.simulation_select_button_text_type}>
                  <SubmitButton
                    label="レシピを新規登録"
                    color="white2"
                    size="large"
                    icon="outline_plus"
                    textPosition="right"
                    onClick={addNewRecipe}
                  />
                </div>
              </>
            </>
          ) : (
            ''
          )}
          {doughPetternInfos && doughPetternInfos.length > 0 ? (
            <>
              <div className={styles.bread_detail_table_tab}>生地</div>
              {doughPetternInfos.map((dough, i) => (
                <>
                  <div className={styles.bread_detail_table_container}>
                    <BreadDetailBoxPrimaryV2
                      petternInformation={dough}
                      editable={true}
                      handleAddButton={handleAddButton}
                      handleEditRecord={handleEditRecord}
                      handleDeleteButton={handleDeleteButton}
                      handleIsPreview={handleIsPreview}
                      dataType={DataType.DOUGH}
                      params={[i]}
                      parentUnitType={
                        breadDisplayGram ? UnitType.GRAM : UnitType.PERCENT
                      }
                      isOpen={true}
                      handleBoxDeleteButton={handleBoxDeleteButton}
                      boxDeleteIndex={i}
                    />
                  </div>
                  <div
                    className={styles.bread_detail_table_margin_bottom}
                  ></div>
                </>
              ))}
              <div className={styles.simulation_select_button_text_type}>
                <SubmitButton
                  label="生地を新規登録"
                  color="white2"
                  size="large"
                  icon="outline_plus"
                  textPosition="right"
                  onClick={addNewDough}
                />
              </div>
            </>
          ) : (
            ''
          )}
          {fillingPetternInfos && fillingPetternInfos.length > 0 ? (
            <>
              <div className={styles.bread_detail_table_tab}>フィリング</div>
              <>
                {fillingPetternInfos.map((filling, i) => (
                  <>
                    <div className={styles.bread_detail_table_container}>
                      <BreadDetailBoxPrimaryV2
                        petternInformation={filling}
                        editable={true}
                        handleAddButton={handleAddButton}
                        handleEditRecord={handleEditRecord}
                        handleDeleteButton={handleDeleteButton}
                        handleIsPreview={handleIsPreview}
                        dataType={DataType.FILLING}
                        params={[i]}
                        parentUnitType={
                          breadDisplayGram ? UnitType.GRAM : UnitType.PERCENT
                        }
                        isOpen={true}
                        handleBoxDeleteButton={handleBoxDeleteButton}
                        boxDeleteIndex={i}
                      />
                    </div>
                    <div
                      className={styles.bread_detail_table_margin_bottom}
                    ></div>
                  </>
                ))}
                <div className={styles.simulation_select_button_text_type}>
                  <SubmitButton
                    label="フィリングを新規登録"
                    color="white2"
                    size="large"
                    icon="outline_plus"
                    textPosition="right"
                    onClick={addNewFilling}
                  />
                </div>
              </>
            </>
          ) : (
            ''
          )}
        </div>
        <div className={styles.bread_detail_table_center}>
          <div>
            <div className={styles.bread_detail_table_margin_top}>
              <InputTextAndUnitWithLightLabel
                labelName="商品1個あたりの重量（焼成前生地量）"
                required={true}
                inputBoxCenter={true}
                description=""
                line={2}
                type="text"
                size="small"
                value={amountDoughBeforeBaking}
                placeholder=""
                unit="g"
                errorText={amountDoughBeforeBakingErrorText}
                onChange={(event) =>
                  handleAmountDoughBeforeBaking(event.target.value)
                }
              />
            </div>
            <div className={styles.bread_detail_table_margin_top}>
              {breadDisplayGram ? (
                <SubmitButton
                  label="1個あたりを自動計算する"
                  color="orange"
                  size="xmiddle"
                  icon="none"
                  onClick={handleCalcAmountDoughbeforeBaking}
                />
              ) : (
                <SubmitButton
                  label="1個あたりを自動計算する"
                  color="gray"
                  size="xmiddle"
                  icon="none"
                  onClick={() => {}}
                />
              )}
            </div>
          </div>
        </div>

        <div className={styles.bread_add_recipe_section}></div>
        <div className={styles.bread_detail_table_center}>
          <div>
            <InputTextAndUnitWithLightLabel
              labelName="ロス率（全工程での生地収量・発酵・分割によるロス）"
              inputBoxCenter={true}
              description=""
              line={2}
              type="text"
              size="small"
              value={lossRate}
              placeholder=""
              unit="%"
              errorText={lossRateErrorText}
              onChange={(event) => handleLossRate(event.target.value)}
            />
            <div className={styles.bread_add_recipe_sum_space}></div>
          </div>
        </div>
        <TextAreaWithLightLabel
          labelName="メモ"
          description=""
          line={1}
          maxLength={1000}
          rows={8}
          size="full"
          value={memo}
          placeholder=""
          onChange={(event) => handleMemo(event.target.value)}
        />
        <div className={styles.bread_detail_cost_simulation_result}>
          <div className={styles.bread_detail_cost_simulation_result_unit}>
            <InputTextAndUnitWithLightLabel
              labelName="売価"
              subLabelName="(税込)"
              isUnitEnd={true}
              description=""
              line={2}
              type="text"
              size="cost_simulation"
              value={String(calcSellingPrice)}
              placeholder=""
              unit="円"
              onChange={(event) => handleCalcSellingPrice(event.target.value)}
            />
          </div>
          <div className={styles.bread_detail_cost_simulation_result_unit}>
            <InputTextAndUnitWithLightLabel
              labelName="原価"
              subLabelName="(税込)"
              isUnitEnd={true}
              description=""
              line={2}
              type="text"
              size="cost_simulation"
              value={calcCostPrice ? String(calcCostPrice) : '-'}
              placeholder=""
              unit="円"
              readOnly={true}
            />
          </div>
          <div className={styles.bread_detail_cost_simulation_result_unit}>
            <InputTextAndUnitWithLightLabel
              labelName="材料費"
              subLabelName="(税込)"
              isUnitEnd={true}
              description=""
              line={2}
              type="text"
              size="cost_simulation"
              value={
                calcMaterialCostPrice ? String(calcMaterialCostPrice) : '-'
              }
              placeholder=""
              unit="円"
              readOnly={true}
            />
          </div>
          <div className={styles.bread_detail_cost_simulation_result_unit}>
            <InputTextAndUnitWithLightLabel
              labelName="包材費"
              subLabelName="(税込)"
              isUnitEnd={true}
              description=""
              line={2}
              type="text"
              size="cost_simulation"
              value={String(calcPackagingCostPrice)}
              placeholder=""
              unit="円"
              onChange={(event) =>
                handleCalcPackagingCostPrice(event.target.value)
              }
            />
          </div>
          <div className={styles.bread_detail_cost_simulation_result_unit}>
            <InputTextAndUnitWithLightLabel
              labelName="原価率"
              isUnitEnd={true}
              description=""
              line={2}
              type="text"
              size="cost_simulation"
              value={calcCostRate ? String(calcCostRate) : '-'}
              placeholder=""
              unit="%"
              readOnly={true}
            />
          </div>
          <div className={styles.bread_detail_cost_simulation_result_unit}>
            <InputTextAndUnitWithLightLabel
              labelName="重量"
              isUnitEnd={true}
              description=""
              line={2}
              type="text"
              size="cost_simulation"
              value={
                calcAmountDoughBeforeBaking
                  ? String(calcAmountDoughBeforeBaking)
                  : '-'
              }
              placeholder=""
              unit="g"
              readOnly={true}
            />
          </div>
        </div>
        <div className={styles.bread_detail_cost_simulation_result_button}>
          <SubmitButton
            label="計算する"
            color="orange"
            size="large"
            icon="none"
            onClick={() => {
              calcAllCost()
              calcAllCost()
            }}
          />
        </div>
        <div className={styles.breadAddButtonContainer}>
          <SubmitButton
            label="キャンセル"
            color="orange_outlined"
            size="large"
            icon="none"
            onClick={() => navigate('/initload')}
          />
          <span className={styles.breadAddButtonSpacer}></span>
          <SubmitButton
            label="登録(更新)する"
            color="orange"
            size="large"
            icon="none"
            onClick={handleSubmit}
          />
        </div>
      </div>
      {isPreviewInternalCapacity && (
        <div className={styles.modal_simulation_internal_capacity}>
          <div className={styles.modal_simulation_internal_capacity_container}>
            <div
              className={
                styles.modal_simulation_internal_capacity_container_form_box
              }
            >
              <InputTextWithLabel
                required={true}
                labelName="内容量"
                description=""
                line={2}
                type="text"
                size="small"
                labelSize="large"
                value={String(internalCapacityCount)}
                placeholder=""
                errorText={internalCapacityCountErrorText}
                onChange={(event) =>
                  handleInternalCapacityCount(event.target.value)
                }
                onBlur={(event) =>
                  handleValidateInternalCapacityCount(event.target.value)
                }
              />
              <SelectBox
                style={{
                  width: 90,
                  height: 54,
                  padding: '10px 20px',
                  marginLeft: 14,
                }}
                size="large2"
                value={internalCapacityUnit}
                options={[
                  {
                    is_default: true,
                    label: 'g',
                    value: 'g',
                  },
                  {
                    is_default: false,
                    label: 'kg',
                    value: 'kg',
                  },
                  {
                    is_default: false,
                    label: 'mL',
                    value: 'mL',
                  },
                  {
                    is_default: false,
                    label: 'L',
                    value: 'L',
                  },
                ]}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  handleInternalCapacityUnit(event.target.value)
                }
              />
            </div>
            <div
              className={
                styles.modal_simulation_internal_capacity_container_form_box
              }
            >
              <InputTextWithLabel
                required={true}
                labelName="入数"
                description=""
                line={2}
                type="text"
                size="small"
                labelSize="large"
                value={String(numberItem)}
                placeholder=""
                onChange={(event) => handleNumberItem(event.target.value)}
              />
              <div
                style={{
                  marginLeft: 14,
                }}
              ></div>
              <InputText
                required={false}
                type="text"
                size="small"
                value={numberItemUnit}
                placeholder="個"
                rightAligned={false}
                onChange={(event) => handleNumberItemUnit(event.target.value)}
              />
            </div>
            <div
              className={
                styles.modal_simulation_internal_capacity_container_form_box
              }
            >
              <InputTextAndUnitWithLabel
                required={true}
                labelName="仕入れ値(税抜)"
                description=""
                line={2}
                type="text"
                size="middle"
                value={costPrice ? String(costPrice) : ''}
                placeholder=""
                unit="円"
                errorText={costPriceErrorText}
                labelSize="large"
                onChange={(event) => handleCostPrice(event.target.value)}
                onBlur={(event) => handleValidateCostPrice(event.target.value)}
              />
            </div>
            <div
              className={
                styles.modal_simulation_internal_capacity_container_form_box
              }
            >
              <InputTextAndUnitWithLabel
                required={false}
                labelName="仕入れ値(税込)"
                description=""
                line={2}
                type="text"
                size="middle"
                value={taxIncludedCostPrice ? taxIncludedCostPrice : ''}
                placeholder=""
                unit="円"
                errorText={costPriceErrorText}
                labelSize="large"
                onChange={(event) =>
                  handleTaxIncludedCostPrice(event.target.value)
                }
                onBlur={(event) => handleValidateCostPrice(event.target.value)}
              />
              <div
                style={{
                  marginLeft: 36,
                }}
              ></div>
              <SubmitButton
                label="反映する"
                color="light_gray"
                size="rectangle_small"
                icon="none"
                onClick={() =>
                  handleTaxIncludedCostPriceToCostPrice(
                    taxIncludedCostPrice ? taxIncludedCostPrice : '',
                  )
                }
              />
            </div>
            <div
              className={
                styles.modal_simulation_internal_capacity_container_form_box
              }
            >
              <RadioButtonWithLabel
                required={true}
                labelName="仕入れ値税率"
                description=""
                line={1}
                radioSize="large"
                labelSize="large"
                checkInfos={[
                  {
                    isChecked: tax === 8,
                    label: '8%',
                    onChange: () => handleTax(8),
                  },
                  {
                    isChecked: tax === 10,
                    label: '10%',
                    onChange: () => handleTax(10),
                  },
                ]}
              />
            </div>
            <div
              className={
                styles.modal_simulation_internal_capacity_container_form_box
              }
            >
              <InputTextAndUnitWithLabel
                required={true}
                labelName="可食部率（歩留率）"
                description=""
                line={2}
                type="text"
                size="minimum"
                value={yieldRate}
                placeholder="100"
                labelSize="large"
                unit="%"
                errorText={yieldRateErrorText}
                onChange={(event) => handleYieldRate(event.target.value)}
              />
            </div>
            <div
              className={
                styles.modal_simulation_internal_capacity_container_form_box
              }
            >
              {/* <TextAreaWithLabel
                required={false}
                labelName="メモ"
                description=""
                labelPosition="top"
                line={1}
                maxLength={1000}
                rows={8}
                size="large"
                value={internalCapacityMemo}
                placeholder="ここにメモを記入できます。"
                onChange={(event) => handleInternalCapacityMemo(event.target.value)}
              /> */}
              <InputTextWithLabel
                required={false}
                labelName="メモ"
                description=""
                line={2}
                type="text"
                size="middle"
                labelSize="large"
                value={String(internalCapacityMemo)}
                placeholder="ここにメモを記入できます。"
                onChange={(event) =>
                  handleInternalCapacityMemo(event.target.value)
                }
              />
            </div>
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="キャンセルする"
              color="orange_outlined"
              size="xmiddle"
              icon="none"
              onClick={handleIsPreviewCancel}
            />
            <div style={{ marginLeft: 32 }}>
              <SubmitButton
                label="登録する"
                color="orange"
                size="xmiddle"
                icon="none"
                onClick={handleIsPreviewSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CostSimulationOperationTemplate
