import { FC } from 'react'
import LoginLayout from 'components/Layout/LoginLayout'
import ResetPasswordTemplate from 'components/Templates/ResetPasswordTemplate'

const ResetPassword: FC = () => {
  return (
    <LoginLayout title={'パスワード再設定'}>
      <ResetPasswordTemplate />
    </LoginLayout>
  )
}

export default ResetPassword
