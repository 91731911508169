import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import SubmitButton from 'components/Button/SubmitButton'
import PageHedding from 'components/PageHedding/PageHedding'
import { functions } from 'fb'
import { httpsCallable } from 'firebase/functions'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { setIsLoading } from 'reducks/loading/slice'
import { RootState } from 'reducks/reducers'
import { getShopById } from 'reducks/shop/slice'
import styles from 'styles/components/template.module.scss'
import { CardCvcModal } from './RegisterPlanTemplate'

const EditPaymentTemplate: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const [isModal, setIsModal] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch<any>()
  const { staff } = useSelector((state: RootState) => state.staffSlice)
  const handleSubmit = async () => {
    if (!stripe || !elements || !staff?.shopId) return
    const cardNumberElement = elements.getElement(CardNumberElement)

    if (!cardNumberElement) return

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    })
    if (error) {
      console.log(error)
    } else {
      dispatch(setIsLoading({ isLoading: true }))
      const shop = await dispatch(getShopById({ id: staff.shopId }))
      /** カード変更 */
      const apiUpdateStripePayment = httpsCallable(
        functions,
        'apiUpdateStripePayment',
      )
      apiUpdateStripePayment({
        customerId: shop.payload.stripe_customer_id,
        paymentMethodId: paymentMethod.id,
        shopId: staff.shopId,
      })
        .then(() => setIsDone(true))
        .catch((e) => console.log(e))
        .finally(() => dispatch(setIsLoading({ isLoading: false })))
    }
  }

  useEffect(() => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
  }, [])

  return (
    <div className={styles.container} style={{ paddingBottom: 80 }}>
      <PageHedding title="契約情報" to="/plan_confirm" />
      <div className={styles.flexColumnCenter}>
        <p className={styles.registerplan_section_title}>支払方法の変更</p>
        <p className={styles.registerplan_section_subtitle}>
          月額基本利用料のお支払いのためのクレジットカード情報をご入力ください。
          <br />
          入力が終わりましたら、「変更する」ボタンを押してください。
        </p>
        <div style={{ marginTop: 40 }}>
          <div>
            <div className={styles.registerplan_plan_box_input_label}>
              カード番号
            </div>
            <div
              className={styles.registerplan_plan_box_input}
              style={{ width: 600 }}
            >
              <CardNumberElement />
            </div>
          </div>
          <div style={{ display: 'flex', width: 600, marginTop: 32 }}>
            <div>
              <div className={styles.registerplan_plan_box_input_label}>
                有効期限
              </div>
              <div
                className={styles.registerplan_plan_box_input}
                style={{ width: 290 }}
              >
                <CardExpiryElement />
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <div className={styles.registerplan_plan_box_input_label}>
                セキュリティコード
              </div>
              <div
                className={styles.registerplan_plan_box_input}
                style={{ width: 290 }}
              >
                <CardCvcElement />
              </div>
              <div className={styles.login_link_container}>
                <div
                  className={styles.login_link_container_text}
                  onClick={() => setIsModal(true)}
                >
                  <p style={{ fontSize: 12 }}>セキュリティコードとは</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.login_button_container}
          style={{ marginTop: 64 }}
        >
          <SubmitButton
            label="変更する"
            color="orange"
            size="semilarge"
            icon="none"
            onClick={handleSubmit}
          />
        </div>
      </div>
      {isDone && (
        <div className={styles.modal_account}>
          <div className={styles.modal_account_text}>
            支払い情報の更新が完了しました
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="契約情報へ戻る"
              color="orange"
              size="xmiddle"
              icon="none"
              onClick={() => {
                navigate('/plan_confirm')
                window.scrollTo(0, 0)
              }}
            />
          </div>
        </div>
      )}
      {isModal && <CardCvcModal setIsModal={setIsModal} />}
    </div>
  )
}

export default EditPaymentTemplate
