import { FC } from 'react'
import styles from 'styles/components/form.module.scss'

type Props = {
  isChecked: boolean
  label: string
  size: string
  type: string
  center?: boolean
  onChange?: () => void
  readOnly?: boolean
}

const CheckBox: FC<Props> = (props) => {
  const { isChecked, label, size, type, center, onChange, readOnly } = props

  const sizeStyle = (() => {
    switch (size) {
      case 'large':
        return styles.checkbox_large
      case 'xlarge':
        return styles.checkbox_xlarge
      case 'semi_large':
        return styles.checkbox_semi_large
      case 'middle':
        return styles.checkbox_middle
      case 'semi_middle':
        return styles.checkbox_semi_middle
      case 'small':
        return styles.checkbox_small
      case 'semi_small':
        return styles.checkbox_semi_small
      case 'table_header_small':
        return styles.checkbox_table_header_small
      case 'table_body_small':
        return styles.checkbox_table_body_small
      default:
        return styles.checkbox_middle
    }
  })()

  const checkTypeStyle = (() => {
    switch (type) {
      case 'default':
        return readOnly
          ? styles.checkbox_style_read_only
          : styles.checkbox_style_default
      case 'table_header':
        return readOnly
          ? styles.checkbox_style_table_header_read_only
          : styles.checkbox_style_table_header
      case 'table_body':
        return readOnly
          ? styles.checkbox_style_table_body_read_only
          : styles.checkbox_style_table_body
      default:
        return styles.checkbox_style_default
    }
  })()

  const checkTypeStyleChecked = (() => {
    switch (type) {
      case 'default':
        return styles.checkbox_style_checked
      case 'table_header':
        return styles.checkbox_style_table_header_checked
      case 'table_body':
        return styles.checkbox_style_table_body_checked
      default:
        return styles.checkbox_style_checked
    }
  })()

  const checkTypeStyleCheckMark = (() => {
    switch (type) {
      case 'default':
        return styles.checkbox_mark
      case 'table_header':
        return styles.checkbox_mark_table_header
      case 'table_body':
        return styles.checkbox_mark_table_body
      default:
        return styles.checkbox_mark
    }
  })()

  const centerClassName = center ? styles.checkbox_center : ''
  const className = `${sizeStyle} ${centerClassName}`
  const checkClassName = `${checkTypeStyle}`
  const checkedClassName = `${checkTypeStyleChecked}`
  const checkMarkClassName = `${checkTypeStyleCheckMark}`

  return (
    <div>
      <label className={className}>
        <div className={isChecked ? checkedClassName : checkClassName}>
          <div className={isChecked ? checkMarkClassName : ''}></div>
        </div>
        <input
          className={styles.checkbox_input}
          checked={isChecked}
          type="checkbox"
          readOnly={readOnly}
          onChange={() => (onChange ? onChange() : null)}
        />
        {label ? (
          <span
            className={
              size === 'table_header_small'
                ? styles.checkbox_text_table_header_small
                : styles.checkbox_text
            }
          >
            {label}
          </span>
        ) : (
          ''
        )}
      </label>
    </div>
  )
}

export default CheckBox
