import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import CostSimulationTemplate from 'components/Templates/CostSimulationTemplate'

const Dough: FC = () => {
  return (
    <Layout title={'原価シミュレーション'} page="CostSimulation">
      <CostSimulationTemplate />
    </Layout>
  )
}

export default Dough
