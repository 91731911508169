import { getApps, initializeApp, getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'
import { getFirestore, initializeFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { firebaseConfig } from 'fb/config'

const app = getApps().length < 1 ? initializeApp(firebaseConfig) : getApp()

initializeFirestore(app, {
  ignoreUndefinedProperties: true,
})

export const auth = getAuth(app)
export const functions = getFunctions(app, 'asia-northeast1')
export const db = getFirestore(app)
export const storage = getStorage(app)
