import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import PlanConfirmTemplate from 'components/Templates/PlanConfirmTemplate'

const PlanConfirmOperation: FC = () => {
  return (
    <Layout title={'現在のご利用プランについて'} page="PlanConfirm">
      <PlanConfirmTemplate />
    </Layout>
  )
}

export default PlanConfirmOperation
