import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { signOut } from 'firebase/auth'
import { auth } from 'fb/index'
import styles from 'styles/components/template.module.scss'
import InputText from 'components/Form/InputText'
import SubmitButton from 'components/Button/SubmitButton'
import { signIn } from 'reducks/auth/slice'
import { getStaffById } from 'reducks/staff/slice'
import { useNavigate } from 'react-router-dom'
import Logo from 'components/Icons/Logo'
import CheckBox from 'components/Form/CheckBox'
// import { RootState } from 'reducks/reducers'
// import { AppDispatch } from 'index'
import { setIsLoading } from 'reducks/loading/slice'

const LoginTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [id, setID] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isAutoLogin, setIsAutoLogin] = useState(false)
  // const { roles } = useSelector((state: RootState) => state.staffSlice)
  const [mailAddressErrorText, setMailAddressErrorText] = useState<
    string | null
  >(null)
  const [passwordErrorText, setPasswordErrorText] = useState<string | null>(
    null,
  )

  const handleID = (id: string) => {
    setID(id)
  }
  const handlePassword = (password: string) => {
    setPassword(password)
  }
  const handleSubmit = async () => {
    let requiredError = false
    if (!id) {
      setMailAddressErrorText('入力されておりません')
      requiredError = true
    }
    if (!password) {
      setPasswordErrorText('入力されておりません')
      requiredError = true
    }
    if (requiredError) {
      return
    }
    dispatch(setIsLoading({ isLoading: true }))
    const user = await dispatch(signIn({ email: id, password, isAutoLogin }))
    const userPayload = user.payload
    if (userPayload === null) {
      // 認証に失敗した場合
      setPasswordErrorText('認証情報が正しくありません')
      setPassword('')
      dispatch(setIsLoading({ isLoading: false }))
      return
    }
    // uidを取得し店舗情報と紐づいているか確認
    if (!userPayload.user || !userPayload.user.uid) {
      // ユーザー情報が不正
      signOut(auth).then(() => {
        setPasswordErrorText('無効なアカウントです')
        setPassword('')
        dispatch(setIsLoading({ isLoading: false }))
        return
      })
      return
    }
    const staff = await dispatch(getStaffById({ id: userPayload.user.uid }))
    console.log(staff)
    if (
      (staff.payload.isAdmin === false &&
        (!staff.payload.roles || staff.payload.roles.length === 0)) ||
      staff.payload.isDeleted
    ) {
      // ユーザー情報が不正
      signOut(auth).then(() => {
        setPasswordErrorText('認証情報が正しくありません')
        setPassword('')
        dispatch(setIsLoading({ isLoading: false }))
        return
      })
      return
    }
    navigate('/initload')
    dispatch(setIsLoading({ isLoading: false }))
  }

  return (
    <div className={styles.login_container}>
      <div className={styles.login_logo}>
        <Logo width={368} />
      </div>
      <div className={styles.login_form_container}>
        <div className={styles.login_form_container_row}>
          <p className={styles.login_form_container_text}>メールアドレス</p>
          <InputText
            required={true}
            type="text"
            size="xlarge"
            value={id}
            placeholder=""
            rightAligned={false}
            isLogin={true}
            onChange={(event) => handleID(event.target.value)}
            style={{ fontSize: 14 }}
            errorText={mailAddressErrorText}
          />
        </div>
        <div className={styles.login_form_container_row}>
          <p className={styles.login_form_container_text}>パスワード</p>
          <InputText
            required={true}
            type="password"
            size="xlarge"
            value={password}
            placeholder="英数字8桁以上"
            rightAligned={false}
            isLogin={true}
            onChange={(event) => handlePassword(event.target.value)}
            style={{ fontSize: 14 }}
            errorText={passwordErrorText}
          />
        </div>
        <CheckBox
          isChecked={isAutoLogin}
          label="ログイン情報を保存する"
          size="large"
          type="default"
          onChange={() => setIsAutoLogin(!isAutoLogin)}
        />
      </div>
      <div className={styles.login_link_container}>
        <Link className={styles.login_link_container_text} to="/password/reset">
          <p>ID・PASSをお忘れの方はコチラから</p>
        </Link>
      </div>
      <div className={styles.login_button_container}>
        <SubmitButton
          label="ログイン"
          color="orange"
          size="semilarge"
          icon="none"
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  )
}

export default LoginTemplate
