import { FC, ChangeEvent } from 'react'
import styles from 'styles/components/label.module.scss'
import SelectBox, { SelectOption } from 'components/Form/SelectBox'
import LightLabel from 'components/Label/LightLabel'

type Props = {
  labelName: string
  description: string
  line: number
  size: string
  value: string
  options: SelectOption[]
  errorText?: string | null
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  style?: React.CSSProperties
}

const SelectBoxWithLightLabel: FC<Props> = (props) => {
  const {
    labelName,
    description,
    line,
    size,
    value,
    options,
    errorText,
    onChange,
    style,
  } = props

  return (
    <div>
      <LightLabel name={labelName} description={description} line={line} />
      <div className={styles.lightLabelInputContainer}>
        <SelectBox
          style={style}
          size={size}
          value={value}
          options={options}
          errorText={errorText}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
      </div>
    </div>
  )
}

export default SelectBoxWithLightLabel
