import { FC, useEffect } from 'react'
import { signOut } from 'firebase/auth'
import { auth } from 'fb/index'
import { useNavigate } from 'react-router-dom'

const LogoutTemplate: FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    signOut(auth).then(() => {
      navigate('/login')
    })
  }, [])
  return <></>
}

export default LogoutTemplate
