import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import BreadAddTemplate from 'components/Templates/BreadAddTemplate'

const BreadOperation: FC = () => {
  return (
    <Layout title={'商品登録'} page="BreadAdd">
      <BreadAddTemplate />
    </Layout>
  )
}

export default BreadOperation
