import { FC, useState } from 'react'
import styles from 'styles/components/accordion.module.scss'
import plusIcon from 'images/Accordion/plus-icon.png'
import minusIcon from 'images/Accordion/minus-icon.png'

type Props = {
  label: string
  text: string
}

const AccordionField: FC<Props> = (props) => {
  const { label, text } = props
  const [viewContent, setViewContent] = useState<boolean>(false)
  return (
    <>
      <div className={styles.field}>
        <div className={styles.field_head}>
          <div className={styles.field_head_text}>{label}</div>
          <div
            className={styles.field_head_img}
            onClick={() => setViewContent(viewContent ? false : true)}
          >
            <img
              className={styles.field_head_img}
              src={viewContent ? minusIcon : plusIcon}
              alt={viewContent ? '-' : '+'}
            ></img>
          </div>
        </div>
        {viewContent ? (
          <div className={styles.field_content}>
            <div className={styles.field_content_text}>{text}</div>
          </div>
        ) : (
          ''
        )}
        {/* {viewContent ? (
          <>
            <div className={styles.field_head}>
              <div className={styles.field_head_text}>{label}</div>
              <div className={styles.field_head_img} onClick={() => setViewContent(false)}>
                <img
                  className={styles.field_head_img}
                  src={minusIcon}
                  alt="-"
                ></img>
              </div>
            </div>
            <div className={styles.field_content}>
            <div className={styles.field_content_text}>{text}</div>
            </div>
          </>
        ) : (
          <div className={styles.field_head}>
            <div className={styles.field_head_text}>{label}</div>
            <div className={styles.field_head_img} onClick={() => setViewContent(true)}>
              <img
                className={styles.field_head_img}
                src={plusIcon}
                alt="+"
              ></img>
            </div>
          </div>
        )} */}
      </div>
    </>
  )
}

export default AccordionField
