import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from 'styles/components/template.module.scss'
import InputText from 'components/Form/InputText'
import SubmitButton from 'components/Button/SubmitButton'
import { useNavigate } from 'react-router-dom'
import Logo from 'components/Icons/Logo'
import { resetPassword } from 'reducks/auth/slice'
// import { RootState } from 'reducks/reducers'
// import { AppDispatch } from 'index'

const ResetPasswordTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [emailErrorText, setEmailErrorText] = useState<string | null>(null)
  // const { roles } = useSelector((state: RootState) => state.staffSlice)

  const handleID = (email: string) => {
    setEmail(email)
  }
  const handleSubmit = async () => {
    if (!email) {
      setEmailErrorText('入力されておりません')
      return
    }
    await dispatch(resetPassword({ email }))
    navigate('/password/reset/send')
  }

  return (
    <div className={styles.login_container}>
      <div className={styles.login_logo}>
        <Logo width={368} />
      </div>
      <div className={styles.login_form_container}>
        <div className={styles.login_description}>
          <div className={styles.login_description_title}>
            パスワードを忘れた場合
          </div>
          <div
            className={styles.login_description_text}
          >{`メールアドレスを入力し、「送信する」ボタンを押してください。
登録されたメールアドレス宛にパスワードの再設定用のURLをお送りします。`}</div>
        </div>
        <div className={styles.login_form_container_row}>
          <p className={styles.login_form_container_text}>メールアドレス</p>
          <InputText
            required={true}
            type="text"
            size="xlarge"
            value={email}
            placeholder=""
            rightAligned={false}
            isLogin={true}
            onChange={(event) => handleID(event.target.value)}
            errorText={emailErrorText}
          />
        </div>
      </div>
      <div className={styles.login_button_container} style={{ marginTop: 40 }}>
        <SubmitButton
          label="送信する"
          color="orange"
          size="semilarge"
          icon="none"
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  )
}

export default ResetPasswordTemplate
