import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  orderBy,
  setDoc,
} from 'firebase/firestore'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'

interface ShopState {
  shop: Shop | null
  shops: Shop[]
}

interface Shop {
  id: string
  contact: string
  name: string
  responsible_person: string
  shop_id: string
  stripe_account_id: string
  stripe_customer_id: string
  stripe_payment_method_id: string
  factory_name?: string
}

export type ShopType = Shop

export enum Allergens {
  EGG = 'egg',
  MILK = 'milk',
  WHEAT = 'wheat',
  SHRIMP = 'shrimp',
  CRUB = 'crub',
  PEANUTS = 'peanuts',
  SOBA = 'soba',
}

const initialState = {
  shop: null,
  shops: [],
} as ShopState

export const getShops = createAsyncThunk<Shop[] | null, void>(
  'shop/getShops',
  async (_) => {
    const shops: Shop[] = []
    const q = query(collection(db, 'shops'), orderBy('name'))
    const querySnapshot = await getDocs(q)
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const shop = doc.data()
        shops.push({
          id: doc.id,
          contact: shop.contact,
          name: shop.name,
          responsible_person: shop.responsible_person,
          shop_id: shop.shop_id,
          stripe_account_id: shop.stripe_account_id,
          stripe_customer_id: shop.stripe_customer_id,
          stripe_payment_method_id: shop.stripe_payment_method_id,
        })
      }),
    )
    return shops
  },
)

export const getShopById = createAsyncThunk<Shop | null, { id: string }>(
  'shop/getShopById',
  async ({ id }) => {
    const q = doc(db, 'shops', id)
    const querySnapshot = await getDoc(q)
    const shop = querySnapshot.data()
    if (!shop) {
      return null
    }
    const response: Shop = {
      id: querySnapshot.id,
      contact: shop.contact,
      name: shop.name,
      responsible_person: shop.responsible_person,
      shop_id: shop.shop_id,
      stripe_account_id: shop.stripe_account_id,
      stripe_customer_id: shop.stripe_customer_id,
      stripe_payment_method_id: shop.stripe_payment_method_id,
      factory_name: shop.factory_name,
    }
    return response
  },
)

export const updateShop = createAsyncThunk<
  string | null,
  {
    shopData: Partial<ShopType>
    shopId: string
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('shop/updateShop', async ({ shopData, shopId }) => {
  const shopRef = doc(db, 'shops', shopId)
  await setDoc(shopRef, shopData, { merge: true })
  return ''
})

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    clearShop(state) {
      state.shop = null
    },
  },
  extraReducers: {
    [getShops.fulfilled.type]: (
      state,
      action: PayloadAction<Shop[] | null>,
    ) => {
      if (action.payload !== null) {
        state.shops = action.payload
      }
    },
    [getShopById.fulfilled.type]: (
      state,
      action: PayloadAction<Shop | null>,
    ) => {
      if (action.payload !== null) {
        state.shop = action.payload
      }
    },
  },
})

export const { clearShop } = shopSlice.actions
export default shopSlice.reducer
