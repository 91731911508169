import { FC } from 'react'
import { useDispatch } from 'react-redux'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import SubmitButton from 'components/Button/SubmitButton'
// import { createMaterialCategories } from 'reducks/materialCategory/slice'
// import { createMaterialSubCategories } from 'reducks/materialSubCategory/slice'
// import { createAdditiveUses } from 'reducks/additiveUse/slice'
// import { createAdditiveMaterials } from 'reducks/additiveMaterial/slice'
import {
  updateBreadDaserunoRecipeStatus,
  getAllDaserunoBread,
  updateCost,
  updatePrechangeCostPrice,
  BreadType,
} from 'reducks/bread/slice'
import {
  getMaterialInfomationByBreadId,
  BreadDetailInformationType,
} from 'reducks/business/slice'

// import { getMailsEmptyStaffs } from 'reducks/staff/slice'

const PortalTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  // const handleCreateMaterialCategories = async () => {
  //   await dispatch(createMaterialCategories())
  // }
  // const handleCreateMaterialSubCategories = async () => {
  //   await dispatch(createMaterialSubCategories())
  // }
  // const handleCreateAdditiveUses = async () => {
  //   await dispatch(createAdditiveUses())
  // }
  // const handleCreateAdditiveMaterials = async () => {
  //   await dispatch(createAdditiveMaterials())
  // }
  const handleUpdateBreadDaserunoRecipeStatus = async () => {
    await dispatch(updateBreadDaserunoRecipeStatus())
  }
  const handleUpdateBreadCost = async () => {
    const breadsInfo = await dispatch(getAllDaserunoBread())
    const breads = breadsInfo.payload
    await Promise.all(
      breads.map(async (bread: BreadType) => {
        const infoPayload = await dispatch(
          getMaterialInfomationByBreadId({ breadId: bread.id }),
        )
        const info: BreadDetailInformationType | null = infoPayload.payload
        if (info) {
          await dispatch(
            updateCost({
              id: bread.id,
              cost_price: info.costPrice,
              not_cost_calculated_reasons: info.notCostCalculatedReasons,
              not_cost_calculated_recipe_reasons:
                info.notCostCalculatedRecipeReasons,
            }),
          )
          await dispatch(
            updatePrechangeCostPrice({
              id: bread.id,
              pre_change_cost_price: info.costPrice,
            }),
          )
        }
      }),
    )
  }
  // const handleGetAllStaffs = async () => {
  //   await dispatch(getMailsEmptyStaffs())
  // }
  return (
    <div className={styles.container}>
      <PageHedding title="マイページ" to="/initload" />
      <div className={styles.mypage}>
        {/* <SubmitButton
          label="原材料大カテゴリを登録する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleCreateMaterialCategories()}
        />
        <SubmitButton
          label="原材料小カテゴリを登録する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleCreateMaterialSubCategories()}
        />
        <SubmitButton
          label="添加物用途を登録する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleCreateAdditiveUses()}
        />
        <SubmitButton
          label="添加物用途を登録する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleCreateAdditiveMaterials()}
        /> */}
        <SubmitButton
          label="レシピ下書きステータスを一括登録する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleUpdateBreadDaserunoRecipeStatus()}
        />
        <SubmitButton
          label="ダセルーノで使用されている商品に原価を一括登録する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleUpdateBreadCost()}
        />
        {/* <SubmitButton
          label="スタッフ情報を全て取得する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleGetAllStaffs()}
        /> */}
      </div>
    </div>
  )
}

export default PortalTemplate
