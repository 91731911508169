import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import LogoutTemplate from 'components/Templates/LogoutTemplate'

const Logout: FC = () => {
  return (
    <Layout title={'ログアウト'} page="Logout">
      <LogoutTemplate />
    </Layout>
  )
}

export default Logout
