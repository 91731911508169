import { FC } from 'react'

type Props = {
  label: string
  subLabel?: string
  value: string
  width: number | string
}
const LabelWithBorder: FC<Props> = ({ label, subLabel, value, width }) => {
  return (
    <div>
      <div
        style={{
          width: width,
          height: 30,
          borderBottom: '1px solid',
          borderBottomColor: '#D9D9D9',
          fontWeight: 'bold',
          fontSize: 14,
        }}
      >
        {label}
        <span style={{ fontWeight: 'normal' }}>{subLabel}</span>
      </div>
      <div style={{ marginTop: 8, fontSize: 14, whiteSpace: 'break-spaces' }}>
        {value}
      </div>
    </div>
  )
}

export default LabelWithBorder
