import { FC } from 'react'
import styles from 'styles/components/label.module.scss'
import CheckBox from 'components/Form/CheckBox'
import LightLabel from 'components/Label/LightLabel'

type Props = {
  labelName: string
  description: string
  line: number
  checkInfos: checkInfo[]
}

export type checkInfo = {
  isChecked: boolean
  label: string
  size: string
  type: string
  onChange?: () => void | void
}

const CheckBoxWithLightLabel: FC<Props> = (props) => {
  const { labelName, description, line, checkInfos } = props
  // const [checkboxItems, setCheckboxItems] = useState<checkInfo[]>(checkInfos)

  // const handleChangeCheckBox = (index: number, onChange: () => void) => {
  //   const newCheckboxItems = [...checkboxItems]
  //   console.log(index)
  //   if (newCheckboxItems[index] && checkboxItems[index]) {
  //     newCheckboxItems[index].isChecked = !checkboxItems[index].isChecked
  //       ? true
  //       : false
  //     setCheckboxItems(newCheckboxItems)
  //   }
  //   onChange()
  // }

  return (
    <div>
      <LightLabel name={labelName} description={description} line={line} />
      <div className={styles.lightLabelInputContainer}>
        {checkInfos.map((checkInfo) => (
          <div
            className={
              checkInfo.label.length > 2
                ? checkInfo.label.length > 3
                  ? checkInfo.label.length > 6
                    ? styles.checkboxAndLabelContainerLong3
                    : styles.checkboxAndLabelContainerLong2
                  : styles.checkboxAndLabelContainerLong
                : styles.checkboxAndLabelContainer
            }
            key={checkInfo.label}
          >
            <CheckBox
              isChecked={checkInfo.isChecked}
              label={checkInfo.label}
              size={checkInfo.size}
              type={checkInfo.type}
              onChange={() =>
                checkInfo.onChange ? checkInfo.onChange() : null
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CheckBoxWithLightLabel
