import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import BreadCostTemplate from 'components/Templates/BreadCostTemplate'

const BreadCost: FC = () => {
  return (
    <Layout title={'原価一覧'} page="BreadCost">
      <BreadCostTemplate />
    </Layout>
  )
}

export default BreadCost
