import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import SacriAdminToolTemplate from 'components/Templates/SacriAdminToolTemplate'

const MyPage: FC = () => {
  return (
    <Layout title={'ツール'} page="SacriAdminTool">
      <SacriAdminToolTemplate />
    </Layout>
  )
}

export default MyPage
