import { FC, ChangeEvent, useState } from 'react'
import styles from 'styles/components/form.module.scss'
import SubmitButton from 'components/Button/SubmitButton'

type Props = {
  required: boolean
  type: string
  size: string
  value: string | number
  placeholder: string
  rightAligned: boolean
  isLogin?: boolean
  errorText?: string | null
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onInput?: () => void
  style?: React.CSSProperties
  errorTextStyle?: React.CSSProperties
  onFocus?: () => void
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
  readOnly?: boolean
}

const InputText: FC<Props> = (props) => {
  const {
    required,
    type,
    size,
    value,
    placeholder,
    rightAligned,
    isLogin,
    errorText,
    onChange,
    onInput,
    style,
    errorTextStyle,
    onFocus,
    onBlur,
    readOnly,
  } = props

  const [visiblePassword, setVisiblePassword] = useState<boolean>(false)

  const handleVisiblePassword = () => {
    setVisiblePassword(visiblePassword ? false : true)
  }

  const inputStyle = (() => {
    return rightAligned
      ? styles.inputtext_right
      : isLogin
      ? styles.inputtext_login
      : styles.inputtext
  })()

  const errorStyle = (() => {
    return errorText ? styles.inputtext_error : styles.inputtext
  })()

  const sizeStyle = (() => {
    switch (size) {
      case 'full':
        return styles.inputtext_full
      case 'xlarge':
        return styles.inputtext_xlarge
      case 'xlarge2':
        return styles.inputtext_xlarge2
      case 'large':
        return styles.inputtext_large
      case 'semilarge':
        return styles.inputtext_semilarge
      case 'middle':
        return styles.inputtext_middle
      case 'semimiddle':
        return styles.inputtext_semimiddle
      case 'semimiddle2':
        return styles.inputtext_semimiddle2
      case 'semimiddle_detail_row':
        return styles.inputtext_semimiddle_detail_row
      case 'small':
        return styles.inputtext_small
      case 'xsmall':
        return styles.inputtext_xsmall
      case 'xsmall_detail_row':
        return styles.inputtext_xsmall_detail_row
      case 'minimum':
        return styles.inputtext_minimum
      case 'minimum_detail_row':
        return styles.inputtext_minimum_detail_row
      case 'in_table':
        return styles.inputtext_in_table
      case 'detail_weight_in_use':
        return styles.inputtext_detail_weight_in_use
      case 'cost_simulation':
        return styles.inputtext_cost_simulation
      default:
        return styles.inputtext_large
    }
  })()

  const visibleIconStyle = (() => {
    switch (size) {
      case 'xlarge':
        return styles.inputtext_visible_icon_xlarge
      case 'semilarge':
        return styles.inputtext_visible_icon_semilarge
      default:
        return styles.inputtext_visible_icon_semilarge
    }
  })()

  const readOnlyStyle = (() => {
    if (readOnly) {
      return styles.inputtext_read_only
    } else {
      return ''
    }
  })()

  const className = `${inputStyle} ${errorStyle} ${sizeStyle} ${readOnlyStyle}`

  return (
    <div>
      <input
        className={className}
        style={style}
        required={required}
        type={type === 'password' && visiblePassword ? 'text' : type}
        value={value}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={(e) => (onChange ? onChange(e) : null)}
        onInput={onInput}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {type === 'password' ? (
        <div className={visibleIconStyle}>
          <SubmitButton
            label=""
            color="none"
            size="icon"
            icon={visiblePassword ? 'visible' : 'invisible'}
            onClick={() => handleVisiblePassword()}
          />
        </div>
      ) : (
        ''
      )}
      {errorText ? (
        <p className={styles.inputtext_error_text} style={errorTextStyle}>
          {errorText}
        </p>
      ) : (
        ''
      )}
    </div>
  )
}

export default InputText
