import { FC, useState } from 'react'
import styles from 'styles/components/button.module.scss'
import { Link } from 'react-router-dom'
import userIcon from 'images/SideMenu/user-icon.png'
import userWhiteIcon from 'images/SideMenu/user-white-icon.png'
import shopIcon from 'images/SideMenu/shop-icon.png'
import shopWhiteIcon from 'images/SideMenu/shop-white-icon.png'
import planIcon from 'images/SideMenu/plan-icon.png'
import planWhiteIcon from 'images/SideMenu/plan-white-icon.png'
import doughIcon from 'images/SideMenu/dough-icon.png'
import doughWhiteIcon from 'images/SideMenu/dough-white-icon.png'
import fillingIcon from 'images/SideMenu/filling-icon.png'
import fillingWhiteIcon from 'images/SideMenu/filling-white-icon.png'
import recipeIcon from 'images/SideMenu/recipe-icon.png'
import recipeWhiteIcon from 'images/SideMenu/recipe-white-icon.png'
import peopleIcon from 'images/SideMenu/people-icon.png'
import peopleWhiteIcon from 'images/SideMenu/people-white-icon.png'

type Props = {
  label: string
  to: string
  icon: string
}

const MyPageLink: FC<Props> = (props) => {
  const { label, to, icon } = props

  const iconImagePath = (() => {
    switch (icon) {
      case 'user':
        return userIcon
      case 'shop':
        return shopIcon
      case 'plan':
        return planIcon
      case 'dough':
        return doughIcon
      case 'filling':
        return fillingIcon
      case 'recipe':
        return recipeIcon
      case 'people':
        return peopleIcon
      default:
        return userIcon
    }
  })()

  const iconWhiteImagePath = (() => {
    switch (icon) {
      case 'user':
        return userWhiteIcon
      case 'shop':
        return shopWhiteIcon
      case 'plan':
        return planWhiteIcon
      case 'dough':
        return doughWhiteIcon
      case 'filling':
        return fillingWhiteIcon
      case 'recipe':
        return recipeWhiteIcon
      case 'people':
        return peopleWhiteIcon
      default:
        return userWhiteIcon
    }
  })()

  const [iconPath, setIconPath] = useState<string>(iconImagePath)

  const handleMouseEnter = () => {
    setIconPath(iconWhiteImagePath)
  }
  const handleMouseLeave = () => {
    setIconPath(iconImagePath)
  }

  return (
    <Link
      className={styles.mypagelink}
      to={to}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div className={styles.mypagelink_center}>
        <div className={styles.mypagelink_center_icon}>
          <img
            className={styles.mypagelink_icon}
            src={iconPath}
            alt={label}
          ></img>
        </div>
        <p className={styles.mypagelink_center_text}>{label}</p>
      </div>
    </Link>
  )
}

export default MyPageLink
