import { FC, useCallback, useEffect, useState } from 'react'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import SelectBox from 'components/Form/SelectBox'
import SubmitButton from 'components/Button/SubmitButton'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import InputText from 'components/Form/WithLightLabel/InputText'
import CheckBoxWithLightLabel from 'components/Form/WithLightLabel/CheckBox'
import { prefectures } from './prefectures'
import { useDispatch, useSelector } from 'react-redux'
import { getShopById } from 'reducks/shop/slice'
import { RootState } from 'reducks/reducers'
import { getStoreById } from 'reducks/store/slice'
import { kataToHira } from 'util/kana'
import { getStaff } from 'reducks/staff/slice'
import { useNavigate, useLocation } from 'react-router-dom'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import RadioButtonWithLightLabel from 'components/Form/WithLightLabel/RadioButton'
import RadioButton from 'components/Form/RadioButton'

type Props = {
  shopName: string
  shopNameErrorText: string | null
  setShopName: React.Dispatch<React.SetStateAction<string>>
  shopNameKana: string
  shopNameKanaErrorText: string | null
  setShopNameKana: React.Dispatch<React.SetStateAction<string>>
  setFactoryName: React.Dispatch<React.SetStateAction<string>>
  officeName: string
  officeNameErrorText: string | null
  setOfficeName: React.Dispatch<React.SetStateAction<string>>
  officerName: string
  officerNameErrorText: string | null
  setOfficerName: React.Dispatch<React.SetStateAction<string>>
  zipCode: string
  zipCodeErrorText: string | null
  setZipCode: React.Dispatch<React.SetStateAction<string>>
  prefecture: string
  prefectureErrorText: string | null
  setPrefecture: React.Dispatch<React.SetStateAction<string>>
  city: string
  cityErrorText: string | null
  setCity: React.Dispatch<React.SetStateAction<string>>
  address: string
  addressErrorText: string | null
  setAddress: React.Dispatch<React.SetStateAction<string>>
  building: string
  setBuilding: React.Dispatch<React.SetStateAction<string>>
  email: string
  emailErrorText: string | null
  setEmail: React.Dispatch<React.SetStateAction<string>>
  phoneNumber: string
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
  phoneNumberErrorText: string | null
  searchAddress: () => void
  handleConfirmed: () => void
  zipCodeSearchErrorText: string | null
  handleSubmit: (is_resend?: boolean) => Promise<void>
  setShopId: React.Dispatch<React.SetStateAction<string>>
  setStoreId: React.Dispatch<React.SetStateAction<string>>
  isRegister: boolean
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>
  isSentEmail: boolean
  isCompany: boolean
  setIsCompany: React.Dispatch<React.SetStateAction<boolean>>
  isSameAddress: boolean
  setIsSameAddress: React.Dispatch<React.SetStateAction<boolean>>
  isBillPayment: boolean
  setIsBillPayment: React.Dispatch<React.SetStateAction<boolean>>
}
const EditContainer: FC<Props> = ({
  shopName,
  setShopName,
  shopNameKana,
  setShopNameKana,
  setFactoryName,
  officeName,
  setOfficeName,
  officerName,
  setOfficerName,
  zipCode,
  setZipCode,
  prefecture,
  setPrefecture,
  city,
  setCity,
  address,
  setAddress,
  building,
  setBuilding,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  shopNameErrorText,
  shopNameKanaErrorText,
  officeNameErrorText,
  officerNameErrorText,
  zipCodeErrorText,
  prefectureErrorText,
  cityErrorText,
  addressErrorText,
  emailErrorText,
  phoneNumberErrorText,
  searchAddress,
  handleConfirmed,
  zipCodeSearchErrorText,
  handleSubmit,
  setShopId,
  setStoreId,
  isRegister,
  setIsAdmin,
  isSentEmail,
  isCompany,
  setIsCompany,
  isSameAddress,
  setIsSameAddress,
  isBillPayment,
  setIsBillPayment,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const location = useLocation()
  const path = location.pathname
  const { staff, adminShopId, adminStoreId } = useSelector(
    (state: RootState) => state.staffSlice,
  )
  const [staffStatus, setStaffStatus] = useState('')
  const dispatchGetShop = useCallback(
    async (shopId: string) => {
      const shop = await dispatch(getShopById({ id: shopId }))
      setOfficerName(shop.payload.responsible_person)
      setOfficeName(shop.payload.name)
      setFactoryName(shop.payload.factory_name)
      // 法人名がない場合は個人事業主
      if (!shop.payload.name && !!shop.payload.responsible_person) {
        setIsCompany(false)
      }
    },
    [dispatch, setFactoryName, setIsCompany, setOfficeName, setOfficerName],
  )
  const dispatchGetStore = useCallback(
    async (storeId: string) => {
      const store = await dispatch(getStoreById({ storeId }))
      setShopName(store.payload.name)
      setShopNameKana(kataToHira(store.payload.name_kana))
      setPhoneNumber(store.payload.personal_phone_number)
      // // 製造所住所データがある場合はお店と異なる住所
      if (!!store.payload.factory_address.postal_code) {
        setIsSameAddress(false)
      }
    },
    [dispatch, setIsSameAddress, setPhoneNumber, setShopName, setShopNameKana],
  )
  const dispatchGetStoreAddress = useCallback(
    async (storeId: string) => {
      const store = await dispatch(getStoreById({ storeId }))
      if (!!store.payload.factory_address.postal_code && !isSameAddress) {
        // 製造所住所をセット
        setZipCode(store.payload.factory_address.postal_code)
        setPrefecture(store.payload.factory_address.state)
        setCity(store.payload.factory_address.city)
        setAddress(store.payload.factory_address.street)
        setBuilding(store.payload.factory_address.building)
      } else if (isSameAddress) {
        // お店の住所をセット
        setZipCode(store.payload.address.postal_code)
        setPrefecture(store.payload.address.state)
        setCity(store.payload.address.city)
        setAddress(store.payload.address.street)
        setBuilding(store.payload.address.building)
      } else {
        setZipCode('')
        setPrefecture('')
        setCity('')
        setAddress('')
        setBuilding('')
      }
    },
    [
      dispatch,
      isSameAddress,
      setAddress,
      setBuilding,
      setCity,
      setPrefecture,
      setZipCode,
    ],
  )
  useEffect(() => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    const getInfo = async () => {
      // デフォルト値セット
      if (staff?.isAdmin) {
        setIsAdmin(true)
      }
      // スーパー管理者による店舗の閲覧
      if (!!adminShopId && !!adminStoreId && staff?.isAdmin) {
        setShopId(adminShopId)
        dispatchGetShop(adminShopId)
        const selectedStaff = await dispatch(getStaff({ uid: staff?.uid }))
        setStoreId(adminStoreId)
        dispatchGetStore(adminStoreId)
        dispatchGetStoreAddress(adminStoreId)
        setEmail(selectedStaff.payload.email)
      } else if (staff?.shopId) {
        // 各店舗の閲覧
        setShopId(staff.shopId)
        dispatchGetShop(staff.shopId)
        setStoreId(staff.storeId || staff.roles[0].store_ref.id)
        dispatchGetStore(staff.storeId || staff.roles[0].store_ref.id)
        dispatchGetStoreAddress(staff.storeId || staff.roles[0].store_ref.id)
        setEmail(staff.email)
      }
    }

    getInfo()
  }, [])

  useEffect(() => {
    const getSetStaffStatus = async () => {
      if (!staff) return
      const selectedStaff = await dispatch(getStaff({ uid: staff?.uid }))
      if (
        selectedStaff.payload.invitationExpiredAt &&
        isBefore(new Date(), selectedStaff.payload.invitationExpiredAt.toDate())
      ) {
        setStaffStatus('招待中')
        return
      }
      if (
        selectedStaff.payload.invitationExpiredAt &&
        isAfter(new Date(), selectedStaff.payload.invitationExpiredAt.toDate())
      ) {
        setStaffStatus('招待期限切れ')
        return
      }

      setStaffStatus('認証済')
    }

    getSetStaffStatus()
  }, [])

  useEffect(() => {
    const setShopAddress = async () => {
      if (!!adminShopId && staff?.isAdmin) {
        const selectedStaff = await dispatch(getStaff({ uid: staff?.uid }))
        dispatchGetStoreAddress(selectedStaff.payload.roles[0].store_ref.id)
      } else if (staff?.shopId) {
        dispatchGetStoreAddress(staff.storeId || staff.roles[0].store_ref.id)
      }
    }

    setShopAddress()
  }, [isSameAddress])
  return (
    <>
      {!isRegister && <PageHedding title="店舗入力" to="/mypage" />}
      <div style={{ marginTop: 32 }}>
        <RadioButtonWithLightLabel
          required={true}
          isChecked={isCompany}
          labelName="店舗の登録形態をお選びください"
          description=""
          line={1}
          checkInfos={[
            {
              isChecked: isCompany,
              label: '法人',
              onChange: () => setIsCompany(true),
            },
            {
              isChecked: !isCompany,
              label: '個人事業主',
              onChange: () => setIsCompany(false),
            },
          ]}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 32 }}>
        <InputTextWithLightLabel
          inputStyle={{ width: 460 }}
          required={true}
          labelName="店舗名"
          description=""
          line={1}
          type="text"
          size="large"
          value={shopName}
          placeholder="sacri Bakery"
          errorText={shopNameErrorText}
          onChange={(event) => setShopName(event.target.value)}
        />
        <InputTextWithLightLabel
          inputStyle={{ width: 460 }}
          containerStyle={{ marginLeft: 'auto' }}
          required={true}
          labelName="店舗名（ふりがな）"
          description=""
          line={1}
          type="text"
          size="large"
          value={shopNameKana}
          placeholder="さくりべーかりー"
          errorText={shopNameKanaErrorText}
          onChange={(event) => setShopNameKana(event.target.value)}
        />
      </div>

      <div style={{ display: 'flex', marginTop: 40 }}>
        {isCompany && (
          <InputTextWithLightLabel
            inputStyle={{ width: 460 }}
            required={true}
            labelName="法人名"
            description=""
            line={1}
            type="text"
            size="large"
            value={officeName}
            placeholder="株式会社sacri"
            errorText={officeNameErrorText}
            onChange={(event) => setOfficeName(event.target.value)}
          />
        )}
        <InputTextWithLightLabel
          inputStyle={{ width: 460 }}
          containerStyle={{ marginLeft: isCompany ? 'auto' : 0 }}
          required={true}
          labelName="責任者名（フルネーム）"
          description=""
          line={1}
          type="text"
          size="large"
          value={officerName}
          placeholder="代表取締役　山田太郎"
          errorText={officerNameErrorText}
          onChange={(event) => setOfficerName(event.target.value)}
        />
      </div>

      <div style={{ marginTop: 32 }}>
        <RadioButtonWithLightLabel
          required={true}
          isChecked={isCompany}
          labelName="製造所の住所について"
          description=""
          line={1}
          checkInfos={[]}
        />
        <div style={{ display: 'flex' }}>
          <RadioButton
            isChecked={isSameAddress}
            label="お店と製造所の住所が同じ"
            onChange={() => setIsSameAddress(true)}
          />
          <div style={{ marginLeft: 32 }}>
            <RadioButton
              isChecked={!isSameAddress}
              label="お店と製造所の住所が異なる ※製造所の住所のみで問題ございません。"
              onChange={() => setIsSameAddress(false)}
            />
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', marginTop: 54, alignItems: 'flex-end' }}>
        <InputTextWithLightLabel
          inputStyle={{ width: 225 }}
          required={true}
          labelName={isSameAddress ? 'お店・製造所の住所' : '製造所の住所'}
          description=""
          line={1}
          type="text"
          size="large"
          value={zipCode}
          placeholder="1010054（ハイフン無し）"
          errorText={zipCodeErrorText}
          onChange={(event) => setZipCode(event.target.value)}
        />
        <div>
          <button
            className={styles.addressSearchButton}
            onClick={searchAddress}
          >
            <div
              style={{
                fontWeight: 'bold',
                paddingRight: 16,
                paddingLeft: 16,
                cursor: 'pointer',
              }}
            >
              郵便番号から住所を入力
            </div>
          </button>
          <div className={styles.zipCodeSearchErrorText}>
            {zipCodeSearchErrorText}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 32 }}>
        <SelectBox
          style={{ width: 225 }}
          containerStyle={{ position: 'relative' }}
          size="large"
          value={prefecture}
          options={prefectures}
          errorText={prefectureErrorText}
          errorTextStyle={{ top: 54 }}
          onChange={(e) => setPrefecture(e.target.value)}
        />
        <InputText
          containerStyle={{ marginLeft: 20, marginTop: 0 }}
          inputStyle={{ width: 225 }}
          required={false}
          labelName=""
          description=""
          line={1}
          type="text"
          size="large"
          value={city}
          placeholder="市区町村"
          errorText={cityErrorText}
          onChange={(event) => setCity(event.target.value)}
        />
        <InputText
          containerStyle={{ marginLeft: 20, marginTop: 0 }}
          inputStyle={{ width: 510 }}
          required={false}
          labelName=""
          description=""
          line={1}
          type="text"
          size="large"
          value={address}
          placeholder="番地"
          errorText={addressErrorText}
          onChange={(event) => setAddress(event.target.value)}
        />
      </div>
      <InputText
        containerStyle={{ marginTop: 32 }}
        inputStyle={{ width: 1002 }}
        required={false}
        labelName=""
        description=""
        line={1}
        type="text"
        size="large"
        value={building}
        placeholder="建物名・号室"
        onChange={(event) => setBuilding(event.target.value)}
      />

      <div style={{ display: 'flex', marginTop: 54 }}>
        <div>
          <InputTextWithLightLabel
            inputStyle={{ width: 460 }}
            required={true}
            labelName="メールアドレス"
            rightRight={
              !!adminShopId && staff?.isAdmin ? (
                <div className={styles.emailVerifyStatus}>{staffStatus}</div>
              ) : null
            }
            description=""
            line={1}
            type="text"
            size="large"
            value={email}
            placeholder="sample@sacri.jp"
            errorText={emailErrorText}
            readOnly={!(staff?.isAdmin && !adminShopId)}
            onChange={(event) => setEmail(event.target.value)}
          />
          {!!staffStatus && staffStatus !== '認証済' && (
            <div
              className={styles.sendEmailButton}
              onClick={() => handleSubmit(true)}
            >
              メールを再送する
            </div>
          )}
        </div>

        <InputTextWithLightLabel
          inputStyle={{ width: 460 }}
          containerStyle={{ marginLeft: 'auto' }}
          required={true}
          labelName="電話番号"
          description="（日中連絡が取れる番号をお願いいたします。）"
          line={1}
          type="text"
          size="large"
          value={phoneNumber}
          placeholder="03112345678（ハイフン無し）"
          errorText={phoneNumberErrorText}
          errorTextStyle={{ width: 460 }}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
      </div>

      {staff?.isAdmin ? (
        <div style={{ display: 'flex', marginTop: 54 }}>
          <CheckBoxWithLightLabel
            labelName="請求書払い"
            description=""
            line={1}
            checkInfos={[
              {
                isChecked: isBillPayment,
                label: '請求書払いにする',
                size: 'large',
                type: 'default',
                onChange: () => setIsBillPayment(!isBillPayment),
              },
            ]}
          />
        </div>
      ) : (
        ''
      )}

      <div className={styles.shopInfoButtonContainer}>
        <>
          <SubmitButton
            label="入力内容を確認する"
            color="orange"
            size="large"
            icon="none"
            onClick={handleConfirmed}
          />
        </>
      </div>

      {isSentEmail && (
        <div className={styles.modal_account}>
          <div className={styles.modal_account_text}>
            招待メールを送信しました
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="マイページに戻る"
              color="orange"
              size="xmiddle"
              icon="none"
              onClick={() => {
                navigate('/')
                window.scrollTo(0, 0)
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default EditContainer
