import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { auth, db } from 'fb/index'
import {
  signInWithEmailAndPassword,
  UserCredential,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
  setPersistence,
  browserLocalPersistence,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { doc, updateDoc } from 'firebase/firestore'

interface AuthState {
  uid: string
}

const initialState = { uid: '' } as AuthState

export const signIn = createAsyncThunk<
  UserCredential | null,
  { email: string; password: string; isAutoLogin: boolean }
>('auth/signIn', async ({ email, password, isAutoLogin }) => {
  // 「次回から自動的にログインする」にチェックが入っていなければ、ブラウザ・タブを閉じたときにログアウト
  if (!isAutoLogin) await setPersistence(auth, browserLocalPersistence)

  try {
    const user = await signInWithEmailAndPassword(auth, email, password)
    return user
  } catch {
    // 認証失敗
    // TODO: エラーケースを分けた方が良い
    return null
  }
})

export const changeEmail = createAsyncThunk<
  void,
  { newEmail: string; password: string }
>('auth/changeEmail', async ({ newEmail, password }) => {
  const user = auth.currentUser
  if (!user || !user.email) {
    return
  }
  const credential = EmailAuthProvider.credential(user.email, password)
  await reauthenticateWithCredential(user, credential)
  await updateEmail(user, newEmail)
  // staffsコレクションの更新
  const ref = doc(db, 'staffs', user.uid)
  const data = {
    email: newEmail,
  }
  await updateDoc(ref, data)
})

export const changePassword = createAsyncThunk<
  void,
  { newPassword: string; password: string }
>('auth/changePassword', async ({ newPassword, password }) => {
  const user = auth.currentUser
  if (!user || !user.email) {
    return
  }
  const credential = EmailAuthProvider.credential(user.email, password)
  await reauthenticateWithCredential(user, credential)
  await updatePassword(user, newPassword)
})

export const resetPassword = createAsyncThunk<void, { email: string }>(
  'auth/resetPassword',
  async ({ email }) => {
    await sendPasswordResetEmail(auth, email)
  },
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<AuthState>) {
      state.uid = action.payload.uid
    },
  },
  extraReducers: {
    [signIn.pending.type]: () => {},
    [signIn.fulfilled.type]: (state, action: PayloadAction<UserCredential>) => {
      if (action.payload !== null) {
        state.uid = action.payload.user.uid
      }
    },
    [signIn.rejected.type]: () => {},
  },
})

export const { setAuth } = authSlice.actions
export default authSlice.reducer
