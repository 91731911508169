import { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import PageHeadAttention from 'components/Attention/PageHeadAttention'
import SubmitButton from 'components/Button/SubmitButton'
import { getMaterials, deleteMaterial } from 'reducks/material/slice'
import { getAllDoughs, DoughType } from 'reducks/dough/slice'
import { getAllFillings, FillingType } from 'reducks/filling/slice'
import { getAllPublicRecipes, RecipeType } from 'reducks/recipe/slice'
import { getShopById, ShopType } from 'reducks/shop/slice'
import { getStoreByShopId, StoreType } from 'reducks/store/slice'
import {
  updateBackingStickerStatus,
  BackingStickerStatus,
} from 'reducks/bread/slice'
import { getTopBreadIdsRecursivelyById, DataType } from 'reducks/business/slice'
import { Scrollbars } from 'react-custom-scrollbars'
import accountIcon from 'images/account-icon.png'
import { setIsLoading } from 'reducks/loading/slice'
import { RootState } from 'reducks/reducers'

interface DeleteConfirmShop {
  imagePath: string
  shopName: string
  doughNames: string
  fillingNames: string
  recipeNames: string
}

interface NameListMap {
  [id: string]: string[]
}

const MaterialDeleteConfirmTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const materialId = path.split('/material/delete/')[1].split('/confirm')[0]
    ? path.split('/material/delete/')[1].split('/confirm')[0]
    : null

  const [deleteConfirmShopList, setDeleteConfirmShopList] = useState<
    DeleteConfirmShop[]
  >([])

  const { staff } = useSelector((state: RootState) => state.staffSlice)

  // TODO: 生地とフィリングの使用不可処理とともに共通処理化したい
  const setDeleteConfirmMaterialInfo = useCallback(
    async (materialId: string | null) => {
      if (!materialId) {
        navigate('/initload')
        window.scrollTo(0, 0)
        return
      }
      if (!staff) {
        navigate('/initload?path=' + path)
        window.scrollTo(0, 0)
        return
      }
      const confirmShopIdList: string[] = []
      const fillingsPayload = await dispatch(getAllFillings())
      const fillings: FillingType[] = fillingsPayload.payload
      const fillingsWaitList: FillingType[] = []
      fillings.forEach((filling) => {
        if (filling.material_informations) {
          filling.material_informations.forEach((material_information) => {
            if (
              material_information.material_ref &&
              material_information.material_ref.id === materialId
            ) {
              fillingsWaitList.push(filling)
            }
          })
        }
      })
      const fillingNameList: NameListMap = {}
      fillingsWaitList.forEach((filling) => {
        if (!confirmShopIdList.includes(filling.shop_ref.id)) {
          confirmShopIdList.push(filling.shop_ref.id)
        }
        if (fillingNameList.hasOwnProperty(filling.shop_ref.id)) {
          fillingNameList[filling.shop_ref.id].push(filling.pettern_name)
        } else {
          fillingNameList[filling.shop_ref.id] = [filling.pettern_name]
        }
      })
      const doughsPayload = await dispatch(getAllDoughs())
      const doughs: DoughType[] = doughsPayload.payload
      const doughsWaitList: DoughType[] = []
      doughs.forEach((dough) => {
        if (dough.material_informations) {
          dough.material_informations.forEach((material_information) => {
            if (
              material_information.material_ref &&
              material_information.material_ref.id === materialId
            ) {
              doughsWaitList.push(dough)
            }
          })
        }
      })
      const doughNameList: NameListMap = {}
      doughsWaitList.forEach((dough) => {
        if (!confirmShopIdList.includes(dough.shop_ref.id)) {
          confirmShopIdList.push(dough.shop_ref.id)
        }
        if (doughNameList.hasOwnProperty(dough.shop_ref.id)) {
          doughNameList[dough.shop_ref.id].push(dough.pettern_name)
        } else {
          doughNameList[dough.shop_ref.id] = [dough.pettern_name]
        }
      })
      const recipesPayload = await dispatch(getAllPublicRecipes())
      const recipes: RecipeType[] = recipesPayload.payload
      const recipesWaitList: RecipeType[] = []
      recipes.forEach((recipe) => {
        if (recipe.dough_material_informations) {
          recipe.dough_material_informations.forEach((material_information) => {
            if (
              material_information.material_ref &&
              material_information.material_ref.id === materialId
            ) {
              recipesWaitList.push(recipe)
            }
          })
        }
        if (recipe.filling_material_informations) {
          recipe.filling_material_informations.forEach(
            (material_information) => {
              if (
                material_information.material_ref &&
                material_information.material_ref.id === materialId
              ) {
                recipesWaitList.push(recipe)
              }
            },
          )
        }
      })
      const recipeNameList: NameListMap = {}
      recipesWaitList.forEach((recipe) => {
        if (!confirmShopIdList.includes(recipe.shop_ref.id)) {
          confirmShopIdList.push(recipe.shop_ref.id)
        }
        if (recipeNameList.hasOwnProperty(recipe.shop_ref.id)) {
          recipeNameList[recipe.shop_ref.id].push(recipe.recipe_name)
        } else {
          recipeNameList[recipe.shop_ref.id] = [recipe.recipe_name]
        }
      })

      const deleteConfirmShopList: DeleteConfirmShop[] = []
      await Promise.all(
        confirmShopIdList.map(async (confirmShopId) => {
          const shopPayload = await dispatch(getShopById({ id: confirmShopId }))
          const shop: ShopType = shopPayload.payload
          const storePayload = await dispatch(
            getStoreByShopId({ shopId: confirmShopId }),
          )
          const store: StoreType = storePayload.payload
          const shopName = store.name
            ? '会社名: ' + shop.name + ' 店舗名: ' + store.name
            : '会社名: ' + shop.name
          deleteConfirmShopList.push({
            shopName: shopName,
            imagePath: '',
            doughNames: doughNameList[confirmShopId]
              ? doughNameList[confirmShopId].join(',')
              : '',
            fillingNames: fillingNameList[confirmShopId]
              ? fillingNameList[confirmShopId].join(',')
              : '',
            recipeNames: recipeNameList[confirmShopId]
              ? recipeNameList[confirmShopId].join(',')
              : '',
          })
        }),
      )
      setDeleteConfirmShopList(deleteConfirmShopList)

      if (
        fillingsWaitList.length > 0 ||
        doughsWaitList.length > 0 ||
        recipesWaitList.length > 0
      ) {
        return true
      } else {
        return false
      }
    },
    [materialId],
  )

  useEffect(() => {
    setDeleteConfirmMaterialInfo(materialId)
  }, [])

  const handleDelete = async () => {
    if (materialId) {
      dispatch(setIsLoading({ isLoading: true }))
      // 関連するbread情報のステータスを更新する
      const updateStatusBreadIdListPayload = await dispatch(
        getTopBreadIdsRecursivelyById({
          id: materialId,
          dataType: DataType.MATERIAL,
        }),
      )
      const updateStatusBreadIdList: string[] =
        updateStatusBreadIdListPayload.payload
      await Promise.all(
        updateStatusBreadIdList.map(async (updateStatusBreadId) => {
          await dispatch(
            updateBackingStickerStatus({
              breadId: updateStatusBreadId,
              backingStickerStatus: BackingStickerStatus.NG,
            }),
          )
        }),
      )
      await dispatch(deleteMaterial({ id: materialId }))
      await dispatch(getMaterials())
      navigate('/material')
      dispatch(setIsLoading({ isLoading: false }))
    }
  }

  return (
    <div className={styles.container}>
      <PageHedding title="原材料使用店舗の確認" to="" />
      <div className={styles.material_delete_confirm_attention}>
        <PageHeadAttention description="削除すると、この原材料を使用している商品の裏貼りシールの出力を行うことが出来なくなります。必ず下記リストに表示している使用店舗をご確認いただいてから削除を行ってください。" />
      </div>
      <div className={styles.material_delete_confirm_shop}>
        <div className={styles.material_delete_confirm_shop_count}>
          {deleteConfirmShopList.length}件の店舗を表示中
        </div>
        <div className={styles.material_delete_confirm_shop_list}>
          <Scrollbars style={{ width: 1002, height: 484 }}>
            {/* {bodies.map((bodyRow, i) => (
              <tr className={styles.dashboardTableBodyRow} key={i}>
                <div className={styles.dashboardTableBodyRow_start}></div>
                {bodyRow.map((bodyCol, j) => (
                  <td className={categoryStyle(bodyCol.category)} key={j}>
                    {categoryElement(
                      bodyCol.category,
                      bodyCol.elementInfo,
                      i,
                      j,
                      false,
                    )}
                  </td>
                ))}
                <div className={styles.dashboardTableBodyRow_end}></div>
              </tr>
            ))} */}
            {deleteConfirmShopList.map((deleteConfirmShop) => (
              <div className={styles.material_delete_confirm_shop_detail}>
                <img
                  className={styles.material_delete_confirm_shop_detail_icon}
                  src={accountIcon}
                  alt="ユーザーアカウント"
                ></img>
                <div
                  className={styles.material_delete_confirm_shop_detail_text}
                >
                  <div
                    className={
                      styles.material_delete_confirm_shop_detail_text_shop_name
                    }
                  >
                    {deleteConfirmShop.shopName}
                  </div>
                  <div
                    className={
                      styles.material_delete_confirm_shop_detail_text_bread_name
                    }
                  >
                    商品名: {deleteConfirmShop.recipeNames}
                    <br />
                    生地パターン名: {deleteConfirmShop.doughNames}
                    <br />
                    フィリングパターン名: {deleteConfirmShop.fillingNames}
                    <br />
                  </div>
                </div>
              </div>
            ))}
          </Scrollbars>
        </div>
      </div>

      <div className={styles.material_submit}>
        {/* <SubmitButton
          label="保存し中断する"
          color="orange_outlined"
          size="large"
          icon="none"
          onClick={() => handleSubmit('/initload')}
        /> */}
        {/* <span className={styles.breadAddButtonSpacer}></span> */}
        <SubmitButton
          label="キャンセルする"
          color="orange_outlined"
          size="large"
          icon="none"
          onClick={() => navigate('/material')}
        />
        <span className={styles.material_submit_spacer}></span>
        <SubmitButton
          label="削除する"
          color="orange"
          size="large"
          icon="none"
          onClick={handleDelete}
        />
      </div>
    </div>
  )
}

export default MaterialDeleteConfirmTemplate
