import { FC } from 'react'
import Layout from 'components/Layout/Layout'
import BreadDashBoardTemplate from 'components/Templates/BreadDashBoardTemplate'

const BreadDashBoard: FC = () => {
  return (
    <Layout title={'ダッシュボード'} page="BreadDashBoard">
      <BreadDashBoardTemplate />
    </Layout>
  )
}

export default BreadDashBoard
