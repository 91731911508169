import { FC, useState } from 'react'
import styles from 'styles/components/template.module.scss'
import SubmitButton from 'components/Button/SubmitButton'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from 'components/Icons/Logo'
import InputText from 'components/Form/InputText'
import { setIsLoading } from 'reducks/loading/slice'
import { useDispatch } from 'react-redux'
//import { getStaff } from 'reducks/staff/slice'
import { signInWithCustomToken } from 'firebase/auth'
import { auth, functions } from 'fb/index'
import { httpsCallable } from 'firebase/functions'

const SetPasswordTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const search = useLocation().search
  const [password, setPassword] = useState('')
  //  const [email, setEmail] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const query = new URLSearchParams(search)
  const firebaseUid = query.get('id')

  // const getStaffInfo = useCallback(
  //   async (firebaseUid: string) => {
  //     const staff = await dispatch(getStaff({ uid: firebaseUid }))
  //     console.log("gfgf")
  //     console.log(staff)
  //     if (staff && staff.payload && staff.payload.email) {
  //       setEmail(staff.payload.email)
  //     }
  //   },
  //   [dispatch, firebaseUid],
  // )

  // useEffect(() => {
  //   if (firebaseUid) {
  //     getStaffInfo(firebaseUid)
  //   }
  // }, [firebaseUid])

  const handleSubmit = async () => {
    if (password !== passwordConfirm) return
    dispatch(setIsLoading({ isLoading: true }))
    const setPasswordDaserunoStaffs = httpsCallable(
      functions,
      'setPasswordDaserunoStaffs',
    )
    setPasswordDaserunoStaffs({
      firebaseUid,
      password,
    })
      .then(async (result: any) => {
        const user = await signInWithCustomToken(auth, result.data.message)
        if (user.user) {
          navigate('/initload')
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        dispatch(setIsLoading({ isLoading: false }))
      })
  }

  return (
    <div className={styles.login_container}>
      <div className={styles.login_logo}>
        <Logo width={368} />
      </div>
      <div className={styles.login_form_container}>
        <div
          className={styles.login_description_text}
          style={{ fontWeight: 'bold' }}
        >{`パスワードを入力し、「設定する」ボタンを押してください。`}</div>
        {/* <div className={styles.login_form_container_row}>
          <p className={styles.login_form_container_text}>メールアドレス</p>
          <InputText
            required={true}
            type="text"
            size="xlarge"
            value={email}
            placeholder=""
            rightAligned={false}
            isLogin={true}
            readOnly={true}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div> */}
        <div className={styles.login_form_container_row}>
          <p className={styles.login_form_container_text}>パスワード</p>
          <InputText
            required={true}
            type="text"
            size="xlarge"
            value={password}
            placeholder=""
            rightAligned={false}
            isLogin={true}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div className={styles.login_form_container_row}>
          <p
            className={styles.login_form_container_text}
            style={{ whiteSpace: 'break-spaces' }}
          >{`パスワードの
再入力`}</p>
          <InputText
            required={true}
            type="text"
            size="xlarge"
            value={passwordConfirm}
            placeholder=""
            rightAligned={false}
            isLogin={true}
            onChange={(event) => setPasswordConfirm(event.target.value)}
          />
        </div>
      </div>
      <div className={styles.login_description_text}>
        <a
          target="_blank"
          href="https://app.daseruno.jp/terms"
          rel="noreferrer"
        >
          利用規約
        </a>
        および
        <a
          target="_blank"
          href="https://app.daseruno.jp/privacy_policy"
          rel="noreferrer"
        >
          プライバシーポリシー
        </a>
        について
      </div>
      <div className={styles.login_button_container} style={{ marginTop: 40 }}>
        <SubmitButton
          label="同意して登録する"
          color="orange"
          size="semilarge"
          icon="none"
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  )
}

export default SetPasswordTemplate
